import { useContext } from 'react';
import { ContractInfo, EmptyingInterval } from '../../../../model';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { LanguageContext } from '../../../../contexts/language-context';
import { printIntervalStartAndEndDates, printddMMDate } from '../../../../util/calendarUtil';
import { printIntervalBasedOnTimesWeek } from '../../../../util/util';

interface Props {
  contractDetails: ContractInfo | undefined | null;
}

const ContractServiceInformationSection = ({ contractDetails }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const theme = useTheme();
  const largerThanPhone = useMediaQuery(theme.breakpoints.up('md'));

  if (!contractDetails) {
    return <></>;
  }

  // \n doesn't seem to work without whiteSpace: pre-wrap
  const renderEmptyingInterval = (interval: EmptyingInterval): JSX.Element => {
    return (
      <Typography
        key={interval.startWeek + '-' + interval.endWeek + '-' + interval.interval}
        sx={{ whiteSpace: largerThanPhone ? 'normal' : 'pre-wrap' }}
        variant='body1'
        color={theme.palette.text.primary}
        marginBottom={largerThanPhone ? 0 : 1}
      >
        {printIntervalBasedOnTimesWeek(getText, interval.interval, interval.amountPerWeek) +
          ', ' +
          '\n' +
          printIntervalStartAndEndDates(new Date().getFullYear(), interval.startWeek, interval.endWeek)}
      </Typography>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.level2,
        paddingX: 4,
        paddingY: 4,
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Grid container direction={largerThanPhone ? 'row' : 'column'} wrap='nowrap'>
        <Grid item xs={12} sm={5}>
          <Typography marginBottom={3} variant='h5'>
            {getText('emptying-location-contract-information')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={7}>
          <Grid container wrap={largerThanPhone ? 'nowrap' : 'wrap'}>
            <Grid item xs={12} sm={6}>
              <Typography component={'div'} variant='body2' color={theme.palette.text.secondary} marginBottom={2}>
                {getText('contract-details-num-of-bins')}
                <Typography variant='body1' color={theme.palette.text.primary}>
                  {contractDetails.amount}
                </Typography>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography component={'div'} variant='body2' color={theme.palette.text.secondary}>
                {getText('order-ordered-label')}
                <Typography variant='body1' color={theme.palette.text.primary}>
                  {printddMMDate(contractDetails.startDate)}
                </Typography>
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={0} sm={6} />
          {contractDetails.emptyingIntervals.length > 0 && !contractDetails.readonlyContract && (
            <Grid item xs={12} sm={12}>
              <Typography
                component={'div'}
                variant='body2'
                color={theme.palette.text.secondary}
                marginTop={largerThanPhone ? 0 : 3}
              >
                {getText('contract-details-emptying-intervals')}
                {contractDetails.emptyingIntervals.map((x) => renderEmptyingInterval(x))}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContractServiceInformationSection;
