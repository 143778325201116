import React, { useContext } from 'react';
import colors from '../../../colors';
import { LanguageContext } from '../../../contexts/language-context';
import { Product } from '../../../model';
import {
  AttachedContent,
  BasketTotalPrice,
  Column,
  SpacedRow,
} from '../../common/containers';
import { TertiaryHeader } from '../../common/headers';
import { ConvertToCurrentLocale } from '../../../util/numberLocalization';

type TotalPricesProps = {
  products: Product[];
};

const TotalPrices = ({ products }: TotalPricesProps): JSX.Element => {
  const { getText } = useContext(LanguageContext);

  return (
    <Column>
      <AttachedContent>
        <SpacedRow>
          <TertiaryHeader style={{ color: colors.lightLabel }}>
            {getText('service-order-alv')}
          </TertiaryHeader>
          {ConvertToCurrentLocale(products
            .map(x => x.alv * x.amount)
            .reduce((a, b) => a + b),
            2
          )}
          &euro;
        </SpacedRow>
      </AttachedContent>

      <AttachedContent>
        <SpacedRow>
          <TertiaryHeader>{getText('service-order-total-sum')}</TertiaryHeader>
          <BasketTotalPrice>
            {ConvertToCurrentLocale(products
              .map(x => x.price * x.amount)
              .reduce((a, b) => a + b),
              2)}
            &euro;
          </BasketTotalPrice>
        </SpacedRow>
      </AttachedContent>
    </Column>
  );
};

export default TotalPrices;
