import { TableBody, TableCell, TableRow, Typography, useTheme } from "@mui/material";
import { ReactElement } from "react";
import { AligntmentStrings, Row } from "./interfaces";

interface DataTableBodyProps<T> {
  alignments: AligntmentStrings[];
  customRows?: JSX.Element[];
  rowKeys: string[];
  rows?: T[];
}

export const DataTableBody = <T extends Row>({
  alignments, customRows, rowKeys, rows
}: DataTableBodyProps<T>): ReactElement | null => {
  const { palette } = useTheme();
  const defaultAlign = "center";
  return (
    <TableBody>
      {
        customRows ?
          customRows
          :
          rows?.map((row) => (
            <TableRow>
              {
                rowKeys.map((key, idx: number) => {
                  if (key === "rId") {
                    return null;
                  }
                  return (
                    <TableCell
                      sx={{
                        borderTop: `1px solid ${palette.divider}`,
                        borderBottom: `1px solid ${palette.divider}`,
                      }}
                      align={alignments[idx] ?? defaultAlign}
                    >
                      <Typography color={palette.text.primary}>{row[key]}</Typography>
                    </TableCell>
                  );
                })
              }
            </TableRow>
          ))
      }
    </TableBody>
  );
};