import React, { useEffect } from 'react';
import FormThemeProvider from '../../components/common/FormThemeProvider';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { FormControl, FormLabel } from '@material-ui/core';
import { getUiSchemaTitle } from './utils';

const CustomNullField = function (props: any) {
  const [label, setLabel] = React.useState<string>(props.label);
  const theme = FormThemeProvider;

  useEffect(() => {
    setLabel(getUiSchemaTitle(props));
  }, [props.formContext.formId, props.formContext.language]);

  return (
    <MuiThemeProvider theme={theme}>
      <FormControl required={props.required}>
        <FormLabel>{label}</FormLabel>
      </FormControl>
    </MuiThemeProvider>
  );
};

export default CustomNullField;
