import { Controller, FieldError, FieldValues, UseControllerProps } from 'react-hook-form';
import { UiTexts } from '../../../model';
import { Validation, validateInput } from '../../common/input/validation';
import FormErrorField from './FormErrorField';
import DropdownSelect, { ValueText } from '../DropdownSelect';
import { useContext } from 'react';
import { LanguageContext } from '../../../contexts/language-context';

interface Props<T extends FieldValues> extends UseControllerProps<T> {
  error: FieldError | undefined;
  label: keyof UiTexts;
  validations: Validation[];
  dropdownItems: ValueText[];
  disabled?: boolean;
}

const ControlDropdownInput = <T extends FieldValues>({
  control,
  label,
  name,
  error,
  validations,
  dropdownItems,
}: Props<T>) => {
  const { getText } = useContext(LanguageContext);

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: { validateFunc: (v) => validateInput(v, validations)[0] },
      }}
      render={({ field: { onChange, value } }) => (
        <>
          <DropdownSelect label={getText(label)} items={dropdownItems} value={value} onChange={onChange} />
          <FormErrorField errorMsg={error?.message} />
        </>
      )}
    />
  );
};

export default ControlDropdownInput;
