import { Box, FormControl, FormControlLabel, Radio, RadioGroup, } from '@mui/material';
import { useContext } from 'react';
import { LanguageContext } from '../../../../contexts/language-context';
import { WIDTH_DESKTOP, WIDTH_MOBILE } from '../models';
import { LargerThanBreakpoint } from '../../../../util/viewportUtils';

interface Props {
  radioValue: "company" | "private";
  setRadioValue: React.Dispatch<React.SetStateAction<"company" | "private">>;
}

const CustomerTypeSection = ({ radioValue, setRadioValue }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const width = LargerThanBreakpoint('md') ? WIDTH_DESKTOP : WIDTH_MOBILE;

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setRadioValue(e.target.value as "company" | "private");
  }

  return (
    <Box width={width}>
      <FormControl>
        <RadioGroup
          aria-labelledby='radio-group-for-selecting-private-or-company'
          value={radioValue}
          onChange={handleChange}
        >
          <FormControlLabel value="private" control={<Radio />} label={getText('user-account-private-customer-desc')} />
          <FormControlLabel value="company" control={<Radio />} label={getText('basic-info-company')} />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default CustomerTypeSection;
