import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  FormEvent,
} from 'react';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '../../../contexts/language-context';
import { ProductCategory, VingoProduct } from '../../../model';
import {
  Column,
  CompactColumn,
  ContentRow,
  Form,
  FormContent,
  FormTop,
  Row,
} from '../../common/containers';
import Header from '../../common/Header';
import { SecondaryHeader, TertiaryHeader } from '../../common/headers';
import TextInput from '../../common/TextInput';
import DropDown from '../../common/DropDown';
import OrderButton from './OrderButton';
import ApiContext from '../../../contexts/api-context';
import { Checkbox, RadioButton } from '../../common/inputs';
import DateInput from '../../common/DateInput';
import { getWasteTypesAndServicesFromContractsCategory } from './order-contract/orderContractLogic';
import DialogContext from '../../../contexts/dialog-context';

type DumsterProps = {
  match: {
    params: {
      id: string;
      categoryid: string;
    };
  };
  location: {
    state: { productCategory: ProductCategory; };
  };
};

type WasteType = 'bio' | 'mixed' | 'energy';

const Dumpster = (props: DumsterProps): JSX.Element => {
  const history = useHistory();
  const { getText } = useContext(LanguageContext);
  const api = useContext(ApiContext);
  const showDialog = useContext(DialogContext);

  const [productGuid, setProductGuid] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [wasteType, setWasteType] = useState<string>('bio');
  const [date, setDate] = useState<Date>(new Date());
  const [pickupDate, setPickupDate] = useState<Date>(new Date());

  const [wasteTypesAndProducts, setWasteTypesAndProducts] = useState<Map<
    string,
    Set<VingoProduct>
  > | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<VingoProduct | null>(null);
  const [availableProducts, setAvailableProducts] = useState<Set<VingoProduct>>(
    new Set()
  );

  const [unknownPickupDate, setUnknownPickupDate] = useState<boolean>(false);
  const [dumpsterLocation, setDumpsterLocation] = useState<string>('');
  const [dumpsterSize, setDumpsterSize] = useState<string>('small');
  const [dumpsterAmount, setDumpsterAmount] = useState<string>('1');
  const [additionalInfo, setAdditionalInfo] = useState<string>('');

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const componentIsMounted = useRef(true);

  useEffect(() => {
    api
      .fetchCategoryWithProducts(
        props.match.params.id,
        props.match.params.categoryid
      )
      .then((category) => {
        if (componentIsMounted) {
          setWasteTypesAndProducts(
            getWasteTypesAndServicesFromContractsCategory(category)
          );
        }
      })
      .catch((err: unknown) => {
        showDialog('error-service-break-title', 'error-service-break-message');
      });

    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const setProducts = ([_wasteTypeName, products]: [
    string,
    Set<VingoProduct>
  ]) => {
    setAvailableProducts(products);
  };

  useEffect(() => {
    if (wasteTypesAndProducts != null) {
      Array.from(wasteTypesAndProducts).forEach(setProducts);
    }
  }, [wasteTypesAndProducts]);

  useEffect(() => {
    if (availableProducts != null) {
      setSelectedProduct(availableProducts.values().next().value);
    }
  }, [availableProducts]);

  useEffect(() => {
    if (selectedProduct != null) {
      setName(selectedProduct.name ?? '');
      setProductGuid(selectedProduct.id ?? '');
    }
  }, [selectedProduct]);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    const dumpsterInfo = {
      name: name,
      amount: Number(dumpsterAmount),
      productGuid: productGuid,
      extraInfo: additionalInfo + ' ' + wasteType + ' ' + dumpsterSize,
      containerLocation: dumpsterLocation,
      deliveryTime: date,
      // pickupTime: pickupDate, throws 500 if enabled currently
      // TODO: Assign waste type to below once related fixed
      // wasteTypes: [],
      orderConfirmation: true
    };

    api
      .addDumpsterOrders(props.match.params.id, dumpsterInfo)
      .then(() => {
        showDialog(
          'message-success-generic-title',
          'add-new-contract-success-message',
          () => history.goBack()
        );
      })
      .catch((err: unknown) => {
        showDialog('error-service-break-title', 'error-service-break-message');
        setIsLoading(false);
      })
      .finally(() => {
        if (componentIsMounted.current) {
          setIsLoading(false);
        }
      });
  };

  const selectDumsterSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDumpsterSize(e.target.value);
  };

  const selectWasteType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setWasteType(e.target.value as WasteType);
  };

  const renderProductSelection = (product: VingoProduct): JSX.Element => {
    return (
      <ContentRow key={product.id} style={{ marginTop: '0.5rem' }}>
        <RadioButton
          type="radio"
          name="product-selection"
          id={product.id}
          checked={product.id === selectedProduct?.id ?? ""}
          onChange={() => setSelectedProduct(product)}
        />
        <label htmlFor={product.id}>{product.name}</label>
      </ContentRow>
    );
  };

  return (
    <Column style={{ marginBottom: '5rem' }}>
      <Header
        headerKey="service-order-pickup-title"
        descriptionKey="service-order-pickup-description"
        backButton={true}
      ></Header>
      <CompactColumn>
        <Form onSubmit={handleSubmit}>
          <FormTop>
            <SecondaryHeader>
              {getText('service-order-dumpster')}
            </SecondaryHeader>
          </FormTop>

          <FormContent>
            <TertiaryHeader>
              {getText('add-contract-service-title')}
            </TertiaryHeader>
            {
              Array.from(availableProducts).map(renderProductSelection)
            }
          </FormContent>

          <FormContent>
            <TertiaryHeader>
              {getText('service-order-importation-date')}
            </TertiaryHeader>
            <DateInput
              date={date}
              onChange={(d) => setDate(d)}
              required={true}
              showError={isSubmitted}
            />
          </FormContent>
          <FormContent>
            <TertiaryHeader>
              {getText('service-order-pickup-date')}
            </TertiaryHeader>

            <Row style={{ marginTop: '1.2rem', marginBottom: '0.5rem' }}>
              <Checkbox
                type="checkbox"
                id="unknown-pickup-checkbox"
                onChange={() => setUnknownPickupDate(!unknownPickupDate)}
              />
              <label htmlFor="unknown-pickup-checkbox">
                {getText('service-order-dumpster-unknown-pickup-date')}
              </label>
            </Row>
            {unknownPickupDate ? null : (
              <DateInput
                date={pickupDate}
                onChange={(d) => setPickupDate(d)}
                required={true}
                showError={isSubmitted}
              />
            )}
          </FormContent>

          <FormContent>
            <TertiaryHeader>
              {getText('service-order-dumpster-location')}
            </TertiaryHeader>

            <TextInput
              val={dumpsterLocation}
              setter={setDumpsterLocation}
              validations={['required']}
              showErrors={isSubmitted}
            />
          </FormContent>

          <FormContent>
            <TertiaryHeader>
              {getText('service-order-dumpster-size')}
            </TertiaryHeader>

            <DropDown
              value={dumpsterSize}
              // TODO options should be fetched from endpoint
              options={[
                ['small', getText('service-order-dumpster-size-small')],
                ['medium', getText('service-order-dumpster-size-medium')],
                ['big', getText('service-order-dumpster-size-big')],
              ]}
              onChange={selectDumsterSize}
            />

            <TextInput
              val={dumpsterAmount}
              inputType="number"
              setter={setDumpsterAmount}
              showErrors={isSubmitted}
              validations={['required', 'integer']}
            />
          </FormContent>

          <FormContent>
            <TertiaryHeader>
              {getText('service-order-dumpster-waste-type')}
            </TertiaryHeader>
            <DropDown
              value={wasteType}
              // TODO options should be fetched from endpoint
              options={[
                ['bio', getText('service-order-dumpster-waste-type-bio')],
                ['mixed', getText('service-order-dumpster-waste-type-mixed')],
                ['energy', getText('service-order-dumpster-waste-type-energy')],
              ]}
              onChange={selectWasteType}
            />
          </FormContent>

          <FormContent>
            <TertiaryHeader>
              {getText('service-order-pickup-additional-info')}
            </TertiaryHeader>
            <TextInput
              multiline={true}
              val={additionalInfo}
              maxLength={500}
              setter={setAdditionalInfo}
              showErrors={isSubmitted}
            />
          </FormContent>

          <OrderButton
            label="service-order-button"
            isLoading={isLoading}
            action={() => setIsSubmitted(true)}
          />
        </Form>
      </CompactColumn>
    </Column>
  );
};

export default Dumpster;
