import { useContext, useState, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Popover } from '@material-ui/core';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { UiTexts } from '../../model';
import { LanguageContext } from '../../contexts/language-context';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import { DEFAULT_BREAKPOINTS } from '../../themes/constants';
import fiLocale from 'date-fns/locale/fi';
import svLocale from 'date-fns/locale/sv';
import enLocale from 'date-fns/locale/en-GB';
import './ResponsiveCalendar.css';
import 'react-datepicker/dist/react-datepicker.css';

interface CalendarHeaderInterface {
  date: Date;
  changeYear(year: number): void;
  changeMonth(month: number): void;
  customHeaderCount: number;
  decreaseMonth(): void;
  increaseMonth(): void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  decreaseYear(): void;
  increaseYear(): void;
  prevYearButtonDisabled: boolean;
  nextYearButtonDisabled: boolean;
}

interface CurrentDateCalendarInterface {
  currentDate: CalendarHeaderInterface;
}

interface ResponsiveCalendarProps {
  required?: boolean;
  inlineCalendar?: boolean;
  popoverHeaderTextKey?: keyof UiTexts;
  availableDates?: Date[];
  onChange: (date: Date) => void;
  renderPopoverContent?: () => JSX.Element;
  renderDayContents?: (day: number, date: Date) => JSX.Element;
}

const mappedMonthsToTranslation = (month: number): keyof UiTexts => {
  switch (month) {
    case 0:
      return 'calendar-jan';
    case 1:
      return 'calendar-feb';
    case 2:
      return 'calendar-mar';
    case 3:
      return 'calendar-apr';
    case 4:
      return 'calendar-may';
    case 5:
      return 'calendar-jun';
    case 6:
      return 'calendar-jul';
    case 7:
      return 'calendar-aug';
    case 8:
      return 'calendar-sep';
    case 9:
      return 'calendar-oct';
    case 10:
      return 'calendar-nov';
    case 11:
      return 'calendar-dec';
    default:
      return 'error-general-title';
  }
};

const ContractCalendarHeader = ({ currentDate }: CurrentDateCalendarInterface) => {
  const theme = useTheme();
  const { getText } = useContext(LanguageContext);
  const largerThanPhone = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      sx={{
        display: 'flex',
        direction: 'row',
        justifyContent: 'space-between',
        paddingRight: largerThanPhone ? 4 : 2,
        paddingLeft: largerThanPhone ? 4 : 2,
      }}
    >
      <Typography component={'div'} variant='body2' color={theme.palette.text.secondary} marginBottom={2}>
        {`${getText(mappedMonthsToTranslation(currentDate.date.getMonth()))} ${currentDate.date.getFullYear()}`}{' '}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '50px' }}>
        <Box sx={{ cursor: 'pointer' }} onClick={() => currentDate.decreaseMonth()}>
          {<ArrowBackIcon />}
        </Box>
        <Box sx={{ cursor: 'pointer' }} onClick={() => currentDate.increaseMonth()}>
          {<ArrowForwardIcon />}
        </Box>
      </Box>
    </Box>
  );
};

const ResponsiveCalendar = (props: ResponsiveCalendarProps): JSX.Element => {
  const { onChange, required, inlineCalendar, renderDayContents, renderPopoverContent, popoverHeaderTextKey } = props;
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const { lang, getText } = useContext(LanguageContext);

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedDate(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getLocale = () => {
    let locale = null;
    switch (lang) {
      case 'fi':
        locale = fiLocale;
        break;
      case 'sv':
        locale = svLocale;
        break;
      default:
        locale = enLocale;
    }
    return locale;
  };

  const handleChange = (date: Date, event: any): void => {
    const { availableDates } = props;

    onChange(date);

    availableDates?.length &&
      availableDates.forEach((availableDate) => {
        if (availableDate.toISOString().split('T')[0] === date.toISOString().split('T')[0]) {
          setSelectedDate(date);
          setAnchorEl(event.target);
        }
      });
  };

  const getResponsiveClassName = (): string => {
    if (windowWidth <= DEFAULT_BREAKPOINTS.md) {
      return 'custom-day-small';
    } else if (windowWidth <= DEFAULT_BREAKPOINTS.lg) {
      return 'custom-day-large';
    } else {
      return 'custom-day-very-large';
    }
  };

  const getHeaderMargin = () => {
    if (windowWidth <= DEFAULT_BREAKPOINTS.md) {
      return '1rem';
    } else if (windowWidth <= DEFAULT_BREAKPOINTS.lg) {
      return '1.5rem';
    }
    return '2rem';
  };

  return (
    <Box className={'responsive-calendar'}>
      <ReactDatePicker
        fixedHeight
        inline={inlineCalendar}
        readOnly={inlineCalendar}
        onChange={handleChange}
        locale={getLocale()}
        required={required}
        withPortal={!inlineCalendar}
        renderCustomHeader={(currentDate) => <ContractCalendarHeader currentDate={currentDate} />}
        dayClassName={getResponsiveClassName}
        weekDayClassName={getResponsiveClassName}
        renderDayContents={renderDayContents}
      />
      {selectedDate && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            {popoverHeaderTextKey && (
              <Typography variant={'h5'} paddingLeft={getHeaderMargin()} paddingTop={2}>
                {getText(popoverHeaderTextKey)}
              </Typography>
            )}
            <IconButton onClick={handlePopoverClose} aria-label='close' style={{ marginTop: 5, marginRight: 5 }}>
              <CloseIcon />
            </IconButton>
          </Box>

          {renderPopoverContent && renderPopoverContent()}
        </Popover>
      )}
    </Box>
  );
};

export default ResponsiveCalendar;
