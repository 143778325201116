import React, { useState, useContext, useEffect, useRef, FormEvent } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import ApiContext from '../../contexts/api-context';
import AuthContext from '../../contexts/auth-context';
import DialogContext from '../../contexts/dialog-context';
import { LanguageContext } from '../../contexts/language-context';
import { AddExtraEmptyingReq } from '../../model';
import { tomorrow } from '../../util/calendarUtil';
import { Column, CompactColumn, Content, Form, FormContent, FormTop, Row } from '../common/containers';
import DateInput from '../common/DateInput';
import Header from '../common/Header';
import { SecondaryHeader, TertiaryHeader } from '../common/headers';
import { Checkbox } from '../common/inputs';
import TextInput from '../common/TextInput';
import VingoButton from '../common/VingoButton';
import { ContractInfo } from '../../model';
import Spinner from '../common/Spinner';
import Terms from '../common/Terms';

type ExtraEmptyingProps = {
  match: {
    params: {
      customerNumber: string;
      position: string;
    };
  };
};

const ExtraEmptying = (props: ExtraEmptyingProps): JSX.Element => {
  const history = useHistory();
  const { getText } = useContext(LanguageContext);
  const api = useContext(ApiContext);
  const showDialog = useContext(DialogContext);
  const { customerNumber, position } = props.match.params;

  const [date, setDate] = useState<Date>(tomorrow());
  const [numOfBins, setNumOfBins] = useState<string>('1');
  const [additionalInfo, setAdditionalInfo] = useState<string>('');
  const [iHaveReadConditions, setIHaveReadConditions] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const componentIsMounted = useRef(true);
  const [contract, setContract] = useState<ContractInfo | null>(null);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    api
      .fetchContractDetails(customerNumber, parseInt(position))
      .then((contractDetails) => {
        if (componentIsMounted.current) {
          setContract(contractDetails);
        }
      })
      .catch((err: any) => {
        if (err.statusCode === 401) {
          showDialog('error-unauthorized-title', 'error-unauthorized-message', () => history.replace('/logout'));
        } else {
          showDialog('error-service-break-title', 'error-service-break-message');
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    const body: AddExtraEmptyingReq = {
      emptyingDate: new Date(date),
      amount: parseInt(numOfBins),
      extraInfo: additionalInfo,
      orderConfirmation: iHaveReadConditions,
    };

    api
      .addExtraEmptying(customerNumber, position, body)
      .then(() => {
        showDialog('message-success-generic-title', 'add-new-contract-success-message', () => history.goBack());
      })
      .catch((err: any) => {
        console.error(err);
        if (err.statusCode === 401) {
          showDialog('error-unauthorized-title', 'error-unauthorized-message', () => history.replace('/logout'));
        } else {
          console.error(err);
          showDialog('customer-service-contact-us-title', 'add-new-contract-failed-message', () => history.goBack());
        }
      })
      .finally(() => {
        if (componentIsMounted.current) {
          setIsLoading(false);
        }
      });
  };

  const renderConditionsAndOrderButton = () => {
    return (
      <Content>
        <Row style={{ marginTop: '1.2rem', marginBottom: '0.5rem' }}>
          <Terms
            type='contract'
            iHaveReadConditions={iHaveReadConditions}
            setIHaveReadConditions={setIHaveReadConditions}
          />
        </Row>
        <VingoButton onClick={() => setIsSubmitted(true)} disabled={!iHaveReadConditions} isLoading={isLoading}>
          {getText('extra-emptying-order-button')}
        </VingoButton>
      </Content>
    );
  };

  if (contract) {
    return (
      <Column>
        <Header
          headerKey='extra-emptying-order'
          descriptionElement={<div>{contract.name}</div>}
          backButton={true}
        ></Header>
        <CompactColumn>
          <Form onSubmit={handleSubmit}>
            <FormTop>
              <SecondaryHeader>{getText('extra-emptying-form-title')}</SecondaryHeader>
            </FormTop>
            <FormContent>
              <TertiaryHeader>{getText('extra-emptying-date')}</TertiaryHeader>

              <DateInput
                date={date}
                onChange={(d) => setDate(d)}
                required={true}
                showError={isSubmitted}
                minDate={tomorrow()}
              />
            </FormContent>
            <FormContent>
              <TertiaryHeader>
                {getText('extra-emptying-num-of-bins')}
                <TextInput
                  val={numOfBins}
                  setter={setNumOfBins}
                  inputType='number'
                  validations={['required', 'gt_zero']}
                  showErrors={isSubmitted}
                />
              </TertiaryHeader>
            </FormContent>
            <FormContent>
              <TertiaryHeader>
                {getText('extra-emptying-additional-info')}
                <TextInput
                  multiline={true}
                  val={additionalInfo}
                  maxLength={500}
                  setter={setAdditionalInfo}
                  placeholder='extra-emptying-additional-info-placeholder'
                  showErrors={isSubmitted}
                />
              </TertiaryHeader>
            </FormContent>
            {renderConditionsAndOrderButton()}
          </Form>
        </CompactColumn>
      </Column>
    );
  } else {
    return (
      <Column>
        <Header backButton={true} />
        <CompactColumn>
          <Spinner />
        </CompactColumn>
      </Column>
    );
  }
};

export default ExtraEmptying;
