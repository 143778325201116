import { FC, useContext } from 'react';
import { Control, FieldErrors, UseFormGetValues } from 'react-hook-form';
import { Stack } from '@mui/material';
import { SmallerThanBreakpoint } from '../../../../util/viewportUtils';
import { EditBillingInfoFormData } from './EditBillingInfo';
import ControlTextInput from '../../../../components/common-materialui/form/ControlTextInput';
import AuthContext from '../../../../contexts/auth-context';

interface Props {
  control: Control<EditBillingInfoFormData, any>;
  getFormValues: UseFormGetValues<EditBillingInfoFormData>;
  errors: FieldErrors<EditBillingInfoFormData>;
}
const EditBillingInfoContactPersonForm: FC<Props> = ({ control, getFormValues, errors }) => {
  const stackWidth = SmallerThanBreakpoint('md') ? '100%' : '50%';
  const authService = useContext(AuthContext);
  const readOnly = authService.isReadOnlyBillingAddressEnabled();
  const disableContactName = !authService.isModifyCompanyBillingContactPersonNameEnabled();
  const isCompany = getFormValues('isCompany');

  return (
    <Stack sx={{ width: stackWidth }}>
      <ControlTextInput
        control={control}
        name='contactFirstname'
        label='billing-data-billing-contact-person-firstname'
        error={errors.contactFirstname}
        validations={['required']}
        disabled={readOnly || (disableContactName && isCompany)}
      />
      <ControlTextInput
        control={control}
        name='contactLastname'
        label='billing-data-billing-contact-person-lastname'
        error={errors.contactLastname}
        validations={['required']}
        disabled={readOnly || (disableContactName && isCompany)}
      />
      <ControlTextInput
        control={control}
        name={'phoneNumber'}
        label='billing-data-billing-contact-person-phonenumber'
        error={errors.phoneNumber}
        validations={['required', 'phone']}
        disabled={readOnly}
      />
      <ControlTextInput
        control={control}
        name='email'
        label='billing-data-billing-contact-person-email'
        error={errors.email}
        validations={['email', 'required']}
        disabled={readOnly}
      />
    </Stack>
  );
};

export default EditBillingInfoContactPersonForm;
