import React, { Dispatch, SetStateAction, useContext } from 'react';
import { Content } from '../../common/containers';
import { SecondaryHeader } from '../../common/headers';
import TextInput from '../../common/TextInput';
import { LanguageContext } from '../../../contexts/language-context';

type InputProps = {
  companyName: string;
  setCompanyName: Dispatch<SetStateAction<string>>;
  phone: string;
  setPhone: Dispatch<SetStateAction<string>>;
  companyId: string;
  setCompanyId: Dispatch<SetStateAction<string>>;
  disableCompanyId?: boolean;
  isSubmitted: boolean;
};

const CompanyBasicInfo = (props: InputProps): JSX.Element => {
  const {
    companyName,
    setCompanyName,
    phone,
    setPhone,
    companyId,
    setCompanyId,
    disableCompanyId,
    isSubmitted,
  } = props;
  const { getText } = useContext(LanguageContext);

  return (
    <Content>
      <SecondaryHeader>{getText('basic-info')}</SecondaryHeader>
      <TextInput
        label="basic-info-customer-name"
        val={companyName}
        setter={setCompanyName}
        validations={['required']}
        showErrors={isSubmitted}
      />
      <TextInput
        label="basic-info-phone"
        val={phone}
        setter={setPhone}
        validations={['required']}
        showErrors={isSubmitted}
      />
      <TextInput
        label="basic-info-company-id"
        val={companyId}
        setter={setCompanyId}
        disabled={disableCompanyId}
        validations={['required', 'company-id']}
        showErrors={isSubmitted}
        maxLength={9}
      />
    </Content>
  );
};

export default CompanyBasicInfo;
