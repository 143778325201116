import React, { useState, useEffect, useMemo, useContext, useCallback } from 'react';
import {
  Box,
  Stack,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  FormControl,
  Divider,
  SelectChangeEvent,
  Typography,
  useTheme,
  Tooltip,
} from '@mui/material';
import { WeekInterval } from '../../OrderProduct';
import DropdownSelect from '../../../../../components/common-materialui/DropdownSelect';
import AuthContext from '../../../../../contexts/auth-context';
import { EmptyingInterval, UiTexts } from '../../../../../model';
import { printIntervalBasedOnTimesWeek } from '../../../../../util/util';
import ExtendedAlert from '../../../../../components/common-materialui/status-handling/ExtendedAlert';
import {
  CONSTANTS,
  autofillSecondDateRange,
  calculateAllowedIntervals,
  handleWeekOverlaps,
} from './WeekIntervalSelectorUtils';
import { WeeksPerDayToggleButton } from './WeekIntervalTimesPerWeek';
import { set } from 'lodash';

interface WeekIntervalSelectorWeeklyRhythmProps {
  allowedIntervals: Array<{ startWeek: number; endWeek: number; intervals: number[] }>;
  alertErrorMsgFirstInterval: keyof UiTexts | null;
  alertErrorMsgSecondInterval: keyof UiTexts | null;
  initialIntervals?: EmptyingInterval[]; // optional, used if provided
  weekIntervalFinishOrder: WeekInterval[];
  setAlertErrorMsgFirstInterval: React.Dispatch<React.SetStateAction<keyof UiTexts | null>>;
  setAlertErrorMsgSecondInterval: React.Dispatch<React.SetStateAction<keyof UiTexts | null>>;
  setSingleInterval: React.Dispatch<React.SetStateAction<boolean>>;
  setWeekIntervalFinishOrder: (param: any) => any;
  singleInterval: boolean;
  getText: (key: keyof UiTexts, params?: string[] | undefined) => string;
}

const WeekIntervalSelectorWeeklyRhythm: React.FC<WeekIntervalSelectorWeeklyRhythmProps> = ({
  allowedIntervals,
  alertErrorMsgFirstInterval,
  alertErrorMsgSecondInterval,
  initialIntervals,
  weekIntervalFinishOrder,
  setAlertErrorMsgFirstInterval,
  setAlertErrorMsgSecondInterval,
  setSingleInterval,
  setWeekIntervalFinishOrder,
  singleInterval,
  getText,
}) => {
  const [startWeek, setStartWeek] = useState<string | undefined>(
    initialIntervals?.[0]?.startWeek?.toString() ?? CONSTANTS.WEEK.START.toString()
  );
  const [endWeek, setEndWeek] = useState<string | undefined>(
    initialIntervals?.[0]?.endWeek?.toString() ?? CONSTANTS.WEEK.END.toString()
  );

  const [secondStartWeek, setSecondStartWeek] = useState<string | undefined>(
    initialIntervals?.[1]?.startWeek?.toString() ?? undefined
  );
  const [secondEndWeek, setSecondEndWeek] = useState<string | undefined>(
    initialIntervals?.[1]?.endWeek?.toString() ?? undefined
  );
  const [allowedIntervalsFirstInterval, setAllowedIntervalsFirstInterval] = useState<number[]>([]);
  const [allowedIntervalsSecondInterval, setAllowedIntervalsSecondInterval] = useState<number[]>([]);
  const [showSecondDropdowns, setShowSecondDropdowns] = useState<boolean>(false);

  // Only re-run the effect if initialIntervals changes
  useEffect(() => {
    // If the the second dropdown was closed manually, keep it closed
    if (singleInterval) {
      setShowSecondDropdowns(false);
    } else if (initialIntervals?.[1]?.startWeek) {
      setShowSecondDropdowns(true);
    }
  }, [initialIntervals, singleInterval]);

  // Frequency (how many weeks between each emptying) : also used saved values if available
  const [firstIntervalRadioValue, setFirstIntervaRadioValue] = useState<string>(
    initialIntervals?.[0]?.interval?.toString() ?? ''
  );
  const [secondIntervalRadioValue, setSecondIntervaRadioValue] = useState<string>(
    initialIntervals?.[1]?.interval?.toString() ?? ''
  );

  // Times per week : also used saved values if available
  const [firstTimesPerWeek, setFirstTimesPerWeek] = useState<string>(
    initialIntervals?.[0]?.amountPerWeek?.toString() ?? '1'
  );
  const [secondTimesPerWeek, setSecondTimesPerWeek] = useState<string>(
    initialIntervals?.[1]?.amountPerWeek?.toString() ?? '1'
  );

  const [displayWeeksOverlapsError, setDisplayWeeksOverlapsError] = useState<boolean>(false);

  const authService = useContext(AuthContext);

  const { palette } = useTheme();

  const excludedWeeks: number[] = useMemo(() => {
    const weeks = [];
    if (startWeek && endWeek) {
      for (let i = Number(startWeek); i <= Number(endWeek); i++) {
        weeks.push(i);
      }
    }
    return weeks;
  }, [startWeek, endWeek]);

  const generateWeekArray = (excludedWeeks: number[] = []): { value: string; text: string }[] => {
    const arr = [];
    for (let i = CONSTANTS.WEEK.START; i <= CONSTANTS.WEEK.END; i++) {
      if (!excludedWeeks.includes(i)) {
        arr.push({
          value: i.toString(),
          text: `${getText('order-product-emptying-rhythm-week-text')} ${i.toString()}`,
        });
      }
    }
    return arr;
  };

  useEffect(() => {
    if (!secondStartWeek && !secondEndWeek) {
      if (startWeek && endWeek && !singleInterval) {
        autofillSecondDateRange({ startWeek, endWeek, setSecondStartWeek, setSecondEndWeek });
      }
    }
  }, [startWeek, endWeek, secondStartWeek, secondEndWeek, singleInterval]);

  useEffect(() => {
    // Prepopulate week intervals from parent component state
    weekIntervalFinishOrder?.forEach((week: WeekInterval, id: number) => {
      if (id === 0) {
        setStartWeek(week.startWeek);
        setEndWeek(week.endWeek);
        setFirstIntervaRadioValue(week.interval);
        setFirstTimesPerWeek(week.timesPerWeek);
      } else {
        setShowSecondDropdowns(true);
        setSecondStartWeek(week.startWeek);
        setSecondEndWeek(week.endWeek);
        setSecondIntervaRadioValue(week.interval);
        setSecondTimesPerWeek(week.timesPerWeek);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Save the week intervals to parent component state
  useEffect(() => {
    const weekIntervalData: WeekInterval[] = [];
    if (startWeek && endWeek) {
      weekIntervalData.push({
        startWeek,
        endWeek,
        timesPerWeek: firstTimesPerWeek,
        interval: firstIntervalRadioValue,
      });
    }

    if (secondStartWeek && secondEndWeek) {
      weekIntervalData.push({
        startWeek: secondStartWeek,
        endWeek: secondEndWeek,
        timesPerWeek: secondTimesPerWeek,
        interval: secondIntervalRadioValue,
      });
    }

    setWeekIntervalFinishOrder(weekIntervalData);
  }, [endWeek, firstIntervalRadioValue, secondEndWeek, secondIntervalRadioValue, firstTimesPerWeek, secondTimesPerWeek, startWeek, secondStartWeek, setWeekIntervalFinishOrder]);

  // If first interval is not set before, calculate the allowed intervals for the selected weeks
  useEffect(() => {
    if (allowedIntervals.length > 0 && !startWeek && !endWeek) {
      const allowedIntervalsForSelectedWeeks = calculateAllowedIntervals(
        CONSTANTS.WEEK.START,
        CONSTANTS.WEEK.END,
        allowedIntervals
      );

      const value = weekIntervalFinishOrder?.[0]?.interval
        ? weekIntervalFinishOrder[0].interval
        : allowedIntervalsForSelectedWeeks?.[0]?.toString() ?? '';

      setFirstIntervaRadioValue(value);

      if (value === '') {
        setAlertErrorMsgFirstInterval('order-product-emptying-rhythm-not-available-due-to-admin-settings');
      } else {
        setAlertErrorMsgFirstInterval(null);
      }

      setAllowedIntervalsFirstInterval(allowedIntervalsForSelectedWeeks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedIntervals, startWeek, endWeek]);

  // Setting up first interval radio value
  // Only change firstIntervalRadioValue if the value is not already set or if the value is not allowed
  useEffect(() => {
    if (startWeek && endWeek) {
      const allowedIntervalsForSelectedWeeks = calculateAllowedIntervals(
        Number(startWeek),
        Number(endWeek),
        allowedIntervals
      );

      if (allowedIntervalsForSelectedWeeks.length === 0) {
        setAlertErrorMsgFirstInterval('order-product-emptying-rhythm-not-available-due-to-admin-settings');
      } else {
        setAlertErrorMsgFirstInterval(null);
      }
      setAllowedIntervalsFirstInterval(allowedIntervalsForSelectedWeeks);

      const value = weekIntervalFinishOrder?.[0]?.interval
        ? weekIntervalFinishOrder[0].interval
        : allowedIntervalsForSelectedWeeks?.[0]?.toString() ?? '';

      if (!firstIntervalRadioValue || !allowedIntervalsForSelectedWeeks.includes(Number(firstIntervalRadioValue))) {
        setFirstIntervaRadioValue(value);
      }  
    }
  }, [startWeek, endWeek, allowedIntervals, weekIntervalFinishOrder, setAlertErrorMsgFirstInterval, firstIntervalRadioValue]);

  // Setting up second interval radio value
  // Only change secondIntervalRadioValue if the value is not already set or if the value is not allowed
  useEffect(() => {
    if (secondStartWeek && secondEndWeek) {
      const weeksOverlap = handleWeekOverlaps({
        startWeek,
        endWeek,
        secondStartWeek,
        secondEndWeek,
        setDisplayWeeksOverlapsError,
        setSecondStartWeek,
        setSecondEndWeek,
        setAllowedIntervalsSecondInterval,
      });

      if (!weeksOverlap) {
        const allowedIntervalsForSelectedWeeks2 = calculateAllowedIntervals(
          Number(secondStartWeek),
          Number(secondEndWeek),
          allowedIntervals
        );

        const msg = allowedIntervalsForSelectedWeeks2.length === 0 ? 'order-product-emptying-rhythm-not-available-due-to-admin-settings' : null;
        setAlertErrorMsgSecondInterval(msg);

        setAllowedIntervalsSecondInterval(allowedIntervalsForSelectedWeeks2);

        const value = weekIntervalFinishOrder?.[1]?.interval
        ? weekIntervalFinishOrder[1].interval
        : allowedIntervalsForSelectedWeeks2?.[0]?.toString() ?? '';

        // Only change secondIntervalRadioValue if the value is not already set or if the value is not allowed
        if (!secondIntervalRadioValue || !allowedIntervalsForSelectedWeeks2.includes(Number(secondIntervalRadioValue))) {
          setSecondIntervaRadioValue(value);
        }
      }
    }
  }, [
    secondStartWeek,
    secondEndWeek,
    allowedIntervals,
    startWeek,
    endWeek,
    weekIntervalFinishOrder,
    setAlertErrorMsgSecondInterval,
    secondIntervalRadioValue,
  ]);

  const addEmptyingIntervalButtonDisabled = useCallback(() => {
    if (startWeek === CONSTANTS.WEEK.START.toString() && endWeek === CONSTANTS.WEEK.END.toString()) {
      return true;
    }
    return false;
  }, [startWeek, endWeek]);

  const handleChangeStartWeek = (event: SelectChangeEvent<string | number>): void => {
    setStartWeek(event.target.value as string);
    setEndWeek(undefined);
  };

  const handleSecondChangeStartWeek = (event: SelectChangeEvent<string | number>): void => {
    if (displayWeeksOverlapsError) {
      setDisplayWeeksOverlapsError(false);
    }
    setSecondStartWeek(event.target.value as string);
    setSecondEndWeek(undefined);
  };

  const handleFirstAllowedIntervalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstIntervaRadioValue(event.target.value);
  };

  const handleSecondAllowedIntervalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSecondIntervaRadioValue(event.target.value);
  };

  const handleTimesPerWeekToggle = (_: any, value: string) => {
    if (value === null) return;
    setFirstTimesPerWeek(value);
  };

  const handleSecondTimesPerWeekToggle = (_: any, value: string) => {
    if (value === null) return;
    setSecondTimesPerWeek(value);
  };

  const renderSecondIntervalBlock = () => {
    if (startWeek === CONSTANTS.WEEK.START.toString() && endWeek === CONSTANTS.WEEK.END.toString()) {
      return (
        <Typography textAlign={'center'} marginTop={3}>
          {getText('order-product-not-available')}
        </Typography>
      );
    }
    return (
      <>
        <Divider style={{ marginTop: '1rem', marginBottom: '1rem' }} />
        <Typography variant='h6'>{getText('order-product-emptying-rhythm-input-label')}</Typography>

        <ExtendedAlert open={Boolean(alertErrorMsgSecondInterval)} severity='error' sx={{ margin: 2 }}>
          {alertErrorMsgSecondInterval ? getText(alertErrorMsgSecondInterval) : null}
        </ExtendedAlert>

        <RadioGroup value={secondIntervalRadioValue} onChange={handleSecondAllowedIntervalChange}>
          <FormControl>
            {allowedIntervalsSecondInterval.map((interval) => (
              <FormControlLabel
                key={interval}
                control={<Radio />}
                label={printIntervalBasedOnTimesWeek(getText, interval, Number(secondTimesPerWeek))}
                value={interval}
              />
            ))}
          </FormControl>
        </RadioGroup>

        <Box marginTop={2}>
          {authService.isEmptyingTimesPerWeekEnabled() && (
            <WeeksPerDayToggleButton
              getText={getText}
              onChange={handleSecondTimesPerWeekToggle}
              value={secondTimesPerWeek}
            />
          )}
        </Box>

        <Stack marginTop={2}>
          <Typography variant='h6'>{getText('order-product-emptying-rhythm-validity')}</Typography>
          {displayWeeksOverlapsError && (
            <Typography color={palette.error.main}>
              {getText('order-product-emptying-rhythm-weeks-cannot-overlap')}
            </Typography>
          )}

          <DropdownSelect
            items={generateWeekArray(excludedWeeks)}
            value={secondStartWeek?.toString() || ''}
            onChange={handleSecondChangeStartWeek}
            label={getText('order-product-emptying-rhythm-validity-start-week')}
          />

          <DropdownSelect
            items={generateWeekArray(excludedWeeks)}
            value={secondEndWeek?.toString() || ''}
            onChange={(event: SelectChangeEvent<string | number>) => setSecondEndWeek(event.target.value as string)}
            label={getText('order-product-emptying-rhythm-validity-end-week')}
          />
        </Stack>
      </>
    );
  };

  if (!allowedIntervals.length) {
    return (
      <Box>
        <Typography>{getText('order-product-emptying-rhythm-not-available-due-to-admin-settings')}</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Stack direction='column' marginTop={1}>
        <Typography variant='h6'>{getText('order-product-emptying-rhythm-input-label')}</Typography>
        <RadioGroup value={firstIntervalRadioValue} onChange={handleFirstAllowedIntervalChange}>
          <FormControl>
            {allowedIntervalsFirstInterval.map((interval) => (
              <FormControlLabel
                key={interval}
                control={<Radio />}
                label={printIntervalBasedOnTimesWeek(getText, interval, Number(firstTimesPerWeek))}
                value={interval}
              />
            ))}
          </FormControl>
        </RadioGroup>

        <ExtendedAlert open={Boolean(alertErrorMsgFirstInterval)} severity='error' sx={{ margin: 2 }}>
          {alertErrorMsgFirstInterval ? getText(alertErrorMsgFirstInterval) : null}
        </ExtendedAlert>

        <Box marginTop={2}>
          {authService.isEmptyingTimesPerWeekEnabled() && (
            <WeeksPerDayToggleButton getText={getText} onChange={handleTimesPerWeekToggle} value={firstTimesPerWeek} />
          )}
        </Box>

        <Stack marginTop={2}>
          <Typography variant='h6'>{getText('order-product-emptying-rhythm-validity')}</Typography>
          <DropdownSelect
            items={generateWeekArray()}
            value={startWeek?.toString() || ''}
            onChange={handleChangeStartWeek}
            label={getText('order-product-emptying-rhythm-validity-start-week')}
          />
          <DropdownSelect
            items={generateWeekArray()}
            value={endWeek?.toString() || ''}
            onChange={(event: SelectChangeEvent<string | number>) => setEndWeek(event.target.value as string)}
            label={getText('order-product-emptying-rhythm-validity-end-week')}
          />
        </Stack>

        {showSecondDropdowns && renderSecondIntervalBlock()}

        <Tooltip
          title={
            addEmptyingIntervalButtonDisabled()
              ? getText('order-product-emptying-rhythm-validity-add-interval-disabled')
              : ''
          }
          PopperProps={{ disablePortal: true }}
          placement='top'
          arrow
        >
          <div>
            <Button
              disabled={addEmptyingIntervalButtonDisabled()}
              onClick={() => {
                setAllowedIntervalsSecondInterval([]);
                setSecondStartWeek(undefined);
                setSecondEndWeek(undefined);
                // save the state of the second dropdowns for parent component
                // showSecondDropdowns true=>false, setSingleInterval(true)
                // showSecondDropdowns false=>true, setSingleInterval(false)
                setSingleInterval(showSecondDropdowns);
                // toggle the second dropdowns (locally)
                setShowSecondDropdowns(!showSecondDropdowns);
              }}
              style={{ margin: 20 }}
            >
              {showSecondDropdowns
                ? `- ${getText('order-product-emptying-rhythm-validity-remove-interval')}`
                : `+ ${getText('order-product-emptying-rhythm-validity-add-interval')}`}
            </Button>
          </div>
        </Tooltip>
      </Stack>
    </Box>
  );
};

export default WeekIntervalSelectorWeeklyRhythm;
