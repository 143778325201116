import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  FormEvent,
} from 'react';
import { useHistory } from 'react-router-dom';
import ApiContext from '../../../contexts/api-context';
import { LanguageContext } from '../../../contexts/language-context';
import {
  doWeekIntervalsOverlap,
  getFirstDateOfWeek,
  getWeekNumber,
  lastWeekOfTheYear,
} from '../../../util/calendarUtil';
import { findAllowedInterval, selectValue } from '../../../util/util';
import {
  Column,
  Form,
  FormContent,
  FormTop,
  Row,
  ValidationError,
  IntervalRow
} from '../../common/containers';
import { SecondaryHeader, TertiaryHeader } from '../../common/headers';
import { Checkbox } from '../../common/inputs';
import IntervalItem from '../order/IntervalItem';
import OrderButton from '../order/OrderButton';
import { IntervalData, EmptyingIntervalUpdate, ContractInfo, VingoProduct, EmptyingInterval } from '../../../model';
import DialogContext from '../../../contexts/dialog-context';
import moment from 'moment';

type IntervalProps = { customerNumber: string; position: string; contractInfo: ContractInfo | null; product: VingoProduct | null; };

const Interval = (props: IntervalProps): JSX.Element => {
  const api = useContext(ApiContext);
  const history = useHistory();
  const { getText } = useContext(LanguageContext);
  const showDialog = useContext(DialogContext);

  const year = 2026;

  const [wholeYear, setWholeYear] = useState<boolean>(false);
  const firstWeekDate: Date = new Date(year, 0, 1);;
  const lastWeekDate: Date = new Date(year, 11, 31);

  const [startWeekDate, setStartWeekDate] = useState<Date | null>(null);
  const [endWeekDate, setEndWeekDate] = useState<Date | null>(null);
  const [interval, setInterval] = useState<string>('');
  const [amountPerWeek, setAmountPerWeek] = useState<string>('1');

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [iWantAnotherInterval, setIWantAnotherInterval] = useState<boolean>(
    false
  );
  const [startWeekDate2, setStartWeekDate2] = useState<Date | null>(null);
  const [endWeekDate2, setEndWeekDate2] = useState<Date | null>(null);
  const [interval2, setInterval2] = useState<string>('');
  const [amountPerWeek2, setAmountPerWeek2] = useState<string>('1');

  const componentIsMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const { customerNumber, position, contractInfo, product } = props;

  const [intervals, setIntervals] = useState<IntervalData[]>([]);

  useEffect(() => {
    // Initialize current values.
    if (contractInfo != null) {
      const emptyingIntervals = contractInfo.emptyingIntervals;

      if (emptyingIntervals.length > 0) {
        setInitialInterval(emptyingIntervals[0], product, setStartWeekDate, setEndWeekDate,
          setInterval, setAmountPerWeek);
      }
      if (emptyingIntervals.length > 1) {
        setIWantAnotherInterval(true);
        setInitialInterval(emptyingIntervals[1], product, setStartWeekDate2, setEndWeekDate2,
          setInterval2, setAmountPerWeek2);
      }
      // If there is only 1 interval for whole year then check the "Whole year" checkbox 
      // so available intervals fill be filtered according to "Whole year" rule (XJHLES-601).
      if (emptyingIntervals.length === 1 && emptyingIntervals[0].startWeek === 1 && emptyingIntervals[0].endWeek >= 52) {
        setWholeYear(true);
      }
    }
  }, []);

  useEffect(() => {
    // Updates intervals when any relative value is changed
    let intervalData = {
      startWeekDate: startWeekDate,
      endWeekDate: endWeekDate,
      interval: Number(interval),
      amountPerWeek: Number(amountPerWeek),
    };

    let intervalData2 = {
      startWeekDate: startWeekDate2,
      endWeekDate: endWeekDate2,
      interval: Number(interval2),
      amountPerWeek: Number(amountPerWeek2),
    };

    setIntervals([intervalData, intervalData2]);

  }, [startWeekDate, endWeekDate, interval, amountPerWeek, startWeekDate2, endWeekDate2, interval2, amountPerWeek2]);

  // Initialize single interval value
  const setInitialInterval = (emptyingInterval: EmptyingInterval,
    product: VingoProduct | null, setInitialStartWeek: any, setInitialEndWeek: any,
    setInterval: any, setAmountPerWeek: any): void => {
    // Check that week number isn't bigger than this years max weeks (53 instead 52)
    let startWeek1 = Math.min(emptyingInterval.startWeek, lastWeekOfTheYear(year));
    let startFirstDayOfWeek = getFirstDateOfWeek(year, startWeek1);
    if (startFirstDayOfWeek < firstWeekDate) {
      startFirstDayOfWeek = firstWeekDate;
    }
    setInitialStartWeek(startFirstDayOfWeek);

    let endWeek1 = Math.min(emptyingInterval.endWeek, lastWeekOfTheYear(year));
    let endFirstDayOfWeek = getFirstDateOfWeek(year, endWeek1)
    if (endFirstDayOfWeek < firstWeekDate) {
      endFirstDayOfWeek = firstWeekDate;
    }
    setInitialEndWeek(endFirstDayOfWeek);
    let toBeSetInterval = "-99";

    setAmountPerWeek(emptyingInterval?.amountPerWeek ?? "1");

    // Check that current interval is in list of allowed intervals 
    if (product?.allowedEmptyingIntervals != null &&
      product?.allowedEmptyingIntervals &&
      product?.allowedEmptyingIntervals.length > 0) {

      // Search correct allowed intervals for selected weeks.
      const allowedIntervals = findAllowedInterval(product?.allowedEmptyingIntervals, startWeek1, endWeek1);
      if (allowedIntervals != null) {
        if (allowedIntervals != null && allowedIntervals.includes(emptyingInterval.interval)) {
          toBeSetInterval = emptyingInterval.interval.toString()
        } else if ( allowedIntervals != null && allowedIntervals.length > 0) {
          // Current interval not in list change it to first one on the list, if possible
          toBeSetInterval = allowedIntervals[0].toString();
        }
      }
      // No allowed intervals, user cannot do anything, set value out of valid range
    }
    // No allowed intervals, user cannot do anything, set value out of valid range 

    setInterval(toBeSetInterval);
  };

  const intervalDataToReqList = (): EmptyingIntervalUpdate[] => {
    let intervalReqList = intervals.map((interval) => ({
      startWeek: getWeekNumber(interval.startWeekDate ?? new Date()),
      endWeek: getWeekNumber(interval.endWeekDate ?? new Date()),
      interval: interval.interval ?? -99,
      amountPerWeek: interval.amountPerWeek,
    }));
    if (!iWantAnotherInterval) {
      intervalReqList.splice(1, 1);
    }
    return intervalReqList;
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    api
      .updateEmptyingIntervals(customerNumber, position, intervalDataToReqList())
      .then(() => {
        showDialog(
          'message-success-generic-title',
          'change-contract-interval-succeed',
          () => history.goBack()
        );
      }).catch((err: unknown) => {

        showDialog(
          'error-general-title',
          'error-general-message'
        );
      })
      .finally(() => {
        if (componentIsMounted.current) {
          setIsLoading(false);
        }
      });
  };

  function handleWholeYearChange(boolChange: boolean) {
    setIWantAnotherInterval(false);
    setWholeYear(boolChange);

    if (boolChange) {
      setStartWeekDate(firstWeekDate);
      setEndWeekDate(lastWeekDate);
      setStartWeekDate2(null);
      setEndWeekDate2(null);
      setInterval(findAllowedInterval(product?.allowedEmptyingIntervals ?? [], 1, 53)?.[0]?.toString() ?? "");
    }
  }

  const overlap: boolean =
    startWeekDate !== null &&
    endWeekDate !== null &&
    startWeekDate2 !== null &&
    endWeekDate2 !== null &&
    iWantAnotherInterval &&
    doWeekIntervalsOverlap(
      getWeekNumber(startWeekDate),
      getWeekNumber(endWeekDate),
      getWeekNumber(startWeekDate2),
      getWeekNumber(endWeekDate2)
    );

  const errorElement = overlap ? (
    <ValidationError>
      {getText('change-contract-interval-overlap-error')}
    </ValidationError>
  ) : null;

  return (
    <Form onSubmit={handleSubmit}>
      <FormTop>
        <SecondaryHeader>{getText('change-contract-interval')}</SecondaryHeader>
      </FormTop>

      <FormContent>

        <TertiaryHeader style={{ marginTop: '1rem' }}>
          {getText('change-contract-new-interval')}
        </TertiaryHeader>

        <IntervalRow>
          <Checkbox
            key={'whole-year-' + wholeYear}
            type="checkbox"
            id="wholeyear-checkbox"
            defaultChecked={wholeYear}
            onChange={() => handleWholeYearChange(!wholeYear)}
          />
          {getText('whole-year-interval')}
        </IntervalRow>

        <IntervalItem
          start={startWeekDate}
          end={endWeekDate}
          setStart={d => setStartWeekDate(d)}
          setEnd={d => setEndWeekDate(d)}
          allowedIntervals={product?.allowedEmptyingIntervals ?? []}
          intervalValue={interval}
          selectIntervalFunc={selectValue(setInterval)}
          updateInterval={setInterval}
          isSubmitted={isSubmitted}
          displayDateInputs={!wholeYear}
          amountPerWeek={amountPerWeek}
          setAmountPerWeek={setAmountPerWeek}
          isWholeYear={wholeYear}
        />

        {
          !wholeYear &&
          <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <Checkbox
              checked={iWantAnotherInterval}
              type="checkbox"
              id="another-interval"
              onChange={() => { setIWantAnotherInterval(!iWantAnotherInterval); }}
            />
            <label htmlFor="another-interval">
              {getText('change-contract-interval-another-interval')}
            </label>
          </Row>
        }

        {iWantAnotherInterval ? (
          <Column>
            <IntervalItem
              start={startWeekDate2}
              end={endWeekDate2}
              setStart={d => setStartWeekDate2(d)}
              setEnd={d => setEndWeekDate2(d)}
              allowedIntervals={product?.allowedEmptyingIntervals ?? []}
              intervalValue={interval2}
              selectIntervalFunc={selectValue(setInterval2)}
              updateInterval={setInterval2}
              isSubmitted={isSubmitted}
              displayDateInputs={true}
              amountPerWeek={amountPerWeek2}
              setAmountPerWeek={setAmountPerWeek2}
            />
            {errorElement}
          </Column>
        ) : null}

        {product?.allowedEmptyingIntervals != null ? (
          <Column style={{ marginTop: '0.5rem', marginBottom: '0rem' }}>
            {product?.allowedEmptyingIntervals.map((interval, index) => (
              <Row key={"interval-" + interval.startWeek + "-" + interval.endWeek} style={{ marginTop: '0rem', marginBottom: '0rem' }}>
                {getText("change-contract-weeks")} {interval.startWeek} - {interval.endWeek}: {getText("change-contract-allowed-intervals")} {interval.intervals.toString()}
              </Row>
            ))}
          </Column>
        ): null}

      </FormContent>

      <OrderButton
        label="service-order-confirm-button"
        action={() => setIsSubmitted(true)}
        disabled={overlap}
        isLoading={isLoading}
      />
    </Form>
  );
};

export default Interval;
