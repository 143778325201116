import { Palette, Skeleton } from '@mui/material';
import { TableHeaderProps } from '../../components/common-materialui/data-table/interfaces';
import { convertToCurrentLocaleLang } from '../../util/numberLocalization';
import { InvoiceInfo, Language, UiTexts } from '../../model';

export function invoiceTableHeaders(getText: (key: keyof UiTexts, params?: string[] | undefined) => string) {
  const headers: TableHeaderProps[] = [
    {
      id: 'invoiceNumber',
      label: getText('invoice-table-header-invoice-number'),
      alignment: 'left',
    },
    {
      id: 'date',
      label: getText('invoice-table-header-date'),
      alignment: 'left',
    },
    {
      id: 'dueDate',
      label: getText('invoice-table-header-due-date'),
      alignment: 'left',
    },
    {
      id: 'sum',
      label: getText('invoice-table-header-sum'),
      alignment: 'left',
    },
    {
      id: 'payment',
      label: getText('invoice-table-header-payment'),
      alignment: 'left',
    },
    {
      id: 'paymentDate',
      label: getText('invoice-table-header-payment-date'),
      alignment: 'left',
    },
    {
      id: 'invoice',
      label: getText('invoice-table-header-invoice'),
      alignment: 'center',
    },
  ];

  return headers;
}

/**
 * Returns "-" if value is undefined, otherwise returns formatted value with currency symbol if applicable
 * @param value Numerical value of invoice amont
 * @param currency (Optional) Type of currency, currently supports "EUR"
 * @returns
 */
export function handleInvoiceCurrency(lang: Language, value?: number, currency?: string) {
  let currencySymbol = '';
  if (value === undefined || value <= 0) {
    return '-';
  }
  if (currency) {
    if (currency === 'EUR') {
      currencySymbol = ' €';
    } else {
      currencySymbol = ' ' + currency;
    }
  }

  return convertToCurrentLocaleLang(lang, value, 2) + currencySymbol;
}

interface SkeletonProps {
  isMobile?: boolean;
}

export const InvoicesTableSkeleton = ({ isMobile }: SkeletonProps): JSX.Element => {
  return (
    <>
      {isMobile ? (
        <>
          <Skeleton variant='rectangular' height={'155px'} width={'100%'} sx={{ my: 0.2, bgcolor: 'grey.200' }} />
          <Skeleton variant='rectangular' height={'70px'} width={'100%'} sx={{ my: 0.2, bgcolor: 'grey.200' }} />
        </>
      ) : (
        <>
          <Skeleton variant='rectangular' height={'55px'} width={'100%'} sx={{ my: 0.2, bgcolor: 'grey.300' }} />
          <Skeleton variant='rectangular' height={'70px'} width={'100%'} sx={{ my: 0.2, bgcolor: 'grey.200' }} />
          <Skeleton variant='rectangular' height={'70px'} width={'100%'} sx={{ my: 0.2, bgcolor: 'grey.200' }} />
        </>
      )}
    </>
  );
};

export function getInvoiceItemColor(info: InvoiceInfo, palette: Palette) {
  if (info.paymentExpired) {
    return palette.error.main;
  }
  if (!info.paymentExpired) {
    return palette.info.main;
  }
}
