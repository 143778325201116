import React, { Dispatch, SetStateAction } from 'react';
import TextInput from '../../common/TextInput';
import { ContentRow } from '../../common/containers';

type ExistingCustomerConnectProps = {
  customerNumber: string;
  setCustomerNumber: Dispatch<SetStateAction<string>>;
  invoiceNumber: string;
  setInvoiceNumber: Dispatch<SetStateAction<string>>;
};

const ExistingCustomerConnect = (props: ExistingCustomerConnectProps): JSX.Element => {
  const {
    customerNumber,
    setCustomerNumber,
    invoiceNumber,
    setInvoiceNumber,
  } = props;

  return (
    <ContentRow>
      <TextInput
        label="basic-info-invoice-number"
        val={invoiceNumber}
        setter={setInvoiceNumber}
        validations={['integer']}
        showErrors={false}
      />
      <TextInput
        label="basic-info-customer-number"
        val={customerNumber}
        setter={setCustomerNumber}
        validations={['required']}
        showErrors={false}
      />
    </ContentRow>
  );
};

export default ExistingCustomerConnect;
