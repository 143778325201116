import { useContext, useEffect, useRef } from 'react';
import { Stack, Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import { LanguageContext } from '../../contexts/language-context';
import Header from '../../components/common-materialui/header/Header';
import AuthContext from '../../contexts/auth-context';
import { IFRAME_ACCESS_TOKEN, IFRAME_REPORTING_URL } from '../../constants';

const iframeStyle = {
  height: '1000px',
  overflow: 'hidden',
  position: 'relative' as 'relative',
};

const innerIframeStyle = {
  position: 'absolute' as 'absolute',
  top: '-120px',
  width: '100%',
};

const Report = (): JSX.Element => {
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const authService = useContext(AuthContext);
  const theme = useTheme();
  const { palette } = useTheme();
  const { getText } = useContext(LanguageContext);
  const largerThanTablet = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    const postMessageToIframe = (token: string) => {
      iFrameRef.current?.contentWindow?.postMessage(
        { token },
        window.__RUNTIME_CONFIG__.IFRAME_REPORTING_ORIGIN_DOMAIN
      );
    };

    const handleIframeLoad = async () => {
      try {
        const tokenData = authService.getToken();

        if (tokenData?.token) {
          postMessageToIframe(tokenData.token);
        }
      } catch (error) {
        console.error('Error getting token for iframe:', error);
      }
    };

    const reportingIframe = iFrameRef.current;
    reportingIframe?.addEventListener('load', handleIframeLoad);

    return () => {
      reportingIframe?.removeEventListener('load', handleIframeLoad);
      localStorage.removeItem(IFRAME_ACCESS_TOKEN);
    };
  }, [authService]);

  return (
    <>
      <Header />
      <Box
        sx={{
          backgroundColor: 'background.level2',
          paddingLeft: largerThanTablet ? '3rem' : '1rem',
          paddingBottom: '40px',
        }}
      >
        <Stack spacing={1} direction={'column'}>
          <Typography variant='h3'>{getText('reporting-header')}</Typography>
          <Typography variant='body1' color={palette.secondary.mainVariant}>
            {getText('reporting-description')}
          </Typography>
        </Stack>
      </Box>
      <Stack style={iframeStyle}>
        <iframe
          ref={iFrameRef}
          id='reporting'
          title='Reporting'
          src={IFRAME_REPORTING_URL}
          style={innerIframeStyle}
          width='100%'
          height='1000'
        />
      </Stack>
    </>
  );
};

export default Report;
