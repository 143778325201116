import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import ApiContext from './contexts/api-context';
import ApiService from './service/apiService';
import AuthContext from './contexts/auth-context';
import AuthService from './service/authService';
import { getText, LanguageContext } from './contexts/language-context';
import App from './components/App';
import { Language, TranslationsObject, UiTexts, EnvironmentSettings, convertToLanguage } from './model';
import './index.css';
import { STORED_LANGUAGE, STORED_COMPANY_INFO } from './localStorageKeys';
import VingoDialog from './components/common/VingoDialog';
import DialogContext from './contexts/dialog-context';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { light as LightTheme } from './themes/light';
import { BreadcrumbBarContextProvider } from './contexts/BreadcrumbBarContextProvider';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Spinner from './components/common/Spinner';
import { LanguageSelectionContextProvider } from './contexts/LanguageSelectionContextProvider';

// TODO: Remove DialogContext and showDialog after all pages are changed to responsive layout

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const AppContainer = (): JSX.Element => {
  // Check language from localStorage
  const getStoredOrDefaultLang = () => {
    const storedLang: string | null = localStorage.getItem(STORED_LANGUAGE);
    return storedLang ?? 'fi';
  };

  const [language, setLanguage] = useState<Language>(getStoredOrDefaultLang() as Language);

  const [environment, setEnvironment] = useState<EnvironmentSettings | undefined>(undefined);
  const [fetchedUiTexts, setFetchedUiTexts] = useState<TranslationsObject | undefined>(undefined);

  const checkContent = async (response: Response) => {
    if (!response.ok) {
      throw new Error('Environment settings fetching failed.');
    }
    const responseJson = await response.json();
    return responseJson;
  };

  function wait(delay: number) {
    return new Promise((resolve) => setTimeout(resolve, delay));
  }

  const fetchEnviromentSettings = (): Promise<EnvironmentSettings> => {
    return fetch(
      `${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/Company/${window.__RUNTIME_CONFIG__.REACT_APP_TENANT_ID}/environment`
    )
      .then(checkContent)
      .catch((x) => {
        throw x;
      });
  };

  const onFail = () => {
    return wait(10000).then(() => {
      fetchEnviromentSettings()
        .then((response) => {
          setEnvironment(response);
        })
        .catch(() => {
          onFail();
        });
    });
  };

  useEffect(() => {
    fetchEnviromentSettings()
      .then((response) => {
        setEnvironment(response);
      })
      .catch(() => {
        onFail();
      });
  }, []);

  const [publicHolidays, setPublicHolidays] = useState<Date[]>([]);

  // Dialog state. Child components can access dialog state by a context
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [dialogTitleKey, setDialogTitleKey] = useState<keyof UiTexts>('error-service-break-title');
  const [dialogMessageKey, setDialogMessageKey] = useState<keyof UiTexts>('error-service-break-message');

  const closeDialogAction = useRef<(() => void) | null>(null);
  const dialogPrimaryAction = useRef<(() => void) | null>(null);

  const handleDialogClose = useCallback(() => {
    if (closeDialogAction.current) {
      closeDialogAction.current();
    }
    setDialogVisible(false);
  }, [closeDialogAction]);

  // Function for showing dialog. This is wrapped in a context.
  const showDialog = useCallback(
    (
      titleKey: keyof UiTexts,
      messageKey: keyof UiTexts,
      // If primaryAction is not given this function is executed when OK is clicked.
      // If closeAction is not given default operation is just closing the dialog.
      closeAction?: () => void,
      // If this is given it is executed on OK button click and closeAction on Close button click.
      primaryAction?: () => void
    ) => {
      setDialogTitleKey(titleKey);
      setDialogMessageKey(messageKey);

      // Sometimes onClick events can return objects? have to check they are actually functions
      if (closeAction && typeof closeAction === 'function') {
        closeDialogAction.current = () => {
          closeAction();
        };
      } else if (!closeAction) {
        closeDialogAction.current = null;
      }

      if (primaryAction && typeof primaryAction === 'function') {
        dialogPrimaryAction.current = () => {
          primaryAction();
          setDialogVisible(false);
        };
      } else if (primaryAction) {
        dialogPrimaryAction.current = () => {
          setDialogVisible(false);
        };
      } else if (!primaryAction) {
        dialogPrimaryAction.current = null;
      }

      setDialogVisible(true);
    },
    []
  );

  const authService = useMemo(() => {
    return new AuthService({
      domain: window.__RUNTIME_CONFIG__.REACT_APP_API_URL as string,
      tenantId: window.__RUNTIME_CONFIG__.REACT_APP_TENANT_ID as string,
      language: language,
      environment: environment,
    });
  }, [window.__RUNTIME_CONFIG__, environment]);

  // Set language to localStorage when it's changed
  useEffect(() => {
    // make sure the language is in the correct format
    const newLanguage = convertToLanguage(language);
    localStorage.setItem(STORED_LANGUAGE, newLanguage);
  }, [language]);

  useEffect(() => {
    authService
      .fetchHolidays()
      .then((holidays) => {
        setPublicHolidays(holidays);
      })
      .catch((err) => {
        console.error(err);
        showDialog('error-service-break-title', 'error-service-break-message');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    authService
      .fetchCompanyInfo()
      .then(info => {
        localStorage.setItem(STORED_COMPANY_INFO, JSON.stringify(info));
      })
      .catch((err) => {
        console.error(err);
        showDialog('error-service-break-title', 'error-service-break-message');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    authService
      .fetchUiTexts()
      .then((texts) => {
        setFetchedUiTexts(texts);
      })
      .catch((err) => {
        console.error(err);
        showDialog('error-service-break-title', 'error-service-break-message');
      });
  }, []);

  return environment == null ? (
    <Spinner />
  ) : (
    <QueryClientProvider client={queryClient}>
      <LanguageContext.Provider value={{ lang: language, getText: getText(language)(fetchedUiTexts), publicHolidays }}>
        <LanguageSelectionContextProvider authService={authService} language={language} setLanguage={setLanguage}>
          <VingoDialog
            titleKey={dialogTitleKey}
            open={dialogVisible}
            closeAction={handleDialogClose}
            primaryAction={dialogPrimaryAction.current}
          >
            {getText(language)(fetchedUiTexts)(dialogMessageKey)}
          </VingoDialog>
          <AuthContext.Provider value={authService}>
            <ApiContext.Provider value={new ApiService(authService)}>
              <ThemeProvider theme={LightTheme}>
                <BreadcrumbBarContextProvider>
                  <DialogContext.Provider value={showDialog}>
                    <App language={language} setLanguage={setLanguage} />
                  </DialogContext.Provider>
                  <CssBaseline />
                </BreadcrumbBarContextProvider>
              </ThemeProvider>
            </ApiContext.Provider>
          </AuthContext.Provider>
        </LanguageSelectionContextProvider>
      </LanguageContext.Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

ReactDOM.render(<AppContainer />, document.querySelector('#root'));
