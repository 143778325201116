import React, { useContext } from 'react';
import { Product, ProductCategory } from '../../../model';
import {
  Column,
  MainWideContent,
  Row,
  RowWideContent,
} from '../../common/containers';
import Header from '../../common/Header';
import VingoButton from '../../common/VingoButton';
import ProductWithPicture from './ProductWithPicture';
import { LanguageContext } from '../../../contexts/language-context';
import styled from 'styled-components';
import { RecordWithTtl } from 'dns';
import { device } from '../../common/devices';

type ShoppingProps = {
  productCategory: ProductCategory;
  products: Product[];
  addProductAmount: any;
  gotoBasket: any;
};

const StickerGridFlex = styled.div`
  @media ${device.desktop} {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: auto auto auto;
    grid-template-columns: auto auto auto;
    grid-gap: 0.1rem;
    margin-left: 9rem;
    margin-right: 9rem;
  }
  @media ${device.mobile} {
    display: flex;
    flex-direction: column;
    margin-left: 0rem;
    margin-right: 0rem;
    justify-content: center;
  }
`;

const StickersButtonWrapper = styled.div`
  @media ${device.desktop} {
    min-width: 15rem;
    margin-bottom: 1rem;
  }
  @media ${device.mobile} {
    width: 100%;
  }
`;

const Shopping = (props: ShoppingProps) => {
  const { productCategory, products, addProductAmount, gotoBasket } = props;
  const { getText } = useContext(LanguageContext);

  const numOfItemsInBasket = products.filter((p) => p.amount > 0).length;

  return (
    <Column style={{ marginBottom: '4rem' }}>
      <Header
        headerElement={<div>{productCategory.name}</div>}
        // TODO description myös productCategory:stä?
        descriptionElement={<div>{getText('service-order-products-stickers')}</div>}
        backButton={true}
      ></Header>
      {numOfItemsInBasket ? (
        <RowWideContent>
          {getText('service-order-products-in-basket', [
            numOfItemsInBasket.toString(),
          ])}
          <StickersButtonWrapper>
            <VingoButton onClick={gotoBasket}>
              {getText('service-order-goto-basket')}
            </VingoButton>
          </StickersButtonWrapper>
        </RowWideContent>
      ) : null}
      <Column>
        <StickerGridFlex>
          {products.map((p) => (
            <ProductWithPicture
              productId={p.id}
              name={p.name}
              info={p.info}
              price={p.price}
              amount={p.amount}
              addProductAmount={addProductAmount}
              gotoBasket={gotoBasket}
              key={p.id}
            />
          ))}
        </StickerGridFlex>
      </Column>
    </Column>
  );
};

export default Shopping;
