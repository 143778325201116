import { Grid, Stack, Typography, useTheme } from '@mui/material';
import { useContext } from 'react';
import { LanguageContext } from '../../../../contexts/language-context';
import { BillingInfoFormData } from '../models';
import { ConsumerEInvoiceType, UiTexts } from '../../../../model';
import { Divider } from '@material-ui/core';

interface Props {
  billingInfoFormData: BillingInfoFormData;
  customerType: string;
}

const BillingSummary = ({ billingInfoFormData, customerType }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const { palette } = useTheme();

  function showData(labelKey: keyof UiTexts, data: string | null) {
    return (
      <Stack direction='column'>
        <Typography variant='body2' color={palette.text.secondary}>
          {getText(labelKey)}
        </Typography>
        <Typography variant='body1' color={palette.text.primary}></Typography>
        {data}
      </Stack>
    );
  }

  const billingContactName =
    billingInfoFormData.contactLastName || billingInfoFormData.contactFirstName
      ? `${billingInfoFormData.contactFirstName}  ${billingInfoFormData.contactLastName}`
      : null;

  return (
    <Grid container columns={2} rowSpacing={2} sx={{ maxWidth: '540px' }}>
      <Grid item xs={2}>
        <Typography variant='h6'>{getText('emptying-address-billing-address')}</Typography>
      </Grid>

      <Grid item xs={1}>
        {showData(
          'billing-info-type-selector',
          customerType === 'private' ? getText('billing-info-type-private') : getText('billing-info-type-company')
        )}
      </Grid>

      {customerType === 'private' && (
        <Grid item xs={1}>
          {showData('basic-info-name', `${billingInfoFormData.firstName}  ${billingInfoFormData.lastName}`)}
        </Grid>
      )}

      <Grid item xs={1}>
        {customerType === 'private'
          ? showData('basic-info-ssn', billingInfoFormData.personalIdentificationNumber)
          : showData('basic-info-company-id', billingInfoFormData.companyCode)}
      </Grid>
      <Grid item xs={1}>
        {showData('billing-data-street-address', billingInfoFormData.address)}
      </Grid>

      <Grid item xs={1}>
        {showData('billing-data-post-code', billingInfoFormData.postCode)}
      </Grid>
      <Grid item xs={1}>
        {showData('billing-data-city', billingInfoFormData.city)}
      </Grid>

      {billingInfoFormData.emailInvoicing && (
        <Grid item xs={2}>
          {showData('email-bill', billingInfoFormData.billingEmail)}
        </Grid>
      )}

      {billingInfoFormData.consumerEInvoiceType === ConsumerEInvoiceType.NotInUse && (
        <Grid item xs={1}>
          {showData('consumer-einvoice-type-not-in-use', getText('consumer-einvoice-type-not-in-use'))}
        </Grid>
      )}
      {billingInfoFormData.consumerEInvoiceType === ConsumerEInvoiceType.EmailInvoicing && (
        <>
          <Grid item xs={1}>
            {showData('consumer-einvoice-type-email-invoicing', getText('consumer-einvoice-type-email-invoicing'))}
          </Grid>
          <Grid item xs={1}>
            {showData('email-bill', billingInfoFormData.billingEmail)}
          </Grid>
        </>
      )}
      {billingInfoFormData.consumerEInvoiceType === ConsumerEInvoiceType.ConsumerChannel &&
        billingInfoFormData.eInvoiceInformation && (
          <>
            <Grid item xs={1}>
              {showData('consume-einvoice-type-consumer-channel', getText('consume-einvoice-type-consumer-channel'))}
            </Grid>
            <Grid item xs={1}>
              {showData('e-bill-address', billingInfoFormData.eInvoiceInformation?.address)}
            </Grid>
            <Grid item xs={1}>
              {showData('broker', billingInfoFormData.eInvoiceInformation.operator)}
            </Grid>
            <Grid item xs={1}>
              {showData('ovt', billingInfoFormData.eInvoiceInformation.ediCode)}
            </Grid>
          </>
        )}

      <Grid item xs={2}>
        <Divider />
      </Grid>

      <Grid item xs={2}>
        <Typography variant='h6'>{getText('billing-data-billing-contact-person')}</Typography>
      </Grid>

      <Grid item xs={1}>
        {showData('basic-info-name', billingContactName)}
      </Grid>
      <Grid item xs={1}>
        {showData('basic-info-phone', billingInfoFormData.phoneNumber)}
      </Grid>

      <Grid item xs={1}>
        {showData('billing-email', billingInfoFormData.billingEmail)}
      </Grid>
    </Grid>
  );
};

export default BillingSummary;
