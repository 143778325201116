import React, { useContext } from 'react';
import { LanguageContext } from '../../contexts/language-context';
import { ValidationError } from './containers';
import { Select } from './inputs';

type DropDownProps = {
  required?: boolean;
  showError?: boolean;
  value: string | undefined;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: [string, string][];
};

const DropDown = ({ showError, required, value, onChange, options }: DropDownProps): JSX.Element => {
  const { getText } = useContext(LanguageContext);

  const errorMessage = showError && required && !value ?  (
    <ValidationError>{getText('validation-required')}</ValidationError>
  ) : null;

  return (
    <React.Fragment>
      <Select
        required={required}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChange(e)}
      >
        {options.map((opt, index) => (
          <option value={opt[0]} key={index}>
            {opt[1]}
          </option>
        ))}
      </Select>
      {errorMessage}
    </React.Fragment>
  );
};

export default DropDown;
