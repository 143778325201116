import { Avatar, Grid, Skeleton, Stack, Typography, useTheme } from "@mui/material";
import { ReactComponent as LocationIcon } from "../../assets/icons/map-pin.svg";
import { EmptyingInfo } from "../../model";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";

interface Props {
  screenWiderThanPhone: boolean,
  emptyingInfo: EmptyingInfo;
}

export const MyEmptyingLocationsCard = ({ screenWiderThanPhone, emptyingInfo }: Props) => {
  const history = useHistory();
  const { palette } = useTheme();
  const stackDirection = screenWiderThanPhone ? "column" : "row";

  const handleBuildingClassificationName = () => {
    if (emptyingInfo?.buildingClassification?.name) {
      return emptyingInfo?.buildingClassification?.name + " • ";
    }
    return "";
  };

  const handleNavigation = useCallback(() => {
    history.push(`/emptying-infos/${emptyingInfo.id}`);
  }, []);

  return (
    <Grid item xs={4} onClick={handleNavigation}>
      <Stack
        minHeight='100%'
        direction={stackDirection}
        spacing={2}
        p="1rem"
        border={1}
        borderColor="divider"
        sx={{
          backgroundColor: palette.background.level2,
          transition: "border-color 0.5s",
          cursor: "pointer",
          '&:hover': {
            borderColor: palette.secondary.main,
          },
        }}
      >
        <Avatar sx={{
          color: palette.primary.main,
          backgroundColor: palette.primary.mainVariant15,
        }}>
          <LocationIcon />
        </Avatar>

        <Stack display="flex" alignItems="left" spacing={0.25}>
          <Typography variant="h6" sx={{ wordWrap: 'break-word' }}>
            {emptyingInfo?.address?.street + ", " + emptyingInfo?.address?.postOffice}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ wordWrap: 'break-word' }}>
            {
              handleBuildingClassificationName() +
              emptyingInfo.name ?? ""
            }
          </Typography>
        </Stack>

      </Stack >
    </Grid >
  );
};

export const SkeletonCard = ({ screenWiderThanPhone }: { screenWiderThanPhone: boolean; }) => {
  const stackDirection = screenWiderThanPhone ? "column" : "row";

  return (
    <Grid item xs={4}>
      <Stack
        direction={stackDirection}
        spacing={2}
        p="1rem"
        border={1}
        borderColor="divider"
        minWidth={"300px"}
      >
        <Avatar>
          <Skeleton />
        </Avatar>
        <Stack display="flex" alignItems="left" spacing={0.25}>
          <Typography variant="h6">
            <Skeleton width={"200px"} />
          </Typography>
          <Typography variant="body2">
            <Skeleton width={"200px"} />
          </Typography>
        </Stack>
      </Stack>
    </Grid >
  );
};