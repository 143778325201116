import React, { useContext, useEffect, useState, useRef, forwardRef } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';

import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';

import { LanguageContext } from '../../../contexts/language-context';
import { Column } from '../../common/containers';
import { UiTexts } from '../../../model';
import colors from '../../../colors';

import { validateInput, Validation } from '../../common/input/validation';
import { ReactComponent as CalendarIcon } from '../../../assets/calendar.svg';

const ERROR_COLOR = colors.errorBackground;
const INPUT_BORDER_COLOR = colors.inputBorder;
const INPUT_BG_COLOR = colors.contentBackground;
const INPUT_FOCUSED_COLOR = colors.inputFocused;
const ERROR_TEXT_COLOR = colors.errorText;
const LABEL_TEXT_COLOR = colors.lightLabel;
const GUIDE_TEXT_BG_COLOR = colors.contentBackground;

type DateFieldProps = {
  val: string;
  label?: keyof UiTexts;
  placeholder?: keyof UiTexts;
  guideText?: string;
  validations?: Validation[];
  showErrors?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

const usedTheme = createTheme({
  palette: {
    error: {
      main: ERROR_TEXT_COLOR,
    },
  },
});

const DateField = forwardRef((props: DateFieldProps, ref): JSX.Element => {
  const fieldRef = useRef<HTMLInputElement>(null);
  const {
    val,
    label,
    placeholder,
    guideText,
    validations,
    showErrors, // This is needed to hide errors before submit
    disabled,
  } = props;

  const [errorMessage, setErrorMessage] = useState<string>('');
  const isError = showErrors && !!errorMessage;

  let rootStyles = {
    fontFamily: 'SpaceGrotesk-Regular',
    fontWeight: 500,
    paddingTop: '0',
    paddingLeft: '0',
    paddingBottom: '0',
    border: isError ? `1px solid ${ERROR_COLOR}` : `1px solid ${INPUT_BORDER_COLOR}`,
    overflow: 'hidden',
    borderRadius: 0,
    backgroundColor: INPUT_BG_COLOR,
    transition: usedTheme.transitions.create(['border-color']),
    '&:hover': {
      backgroundColor: INPUT_BG_COLOR,
    },
    '&$focused': {
      backgroundColor: INPUT_BG_COLOR,
      borderColor: INPUT_FOCUSED_COLOR,
    },
  };

  const useStyles = makeStyles((theme) => ({
    root: rootStyles,
    focused: { backgroundColor: INPUT_BG_COLOR },
  }));

  const useLabelStyles = makeStyles((theme) => ({
    root: {
      fontFamily: 'SpaceGrotesk-Regular',
      fontWeight: 500,
      color: LABEL_TEXT_COLOR,
      '&:hover': {
        color: LABEL_TEXT_COLOR,
      },
      '&$focused': {
        color: LABEL_TEXT_COLOR,
      },
    },
    focused: { color: LABEL_TEXT_COLOR },
  }));

  const useHelperTextStyles = makeStyles((theme) => ({
    root: {
      'font-family': 'SpaceGrotesk-Regular',
      backgroundColor: isError ? ERROR_COLOR : GUIDE_TEXT_BG_COLOR,
      'margin-left': '0px',
      'margin-top': '0.4rem',
      'padding-left': '0.5rem',
      'padding-top': isError ? '0.5rem' : '0rem',
      'padding-bottom': '0.5rem',
      width: 'calc(100% - 0.5rem)',
    },
  }));

  useEffect(() => {
    if (validations) {
      const errorMessages = validateInput(val, validations)
        .map((key) => getText(key))
        .join(', ');
      setErrorMessage(errorMessages);

      // This prevents form submission and puts focus on first invalid field.
      fieldRef.current?.setCustomValidity(errorMessages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validations, val, showErrors]);

  const { getText } = useContext(LanguageContext);

  const classes = useStyles();
  const labelClasses = useLabelStyles();
  const helperTextClasses = useHelperTextStyles();

  const inputProps = {
    classes,
    disableUnderline: true,
    endAdornment: (
      <InputAdornment position='end'>
        <CalendarIcon />
      </InputAdornment>
    ),
  };

  return (
    <Column>
      <ThemeProvider theme={usedTheme}>
        <TextField
          {...props}
          InputProps={inputProps}
          InputLabelProps={{ classes: labelClasses }}
          FormHelperTextProps={{ classes: helperTextClasses }}
          // this onInvalid prevents HTML5 validation bubles
          onInvalid={(event) => {
            event.preventDefault();
          }}
          label={label ? getText(label) : null}
          hiddenLabel={label ? false : true}
          placeholder={placeholder ? getText(placeholder) : ''}
          variant='filled'
          error={isError}
          helperText={showErrors && errorMessage !== '' ? errorMessage : guideText}
          margin='dense'
          disabled={disabled}
        />
      </ThemeProvider>
    </Column>
  );
});

export default DateField;
