import { createContext } from 'react';
import { UiTexts } from '../model';

const DialogContext = createContext<
  (
    titleKey: keyof UiTexts,
    messageKey: keyof UiTexts,
    closeAction?: () => void,
    primaryAction?: () => void,
  ) => void
>(
  (
    _titleKey: keyof UiTexts,
    _messageKey: keyof UiTexts,
    _closeAction?: () => void,
    _primaryAction?: () => void,
  ) => null
);

export default DialogContext;
