import { useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, Typography, Link as MuiLink, Stack, Divider } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import AuthContext from '../../contexts/auth-context';
import DialogContext from '../../contexts/dialog-context';
import { LanguageContext } from '../../contexts/language-context';
import { useForm } from 'react-hook-form';
import ControlTextInput from '../../components/common-materialui/form/ControlTextInput';
import { Language, UserLanguageUpdateInput } from '../../model';
import ApiContext from '../../contexts/api-context';
import ProgressComponent from '../../components/common-materialui/ProgressComponent';

interface SignInData {
  username: string;
  password: string;
}

interface SignInProps {
  language: Language;
}

const SignIn = ({ language }: SignInProps): JSX.Element => {

  const api = useContext(ApiContext);

  const suomiFIBackgroundColor = '#003479';
  const suomiFIBackgroundColorHover = '#002455';
  const history = useHistory();
  const { getText } = useContext(LanguageContext);
  const authService = useContext(AuthContext);
  const isStrongAuthEnabled = authService.isStrongAuthenticationEnabled();
  const isCreateNewCustomerEnabled = authService.isCreateNewCustomerEnabled();
  const isCreateExistingCustomerEnabled = authService.isCreateExistingCustomerEnabled();
  const showDialog = useContext(DialogContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInData>();

  const componentIsMounted = useRef(true);

  const updateLanguage = async (language: Language): Promise<void> => {
      setIsLoading(true);
      const input: UserLanguageUpdateInput = {
        language: language,
      };
      api.updateUserLanguage(input).then(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const handleSubmitLogin = (data: SignInData) => {
    const { logIn } = authService;
    logIn(data.username, data.password)
      .then(() => {
        updateLanguage(language);
        history.replace('/emptying-infos');
      })
      .catch((err: any) => {
        if (err.statusCode === 401) {
          showDialog('error-general-title', 'error-unauthorized-login-message', () => history.replace('/logout'));
        } else {
          showDialog('error-service-break-title', 'error-service-break-message');
        }
      });
  };

  return (
    <Stack
      sx={{
        maxWidth: '450px',
        mx: 4,
        width: '90%',
      }}
    >
      <ProgressComponent isLoading={isLoading} />
      <Typography
        sx={{
          typography: { xs: 'h4', md: 'h3', lg: 'h2', xl: 'h2' },
          mt: { xs: 4, sm: 4, lg: 7, xl: 6 },
        }}
        component={'div'}
      >
        <Typography sx={{ typography: { xs: 'h4', md: 'h3', lg: 'h2', xl: 'h2' } }}>
          {getText('login-info-signin')}
        </Typography>
      </Typography>
      <Typography
        sx={{
          mt: 3,
          mb: { xs: 2, sm: 2, lg: 6, xl: 6 },
        }}
        component={'div'}
      >
        {isCreateNewCustomerEnabled && (
          <>
            <Typography>
              {getText('login-info-no-account')}{' '}
            </Typography>
            <Link to='/register'>
              <Typography sx={{ textDecoration: 'underline' }} display='inline'>
                {getText('login-info-create-account')}
              </Typography>
            </Link>
          </>
        )}
        {isCreateExistingCustomerEnabled && (
          <Typography>
            {getText('login-info-existing-account') + ' '}
            <MuiLink onClick={() => history.push('/register/existing')} component='button'>
              {getText('login-info-create-account-existing')}
            </MuiLink>
          </Typography>
        )}
      </Typography>
      <ControlTextInput
        control={control}
        name='username'
        label='username'
        error={errors.username}
        validations={['required']}
      />
      <ControlTextInput
        control={control}
        name='password'
        label='password'
        error={errors.password}
        inputType='password'
        validations={['required']}
      />

      <Box>
        <Link to='/forgot-username'>
          <Typography variant='body2' sx={{ textDecoration: 'underline' }} display='inline'>
            {getText('forgot-password-forgot-username')}
          </Typography>
        </Link>
      </Box>
      <Box>
        <Link to='/forgot-password'>
          <Typography variant='body2' sx={{ textDecoration: 'underline' }} display='inline'>
            {getText('login-forgot-password')}
          </Typography>
        </Link>
      </Box>
      <Button
        type='submit'
        fullWidth
        variant='contained'
        onClick={handleSubmit((e) => handleSubmitLogin(e))}
        sx={{
          mt: 3,
          mb: 3,
        }}
        color='primary'
        style={{ padding: '14px 0px' }}
      >
        {getText('login-sign-in')}
      </Button>
      {isStrongAuthEnabled && (
        <Typography variant='body1' align='center' component={'div'}>
          <Divider>{getText('login-selection-or')}</Divider>
        </Typography>
      )}
      {isStrongAuthEnabled &&
        <Link to={isStrongAuthEnabled ? '/authenticate' : ''}>
          <Button
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 3,
              backgroundColor: suomiFIBackgroundColor,
              '&:hover': {
                backgroundColor: suomiFIBackgroundColorHover,
                color: '#FFFFFF',
              },
            }}
            style={{ textTransform: 'none', padding: '14px 0px' }}
            disabled={!isStrongAuthEnabled}
          >
            {getText('login-strong-authentication')}
          </Button>
        </Link>
      }
    </Stack>
  );
};

export default SignIn;
