import { useContext } from 'react';
import { Box, Card, CardContent, Stack, Typography, useTheme } from '@mui/material';
import { LanguageContext } from '../../contexts/language-context';
import { EmptyingInfo, FeedbackInfo } from '../../model';
import { printddMMDate } from '../../util/calendarUtil';

interface CustomerServiceFeedbackCardProps {
  feedback: FeedbackInfo;
  locations: EmptyingInfo[] | null | undefined;
}

export const CustomerServiceFeedbackCard = ({ feedback, locations }: CustomerServiceFeedbackCardProps) => {
  const { palette } = useTheme();
  const { getText } = useContext(LanguageContext);

  const currentLocation = locations?.find((location: EmptyingInfo) => location.id === feedback.emptyingInfoId);

  return (
    <>
      <Card sx={{ boxShadow: 0, border: `1px solid ${palette.divider}` }}>
        <CardContent
          sx={{
            paddingLeft: '12px',
          }}
        >
          <Stack direction={'row'} justifyContent={'space-between'} px={1} mb={2} mt={1}>
            <Typography variant='h5'> {feedback.header}</Typography>
          </Stack>
          <Stack px={1}>
            <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              <Typography variant='body2' color='textSecondary' display={'inline'} noWrap>
                {getText('feedback-table-header-message') + ' '}
              </Typography>
              <Typography noWrap variant='body2' color='textPrimary' display={'inline'}>
                {feedback.message}
              </Typography>
            </Box>

            <Box>
              <Typography variant='body2' color='textSecondary' display={'inline'}>
                {getText('feedback-table-header-location') + ' '}
              </Typography>
              <Typography variant='body2' color='textPrimary' display={'inline'}>
                {currentLocation && `${currentLocation.address.street} ${currentLocation.address.postOffice}`}
              </Typography>
            </Box>

            <Box>
              <Typography variant='body2' color='textSecondary' display={'inline'}>
                {getText('feedback-table-header-date') + ' '}
              </Typography>
              <Typography variant='body2' color='textPrimary' display={'inline'}>
                {printddMMDate(feedback.date)}
              </Typography>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};
