import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../contexts/auth-context';
import DialogContext from '../../contexts/dialog-context';
import { LanguageContext } from '../../contexts/language-context';
import { Language } from '../../model';
import HttpError from '../../util/HttpError';
import { Box, Button, Container, Stack, Typography, useTheme, Link } from '@mui/material';
import LogoNavigationLanguageBar from '../../components/common-materialui/header/LogoNavigationLanguageBar';
import { useForm, useWatch } from 'react-hook-form';
import { ForgotPasswordFormData } from './ForgotPassword.model';
import ControlTextInput from '../../components/common-materialui/form/ControlTextInput';
import ProgressComponent from '../../components/common-materialui/ProgressComponent';
import { useMutation } from '@tanstack/react-query';

type ForgotPasswordProps = {
  language: Language;
  setLanguage: (lang: Language) => void;
};

const ForgotPassword = (props: ForgotPasswordProps): JSX.Element => {
  const history = useHistory();
  const { palette } = useTheme();
  const { language, setLanguage } = props;
  const { getText } = useContext(LanguageContext);
  const { forgotPassword } = useContext(AuthContext);
  const showDialog = useContext(DialogContext);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormData>();

  const { mutateAsync: orderNewPassword, isLoading: orderNewPasswordLoading } = useMutation(() =>
    forgotPassword(currentEmail, currentUsername)
  );

  const currentEmail = useWatch({
    control,
    name: 'email',
  });

  const currentUsername = useWatch({
    control,
    name: 'username',
  });

  const handleForgotPassword = async (data: ForgotPasswordFormData) => {
    try {
      await orderNewPassword();
      showDialog('message-success-generic-title', 'forgot-password-ok-message', () => history.replace('/login'));
    } catch (err: unknown) {
      if (err instanceof HttpError) {
        if (
          err.statusCode === 400 &&
          err.response != null &&
          err.response.type != null &&
          err.response.type === '/problems/e-services-users/user-account-not-valid'
        ) {
          showDialog('forgot-password-account-not-valid-title', 'forgot-password-account-not-valid-message');
        }
      } else {
        showDialog('error-general-title', 'error-general-message');
      }
    }
  };

  return (
    <>
      <LogoNavigationLanguageBar />
      <Container>
        <Box
          sx={{
            backgroundColor: palette.background.level2,
            padding: 3,
            maxWidth: '530px',
            margin: 'auto',
            border: `1px solid ${palette.divider}`,
          }}
        >
          <Stack spacing={4}>
            <Stack spacing={2}>
              <Typography variant='h3'>{getText('forgot-password-form-header')}</Typography>
              <Typography variant={'body2'} color={palette.text.primary}>
                {getText('forgot-password-description')}
              </Typography>
            </Stack>
            <Stack spacing={0}>
              <ControlTextInput
                control={control}
                name='username'
                label='username'
                error={errors.username}
                validations={['required']}
              />
              <ControlTextInput
                control={control}
                name='email'
                label='email'
                error={errors.email}
                validations={['required', 'email']}
              />
            </Stack>
            <Stack spacing={3} justifyContent={'center'} textAlign={'center'}>
              <Button
                variant='contained'
                disabled={orderNewPasswordLoading}
                onClick={(e) => void handleSubmit(handleForgotPassword)(e)}
              >
                {orderNewPasswordLoading ? (
                  <ProgressComponent variant='circle' isLoading={orderNewPasswordLoading} />
                ) : (
                  <Typography py={1} variant='button'>
                    {getText('forgot-password-submit-button')}
                  </Typography>
                )}
              </Button>
              <Link component='button' onClick={() => history.push('/')}>
                {getText('forgot-password-return-to-login-link')}
              </Link>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </>
  );
};

export default ForgotPassword;
