import React, { useContext, useState } from 'react';

import { LanguageContext } from '../../contexts/language-context';

import {
  Column,
  CompactColumn,
  Content,
} from '../common/containers';
import LoginHeader from '../common/LoginHeader';
import { Language, CustomerType } from '../../model';
import { SecondaryHeader } from '../common/headers';

import PrivateCustomer from './register/PrivateCustomer';
import Company from './register/Company';
import ExistingAccount from './register/ExistingAccount';
import AuthContext from '../../contexts/auth-context';
import { RenderCustomerTypeSelection } from './register/registerUtils';

type RegisterProps = {
  language: Language;
  setLanguage: (lang: Language) => void;
};

const Register = (props: RegisterProps): JSX.Element => {
  const { language, setLanguage } = props;
  const { getText } = useContext(LanguageContext);
  const authService = useContext(AuthContext);

  const [customerType, setCustomerType] = useState<CustomerType>(null);

  const renderContent = () => {
    switch (customerType) {
      case 'private':
        return <PrivateCustomer />;
      case 'company':
        return <Company />;
      case 'old':
        return <ExistingAccount />;
      default:
        return null;
    }
  };

  return (
    <Column>
      <LoginHeader
        headerKey="create-user"
        descriptionKey="register-description"
        language={language}
        setLanguage={setLanguage}
        backButton={true}
      />
      <CompactColumn>
        <Content>
          <SecondaryHeader>{getText('customer-type-text')}</SecondaryHeader>
          {authService.isAllowRegisterPrivateEnabled() && RenderCustomerTypeSelection('private-customer', 'private', setCustomerType)}
          {authService.isAllowRegisterCompanyEnabled() && RenderCustomerTypeSelection('company-or-community', 'company', setCustomerType)}
          {RenderCustomerTypeSelection('old-customer', 'old', setCustomerType)}
        </Content>

        {renderContent()}
      </CompactColumn>
    </Column>
  );
};

export default Register;
