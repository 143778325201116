import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Language } from '../../model';
import ApiContext from '../../contexts/api-context';
import AuthContext from '../../contexts/auth-context';
import DialogContext from '../../contexts/dialog-context';
import OverlayDialog from '../../components/common-materialui/OverlayDialog';
import RegisterSelection from './registration-saml-wizard/RegisterSelection';

type RegisterSamlProps = {
  id: string;
  nameId: string | null;
  sessionIndex: string | null;
  language: Language;
  setLanguage: Dispatch<SetStateAction<Language>>;
};

const RegisterSaml: React.FC<RegisterSamlProps> = ({ id, nameId, sessionIndex, language, setLanguage }) => {
  const history = useHistory();
  const api = useContext(ApiContext);
  const authService = useContext(AuthContext);
  const showDialog = useContext(DialogContext);
  const [showSamlRegisterDialog, setShowSamlRegisterDialog] = useState<boolean>(true);

  const handleCloseSamlRegisterDialog = useCallback(() => {
    setShowSamlRegisterDialog(false);
    history.push('/');
  }, []);

  useEffect(() => {
    if (sessionIndex != null && nameId != null)
      authService.setSamlSession(nameId, sessionIndex);
  }, [sessionIndex, nameId, authService]);

  useEffect(() => {
    api.fetchIsDuplicatePersonalIdentifier(id).then((isDuplicate) => {
      if (isDuplicate) {
        showDialog(
          "error-register-saml-title",
          "error-register-saml-duplicate-message",
          () => {
            history.replace('/login');
          }
        );
      }
    })
      .catch((err: any) => {
        showDialog(
          'error-service-break-title',
          'error-service-break-message',
          () => { history.replace('/login'); });
      });
  });

  return (
    <>
      <OverlayDialog
        headerTextKey={'register-wizard-header'}
        open={showSamlRegisterDialog}
        onClose={handleCloseSamlRegisterDialog}
      >
        <RegisterSelection
          id={id}
          onClose={handleCloseSamlRegisterDialog}
          language={language}
          setLanguage={setLanguage} />
      </OverlayDialog>
    </>
  );
};

export default RegisterSaml;