import { Container, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Header from '../../components/common-materialui/header/Header';
import { useContext } from 'react';
import { LanguageContext } from '../../contexts/language-context';
import { useQuery } from '@tanstack/react-query';
import ApiContext from '../../contexts/api-context';
import { UserAccountInfo } from '../../model';
import ProgressComponent from '../../components/common-materialui/ProgressComponent';
import ExceptionHandler from '../../components/common-materialui/status-handling/ExceptionHandler';
import { AccountSettingsCard } from './AccountSettingsCard';
import AuthContext from '../../contexts/auth-context';

const AccountSettings = (): JSX.Element => {
  const theme = useTheme();
  const { getText } = useContext(LanguageContext);
  const { fetchUserAccountInfo } = useContext(ApiContext);
  const authService = useContext(AuthContext);

  const largerThanPhone = useMediaQuery(theme.breakpoints.up('md'));
  const headerStackDirection = largerThanPhone ? 'row' : 'column';

  const {
    isLoading,
    data: userAccountInfo,
    error,
  } = useQuery<UserAccountInfo>(['user-account-info'], () => fetchUserAccountInfo());

  const headerContent = () => {
    return (
      <Stack
        spacing={3}
        justifyContent='space-between'
        direction={headerStackDirection}
        marginTop={largerThanPhone ? 1 : 0.75}
        marginBottom={largerThanPhone ? 3 : 2}
      >
        <Typography variant='h2'>
          {getText('account-settings')}
          <Typography variant='body1' mt={1}>
            {`${userAccountInfo?.firstName} ${userAccountInfo?.lastName}, `}
            <span style={{ color: 'grey' }}>{userAccountInfo?.userName}</span>
          </Typography>
        </Typography>
      </Stack>
    );
  };

  return (
    <>
      <Header content={headerContent()} />
      <ProgressComponent isLoading={isLoading} />
      {!isLoading && (
        <ExceptionHandler error={error}>
          <Container>
            <Grid container>
              <AccountSettingsCard
                screenWiderThanPhone={largerThanPhone}
                title='account-settings-user-info-title'
                description='account-settings-user-info-desc'
                icon='user-info'
                userAccountInfo={userAccountInfo}
              />
              <AccountSettingsCard
                screenWiderThanPhone={largerThanPhone}
                title='account-settings-login-info-title'
                description='account-settings-login-info-desc'
                icon='login-info'
                userAccountInfo={userAccountInfo}
              />
              {authService.isDevelopmentEnabled() && (
                <AccountSettingsCard
                  screenWiderThanPhone={largerThanPhone}
                  title='account-settings-billing-info-title'
                  description='account-settings-billing-info-desc'
                  icon='billing-info'
                  userAccountInfo={userAccountInfo}
                />
              )}
              <AccountSettingsCard
                screenWiderThanPhone={largerThanPhone}
                title='account-settings-general-info-title'
                description='account-settings-general-info-desc'
                icon='general-info'
                userAccountInfo={userAccountInfo}
              />
            </Grid>
          </Container>
        </ExceptionHandler>
      )}
    </>
  );
};

export default AccountSettings;
