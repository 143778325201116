export const fieldConversion: { [key: string]: string; } = {
  'address.street': 'address',
  'address.postalCode': 'postCode',
  'address.postOffice': 'city',
  'contactPerson.email': 'emailAddress',
  'contactPerson.name': 'contactName',
  'contactPerson.phoneNumber': 'phoneNumber'
};

export const parseFlattenedDataForSchema = (flatted: any, type?: string) => {
  let cleaned: any = {};
  const isCompost: boolean = type === "Compost";
  for (const property in flatted) {
    let value = flatted[property];

    // Special conversion for name field for splitting 
    // (can be removed if and when billing-info return value is splitted properly,
    // but even if it is left, it shouldn't broke anything )

    if (property == "name") {
      if (flatted.isCompany) {
        cleaned["companyName"] = value;
      }
      else {
        let splittedValue = value.split(' ');
        cleaned["lastName"] = splittedValue[0];
        cleaned["firstName"] = splittedValue[1];
      }

      continue;
    }

    let converted = fieldConversion[property];
    if (converted === "postCode" && isCompost) {
      cleaned["postalCode"] = value;
    }
    if (converted === "city" && isCompost) {
      cleaned["postTown"] = value;
    }
    if (converted != null) {
      cleaned[converted] = value;
      continue;
    }
    cleaned[property] = value;
  }
  
  return cleaned;
}