import { useCallback, useMemo, useState } from 'react';
import OrderProductCategory from './order-product-wizard/OrderProductCategory';
import OrderProductCompleteService from './order-product-wizard/OrderProductCompleteService';
import OrderProductSummary from './order-product-wizard/OrderProductSummary';
import PhaseBar, { Phase } from '../../../components/common-materialui/PhaseBar';
import { VingoProduct, PriceCalculationResult, BinOwnership } from '../../../model';

export interface WeekInterval {
  startWeek: string;
  endWeek: string;
  timesPerWeek: string;
  interval: string;
}
export interface FormDataFinishOrder {
  additionalInformation: string | undefined;
  // using empty string as unset value, to avoid uncontrolled form component warning
  containerOwnership: BinOwnership | string; 
  containerSize: string;
  contractStartDate: Date;
  gateKeyCode: string | undefined;
  numberOfContainers: string;
  wasteType: string;
}

interface Props {
  emptyingId: string;
  onClose: () => void;
}

const FormDataInitialState: FormDataFinishOrder = {
  additionalInformation: undefined,
  containerOwnership: '',
  containerSize: '',
  contractStartDate: new Date(),
  gateKeyCode: undefined,
  numberOfContainers: '1',
  wasteType: '',
};

const OrderProduct = ({ emptyingId, onClose }: Props): JSX.Element => {
  const [currentPhase, setCurrentPhase] = useState<Phase>(Phase.SelectProduct);
  const [categoryId, setCategoryId] = useState<string>('');
  const [formDataFinishOrder, setFormDataFinishOrder] = useState<FormDataFinishOrder>(FormDataInitialState);
  const [priceFinishOrder, setPriceFinishOrder] = useState<PriceCalculationResult | undefined>();
  const [containerSizeFinishOrder, setContainerSizeFinishOrder] = useState<VingoProduct | null | undefined>();
  const [weekIntervalFinishOrder, setWeekIntervalFinishOrder] = useState<WeekInterval[] | undefined>();

  const phases = useMemo(() => [Phase.SelectProduct, Phase.FinishOrder, Phase.Summary], []);

  const handleWizardNavigation = (phaseStep: number): void => {
    const currentPhaseIndex = phases.findIndex((phase) => phase === currentPhase);

    if (currentPhaseIndex < phases.length - 1 || (currentPhaseIndex === phases.length - 1 && phaseStep === -1)) {
      setCurrentPhase(phases[currentPhaseIndex + phaseStep]);
      document.getElementById('phase-bar-element')?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const goToNextPhase = useCallback(() => {
    handleWizardNavigation(1);
  }, [currentPhase, phases]);

  const goToPreviousPhase = useCallback(() => {
    handleWizardNavigation(-1);
  }, [[currentPhase, phases]]);

  const renderPhaseForm = () => {
    switch (currentPhase) {
      case Phase.SelectProduct:
        return (
          <OrderProductCategory
            onSelectedCategoryId={setCategoryId}
            emptyingId={emptyingId}
            onPhaseChange={goToNextPhase}
            onCancel={onClose}
          />
        );
      case Phase.FinishOrder:
        return (
          <OrderProductCompleteService
            emptyingId={emptyingId}
            categoryId={categoryId}
            onNextPhaseChange={goToNextPhase}
            onCancel={onClose}
            onPreviousPhaseChange={goToPreviousPhase}
            formDataFinishOrder={formDataFinishOrder}
            setFormDataFinishOrder={setFormDataFinishOrder}
            setPriceFinishOrder={setPriceFinishOrder}
            priceFinishOrder={priceFinishOrder}
            setContainerSizeFinishOrder={setContainerSizeFinishOrder}
            containerSizeFinishOrder={containerSizeFinishOrder}
            setWeekIntervalFinishOrder={setWeekIntervalFinishOrder}
            weekIntervalFinishOrder={weekIntervalFinishOrder}
          />
        );
      case Phase.Summary:
        return (
          <OrderProductSummary
            emptyingId={emptyingId}
            formDataFinishOrder={formDataFinishOrder}
            priceFinishOrder={priceFinishOrder}
            containerSizeFinishOrder={containerSizeFinishOrder}
            weekIntervalFinishOrder={weekIntervalFinishOrder}
            onCancel={onClose}
            onPreviousPhaseChange={goToPreviousPhase}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <PhaseBar phases={phases} currentPhase={currentPhase} setCurrentPhase={setCurrentPhase} />
      {renderPhaseForm()}
    </>
  );
};

export default OrderProduct;
