import { EServiceLanguage } from './service/authService';

export type Language = 'fi' | 'sv' | 'en';

export const convertToEServiceLanguage = (language: Language | string | undefined): EServiceLanguage => {
  if (!language) {
    return EServiceLanguage.Fi;
  }
  const newValue = language.toLocaleLowerCase();
  switch (newValue) {
    case 'sv':
      return EServiceLanguage.Sv;
    case 'en':
      return EServiceLanguage.En;
    case 'fi':
    default:
      return EServiceLanguage.Fi;
  }
};

export const convertToLanguage = (language: EServiceLanguage | string | undefined): Language => {
  if (!language) {
    return 'fi';
  }
  const newValue = language.toLocaleLowerCase();
  switch (newValue) {
    case 'fi':
    case 'sv':
    case 'en':
      return newValue;
    default:
      return 'fi';
  }
};

export type Translations = { fi: string; sv: string; en: string; };

export type TranslationsObject = {
  [Key: string]: Translations;
};

export type BillType = 'paper' | 'email' | 'eBill';

export type ContactMethod = 'email' | 'phone' | 'none';

export type TemplateType = 'EmptyingPrivate' | 'EmptyingCompany' | 'BillingPrivate' | 'BillingCompany';

export enum EmptyingEventEnum {
  Missed = 'Missed',
  Successful = 'Successful',
  Planned = 'Planned',
}

export type ViewBreakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type ViewBreakpoints = {
  [key in ViewBreakpoint]: number;
};

export type CustomerType = 'private' | 'company' | 'new' | 'old' | 'old-ssn' | null;

export enum WasteUnitType {
  Unknown = 'Unknown',
  Piece = 'Piece',
  Kilogram = 'Kilogram',
  Ton = 'Ton',
  Liter = 'Liter',
  CubicMeter = 'CubicMeter',
  SquareMeter = 'SquareMeter',
  Meter = 'Meter',
  Kilometer = 'Kilometer',
  MegaWattHour = 'MegaWattHour',
  Hour = 'Hour',
  Day = 'Day',
  Month = 'Month',
  Year = 'Year',
}

export enum ConsumerEInvoiceType {
  Unknown = 'Unknown',
  ConsumerChannel = 'ConsumerChannel', // Not allowed to change in UI
  DirectInvoicing = 'DirectInvoicing', // Not allowed to change in UI
  EmailInvoicing = 'EmailInvoicing',
  KivraInvoicing = 'KivraInvoicing',   // Not allowed to change in UI
  NotInUse = 'NotInUse',
}

export enum ExtendedInvoiceMethodType {
  // Only for internal use in UI. Not used in API.
  OnlineInvoicing = 'OnlineInvoicing',
  PaperBillInvoicing = 'PaperBillInvoicing',
}

// This is a union type of all invoicing methods for internal use in UI.
export type AllInvoicingMethodTypes = ConsumerEInvoiceType | ExtendedInvoiceMethodType;

export enum ServiceType {
  Contract = 'Contract',
  WaitingContract = 'WaitingContract',
  SharedContainerContract = 'SharedContainerContract',
  Order = 'Order',
}

export type UiTexts = {
  'selected-language': Translations;
  'language-finnish': Translations;
  'language-swedish': Translations;
  'language-english': Translations;
  'language-chosen-finnish': Translations;
  'language-chosen-swedish': Translations;
  'language-chosen-english': Translations;
  'not-yet-implemented': Translations;
  // Error messages
  'error-resource-not-found-title': Translations;
  'error-resource-not-found-message': Translations;
  'error-fetching-file': Translations;
  'error-general-message': Translations;
  'error-general-title': Translations;
  'error-service-break-title': Translations;
  'error-service-break-message': Translations;
  'error-login-refresh': Translations;
  'error-unauthorized-title': Translations;
  'error-unauthorized-login-message': Translations;
  'error-unauthorized-message': Translations;
  'error-register-saml-duplicate-message': Translations;
  'error-register-saml-title': Translations;
  'error-register-invoice-not-found-title': Translations;
  'error-register-invoice-not-found-message': Translations;
  'error-register-user-account-already-registered-title': Translations;
  'error-register-user-account-already-registered-message': Translations;
  'error-identity-operation-failed': Translations;
  'error-loginfailed-login-message': Translations;
  'error-single-logout-failed-message': Translations;
  'error-incorrect-login-password': Translations;
  'single-logout-success-message': Translations;
  'login-header-interrupted': Translations;
  'single-login-interrputed': Translations;
  'error-billing-account-expired-title': Translations;
  'error-billing-account-expired-message': Translations;
  'no-connectable-accounts-found-title': Translations;
  'no-connectable-accounts-found-message': Translations;
  // Messages
  'message-success-generic-title': Translations;
  'message-data-saved': Translations;
  'message-confirmation-failed': Translations;
  'message-forgot-password-reset-link-send-failed': Translations;

  // Login
  login: Translations;
  email: Translations;
  username: Translations;
  password: Translations;
  'own-waste': Translations;
  loginDecription: Translations;
  'login-info-welcome': Translations;
  'login-info-signin': Translations;
  'login-info-header': Translations;
  'login-info-text': Translations;
  'login-info-no-account': Translations;
  'login-info-existing-account': Translations;
  'login-info-create-account': Translations;
  'login-info-create-account-existing': Translations;
  'login-info-less-than-minute': Translations;
  'login-forgot-password': Translations;
  'login-sign-in': Translations;
  'login-selection-or': Translations;
  'login-strong-authentication': Translations;
  'login-info-box-1-title': Translations;
  'login-info-box-1-text': Translations;
  'login-info-box-2-title': Translations;
  'login-info-box-2-text': Translations;
  'register-now': Translations;
  'strong-authentication': Translations;
  'forgot-pw': Translations;
  'login-send-feedback': Translations;
  'login-error-dialog-title': Translations;
  'login-error-dialog-message': Translations;
  'logout-header': Translations;

  // Login feedback
  'feedback-header': Translations;
  'feedback-header-description': Translations;
  feedback: Translations;
  'feedback-contact-method': Translations;
  'feedback-message-header': Translations;
  'feedback-message': Translations;
  'feedback-message-category': Translations;
  'feedback-contact-method-phone': Translations;
  'feedback-contact-method-email': Translations;
  'feedback-contact-method-none': Translations;
  'feedback-email': Translations;
  'feedback-phone': Translations;
  'feedback-send-button': Translations;
  'feedback-sent-message': Translations;

  // Customer service (Asiakaspalvelu)
  'customer-service-header': Translations;
  'customer-service-header-description': Translations;
  'customer-service-contact-us-title': Translations;
  'customer-service-emptying-location-placeholder': Translations;
  'customer-service-subject-placeholder': Translations;
  'customer-service-message-placeholder': Translations;
  'cusomer-service-contact-message': Translations;
  'customer-service-contact-option-placeholder': Translations;
  'customer-service-contact-option-phone-value': Translations;
  'customer-service-contact-option-phone-placeholder': Translations;
  'customer-service-contact-option-email-value': Translations;
  'customer-service-contact-option-email-placeholder': Translations;
  'customer-service-contact-options-no-contact-value': Translations;
  'customer-service-send-message': Translations;
  'customer-service-confirmation-title': Translations;
  'customer-service-confirmation-message': Translations;
  'feedback-emptying-info': Translations;
  'feedback-table-header-subject': Translations;
  'feedback-table-header-message': Translations;
  'feedback-table-header-location': Translations;
  'feedback-table-header-date': Translations;

  // Register wizard
  'register-wizard-header': Translations;
  'select-register-type': Translations;
  'select-register-i-am': Translations;
  'complete-registration': Translations;

  // Register
  'create-user': Translations;
  'create-account': Translations;
  'register-description': Translations;
  'register-new-account-description': Translations;
  'register-existing-account-description': Translations;
  'register-customership-information': Translations;
  'register-customership-information-description': Translations;
  'customer-type-text': Translations;
  'private-customer': Translations;
  'company-or-community': Translations;
  'old-customer': Translations;
  'old-customer-ssn': Translations;
  'new-customer': Translations;
  'basic-info': Translations;
  'basic-info-company': Translations;
  'basic-info-modify': Translations;
  'basic-info-username': Translations;
  'basic-info-name': Translations;
  'basic-info-first-name': Translations;
  'basic-info-last-name': Translations;
  'basic-info-customer-number': Translations;
  'basic-info-invoice-number': Translations;
  'basic-info-phone': Translations;
  'basic-info-email': Translations;
  'basic-info-ssn': Translations;
  'basic-info-company-id': Translations;
  'basic-info-customer-name': Translations;
  'basic-info-language': Translations;
  'username-and-pw': Translations;
  'password-minimum12': Translations;
  'confirm-pw': Translations;
  'pw-info': Translations;
  'billing-data': Translations;
  'billing-data-edit': Translations;
  'billing-data-save': Translations;
  'billing-data-save-cancel': Translations;
  'billing-data-street-address': Translations;
  'billing-data-post-code': Translations;
  'billing-data-city': Translations;
  'billing-data-country': Translations;
  'billing-data-billing-contact-person': Translations;
  'billing-data-billing-contact-person-use-same-data': Translations;
  'billing-data-billing-contact-person-firstname': Translations;
  'billing-data-billing-contact-person-lastname': Translations;
  'billing-data-billing-contact-person-phonenumber': Translations;
  'billing-data-billing-contact-person-email': Translations;
  'billing-data-billing-method': Translations;
  'billing-data-billing-method-question': Translations;
  'billing-data-billing-method-paper': Translations;
  'billing-data-billing-method-email': Translations;
  'billing-data-billing-method-e-invoice': Translations;
  'billing-data-customership-info': Translations;
  'paper-bill': Translations;
  'email-bill': Translations;
  'email-bill-summary': Translations;
  'e-bill': Translations;
  'e-bill-private': Translations;
  'e-direct-payment': Translations;
  'i-have-read': Translations;
  conditions: Translations;
  'contract-conditions': Translations;
  register: Translations;
  'contact-person': Translations;
  'billing-email': Translations;
  'e-bill-address': Translations;
  broker: Translations;
  ovt: Translations;

  // Forgot username
  'forgot-username-header': Translations;
  'forgot-username-description': Translations;
  'forgot-username-form-header': Translations;
  'forgot-username-return-to-login-link': Translations;
  'forgot-username-send-button': Translations;
  'forgot-username-ok-message': Translations;

  // Forgot password
  'forgot-password-header': Translations;
  'forgot-password-description': Translations;
  'forgot-password-form-header': Translations;
  'forgot-password-forgot-username': Translations;
  'forgot-password-return-to-login-link': Translations;
  'forgot-password-submit-button': Translations;
  'forgot-password-ok-message': Translations;
  'forgot-password-failed-message': Translations;
  'forgot-password-account-not-valid-title': Translations;
  'forgot-password-account-not-valid-message': Translations;

  // Reset Password
  'reset-password-header': Translations;
  'reset-password-form-header': Translations;
  'reset-password-description': Translations;
  'reset-password-ok-message': Translations;
  'reset-password-send-button': Translations;
  'reset-password-identity-failed-message': Translations;
  'reset-password-identity-failed-header': Translations;

  // Emptying infos (Omat kohteet)
  'search-bar-label': Translations;
  'emptying-infos': Translations;
  'emptying-infos-desc': Translations;
  'your-climate-level': Translations;
  'your-climate-level-desc': Translations;
  'your-climate-level-more-info': Translations;
  'add-emptying-info-link': Translations;
  'add-billing-info-link': Translations;
  'emptying-locations-no-emptyings-found-header': Translations;
  'emptying-locations-no-emptyings-found-description': Translations;

  // Billing info
  'billing-info-header': Translations;
  'billing-info-header-desc': Translations;
  'billing-info-existing-selector': Translations;
  'billing-info-existing-selector-dropdown-label': Translations;
  'billing-info-existing-exists': Translations;
  'billing-info-existing-new': Translations;
  'billing-info-type-selector': Translations;
  'billing-info-type-private': Translations;
  'billing-info-type-company': Translations;
  'billing-info-send': Translations;
  'billing-info-invoicing-type-title': Translations;
  'billing-info-invoicing-type-e-invoicing': Translations;
  'billing-info-invoicing-type-direct-payment': Translations;
  'billing-info-invoicing-type-kivra': Translations;

  // Emptying info
  'emptying-info-header': Translations;
  'emptying-info-header-desc': Translations;
  'emptying-info-type-selector': Translations;
  'emptying-info-copy-billing-info': Translations;
  'emptying-info-send': Translations;
  'emptying-info-send-success-title': Translations;
  'emptying-info-send-success-message': Translations;

  // Emptying address
  // Links
  'emptying-address-order': Translations;
  'emptying-address-edit': Translations;
  'emptying-address-billing-edit': Translations;
  'emptying-address-emptying-edit': Translations;
  'emptying-address-edit-contact-person': Translations;
  'emptying-address-contract-history': Translations;
  'emptying-address-billing-address': Translations;

  //Emptying location order product dialog
  'emptying-location-order-product': Translations;
  'emptying-location-order-product-header': Translations;
  'emptying-location-order-product-select-phase': Translations;
  'emptying-location-order-product-select-phase-header': Translations;
  'emptying-location-order-product-select-phase-header-no-services': Translations;
  'emptying-location-order-product-finish-phase': Translations;
  'emptying-location-order-product-summary-phase': Translations;
  'emptying-location-order-product-category-header': Translations;
  'emptying-location-order-product-no-categories-header': Translations;
  'emptying-location-order-product-category-contract': Translations;
  'emptying-location-order-product-category-order': Translations;

  // Emptying location (new layout)
  'emptying-location-order-new-service': Translations;
  'emptying-location-service-list-service': Translations;
  'emptying-location-service-list-service-plural': Translations;
  'emptying-location-service-list-order': Translations;
  'emptying-location-service-list-order-plural': Translations;
  'emptying-location-contract-next-collection': Translations;
  'emptying-location-contract-amount': Translations;
  'emptying-location-contract-amount-pcs': Translations;
  'emptying-location-contract-interval': Translations;
  'emptying-location-contract-waiting-emptying-rhythm': Translations;
  'emptying-location-contract-price-pcs': Translations;
  'emptying-location-contract-price-ton': Translations;
  'emptying-location-contract-waiting-approve': Translations;
  'emptying-location-orders-date-range': Translations;
  'emptying-location-order-date': Translations;
  'emptying-location-order-requested-date': Translations;
  'emptying-location-order-info': Translations;
  'emptying-location-order-status-received': Translations;
  'emptying-location-order-status-processed': Translations;
  'emptying-location-order-status-done': Translations;
  'emptying-location-billing-data': Translations;
  'emptying-location-billing-contact-person': Translations;
  'emptying-location-contact-person': Translations;
  'emptying-location-contract-price-bin-emptying': Translations;
  'emptying-location-contract-price-waste-disposal': Translations;
  'emptying-location-contract-price-total': Translations;
  'emptying-location-contract-information': Translations;
  'emptying-location-approve-contract-dialog-title': Translations;
  'emptying-location-approve-contract-dialog-text': Translations;
  'emptying-location-approve-contract-dialog-approve-button': Translations;
  'emptying-location-approve-contract-dialog-cancel-button': Translations;
  'emptying-location-reject-contract-dialog-title': Translations;
  'emptying-location-reject-contract-dialog-text': Translations;
  'emptying-location-reject-contract-dialog-approve-button': Translations;
  'emptying-location-reject-contract-dialog-cancel-button': Translations;

  // Modify emptying location info
  'modify-emptying-location-header': Translations;
  'modify-emptying-info-bin-location-contact': Translations;
  'modify-emptying-location-save': Translations;
  'modify-emptying-location-cancel': Translations;
  // Modify billing info
  'modify-billing-info-header': Translations;

  // Add emptying location (New Layout)
  'add-emptying-location-header': Translations;
  'add-emptying-location-billing-data': Translations;
  'add-emptying-location-data': Translations;
  'add-emptying-location-summary': Translations;
  'add-emptying-location-phase': Translations;
  'add-emptying-location-cancel': Translations;
  'add-emptying-location-save': Translations;
  'add-emptying-location-close': Translations;
  'add-emptying-location-next': Translations;
  'add-emptying-location-previous': Translations;

  'add-emptying-location-basic-info': Translations;
  'add-emptying-location-basic-info-use-same-name-data': Translations;
  'add-emptying-location-basic-info-first-name': Translations;
  'add-emptying-location-basic-info-last-name': Translations;
  'add-emptying-location-basic-info-street-address': Translations;
  'add-emptying-location-basic-info-post-code': Translations;
  'add-emptying-location-basic-info-city': Translations;

  'add-emptying-location-contact': Translations;
  'add-emptying-location-use-same-contact-data': Translations;
  'add-emptying-location-contact-phone': Translations;
  'add-emptying-location-contact-email': Translations;

  'add-emptying-location-building-info': Translations;
  'add-emptying-location-building-info-area': Translations;
  'add-emptying-location-building-info-area-error': Translations;
  'add-emptying-location-building-info-conurbation': Translations;
  'add-emptying-location-building-info-conurbation-error': Translations;
  'add-emptying-location-building-info-permanent-id': Translations;
  'add-emptying-location-building-info-use': Translations;
  'add-emptying-location-building-info-type': Translations;
  'add-emptying-location-building-info-apartment-count': Translations;
  'add-emptying-location-building-info-population': Translations;
  'add-emptying-location-building-info-property-id': Translations;
  'add-emptying-location-building-info-usage-data-error': Translations;

  'add-emptying-location-contract-info': Translations;
  'add-emptying-location-contract-info-start-date': Translations;
  'add-emptying-location-contract-info-reference-number': Translations;

  // Emptying group
  'emptying-group-continuing-contracts': Translations;
  'emptying-group-shared-container-contracts': Translations;
  'emptying-group-ended-contracts': Translations;
  'emptying-group-orders-date-range': Translations;
  'emptying-group-desc': Translations;
  'emptying-group-shared-container-responsible-person': Translations;
  'emptying-group-shared-container-shareholders-table-header': Translations;
  'emptying-group-shared-container-address-table-header': Translations;
  'emptying-group-shared-container-share-table-header': Translations;
  'emptying-group-shared-container-weighing-table-header': Translations;
  'emptying-group-shared-container-role': Translations;
  'emptying-group-shared-container-role-corresponding': Translations;
  'emptying-group-shared-container-role-participant': Translations;
  'emptying-group-shared-container-product': Translations;

  // Emptying customer
  'emptying-customer-type': Translations;
  'emptying-customer-type-select': Translations;

  // Order item
  'order-ordered-label': Translations;
  'order-waiting': Translations;
  'order-being-transported': Translations;
  'order-requested-date': Translations;
  'order-order-date': Translations;
  'order-approved': Translations;
  'order-done': Translations;
  'order-saved': Translations;
  'order-number': Translations;

  // Contract item
  'contract-collect-time': Translations;
  'contract-amount-of-bins': Translations;
  'contract-interval': Translations;
  'contract-interval-on-demand': Translations;
  'contract-interval-once-a-week': Translations;
  'contract-interval-twice-a-week': Translations;
  'contract-interval-three-times-a-week': Translations;
  'contract-interval-four-times-a-week': Translations;
  'contract-interval-five-times-a-week': Translations;
  'contract-interval-every-x-weeks': Translations;
  'contract-interval-multiple-times-per-week': Translations;
  'contract-interval-times-per-week': Translations;
  'contract-interval-every-second-week': Translations;
  'contract-interval-multiple-weeks': Translations;
  'contract-times-per-week': Translations;

  // Contract details
  'contract-details-change-contract': Translations;

  'contract-details-emptying-intervals': Translations;
  'contract-details-emptying-single-interval': Translations;
  'contract-details-emptying-dates': Translations;
  'contract-details-num-of-bins': Translations;
  'contract-details-emptying-price': Translations;
  'contract-details-waste-disposal-fee': Translations;
  'contract-details-total-price': Translations;
  'contract-details-approve': Translations;
  'contract-details-approved-message-title': Translations;
  'contract-details-approved-message': Translations;
  'contract-details-modification-message': Translations;
  'contract-details-reject': Translations;
  'contract-confirm-reject-title': Translations;
  'contract-confirm-reject-message': Translations;
  'contract-confirm-reject-yes': Translations;
  'contract-confirm-reject-no': Translations;
  'contract-details-reject-message-title': Translations;
  'contract-details-reject-message': Translations;
  'contract-details-edit': Translations;
  'contract-details-confirm-changes': Translations;
  'contract-details-cancel-changes': Translations;
  'contract-details-change-applies-to': Translations;
  'contract-details-number-of-bins': Translations;
  'contract-details-number-of-bins-title': Translations;
  'contract-details-number-of-interval': Translations;
  'contract-details-pause': Translations;
  'contract-details-terminating': Translations;
  'contract-details-terminating-title': Translations;
  'contract-details-terminating-date-of-termination': Translations;
  'contract-details-terminating-reason-for-termination': Translations;
  'contract-details-terminating-additional-information': Translations;
  'contract-details-problems-emptying-interval-not-valid': Translations;
  'contract-details-problems-emptying-interval-not-defined': Translations;
  'contract-details-pause-form-header': Translations;
  'contract-details-pause-form-guide-text': Translations;
  'contract-details-pause-form-start-date': Translations;
  'contract-details-pause-form-end-date': Translations;
  'contract-details-pause-form-reason': Translations;
  'contract-details-driver-instructions': Translations;
  'contract-details-driver-instructions-gate-key': Translations;

  //Contract Calendar
  'contract-calendar-header': Translations;
  'contract-calendar-popover-date-and-time': Translations;
  'contract-calendar-popover-at': Translations;
  'contract-calendar-popover-status': Translations;
  'contract-calendar-popover-next-status': Translations;
  'contract-calendar-popover-successful-status': Translations;
  'contract-calendar-popover-missed-status': Translations;
  'contract-calendar-popover-planned-status': Translations;
  'contract-calendar-popover-feedback': Translations;
  'contract-shared-corresponding-person-approval-waiting': Translations;

  // Change contract
  'change-contract-change-what': Translations;
  'change-contract-header-desc': Translations;
  'change-contract-change-bins': Translations;
  'change-contract-change-interval': Translations;
  'change-contract-change-pause': Translations;
  'change-contract-change-ending': Translations;
  'change-contract-effective': Translations;
  'change-contract-additional-info': Translations;
  'change-contract-interval': Translations;
  'change-contract-new-interval': Translations;
  'change-contract-interval-start': Translations;
  'change-contract-interval-end': Translations;
  'whole-year-interval': Translations;
  'change-contract-interval-another-interval': Translations;
  'change-contract-interval-1': Translations;
  'change-contract-interval-2': Translations;
  'change-contract-weeks': Translations;
  'change-contract-allowed-intervals': Translations;
  'change-contract-interval-succeed': Translations;
  'change-contract-interval-overlap-error': Translations;
  'change-contract-pause-title': Translations;
  'change-contract-pause-desc': Translations;
  'change-contract-pause-start': Translations;
  'change-contract-pause-end': Translations;
  'change-contract-end-title': Translations;
  'change-contract-end-date': Translations;
  'change-contract-end-button': Translations;
  'change-contract-message-title': Translations;
  'change-contract-message-ending': Translations;
  'change-contract-message-pause': Translations;
  'change-contract-amount-title': Translations;
  'change-contract-amount-amount': Translations;
  'change-contract-message-amount': Translations;

  // Additional collection
  'order-additional-collection-dialog-header': Translations;
  'order-additional-collection-form-header': Translations;
  'order-additional-collection-cancel-button': Translations;
  'order-additional-collection-submit-button': Translations;
  'order-additional-collection-date': Translations;
  'order-additional-collection-amount': Translations;
  'order-additional-collection-info': Translations;

  // Extra emptying
  'extra-emptying-order': Translations;
  'extra-emptying-description': Translations;
  'extra-emptying-form-title': Translations;
  'extra-emptying-date': Translations;
  'extra-emptying-date-description': Translations;
  'extra-emptying-num-of-bins': Translations;
  'extra-emptying-additional-info': Translations;
  'extra-emptying-additional-info-placeholder': Translations;
  'extra-emptying-order-button': Translations;
  'extra-emptying-invalid-date': Translations;

  // Service Order (Tilaa palvelu)
  'service-order-label': Translations;
  'service-order-desc': Translations;
  'service-order-no-products-title': Translations;
  'service-order-no-products-text': Translations;
  'service-order-single-orders': Translations;
  'service-order-contracts': Translations;
  'service-order-contracts-waiting-for-approval': Translations;
  'service-order-button': Translations;
  'service-order-confirm-button': Translations;
  'service-order-alv-included': Translations;
  'service-order-goto-basket': Translations;
  'service-order-products-in-basket': Translations;
  'service-order-products-stickers': Translations;
  'service-order-basket': Translations;
  'service-order-basket-description': Translations;
  'service-order-continue-shopping': Translations;
  'service-order-alv': Translations;
  'service-order-total-sum': Translations;
  'service-order-thanks': Translations;
  'service-order-back-to-shopping': Translations;
  'service-order-summary-title': Translations;
  'service-order-pickup-title': Translations;
  'service-order-pickup-description': Translations;
  'service-order-pickup': Translations;
  'service-order-pickup-date': Translations;
  'service-order-product-group': Translations;
  'service-order-product-entity': Translations;
  'service-order-product-amount': Translations;
  'service-order-pickup-waste-type-title': Translations;
  'service-order-pickup-additional-info': Translations;
  'service-order-pickup-price': Translations;
  'service-order-pickup-price-pickup': Translations;
  'service-order-pickup-price-waste-handling': Translations;
  'service-order-pickup-price-total': Translations;
  'service-order-pickup-add-order': Translations;
  'service-order-pickup-remove-order': Translations;
  'service-order-dumpster': Translations;
  'service-order-importation-date': Translations;
  'service-order-dumpster-unknown-pickup-date': Translations;
  'service-order-dumpster-location': Translations;
  'service-order-dumpster-size': Translations;
  'service-order-dumpster-size-small': Translations;
  'service-order-dumpster-size-medium': Translations;
  'service-order-dumpster-size-big': Translations;
  'service-order-dumpster-waste-type': Translations;
  'service-order-dumpster-waste-type-bio': Translations;
  'service-order-dumpster-waste-type-mixed': Translations;
  'service-order-dumpster-waste-type-energy': Translations;
  // Add contract (Tilaa palvelu -> Astian tyhjennyspalvelu)
  'add-contract-wastetype-header': Translations;
  'add-contract-wastetype-desc': Translations;
  'add-contract-finish-order': Translations;
  'add-contract-finish-order-desc': Translations;
  'add-contract-start-date': Translations;
  'add-contract-amount-per-week': Translations;
  'add-contract-service-title': Translations;
  'add-contract-bin-amount': Translations;
  'add-contract-interval': Translations;
  'add-contract-bin-ownership-label': Translations;
  'add-contract-bin-ownership-rent': Translations;
  'add-contract-bin-ownership-buy': Translations;
  'add-contract-bin-ownership-owned': Translations;
  'add-contract-bin-ownership-delivery': Translations;
  'add-contract-for-driver': Translations;
  'add-contract-for-driver-key-code': Translations;
  'add-contract-for-driver-info': Translations;
  'add-contract-price': Translations;
  'add-contract-price-emptying': Translations;
  'add-contract-price-waste-management': Translations;
  'add-contract-price-bin': Translations;
  'add-contract-price-total': Translations;
  'add-new-contract-success-message': Translations;
  'add-new-contract-failed-message': Translations;

  // Contracts (Tilaushistoria)
  'contracts-label': Translations;
  'contracts-desc': Translations;

  // Account settings

  'account-settings': Translations;
  'account-settings-user-info-title': Translations;
  'account-settings-user-info-desc': Translations;
  'account-settings-edit-user-info-header': Translations;
  'account-settings-edit-user-info-guide-text': Translations;
  'account-settings-edit-user-info-form-header': Translations;
  'account-settings-login-info-title': Translations;
  'account-settings-login-info-desc': Translations;
  'account-settings-edit-login-info-header': Translations;
  'account-settings-edit-login-info-guide-text': Translations;
  'account-settings-edit-login-info-form-header': Translations;
  'account-settings-billing-info-title': Translations;
  'account-settings-billing-info-desc': Translations;
  'account-settings-general-info-title': Translations;
  'account-settings-general-info-desc': Translations;
  'account-settings-edit-general-info-header': Translations;
  'account-settings-edit-general-info-guide-text': Translations;
  'account-settings-edit-general-info-form-header': Translations;
  'account-settings-edit-general-info-form-options-header': Translations;

  // User account data (Omat tiedot)
  'user-account': Translations;
  'user-account-private-customer-desc': Translations;
  'user-account-company-desc': Translations;
  'user-account-change-pw': Translations;
  'user-account-current-pw': Translations;
  'user-account-new-pw': Translations;
  'user-account-confirm-new-pw': Translations;
  'user-account-save': Translations;

  // Customer Service (Asiakastuki)
  'customer-service': Translations;

  // Invoices (Laskut)
  invoices: Translations;
  'invoices-header-description': Translations;
  'invoice-item-invoice-date': Translations;
  'invoice-item-open-title': Translations;
  'invoice-item-paid-title': Translations;
  'invoice-item-due-date': Translations;
  'invoice-item-invoice-number': Translations;
  'invoice-item-invoice-amount-completed': Translations;
  'invoice-item-invoice-pdf': Translations;
  'invoice-page-no-invoices-found-header': Translations;
  'invoice-page-no-invoices-found-description': Translations;
  'invoice-table-header-sum': Translations;
  'invoice-table-header-date': Translations;
  'invoice-table-header-due-date': Translations;
  'invoice-table-header-invoice-number': Translations;
  'invoice-table-header-payment': Translations;
  'invoice-table-header-payment-date': Translations;
  'invoice-table-header-invoice': Translations;
  'invoice-item-payment-date': Translations;

  // Template
  'template-link': Translations;
  'template-header': Translations;
  'template-header-desc': Translations;
  'template-account-creation-success': Translations;
  'array-add-button': Translations;
  'array-remove-button': Translations;

  // Template errors
  'template-error-required': Translations;
  'template-error-min-length': Translations;
  'template-error-min-items': Translations;
  'template-error-max-length': Translations;
  'template-error-pattern': Translations;
  'template-error-numbers-only': Translations;
  'template-error-format-date': Translations;
  'template-error-date-format-too-far-past': Translations;
  'template-error-date-format-too-far-future': Translations;
  'template-error-type-string': Translations;
  'template-error-type-array': Translations;
  'template-error-date-format-invalid': Translations;
  'template-error-format-email': Translations;
  'template-error-number-value-too-small': Translations;
  'template-error-number-value-too-big': Translations;
  'template-error-format-phone': Translations;
  'template-error-enum-value': Translations;
  'template-error-additional-properties': Translations;
  'template-error-checkbox-not-checked': Translations;

  // Order product
  'order-product-waste-type-header': Translations;
  'order-product-show-more': Translations;
  'order-product-show-less': Translations;
  'order-product-container-size-header': Translations;
  'order-product-contract-information-header': Translations;
  'order-product-contract-information-containers-label': Translations;
  'order-product-contract-information-date-label': Translations;
  'order-product-container-ownership-header': Translations;
  'order-product-container-ownership-rent': Translations;
  'order-product-container-ownership-buy': Translations;
  'order-product-container-ownership-owned': Translations;
  'order-product-container-ownership-delivered': Translations;
  'order-product-emptying-rhythm-header': Translations;
  'order-product-emptying-rhythm-input-label': Translations;
  'order-product-emptying-rhythm-input-label-for-whole-year': Translations;
  'order-product-emptying-rhythm-input-label-for-summer': Translations;
  'order-product-emptying-rhythm-input-label-for-winter': Translations;
  'order-product-emptying-rhythm-times-a-week-label': Translations;
  'order-product-emptying-rhythm-validity': Translations;
  'order-product-emptying-rhythm-validity-start-week': Translations;
  'order-product-emptying-rhythm-validity-end-week': Translations;
  'order-product-emptying-rhythm-validity-add-interval': Translations;
  'order-product-emptying-rhythm-validity-add-interval-disabled': Translations;
  'order-product-emptying-rhythm-validity-remove-interval': Translations;
  'order-product-emptying-rhythm-validity-whole-year': Translations;
  'order-product-emptying-rhythm-validity-winter-summer': Translations;
  'order-product-emptying-rhythm-validity-winter-summer-disabled': Translations;
  'order-product-emptying-rhythm-week-text': Translations;
  'order-product-emptying-rhythm-not-available-due-to-admin-settings': Translations;
  'order-product-emptying-rhythm-not-available-due-to-form-settings': Translations;
  'order-product-emptying-rhythm-overlapping-weeks-due-to-admin-settings': Translations;
  'order-product-emptying-rhythm-weeks-cannot-overlap': Translations;
  'order-product-driver-info-header': Translations;
  'order-product-driver-info-gate-key': Translations;
  'order-product-driver-info-additional-information': Translations;
  'order-product-price-header': Translations;
  'order-product-service-header': Translations;
  'order-product-unit-price-header': Translations;
  'order-product-amount-header': Translations;
  'order-product-total-header': Translations;
  'order-product-row-waste-management': Translations;
  'order-product-price-container-collection': Translations;
  'order-product-price-waste-management': Translations;
  'order-product-price-total-price': Translations;
  'order-product-not-available': Translations;
  'order-product-summary-product-information-header': Translations;
  'order-product-summary-waste-type-and-container-header': Translations;
  'order-product-summary-waste-type': Translations;
  'order-product-summary-container-size': Translations;
  'order-product-summary-container-ownership': Translations;
  'order-product-summary-contract-information-header': Translations;
  'order-product-summary-number-of-containers': Translations;
  'order-product-summary-contract-start-date': Translations;
  'order-product-summary-emptying-intervals-header': Translations;
  'order-product-summary-contract-validity-weeks': Translations;
  'order-product-summary-contract-times-a-week': Translations;
  'order-product-summary-contract-once-a-week': Translations;
  'order-product-summary-contract-every-n-weeks': Translations;
  'order-product-summary-info-for-driver-header': Translations;
  'order-product-summary-gate-key-code': Translations;
  'order-product-summary-additional-information': Translations;
  'order-product-summary-price-header': Translations;

  // Permit Application
  'permit-application': Translations;
  'permit-application-desc': Translations;
  'permit-application-new': Translations;
  'permit-application-previous': Translations;
  'add-new-permit-application-success-message': Translations;
  'permit-application-extended-emptying-interval': Translations;
  'permit-application-shared-container': Translations;
  'permit-application-pdf': Translations;
  'permit-application-waste-type': Translations;
  'permit-application-status': Translations;
  'permit-application-status-sent': Translations;
  'permit-application-status-inprocess': Translations;
  'permit-application-status-ready': Translations;
  'permit-application-no-forms-title': Translations;
  'permit-application-no-forms-text': Translations;
  'permit-application-submit': Translations;
  'permit-application-table-header-subject': Translations;
  'permit-application-table-header-application-sent': Translations;
  'permit-application-table-header-status': Translations;
  'permit-application-table-header-permit': Translations;

  // Composting Notifications
  'composting-notifications': Translations;
  'composting-notifications-desc': Translations;
  'add-new-composting-notification-success-message': Translations;
  'new-composting-notification': Translations;
  'composting-notifications-header': Translations;
  'composting-notifications-header-description': Translations;
  'composting-notifications-location-column': Translations;
  'composting-notifications-notification-sent-column': Translations;
  'composting-notifications-notifier-column': Translations;
  'composting-notifications-notification-to-be-remade-column': Translations;
  'composting-notifications-status-column': Translations;
  'composting-notifications-status-ready': Translations;
  'composting-notifications-status-expired': Translations;
  'composting-notifications-status-expiring-soon': Translations;

  // Terms of use/service/etc
  'terms-of-service-title': Translations;
  'terms-of-service-text': Translations;
  'terms-of-service-link': Translations;
  'terms-of-use-title': Translations;
  'terms-of-use-text': Translations;

  // Reporting
  reporting: Translations;
  'reporting-header': Translations;
  'reporting-description': Translations;

  // Logout in menu
  logout: Translations;
  menu: Translations;

  // Validations
  'validation-required': Translations;
  'validation-invalid-ssn': Translations;
  'validation-invalid-company-id': Translations;
  'validation-invalid-email': Translations;
  'validation-invalid-username': Translations;
  'validation-invalid-password': Translations;
  'validation-invalid-integer': Translations;
  'validation-invalid-positive-integer': Translations;
  'validation-invalid-date': Translations;
  'validation-not-matching': Translations;
  'validation-matching': Translations;
  'validation-too-short': Translations;
  'validation-greater-than-zero': Translations;
  'validation-invalid-phone': Translations;
  'validation-invalid-post-code': Translations;
  'validation-integer-not-start-zero': Translations;
  'validation-equal-start-end-dates': Translations;
  'validation-startdate-greater-than-enddate': Translations;
  'validation-startdate-before-now': Translations;
  'validation-enddate-before-now': Translations;
  'validation-enddate-too-far': Translations;

  // Calendar
  'calendar-jan': Translations;
  'calendar-feb': Translations;
  'calendar-mar': Translations;
  'calendar-apr': Translations;
  'calendar-may': Translations;
  'calendar-jun': Translations;
  'calendar-jul': Translations;
  'calendar-aug': Translations;
  'calendar-sep': Translations;
  'calendar-oct': Translations;
  'calendar-nov': Translations;
  'calendar-dec': Translations;

  'calendar-mon': Translations;
  'calendar-tue': Translations;
  'calendar-wed': Translations;
  'calendar-thu': Translations;
  'calendar-fri': Translations;
  'calendar-sat': Translations;
  'calendar-sun': Translations;

  // Dialog window
  'dialog-ok': Translations;
  'dialog-cancel': Translations;
  'dialog-save': Translations;
  'dialog-next': Translations;
  'dialog-back': Translations;

  // Generic
  'generic-show-more': Translations;
  'generic-show-less': Translations;

  // Price
  'includes-vat': Translations;

  // For unit tests
  'test-languages': Translations;
  'test-one-param': Translations;
  'test-two-params': Translations;

  // Waste unit type translations
  'waste-unit-type-Unknown': Translations;
  'waste-unit-type-Piece': Translations;
  'waste-unit-type-Kilogram': Translations;
  'waste-unit-type-Ton': Translations;
  'waste-unit-type-Liter': Translations;
  'waste-unit-type-CubicMeter': Translations;
  'waste-unit-type-SquareMeter': Translations;
  'waste-unit-type-Meter': Translations;
  'waste-unit-type-Kilometer': Translations;
  'waste-unit-type-MegaWattHour': Translations;
  'waste-unit-type-Hour': Translations;
  'waste-unit-type-Day': Translations;
  'waste-unit-type-Month': Translations;
  'waste-unit-type-Year': Translations;

  //Phase bar
  'phase-bar-step': Translations;

  'consumer-einvoice-type-not-in-use': Translations;
  'consumer-einvoice-type-email-invoicing': Translations;
  'consume-einvoice-type-consumer-channel': Translations;

  //Well Collection
  'well-collection-menu-create-notification-item': Translations;
  'emptying-location-waste-water-header': Translations;
  'well-collection-property-water-supply': Translations;
  'well-collection-property-water-supply-title': Translations;
  'well-collection-property-water-supply-wc-type': Translations;
  'well-collection-property-water-supply-water-wc': Translations;
  'well-collection-property-water-supply-non-water-wc': Translations;
  'well-collection-property-water-supply-household-water-source': Translations;
  'well-collection-property-water-supply-household-water-pressure': Translations;
  'well-collection-property-water-supply-household-water-carried': Translations;
  'well-collection-property-water-supply-equipment-level': Translations;
  'well-collection-property-water-supply-equipment-shower': Translations;
  'well-collection-property-water-supply-equipment-dishwasher': Translations;
  'well-collection-property-water-supply-equipment-washing-machine': Translations;
  'well-collection-waste-water-treatment': Translations;
  'well-collection-waste-water-report-summary': Translations;

  //Waste Water
  'waste-water-investigation-header': Translations;
  'waste-water-connection-sewer-network': Translations;
  'waste-water-sewer-network-connected': Translations;
  'waste-water-sewer-network-not-connected': Translations;
  'waste-water-sewer-network-connected-confirmation-message': Translations;
  'waste-water-treatment-header': Translations;
  'waste-water-treatment-options-header': Translations;
  'waste-water-treatment-option-A': Translations;
  'waste-water-treatment-option-B': Translations;
  'waste-water-treatment-option-C': Translations;
  'waste-water-treatment-option-D': Translations;
  'waste-water-treatment-option-E': Translations;
  'waste-water-treatment-option-F': Translations;
  'waste-water-treatment-option-G': Translations;
  'waste-water-treatment-validation-message-wc-water': Translations;
  'waste-water-treatment-validation-message-wc-water-gray': Translations;
  'waste-water-popover-content': Translations;

  //septic-tank-form
  'septic-tank-form-head': Translations;
  'septic-tank-covers-number': Translations;
  'septic-tank-volume': Translations;
  'septic-tank-access-limitations-header': Translations;
  'septic-tank-access-limitations-option-no-limits': Translations;
  'septic-tank-access-limitations-option-no-winter': Translations;
  'septic-tank-access-limitations-option-locked': Translations;
  'septic-tank-access-limitations-option-weight-limit': Translations;
  'septic-tank-access-limitations-weight-limit-details': Translations;
  'septic-tank-access-limitations-option-weight-expections': Translations;
  'septic-tank-access-limitations-option-weight-other': Translations;
  'septic-tank-access-limitations-other-details': Translations;
  'septic-tank-access-limitaions-location': Translations;
  'septic-tank-access-limitations-take-other-steps': Translations;

  //other-form
  'other-grey-water-filter-option': Translations;
  'other-ground-filter-option': Translations;
  'other-no-waste-water-treatment-system-option': Translations;
  'other-no-waste-water-treatment-system-input': Translations;

  //treatment-plant-form
  'treatment-plant-manufacturer': Translations;
  'treatment-plant-model': Translations;
  'treatment-plant-emptying-guide': Translations;
  'treatment-plant-volume': Translations;
  'treatment-plant-vacuum-truck': Translations;
  'treatment-plant-vacuum-yes-options': Translations;
  'treatment-plant-vacuum-no-options': Translations;
  'treatment-plant-vacuum-info-notice': Translations;

  //closed tank form
  'closed-tank-form-head': Translations;
  'closed-tank-volume': Translations;
  //need for emptying form
  'need-for-emptying-closed-tank-weekly-frequency-on-demand': Translations;
  'need-for-emptying-weekly-frequency-other': Translations;
  'need-for-emptying-closed-tank-other-input-label': Translations;
  'need-for-emptying-black-water-resident-count': Translations;
  'need-for-emptying-weekly-frequency-half-year': Translations;
  'need-for-emptying-weekly-frequency-year': Translations;
  'need-for-emptying-weekly-frequency-two-year': Translations;
  'need-for-emptying-weekly-frequency-three-year': Translations;
  'need-for-emptying-weekly-frequency-dropdown-label': Translations;
  'need-for-emptying-other-input-label': Translations;

  //well-collection-summary
  'property-water-supply-summary-title':Translations;
  'property-water-supply-summary-information-title':Translations;
  'property-water-supply-summary-wc-type':Translations;
  'property-water-supply-summary-household-water-source':Translations;
  'property-water-supply-summary-household-equipment-level':Translations;
};

export type IntervalData = {
  startWeekDate: Date | null;
  endWeekDate: Date | null;
  interval: number | null;
  amountPerWeek: number;
};

export type SharedContainerBasicData = {
  containerId: string;
  isCorrespondingPerson: boolean;
};

// -----------------------------------------
// Generated with https://app.quicktype.io/
// -----------------------------------------
export interface EmptyingInfo {
  id: string;
  name: string;
  address: Address;
  contractDate: Date;
  contractEndDate: Date;
  coordinates: Coordinates;
  buildingClassification: BuildingClassification;
  invoicingId: string;
  concernId: string;
  managerId: string;
  customerGroup: string;
  isCompany: boolean;
  contactPerson: ContactPerson;
  sharedContainer: SharedContainer[];
}

export interface IdentifiedPersonInfo {
  personalIdentifier: string;
  name: string;
  address: Address;
  email: string;
}

export interface Address {
  street: string;
  postOffice: string;
  postalCode: string;
}

export interface BuildingType {
  code: string;
  name: string;
}

export interface BuildingClassification {
  code: string;
  name: string;
}

export interface Area {
  code: string;
  name: string;
}

export interface Conurbation {
  id: string;
  name: string;
}

export interface Coordinates {
  x: number;
  y: number;
}

export interface ContractInfo {
  customerId: string;
  productCode: number;
  position: number;
  name: string;
  productGuid: string;
  nextEmptying: Date;
  emptyingIntervals: EmptyingInterval[];
  amount: number;
  unit: WasteUnitType;
  size: string;
  transportPrice: number;
  handlingPrice: number;
  allEmptyings: Emptyings;
  price: Prices;
  endDate: Date;
  status: ContractStatus;
  sharedContainer: boolean;
  readonlyContract: boolean;
  startDate: Date;
  gateKeyCode: string;
  correspondingPersonId: string;
}

export interface Prices {
  handlingPrice: number;
  handlingPriceIncludingVat: number;
  handlingUnitPrice: number;
  handlingUnitPriceIncludingVat: number;
  transportPrice: number;
  transportPriceIncludingVat: number;
  transportUnitPrice: number;
  transportUnitPriceIncludingVat: number;
  wasteTaxPrice: number;
  wasteTaxPriceIncludingVat: number;
  wasteUnitTaxPrice: number;
  wasteUnitTaxPriceIncludingVat: number;
  totalPriceGross: number;
  totalPriceNet: number;
}

export interface Contract {
  customerId: string;
  productCode: number;
  position: number;
  name: string;
  nextEmptying: Date;
  emptyingIntervals: EmptyingInterval[];
  amount: number;
  size: string;
  transportPrice: number;
  handlingPrice: number;
  endDate: Date;
  status: ContractStatus;
  sharedContainer: boolean;
  readonlyContract: boolean;
  startDate: Date;
}

export type ContractStatus = 'Unspecified' | 'Approved' | 'Waiting';

export interface EmptyingInterval {
  startWeek: number;
  endWeek: number;
  interval: number;
  amountPerWeek: number;
  sequence: number;
}

export interface Emptyings {
  Missed: EmptyingEvent[];
  Successful: EmptyingEvent[];
  Planned: EmptyingEvent[];
}

export interface EmptyingEvent {
  description: string | null;
  eventType: EmptyingEventEnum;
  emptyingDate: Date;
}

export interface EmptyingIntervalUpdate {
  startWeek: number;
  endWeek: number;
  interval: number;
}

export interface Order {
  orderNumber: string;
  orderStatus: string;
  seller: string;
  orderDate: Date;
  requestedDate: Date;
  emptyingId: string;
  billingId: string;
  extraInformation: string;
  x: number;
  y: number;
  modified: string;
  orderRows: OrderRow[];
}

export interface OrderRow {
  emptyingId: string;
  orderTimestamp: Date;
  requestedDate: Date;
  position: number;
  contractPosition: number;
  contractType: number;
  contractName: string;
  amount: number;
  productGuid: string;
  transportUnitPrice: number;
  grossPricing: boolean;
  transportUnitGrossPrice: number;
  handlingUnitPrice: number;
  handlingUnitGrossPrice: number;
  totalPrice: number;
  totalGrossPrice: number;
  wasteTaxUnitPrice: number;
  wasteTaxUnitGrossPrice: number;
  weight: number;
  weightBased: boolean;
  weightUnit: string;
  amountUnit: string;
  transportDiscount: number;
  handlingDiscount: number;
  rdCode: string;
  ewcCode: string;
  serviceResponsibility: string;
  bookkeepingAccountId: string;
  costCenter: string;
  tripLength: number;
  reverseTripLength: number;
  wasteCenterId: string;
  transportVatRate: number;
  handlingVatRate: number;
  ecoVatRate: number;
  transportAccount: string;
  handlingAccount: string;
  ecoAccount: string;
  transportVatAccount: string;
  handlingVatAccount: string;
  ecoVatAccount: string;
  transportSum: number;
  handlingSum: number;
  ecoSum: number;
  transportVatSum: number;
  handlingVatSum: number;
  ecoVatSum: number;
  transportBreakdownAccountId: string;
  handlingBreakdownAccountId: string;
  comment: string;
  vehicleRegistrationNumber: string;
  weighingId: number;
  customersOrderNumber: string;
  salesPerson: string;
  userName: string;
  createdBy: string;
  creationTime: Date;
  transportTarget: string;
  handlingTarget: string;
  ecoTarget: string;
  energyRate: number;
  materialRate: number;
  orderNumber: string;
}

export interface BillingInfo {
  id: string;
  name?: string;
  firstName: string;
  lastName: string;
  companyName: string;
  address: Address;
  contractDate: Date;
  contractEndDate: Date;
  coordinates: Coordinates;
  isCompany: boolean;
  companyId: string;
  eInvoicing?: EInvoicing;
  contactPerson: ContactPerson | null;
  emailInvoicing: boolean;
  consumerEInvoiceType: ConsumerEInvoiceType;
  billingEmail: string | null;
  billingPhone: string | null;
  concernId: string;
  managerId: string;
  customerGroup: string;
}

export interface ContactPerson {
  name: string;
  phoneNumber: string;
  email: string;
  binLocationContactPerson: string;
}

export interface EInvoicing {
  address: string;
  operator: string;
  ediCode: string;
}

export interface UserAccountInfo {
  userName: string;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  companyName: string;
}

export interface ProductCategories {
  productCategories: ProductCategory[];
}

export interface ProductCategory {
  id: string;
  name: string;
  productType: string;
  formType: string;
  products: string[];
}

export interface CategoryWithProducts {
  id: string;
  name: string;
  formType: string;
  productType: string;
  products: VingoProduct[];
}

export interface VingoProduct {
  price: Prices;
  productCode: number;
  id: string;
  productGroup: string;
  productGroupName: string | null;
  name: string | null;
  size?: string | null;
  infoText: string | null;
  rental: string | null;
  allowedEmptyingIntervals: AllowedEmptyingInterval[] | null;
  canRent: boolean;
  canBuy: boolean;
  canProvideOwn: boolean;
  canDelivered: boolean;
  wasteType: string | null;
  wasteTypeName: string | null;
  earliestContractStartDate: Date | null;
  maxPauseLength: number | null;
  additionalProducts?: AdditionalProduct[];
}

export interface AdditionalProduct {
  id: string;
  name: string;
  code: number;
  destination: string;
  eventType: string;
}

export interface AllowedEmptyingInterval {
  intervals: number[];
  startWeek: number;
  endWeek: number;
}

// Invoices
export type InvoiceInfo = {
  invoiceNumber: number;
  customerNumber: string;
  invoiceDate: Date;
  dueDate: Date;
  paymentDate: Date | null;
  amountOpen: number;
  amountCompleted: number;
  currency: string;
  billingName: string;
  customerAccount: string;
  paymentStatus: string;
  paymentExpired: boolean;
};

// Request interfaces

export type SaveUserInfoReq = {
  email: string;
  phoneNumber: string;
  companyName?: string;
  firstName?: string;
  lastName?: string;
  password?: Password;
};

export type Password = {
  currentPassword: string;
  newPassword: string;
};

export type SavePrivateCustomerBillingInfoReq = {
  name?: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  billingEmail: string;
  billingAddress: string;
  contactPerson: string;
  consumerEInvoiceType: ConsumerEInvoiceType;
};

export type SaveCompanyBillingInfoReq = {
  companyName: string;
  phoneNumber: string; // PARTel
  billingEmail: string; //PAREmail
  billingAddress: string;
  consumerEInvoiceType: ConsumerEInvoiceType;
  contactName: string;
  eInvoiceInformation?: EInvoiceInformation;
  billingContactPhone: string | null; // PARTel2
};

export type EInvoiceInformation = {
  address: string;
  operator: string;
  ediCode: string;
};

export type AddExtraEmptyingReq = {
  emptyingDate: Date;
  amount: number;
  extraInfo: string;
  orderConfirmation: boolean;
};

export type NewOrder = {
  name: string;
  amount: number;
  productGuid: string;
  extraInfo: string;
  orderConfirmation: boolean;
};

export type NewOrderReq = {
  orders: NewOrder[];
};

export type NewDumpsterOrderReq = {
  name: string;
  amount: number;
  productGuid: string;
  extraInfo: string;
  containerLocation: string;
  deliveryTime: Date;
  pickupTime?: Date;
  wasteTypes?: string[];
  orderConfirmation: boolean;
};

export type Product = {
  id: string;
  name: string;
  info: string;
  price: number;
  amount: number;
  alv: number;
};

export type AddNewContractReq = {
  products: ProductOrder[];
};

export type ProductOrder = {
  name: string | null;
  amount: number;
  productGuid: string;
  emptyingIntervals: NewEmptyingInterval[];
  binOwnership: BinOwnership;
  startingDate: Date;
  key: string | null;
  comment: string | null;
};

export type NewEmptyingInterval = {
  startWeek: number;
  endWeek: number;
  interval: number;
  amountPerWeek: number;
};

export type FeedbackInfo = {
  date: Date;
  header: string;
  id: string;
  message: string;
  emptyingInfoId: string;
  email: string;
  phone: string;
};

export type FeedbackInput = {
  header: string;
  message: string;
  customerNumber: string;
  feedbackCategoryId: string;
  name: string;
  phone?: string;
  address: Address;
  messageCategory: string;
};

export type MessageCategory = {
  id: string;
  name: string;
};

export type EnvironmentSettings = {
  PermitApplication?: boolean;
  CustomerSupport?: boolean;
  CompostNotification?: boolean;
  AddEmptyingLocation?: boolean;
  AddBillingLocation?: boolean;
  ReadOnlyBillingAddress?: boolean;
  StrongAuthentication?: boolean;
  ExtraEmptying?: boolean;
  ModifyService?: boolean;
  ModifyEmptyingInterval?: boolean;
  ModifyEmptyingLocation?: boolean;
  ModifyCompanyBillingContactPersonName?: boolean;
  AddService?: boolean;
  ShowInvoices?: boolean;
  ShowInvoicePicture?: boolean;
  RegisterPrivate?: boolean;
  RegisterCompany?: boolean;
  RegisterEmailInvoicing?: boolean;
  ShowOrderHistory?: boolean;
  ShowOrderExtraInformation?: boolean;
  ShowEmptyingTimesPerWeek?: boolean;
  Development?: boolean;
  ReportingEnabled?: boolean;
  CreateNewCustomer?: boolean;
  CreateExistingCustomer?: boolean;
  WellCollectionEnabled?: boolean;
  SeasonalRhythm?: boolean;
};

export type TemplateInfo = {
  id: string;
  name: string;
  schema: string;
  uiSchema: string;
  type: TemplateType;
  language: string;
  active: boolean;
};

export type PermitApplicationInput = {
  formId: string;
  formData: object;
};

export type PermitItemInfo = {
  permitAppId: string;
  sentDate: Date;
  expirationDate?: Date;
  header: string;
  rows: string[][];
  status: string;
};

export type CompostingNotificationInput = {
  formId: string;
  data: object;
};

export type PermitForm = {
  id: string;
  formName: string;
  formSchema: string;
  uiSchema: string;
  createdTime: Date;
  expirationDate: Date;
};

export type PermitApplicationReply = {
  id: string;
  formId: string;
  formData: string;
  createdTime: Date;
  status: string;
};

export type PermitFormReply = {
  id: string;
  formName: string;
  formSchema: string;
  uiSchema: string;
  createdTime: Date;
  expirationDate: Date;
};

export type CompostingForm = {
  id: string;
  schema: string;
  uiSchema: string;
};

export type BinOwnership = 'Buy' | 'Rent' | 'Owned' | 'Delivered';

// Default value to display / send to backend is set here as 'Buy', backend does not accept undefined or null values
export const convertStringToBinOwnership = (value: string | undefined): BinOwnership => {
  if (!value) {
    return 'Buy';
  }
  switch (value) {
    case 'Rent':
    case 'Owned':
    case 'Delivered':
    case 'Buy':
      return value;
    default:
      return 'Buy';
  }
};

export type NewBillingInfoPrivate = {
  personalIdentificationNumber: string;
  firstName: string;
  lastName: string;
  address: string;
  postCode: string;
  city: string;
  email: string;
  phoneNumber: string;
  consumerEInvoiceType: ConsumerEInvoiceType;
  municipalityCode: string | null;
};

export type NewBillingInfoCompany = {
  companyCode: string;
  name: string;
  address: string;
  postCode: string;
  city: string;
  email: string;
  phoneNumber: string;
  consumerEInvoiceType: ConsumerEInvoiceType;
  contactName: string;
  billingEmail: string | null;
  eInvoiceInformation?: EInvoiceInformation;
  reference: string | null;
  municipalityCode: string | null;
};

export type NewEmptyingInfo = {
  name: string | null;
  address: string | null;
  pickupAddress: string | null;
  postCode: string | null;
  city: string | null;
  contactPerson: string | null;
  personalIdentificationNumber: string | null;
  companyCode: string | null;
  municipalityCode: string | null;
  buildingId: string | null;
  realEstateId: string | null;
  buildingClassificationCode: string | null;
  residentCount: number | null;
  apartmentCount: number | null;
  joinType: number | null;
  sewerSystem: boolean | null;
  bioWasteHandling: number | null;
  extraInformation: string | null;
};

export type UpdateEmptyingInfoCompanyReq = {
  locationContactFirstName?: string;
  locationContactLastName?: string;
  contactPerson: string; // Pervasive : this should be saved to PARDYhthlo
  phoneNumber: string;
  email: string;
};

export type UpdateEmptyingInfoPrivateReq = {
  locationContactFirstName?: string;
  locationContactLastName?: string;
  contactPerson: string; // Pervasive : this should be saved to PARDYhthlo
  phoneNumber: string;
  email: string;
};

export interface PriceCalculationRequest {
  productGuid: string | undefined;
  customerId: string;
  amount: number;
  weight: number;
}

export type PriceCalculationResult = {
  AmountUnit: string;
  HandlingPrice: number;
  HandlingPriceIncludingVat: number;
  HandlingUnitPrice: number;
  HandlingUnitPriceIncludingVat: number;
  IsWeightBased: boolean;
  TransportPrice: number;
  TransportPriceIncludingVat: number;
  TransportUnitPrice: number;
  TransportUnitPriceIncludingVat: number;
  WasteTaxPrice: number;
  WasteTaxPriceIncludingVat: number;
  WeightUnit: string;
  WasteUnitTaxPrice: number;
  WasteUnitTaxPriceIncludingVat: number;
  TotalPriceGross: number;
  TotalPriceNet: number;
};

export interface PickUpOrderContent {
  id: number;
  name: string;
  pickUpDate: Date;
  customerId: string;
  categoryId: string;
  productGroup: string;
  product: string;
  amount: number;
  weight: number;
}

export type NewPickUpOrder = {
  name: string;
  pickupTime?: Date;
  productGuid: string;
  amount: number;
  weight: number;
};

export type NewPickUpOrderReq = {
  products: NewPickUpOrder[];
  extraInfo: string;
  orderConfirmation: boolean;
};

export type NewPickUpOrderReply = {
  orderNumber: string;
  addedRows: number;
  orderConfirmed: string;
};

export type UserLanguageUpdateInput = {
  language: string;
};

export interface CustomerEServiceSettings extends Record<string, string> { }

export type CommonDetails = {
  companyName: string;
  firstName: string;
  lastName: string;
  address: string;
  postCode: string;
  city: string;
  contactFirstName: string;
  contactLastName: string;
  phoneNumber: string;
  email: string;
};

export interface SharedContainer {
  containerId: string;
  sharedGroupName: string;
  isCorrespondingPerson: boolean;
  correspondingPersonName: string;
  products: SharedContainerProduct[];
}

export interface SharedContainerProduct {
  name: string;
  identifier?: string;
  amount: number;
  partners: SharedContainerPartner[];
}

export interface SharedContainerPartner {
  name: string;
  address: SharedContainerPartnerAddress;
  percentage: number;
  emphasis: number;
}

export interface SharedContainerPartnerAddress {
  streetAddress: string;
  postOffice: string;
  postalCode: string;
}

export interface OnlineBroker {
  code: string;
  name: string;
}
