import { FC, useContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import ProgressComponent from './ProgressComponent';
import { LanguageContext } from '../../contexts/language-context';
import { UiTexts } from '../../model';

interface Props {
  open: boolean;
  isLoading: boolean;
  titleMessageKey?: keyof UiTexts;
  descriptionMessageKey: keyof UiTexts;
  confirmationMessageKey: keyof UiTexts;
  cancelationMessageKey: keyof UiTexts;
  handleClose: () => void;
  handleConfirmation: () => void;
}

const ApproveContractDialog: FC<Props> = ({
  open,
  isLoading,
  titleMessageKey,
  descriptionMessageKey,
  confirmationMessageKey,
  cancelationMessageKey,
  handleClose,
  handleConfirmation,
}) => {
  const { getText } = useContext(LanguageContext);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='approve-dialog-title'>
      <ProgressComponent isLoading={isLoading} />
      {titleMessageKey && (
        <DialogTitle id='approve-dialog-title'>
          <Typography>{getText(titleMessageKey)}</Typography>
        </DialogTitle>
      )}
      <DialogContent>
        <Typography>{getText(descriptionMessageKey)}</Typography>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} size='large' variant='contained' onClick={() => handleConfirmation()}>
          {getText(confirmationMessageKey)}
        </Button>
        <Button disabled={isLoading} size='large' color='secondary' variant='contained' onClick={() => handleClose()}>
          {getText(cancelationMessageKey)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApproveContractDialog;
