import { FC, useContext, ChangeEvent } from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { LanguageContext } from '../../../contexts/language-context';

export enum WasteWaterInvestigationOptionEnum {
  Connected = 'connected',
  NotConnected = 'notConnected',
}

interface Props {
  option?: WasteWaterInvestigationOptionEnum;
  setOption: (option: WasteWaterInvestigationOptionEnum) => void;
  onSave: () => void;
}

const WasteWaterInvestigation: FC<Props> = ({ setOption, option, onSave }): JSX.Element => {
  const { getText } = useContext(LanguageContext);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOption(e.target.value as WasteWaterInvestigationOptionEnum);
  };

  const getButtonText = () => {
    if (option === WasteWaterInvestigationOptionEnum.Connected) {
      return getText('dialog-save');
    } else {
      return getText('dialog-next');
    }
  };

  return (
    <>
      <Container>
        <Grid container columns={{ xs: 1, md: 4, lg: 4 }}>
          <Grid item md={2} lg={2}>
            <Typography variant='h6'>{getText('waste-water-connection-sewer-network')}</Typography>
          </Grid>
          <Grid item>
            <FormControl>
              <RadioGroup onChange={handleOnChange}>
                <FormControlLabel
                  value={WasteWaterInvestigationOptionEnum.NotConnected}
                  control={<Radio />}
                  label={getText('waste-water-sewer-network-not-connected')}
                />
                <FormControlLabel
                  value={WasteWaterInvestigationOptionEnum.Connected}
                  control={<Radio />}
                  label={getText('waste-water-sewer-network-connected')}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Container>
      <Divider />
      <Container>
        <Stack direction='row' justifyContent='flex-end' spacing={2} paddingX={3} marginBottom={3}>
          <Box>
            <Button size='large' color='primary' variant='contained' onClick={onSave} disabled={!option}>
              {getButtonText()}
            </Button>
          </Box>
        </Stack>
      </Container>
    </>
  );
};

export default WasteWaterInvestigation;
