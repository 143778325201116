import { TableCell, TableHead, TableRow, useTheme } from "@mui/material";
import { FC } from "react";
import { CustomHeaderProps, TableHeaderProps } from "./interfaces";

interface DataTableHeaderProps {
  headers?: TableHeaderProps[];
  customHeaders?: CustomHeaderProps[];
}

export const DataTableHeader: FC<DataTableHeaderProps> = ({ headers, customHeaders }) => {
  const { palette } = useTheme();

  return (
    <TableHead>
      <TableRow
        sx={{
          backgroundColor: palette.divider,
        }}
      >
        {
          customHeaders
            ?
            customHeaders.map(x => x.tableCell)
            :
            headers?.map((header, idx) => {
              return (
                <TableCell key={idx} align={header.alignment}>{header.label}</TableCell>
              );
            })
        }
      </TableRow>
    </TableHead>
  );
};