import React, { useState, useContext, useEffect, useRef } from 'react';
import ApiContext from '../../contexts/api-context';
import AuthContext from '../../contexts/auth-context';
import { LanguageContext } from '../../contexts/language-context';

import Header from '../common/Header';
import {
  Column,
  Row,
  WideContent,
  HalfScreenColumn,
  CompactColumn,
} from '../common/containers';
import { ContractInfo, EmptyingInterval } from '../../model';
import Spinner from '../common/Spinner';

import Calendar from '../common/Calendar';
import { SmallHeader } from '../common/headers';
import { printIntervalStartAndEndDates } from '../../util/calendarUtil';
import { Link, useHistory } from 'react-router-dom';
import DialogContext from '../../contexts/dialog-context';
import { printInterval } from '../../util/util';
import { ConvertToCurrentLocale } from '../../util/numberLocalization';
import styled from 'styled-components';

type ContractDetailsProps = {
  match: {
    params: {
      customerNumber: string;
      position: string;
    };
  };
  location: {
    state: { name: string, address: string; };
  };
};

const SmallHeaderSameLine = styled(SmallHeader)`
  display: block ruby;
  white-space: nowrap;
`;

const ContractDetails = (props: ContractDetailsProps): JSX.Element => {
  const api = useContext(ApiContext);
  const { getText } = useContext(LanguageContext);
  const showDialog = useContext(DialogContext);
  const history = useHistory();
  const authService = useContext(AuthContext);

  const { name, address } = props.location.state ?? { name: "", address: "" };
  const { customerNumber, position } = props.match.params;
  const [contract, setContract] = useState<ContractInfo | null>(null);
  const paddingXValue = "3rem";

  const componentIsMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    api
      .fetchContractDetails(customerNumber, parseInt(position))
      .then((contractDetails) => {
        if (componentIsMounted.current) {
          setContract(contractDetails);
        }
      })
      .catch((err: any) => {
        if (err.statusCode === 401) {
          showDialog(
            'error-unauthorized-title',
            'error-unauthorized-message',
            () => history.replace('/logout')
          );
        }
        else {
          showDialog("error-service-break-title", "error-service-break-message");
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderHeaderLinks = (contract: ContractInfo): JSX.Element | null => {
    if (contract.status === 'Waiting' || contract.readonlyContract) {
      return null;
    } else {
      return (
        <Column>
          {(authService.isExtraEmptyingEnabled() && !contract.sharedContainer) &&
            <Link
              to={`/contracts/${customerNumber}/${position}/extra-emptying`}
            >
              {getText('extra-emptying-order')} &rarr;
            </Link>
          }
          {(authService.isModifyServiceEnabled() && !contract.sharedContainer) &&
            <Link
              style={{ marginTop: '1rem' }}
              to={{
                pathname: `/contracts/${customerNumber}/${position}/change`,
                state: { name, address }
              }}
            >
              {getText('contract-details-change-contract')} &rarr;
            </Link>
          }
        </Column>
      );
    }
  };

  const getCalenderData = () => {
    if (contract) {
      return [...contract.allEmptyings.Successful, ...contract.allEmptyings.Missed, ...contract.allEmptyings.Planned];
    }
    return [];
  };

  const renderTopSection = () => {
    if (!contract) {
      return null;
    }

    return (
      contract.readonlyContract
        ?
        <WideContent style={{ paddingLeft: paddingXValue, paddingRight: paddingXValue }}>
          <Calendar
            emptyingEvents={
              getCalenderData()
            }
          />
        </WideContent>
        :
        <WideContent style={{ paddingLeft: paddingXValue, paddingRight: paddingXValue }}>
          <SmallHeader>
            {contract.emptyingIntervals.length === 1
              ? getText('contract-details-emptying-single-interval')
              : getText('contract-details-emptying-intervals')}
          </SmallHeader>
          {contract.emptyingIntervals.map(renderEmptyingInterval)}

          <SmallHeader>
            {getText('contract-details-emptying-dates')}
          </SmallHeader>
          <Calendar
            emptyingEvents={
              getCalenderData()
            }
          />
        </WideContent>
    );
  };

  const renderEmptyingInterval = (interval: EmptyingInterval): JSX.Element => {
    return (
      <Column key={interval.startWeek}>
        <div>
          {printIntervalStartAndEndDates(
            new Date().getFullYear(),
            interval.startWeek,
            interval.endWeek
          )}
        </div>
        <div>{printInterval(getText, interval.interval, interval.amountPerWeek)}</div>
        <br />
      </Column>
    );
  };
  if (contract) {
    return (
      <Column>
        <Header
          headerElement={<div>{contract.name}</div>}
          descriptionElement={<div>{name !== "" || address !== "" ? name + ", " + address : ""}</div>}
          backButton={true}
        >
          {renderHeaderLinks(contract)}
        </Header>
        <CompactColumn>
          <div>

            {renderTopSection()}

            <WideContent style={{ paddingTop: '1.5rem', paddingLeft: paddingXValue, paddingRight: paddingXValue }}>
              <Row>
                <HalfScreenColumn>
                  <Column>
                    <SmallHeaderSameLine>
                      {getText('contract-details-num-of-bins')}
                    </SmallHeaderSameLine>
                    <div>{contract.amount}</div>
                  </Column>
                  <br />
                  <Column>
                    <SmallHeaderSameLine>
                      {getText('contract-details-waste-disposal-fee')}
                    </SmallHeaderSameLine>
                    <div>
                      {contract.price && ConvertToCurrentLocale(contract.price.handlingUnitPriceIncludingVat)}
                      &euro;
                    </div>
                  </Column>
                </HalfScreenColumn>
                <Column>
                  <Column>
                    <SmallHeaderSameLine>
                      {getText('contract-details-emptying-price')}
                    </SmallHeaderSameLine>
                    <div>
                      {contract.price && ConvertToCurrentLocale(contract.price.transportUnitPriceIncludingVat)}
                      &euro;
                    </div>
                  </Column>
                  <br />
                  <Column>
                    <SmallHeaderSameLine>
                      {getText('contract-details-total-price')}
                    </SmallHeaderSameLine>
                    <div>
                      {contract.price && ConvertToCurrentLocale(contract.price.totalPriceGross)}&euro;
                    </div>
                  </Column>
                </Column>
              </Row>
            </WideContent>
          </div>
        </CompactColumn>
      </Column>
    );
  } else {
    return (
      <Column>
        <Header backButton={true} />
        <CompactColumn>
          <Spinner />
        </CompactColumn>
      </Column>
    );
  }
};

export default ContractDetails;
