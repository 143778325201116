import { Box, Container, Typography, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import BreadcrumbBarContext from '../../../contexts/BreadcrumbBarContext';
import { PathInfo } from '../../../contexts/BreadcrumbBarContextProvider';

// Note: Does not support currently duplicate url paths ex. localhost/test/1000/mytest/1000/1
// This causes a conflict with 1000 and 1000
const BreadcrumbBar = (): JSX.Element => {
  const theme = useTheme();
  const { palette } = useTheme();
  const location = useLocation();
  const { pathsWithNames } = useContext(BreadcrumbBarContext);
  const [currentPaths, setCurrentPaths] = useState<PathInfo[]>([]);

  useEffect(() => {
    setCurrentPaths(
      location.pathname
        .split('/')
        .map((p) => pathsWithNames[p])
        .filter((x) => x !== undefined)
    );
  }, [pathsWithNames]);

  const renderBreadCrumbText = () => {
    if (currentPaths.length) {
      return currentPaths.map((x, idx) => {
        if (idx < currentPaths.length - 1) {
          return (
            <React.Fragment key={x.pathTo + x.name}>
              <Link to={x.pathTo}>{x.name}</Link> {' > '}
            </React.Fragment>
          );
        } else {
          return x.name;
        }
      });
    }
  };

  return (
    <>
      {currentPaths.length > 1 ? (
        <Box sx={{ backgroundColor: palette.divider }}>
          <Container
            sx={{
              ...theme.components?.MuiContainer?.defaultProps?.sx,
              backgroundColor: palette.divider,
              py: '1rem',
            }}
          >
            <Typography variant='body2'>{renderBreadCrumbText()}</Typography>
          </Container>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default BreadcrumbBar;
