import React, { useContext, useState } from 'react';

import { Column, CompactColumn, Content } from '../common/containers';
import { SecondaryHeader } from '../common/headers';
import Header from '../common/Header';
import { Language, CustomerType } from '../../model';
import { LanguageContext } from '../../contexts/language-context';
import PrivateCustomer from './billing-address/PrivateCustomer';
import Company from './billing-address/Company';
import { RenderCustomerTypeSelection } from './register/registerUtils';

type RegisterProps = {
  language: Language;
  setLanguage: (lang: Language) => void;
};

const BillingInfo = (props: RegisterProps): JSX.Element => {
  const { getText } = useContext(LanguageContext);

  const [customerType, setCustomerType] = useState<CustomerType>(null);

  const renderContent = () => {
    switch (customerType) {
      case 'private':
        return <PrivateCustomer />;
      case 'company':
        return <Company />;
      default:
        return null;
    }
  };

  return (
    <Column style={{ marginBottom: '5rem' }}>
      <Header
        headerKey='billing-info-header'
        descriptionKey='billing-info-header-desc'
        backButton={false}
      ></Header>
      <CompactColumn>
        <Content>
          <SecondaryHeader>{getText('billing-info-type-selector')}</SecondaryHeader>
          {RenderCustomerTypeSelection('private-customer', 'private', setCustomerType)}
          {RenderCustomerTypeSelection('company-or-community', 'company', setCustomerType)}
        </Content>

        {renderContent()}
      </CompactColumn>
    </Column>
  );
};

export default BillingInfo;
