import { Dispatch, SetStateAction, useContext } from 'react';

import styled from 'styled-components';

import Dialog from '@material-ui/core/Dialog';
import colors from '../../colors';
import { CONTENT_MARGIN } from '../../styleConstants';
import VingoButton from './VingoButton';
import { UiTexts } from '../../model';
import { LanguageContext } from '../../contexts/language-context';
import { SecondaryHeader } from './headers';
import { Row } from './containers';

const ButtonRow = styled(Row)`
  flex-direction: row-reverse;
  margin-top: ${CONTENT_MARGIN};
`;

type InputProps = {
  openState: boolean;
  openStateHandler: Dispatch<SetStateAction<boolean>>;
  titleKey: keyof UiTexts;
  buttonLabelKey?: keyof UiTexts;
  message: keyof UiTexts;
};

const CustomMessageDialog = (props: InputProps): JSX.Element => {
  const { titleKey, buttonLabelKey, message, openState, openStateHandler } = props;

  const { getText } = useContext(LanguageContext);

  const handleClose = () => {
    openStateHandler(false);
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: colors.contentBackground,
          boxShadow: 'none',
          borderRadius: 0,
          padding: CONTENT_MARGIN,
          paddingTop: 0,
          whiteSpace: 'pre-wrap',
        },
      }}
      style={{ zIndex: 13000 }}
      aria-labelledby='vingo2-dialog-title'
      open={openState}
      onClose={handleClose}
    >
      <SecondaryHeader id='vingo2-dialog-title'>{getText(titleKey)}</SecondaryHeader>
      {getText(message)}
      <ButtonRow>
        <VingoButton onClick={handleClose} compact={true} minWidth='120px'>
          {buttonLabelKey ? getText(buttonLabelKey) : getText('dialog-ok')}
        </VingoButton>
      </ButtonRow>
    </Dialog>
  );
};

export default CustomMessageDialog;
