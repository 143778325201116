import React, { useContext, useEffect, useState } from 'react';
import {
  Column,
  CompactColumn,
  ContentNoBackground,
  ProductCategoryItem,
} from '../common/containers';
import Header from '../common/Header';
import ApiContext from '../../contexts/api-context';
import { LanguageContext } from '../../contexts/language-context';
import DialogContext from '../../contexts/dialog-context';

import { ProductCategory, UiTexts } from '../../model';
import Spinner from '../common/Spinner';
import { SecondaryHeader, SmallHeader } from '../common/headers';
import { Link, useHistory } from 'react-router-dom';
import {
  PRODUCT_TYPE_CONTRACT,
  PRODUCT_TYPE_SINGLE_ORDER,
  PRODUCT_FORM_STICKERS,
  PRODUCT_FORM_PICKUP,
  PRODUCT_FORM_DUMPSTER,
  PRODUCT_FORM_CONTRACT,
} from '../../constants';

type ServiveOrderProps = {
  match: {
    params: {
      id: string;
    };
  };
};

function inferProductPageName(productCategory: ProductCategory): string {
  switch (productCategory.formType) {
    case PRODUCT_FORM_STICKERS:
      return 'stickers';
    case PRODUCT_FORM_PICKUP:
      return 'pickup';
    case PRODUCT_FORM_DUMPSTER:
      return 'dumpster';
    case PRODUCT_FORM_CONTRACT:
      return 'contract';
    default:
      return 'stickers';
  }
}

const OrderProduct = (props: ServiveOrderProps): JSX.Element => {
  const { match } = props;
  const { getText } = useContext(LanguageContext);
  const api = useContext(ApiContext);
  const showDialog = useContext(DialogContext);
  const history = useHistory();

  const emptyingId = match.params.id;
  const [productCategories, setProductCategories] = useState<
    ProductCategory[] | null
  >(null);

  const handleError = (err: any) => {
    if (err.statusCode === 401) {
      showDialog(
        'error-unauthorized-title',
        'error-unauthorized-message',
        () => history.replace('/logout')
      );
    }
    else {
      console.error(err);
      showDialog("error-service-break-title", "error-service-break-message");
    }
  };

  useEffect(() => {
    api
      .fetchProductCategories(emptyingId)
      .then((res) => setProductCategories(res.productCategories))
      .catch((err: unknown) => {
        handleError(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderProductCategory = (
    productCategory: ProductCategory
  ): JSX.Element => {
    const productPage = inferProductPageName(productCategory);
    return (
      <ProductCategoryItem key={productCategory.id}>
        <Link
          to={{
            pathname: `/emptying-infos/${emptyingId}/order-product/${productPage}/${productCategory.id}`,
            // TODO remove state assignment. Use param :categoryid instead.
            // Changes needed to Stickers, PickUpService and Dumpster components
            // Using state causes error when loading page with url.
            state: { productCategory },
          }}
        >
          <SecondaryHeader>{productCategory.name} </SecondaryHeader>
        </Link>
      </ProductCategoryItem>
    );
  };

  const renderCategoriesWithTitle = (
    productTypeTitle: keyof UiTexts,
    categories: ProductCategory[]
  ): JSX.Element | null => {
    if (categories.length > 0) {
      return (
        <Column>
          <SmallHeader>{getText(productTypeTitle)}</SmallHeader>
          {categories.map(renderProductCategory)}
          <br />
        </Column>
      );
    }
    return null;
  };

  const renderCategories = (categories: ProductCategory[]): JSX.Element => {
    const contractCategories = categories.filter(
      (c) => c.productType === PRODUCT_TYPE_CONTRACT
    );
    const singleOrderCategories = categories.filter(
      (c) => c.productType === PRODUCT_TYPE_SINGLE_ORDER
    );

    // No products to order or contract show message and empty screen.
    if (categories.length == 0) {
      showDialog('service-order-no-products-title', 'service-order-no-products-text', () => history.goBack());
      return <ContentNoBackground />
    }

    return (
      <ContentNoBackground>
        {renderCategoriesWithTitle(
          'service-order-contracts',
          contractCategories
        )}
        {renderCategoriesWithTitle(
          'service-order-single-orders',
          singleOrderCategories
        )}
      </ContentNoBackground>
    );
  };

  return (
    <Column>
      <Header
        headerKey="service-order-label"
        descriptionKey="service-order-desc"
        backButton={true}
      ></Header>
      <CompactColumn>
        {productCategories ? renderCategories(productCategories) : <Spinner />}
      </CompactColumn>
    </Column>
  );
};

export default OrderProduct;
