import { useContext, useState, useEffect, Dispatch, SetStateAction, useMemo } from 'react';
import {
  Stack,
  Container,
  Box,
  Button,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Skeleton,
  Divider,
} from '@mui/material';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useQuery, useMutation } from '@tanstack/react-query';
import ApiContext from '../../../../contexts/api-context';
import { LanguageContext } from '../../../../contexts/language-context';
import ExceptionHandler from '../../../../components/common-materialui/status-handling/ExceptionHandler';
import ContentLoadingHandler from '../../../../components/common-materialui/status-handling/ContentLoadingHandler';
import ControlTextInput from '../../../../components/common-materialui/form/ControlTextInput';
import ControlDateInput from '../../../../components/common-materialui/form/ControlDateInput';
import FormErrorField from '../../../../components/common-materialui/form/FormErrorField';
import { LargerThanBreakpoint } from '../../../../util/viewportUtils';
import {
  CategoryWithProducts,
  VingoProduct,
  PriceCalculationRequest,
  PriceCalculationResult,
  UiTexts,
} from '../../../../model';
import OrderProductPrice from './OrderProductPrice';
import ContainerSizeCollapsable from './ContainerSizeCollapsable';
import WasteTypesCollapsable from './WasteTypesCollapsable';
import WeekIntervalSelector from './WeekIntervalSelector/WeekIntervalSelector';
import { validateInput } from '../../../../components/common/input/validation';
import { getWasteTypesAndServicesBasedOnCategory } from './utils';
import { FormDataFinishOrder, WeekInterval } from '../OrderProduct';
import ProgressComponent from '../../../../components/common-materialui/ProgressComponent';

interface Props {
  emptyingId: string;
  categoryId: string;
  onNextPhaseChange: () => void;
  onPreviousPhaseChange: () => void;
  onCancel: () => void;
  setFormDataFinishOrder: (payload: any) => void;
  formDataFinishOrder: FormDataFinishOrder;
  setPriceFinishOrder: Dispatch<SetStateAction<PriceCalculationResult | undefined>>;
  priceFinishOrder: any;
  setContainerSizeFinishOrder: Dispatch<SetStateAction<VingoProduct | undefined | null>>;
  containerSizeFinishOrder: VingoProduct | null | undefined;
  setWeekIntervalFinishOrder: Dispatch<SetStateAction<any>>;
  weekIntervalFinishOrder: WeekInterval[] | undefined;
}

interface ContainerOption {
  key: string;
  prop: keyof VingoProduct;
  label: keyof UiTexts;
  value: string;
}

interface FormInterface {
  wasteType: string;
  containerSize: string;
  numberOfContainers: string;
  contractStartDate: Date;
  containerOwnership: string;
  gateKeyCode: string;
  additionalInformation: string;
}

const OrderProductCompleteService = ({
  emptyingId,
  categoryId,
  onNextPhaseChange,
  onPreviousPhaseChange,
  onCancel,
  setFormDataFinishOrder,
  formDataFinishOrder,
  setPriceFinishOrder,
  priceFinishOrder,
  setContainerSizeFinishOrder,
  containerSizeFinishOrder,
  setWeekIntervalFinishOrder,
  weekIntervalFinishOrder,
}: Props): JSX.Element => {
  const [wasteTypeSelected, setWasteTypeSelected] = useState<Map<string, Set<VingoProduct>> | null>(new Map());
  const [containerSizeSelected, setContainerSizeSelected] = useState<VingoProduct | null>();
  const [priceResponseData, setPriceResponseData] = useState<PriceCalculationResult>();

  const { getText } = useContext(LanguageContext);
  const { calculatePrice } = useContext(ApiContext);
  const api = useContext(ApiContext);

  const titleTypographyVariant = LargerThanBreakpoint('lg') ? 'h5' : 'h6';
  const orientation = LargerThanBreakpoint('md') ? 'row' : 'column';

  // For week interval selector : this has to be here at the top level, so it won't be reset when the form is submitted
  const [singleInterval, setSingleInterval] = useState<boolean>(true);

  const {
    data: categoryWithProducts,
    isLoading: categoryWithProductsIsLoading,
    error: categoryWithProductsError,
  } = useQuery<CategoryWithProducts>(
    ['category-with-product'],
    () => api.fetchCategoryWithProducts(emptyingId, categoryId),
    { cacheTime: 0 }
  );

  const {
    mutate: calculatePriceMutation,
    error: priceCalculationError,
    isLoading: calculatePriceMutationIsLoading,
  } = useMutation((newPriceCalculationRequest: PriceCalculationRequest) => calculatePrice(newPriceCalculationRequest));

  let wasteTypesByCategory: string[] = [];
  if (categoryWithProducts) {
    wasteTypesByCategory = Array.from(getWasteTypesAndServicesBasedOnCategory(categoryWithProducts)).map(
      (wasteType) => wasteType[0]
    );
  }

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormInterface>({
    defaultValues: formDataFinishOrder,
  });

  const currentNumberOfContainers = useWatch({
    control,
    name: 'numberOfContainers',
  });

  useEffect(() => {
    // Prepopulate values if user navigated back from summary
    if (formDataFinishOrder.wasteType && categoryWithProducts) {
      const wasteTypeEntry = Array.from(getWasteTypesAndServicesBasedOnCategory(categoryWithProducts));
      const mapVal = wasteTypeEntry.find((elem) => elem[0] === formDataFinishOrder.wasteType) || [];

      const newMap = new Map();
      newMap.set(mapVal[0], mapVal[1]);
      setWasteTypeSelected(newMap);
      setValue('wasteType', formDataFinishOrder.wasteType);
      setContainerSizeSelected(containerSizeFinishOrder);
      return;
    }

    if (categoryWithProducts) {
      const wasteTypeEntry = Array.from(getWasteTypesAndServicesBasedOnCategory(categoryWithProducts))[0];
      if (wasteTypeEntry) {
        const newMap = new Map();
        newMap.set(wasteTypeEntry[0], wasteTypeEntry[1]);
        setWasteTypeSelected(newMap);
        setContainerSizeSelected(null);

        const defaultValue = wasteTypesByCategory[0];
        setValue('wasteType', defaultValue);
      } else {
        setWasteTypeSelected(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryWithProducts]);

  useEffect(() => {
    if (containerSizeSelected && Number(currentNumberOfContainers) && Number(currentNumberOfContainers) > 0) {
      const newPriceCalculationRequest: PriceCalculationRequest = {
        productGuid: containerSizeSelected?.id,
        customerId: emptyingId,
        amount: Number(currentNumberOfContainers),
        weight: 1,
      };

      calculatePriceMutation(newPriceCalculationRequest, {
        onError: (err) => {
          console.log(err);
        },
        onSuccess: (data) => {
          setPriceResponseData(data);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerSizeSelected, currentNumberOfContainers]);

  const [alertErrorMsgFirstInterval, setAlertErrorMsgFirstInterval] = useState<keyof UiTexts | null>(null);
  const [alertErrorMsgSecondInterval, setAlertErrorMsgSecondInterval] = useState<keyof UiTexts | null>(null);

  const choseWasteType = (
    <Controller
      control={control}
      name='wasteType'
      render={({ field: { onChange, value } }) => {
        return (
          <Stack marginTop={2}>
            <RadioGroup
              value={value}
              onChange={(e) => {
                onChange(e);
                const wasteTypeKey = e.target.value;
                if (categoryWithProducts) {
                  const wasteTypeEntry = Array.from(getWasteTypesAndServicesBasedOnCategory(categoryWithProducts)).find(
                    (element) => element[0] === wasteTypeKey
                  );

                  if (wasteTypeEntry) {
                    const newMap = new Map();
                    newMap.set(wasteTypeEntry[0], wasteTypeEntry[1]);

                    setWasteTypeSelected(newMap);
                    setContainerSizeSelected(null);
                    setValue('containerSize', '');
                  } else {
                    setWasteTypeSelected(null);
                  }
                }
              }}
            >
              <WasteTypesCollapsable wasteItems={wasteTypesByCategory} selectedValue={value} />
            </RadioGroup>
          </Stack>
        );
      }}
    />
  );

  const serviceContractInformation = (
    <>
      <ControlTextInput
        control={control}
        inputType='number'
        name='numberOfContainers'
        label='order-product-contract-information-containers-label'
        error={errors.numberOfContainers}
        validations={['required', 'positive-integer']}
      />
      <ControlDateInput
        control={control}
        label='order-product-contract-information-date-label'
        name='contractStartDate'
        error={errors.contractStartDate}
        validations={['required']}
      />
    </>
  );

  const chooseContainerSize = (
    <>
      {wasteTypeSelected?.size ? (
        <Controller
          control={control}
          name='containerSize'
          rules={{
            validate: { validateFunc: (v) => validateInput(v, ['required'])[0] },
          }}
          render={({ field: { onChange, value } }) => {
            return (
              <Stack>
                <RadioGroup
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                >
                  <ContainerSizeCollapsable
                    wasteTypeSelected={wasteTypeSelected}
                    setContainerSizeSelected={setContainerSizeSelected}
                  />
                  <FormErrorField errorMsg={errors.containerSize?.message} />
                </RadioGroup>
              </Stack>
            );
          }}
        />
      ) : (
        <Typography>{getText('order-product-not-available')}</Typography>
      )}
    </>
  );

  const containerOwnership = useMemo(() => {
    const ownershipArray: JSX.Element[] = [];

    // Trying to match e-services admin order
    const options: ContainerOption[] = [
      { key: 'buy', prop: 'canBuy', label: 'order-product-container-ownership-buy', value: 'Buy' },
      { key: 'owned', prop: 'canProvideOwn', label: 'order-product-container-ownership-owned', value: 'Owned' },
      { key: 'ownership', prop: 'canRent', label: 'order-product-container-ownership-rent', value: 'Rent' },
      {
        key: 'delivered',
        prop: 'canDelivered',
        label: 'order-product-container-ownership-delivered',
        value: 'Delivered',
      },
    ];

    options
      .filter((option) => containerSizeSelected?.[option.prop] === true)
      .forEach((option) => {
        ownershipArray.push(
          <FormControlLabel key={option.key} value={option.value} control={<Radio />} label={getText(option.label)} />
        );
      });

    return ownershipArray;
  }, [containerSizeSelected, getText]);

  // Set up bin container ownership default value once it's loaded
  useEffect(() => {
    // empty string => unset value
    if (containerOwnership?.length > 0 && formDataFinishOrder?.containerOwnership === '') {
      const propValue = containerOwnership[0]?.props.value ?? '';
      setValue('containerOwnership', propValue);
    }
  }, [containerOwnership, formDataFinishOrder?.containerOwnership, setValue]);

  const choseWasteContainerOwnership = (
    <>
      {containerOwnership?.length ? (
        <Controller
          control={control}
          name='containerOwnership'
          rules={{
            validate: { validateFunc: (v) => validateInput(v, ['required'])[0] },
          }}
          render={({ field: { onChange, value } }) => {
            return (
              <Stack>
                <RadioGroup
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                >
                  {containerOwnership}
                </RadioGroup>
                <FormErrorField errorMsg={errors.containerOwnership?.message} />
              </Stack>
            );
          }}
        />
      ) : (
        <Typography key={'product-not-available'}>{getText('order-product-not-available')}</Typography>
      )}
    </>
  );

  const choseInfoForDriver = (
    <>
      <ControlTextInput
        control={control}
        inputType='string'
        name='gateKeyCode'
        label='order-product-driver-info-gate-key'
        error={errors.gateKeyCode}
        validations={[]}
      />
      <ControlTextInput
        control={control}
        inputType='string'
        name='additionalInformation'
        label='order-product-driver-info-additional-information'
        error={errors.additionalInformation}
        validations={[]}
        multiline
      />
    </>
  );

  const renderSkeleton = (): JSX.Element => {
    return (
      <>
        {orientation === 'row' ? (
          <>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Skeleton variant='rectangular' height={56} width={'40%'} />
              <Skeleton variant='rectangular' height={100} width={'50%'} />
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'} marginTop={2}>
              <Skeleton variant='rectangular' height={56} width={'40%'} />
              <Skeleton variant='rectangular' height={56} width={'50%'} />
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'} marginTop={2}>
              <Skeleton variant='rectangular' height={56} width={'40%'} />
              <Skeleton variant='rectangular' height={150} width={'50%'} />
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'} marginTop={2}>
              <Skeleton variant='rectangular' height={56} width={'40%'} />
              <Skeleton variant='rectangular' height={56} width={'50%'} />
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'} marginTop={2}>
              <Skeleton variant='rectangular' height={56} width={'40%'} />
              <Skeleton variant='rectangular' height={150} width={'50%'} />
            </Stack>
          </>
        ) : (
          <>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Skeleton variant='rectangular' height={100} width={'100%'} />
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'} marginTop={2}>
              <Skeleton variant='rectangular' height={100} width={'100%'} />
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'} marginTop={2}>
              <Skeleton variant='rectangular' height={100} width={'100%'} />
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'} marginTop={2}>
              <Skeleton variant='rectangular' height={100} width={'100%'} />
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'} marginTop={2}>
              <Skeleton variant='rectangular' height={100} width={'100%'} />
            </Stack>
          </>
        )}
      </>
    );
  };

  const submitButtonDisabled: boolean = useMemo(() => {
    return alertErrorMsgFirstInterval !== null || alertErrorMsgSecondInterval !== null;
  }, [alertErrorMsgFirstInterval, alertErrorMsgSecondInterval]);

  return (
    <Stack direction='column'>
      <ExceptionHandler error={categoryWithProductsError || priceCalculationError}>
        <ContentLoadingHandler isLoading={categoryWithProductsIsLoading} skeleton={renderSkeleton()}>
          <Container>
            <Grid container direction={orientation} wrap='nowrap'>
              <Grid item xs={12} sm={7} md={8}>
                <Typography variant={titleTypographyVariant}>{getText('order-product-waste-type-header')}</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                {choseWasteType}
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: 2 }} />

            <Grid container direction={orientation} wrap='nowrap' marginTop={2}>
              <Grid item xs={12} sm={7} md={8}>
                <Typography variant={titleTypographyVariant}>
                  {getText('order-product-container-size-header')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                {chooseContainerSize}
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: 2 }} />

            <Grid container direction={orientation} wrap='nowrap' marginTop={2}>
              <Grid item xs={12} sm={7} md={8}>
                <Typography variant={titleTypographyVariant}>
                  {getText('order-product-contract-information-header')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                {serviceContractInformation}
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: 2 }} />

            <Grid container direction={orientation} wrap='nowrap' marginTop={2}>
              <Grid item xs={12} sm={7} md={8}>
                <Typography variant={titleTypographyVariant}>
                  {getText('order-product-container-ownership-header')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                {choseWasteContainerOwnership}
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: 2 }} />

            <Grid container direction={orientation} wrap='nowrap' marginTop={2}>
              <Grid item xs={12} sm={7} md={8}>
                <Typography variant={titleTypographyVariant}>
                  {getText('order-product-emptying-rhythm-header')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <WeekIntervalSelector
                  allowedIntervals={containerSizeSelected?.allowedEmptyingIntervals ?? []}
                  alertErrorMsgFirstInterval={alertErrorMsgFirstInterval}
                  alertErrorMsgSecondInterval={alertErrorMsgSecondInterval}
                  getText={getText}
                  setAlertErrorMsgFirstInterval={setAlertErrorMsgFirstInterval}
                  setAlertErrorMsgSecondInterval={setAlertErrorMsgSecondInterval}
                  setWeekIntervalFinishOrder={setWeekIntervalFinishOrder}
                  setSingleInterval={setSingleInterval}
                  singleInterval={singleInterval}
                  weekIntervalFinishOrder={weekIntervalFinishOrder ?? []}
                />
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: 2 }} />

            <Grid container direction={orientation} wrap='nowrap' marginTop={2}>
              <Grid item xs={12} sm={7} md={8}>
                <Typography variant={titleTypographyVariant}>{getText('order-product-driver-info-header')}</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                {choseInfoForDriver}
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: 2 }} />

            <Grid container direction={orientation} wrap='nowrap' marginTop={2}>
              <Grid item xs={12} sm={4} md={4}>
                <Typography variant={titleTypographyVariant}>{getText('order-product-price-header')}</Typography>
              </Grid>
              <Grid item xs={12} sm={8} md={8}>
                <OrderProductPrice
                  price={priceResponseData || priceFinishOrder}
                  productName={containerSizeSelected?.name ?? ''}
                  containerSizeSelected={containerSizeSelected}
                  currentNumberOfContainers={Number(currentNumberOfContainers)}
                  emptyingId={emptyingId}
                />
                <ProgressComponent isLoading={calculatePriceMutationIsLoading} />
              </Grid>
            </Grid>
          </Container>

          <Container>
            <Stack direction='row' justifyContent='space-between' spacing={2} paddingX={3} marginBottom={3}>
              <Box>
                <Button size='large' color='primary' onClick={onPreviousPhaseChange}>
                  {getText('dialog-back')}
                </Button>
              </Box>
              <Stack direction='row' spacing={2}>
                <Box>
                  <Button size='large' color='secondary' onClick={onCancel}>
                    {getText('dialog-cancel')}
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant='contained'
                    size='large'
                    disabled={submitButtonDisabled}
                    onClick={handleSubmit((formData) => {
                      setFormDataFinishOrder(formData);
                      setPriceFinishOrder(priceResponseData);
                      setContainerSizeFinishOrder(containerSizeSelected);

                      onNextPhaseChange();
                    })}
                  >
                    {getText('dialog-next')}
                  </Button>
                </Box>
              </Stack>
            </Stack>
          </Container>
        </ContentLoadingHandler>
      </ExceptionHandler>
    </Stack>
  );
};

export default OrderProductCompleteService;
