import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../../../../contexts/language-context';
import { AllowedEmptyingInterval, IntervalData } from '../../../../model';
import { findAllowedInterval, selectValue } from '../../../../util/util';
import { Column, Row, ValidationError, IntervalRow } from '../../../common/containers';
import { Checkbox } from '../../../common/inputs';
import IntervalItem from '../IntervalItem';

type IntervalInputProps = {
  allowedIntervals: AllowedEmptyingInterval[];
  intervals: IntervalData[];
  updateInterval: (
    index: number,
    updater: (interval: IntervalData) => IntervalData
  ) => void;
  iWantAnotherInterval: boolean;
  setIWantAnotherInterval: React.Dispatch<React.SetStateAction<boolean>>;
  intervalOverlap: boolean;
  isSubmitted: boolean;
};

const IntervalInput = (props: IntervalInputProps): JSX.Element => {
  const {
    allowedIntervals,
    intervals,
    updateInterval,
    iWantAnotherInterval,
    setIWantAnotherInterval,
    intervalOverlap,
    isSubmitted,
  } = props;

  const year = 2026;

  const { getText } = useContext(LanguageContext);
  const [wholeYear, setWholeYear] = useState<boolean>(true);
  const firstWeekDate: Date = moment().isoWeekYear(year).isoWeek(1).isoWeekday(1).toDate();
  const lastWeekDate: Date = new Date(year, 11, 31);

  useEffect(() => {
    if (!iWantAnotherInterval) {
      updateInterval(1, iv => ({ ...iv, startWeekDate: null, endWeekDate: null, amountPerWeek: 1 }));
    }
  }, [iWantAnotherInterval]);

  function handleWholeYearChange(boolChange: boolean) {
    setIWantAnotherInterval(false);
    setWholeYear(boolChange);

    if (boolChange) {
      updateInterval(0, iv => ({
        ...iv,
        startWeekDate: firstWeekDate,
        endWeekDate: lastWeekDate,
        interval: findAllowedInterval(allowedIntervals, 1, 53)?.[0] ?? null,
        amountPerWeek: 1
      }));
    }
  }

  const renderIntervalData = (
    allowedIntervals: AllowedEmptyingInterval[],
    interval: IntervalData,
    index: number
  ): JSX.Element => {
    return (
      <IntervalItem
        start={interval.startWeekDate}
        end={interval.endWeekDate}
        setStart={d =>
          updateInterval(index, iv => ({ ...iv, startWeekDate: d }))
        }
        setEnd={d =>
          updateInterval(index, iv => ({ ...iv, endWeekDate: d }))
        }
        allowedIntervals={allowedIntervals}
        intervalValue={interval.interval ? interval.interval.toString() : ''}
        selectIntervalFunc={selectValue(intervalString =>
          updateInterval(index, iv => ({
            ...iv,
            interval: parseInt(intervalString),
          }))
        )}
        updateInterval={(intervalString =>
          updateInterval(index, iv => ({
            ...iv,
            interval: parseInt(intervalString),
          }))
        )}
        isSubmitted={isSubmitted}
        key={index}
        displayDateInputs={!wholeYear}
        amountPerWeek={interval.amountPerWeek.toString()}
        setAmountPerWeek={x =>
          updateInterval(index, iv => ({ ...iv, amountPerWeek: Number(x) }))
        }
        isWholeYear={wholeYear}
      />
    );
  };

  useEffect(() => {
    updateInterval(0, _iv => ({
      startWeekDate: firstWeekDate,
      endWeekDate: lastWeekDate,
      interval: findAllowedInterval(allowedIntervals, 1, 53)?.[0] ?? null,
      amountPerWeek: 1
    }));
  }, []);

  return (
    <Column>
      <IntervalRow>
        <Checkbox
          type="checkbox"
          id="wholeyear-checkbox"
          defaultChecked={wholeYear}
          onChange={() => handleWholeYearChange(!wholeYear)}
        />
        {getText('whole-year-interval')}
      </IntervalRow>
      {renderIntervalData(allowedIntervals, intervals[0], 0)}
      {
        !wholeYear &&
        <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <Checkbox
            type="checkbox"
            id="another-interval"
            onChange={() => setIWantAnotherInterval(!iWantAnotherInterval)}
          />
          <label htmlFor="another-interval">
            {getText('change-contract-interval-another-interval')}
          </label>
        </Row>
      }

      {iWantAnotherInterval
        ? renderIntervalData(allowedIntervals, intervals[1], 1)
        : null}
      {intervalOverlap ? (
        <ValidationError>
          {getText('change-contract-interval-overlap-error')}
        </ValidationError>
      ) : null}

      {allowedIntervals != null ? (
        <Column style={{ marginTop: '0.5rem', marginBottom: '0rem' }}>
          {allowedIntervals.map((interval, index) => (
            <Row key={"interval-" + interval.startWeek + "-" + interval.endWeek} style={{ marginTop: '0rem', marginBottom: '0rem' }}>
              {getText("change-contract-weeks")} {interval.startWeek} - {interval.endWeek}: {getText("change-contract-allowed-intervals")} {interval.intervals.toString()}
            </Row>
          ))}
        </Column>
      ) : null}
    </Column>
  );
};

export default IntervalInput;
