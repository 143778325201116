import { Box, Typography, useTheme } from "@mui/material";
import { FC, useContext } from "react";
import { LanguageContext } from "../../../contexts/language-context";
import { UiTexts } from "../../../model";

interface Props {
  errorMsg?: string;
}

const FormErrorField: FC<Props> = ({ errorMsg }) => {
  const { getText } = useContext(LanguageContext);
  const { palette } = useTheme();

  const uiTextsKey = errorMsg as keyof UiTexts ?? undefined;

  return (
    <>
      {
        uiTextsKey
          ?
          <Box 
            paddingX={1}
            paddingY={0.5}
            bgcolor={palette.warning.background}
            border={`solid 1px ${palette.warning.border}`}
          >
            <Typography color={palette.text.primary} variant="body1">
              {getText(uiTextsKey)}
            </Typography>
          </Box>
          :
          null
      }
    </>
  );
};

export default FormErrorField;