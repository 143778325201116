import { Stack, useTheme, Tooltip, Typography, Box, IconButton } from '@mui/material';
import { useContext, useState, FC } from 'react';
import { LanguageContext } from '../../../contexts/language-context';
import { BillingInfo } from '../../../model';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import EditBillingInfo from './edit-billing-info/EditBillingInfo';

interface Props {
  billingInfo?: BillingInfo;
  emptyingId: string;
}

const EmptyingLocationBillingInfo: FC<Props> = ({ billingInfo, emptyingId }): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const { palette } = useTheme();
  const address = billingInfo?.address;
  const contactPerson = billingInfo?.contactPerson;
  const [modalOpenState, setModalOpenState] = useState<boolean>(false);

  function renderBillingInfoCompanyModal() {
    return (
      <EditBillingInfo
        open={modalOpenState}
        handleClose={setModalOpenState}
        billingInfo={billingInfo}
        emptyingId={emptyingId}
      />
    );
  }

  function renderEditBillingInfoIconButton() {
    return (
      <Tooltip title={getText('billing-data-edit')} placement='top' arrow>
        <IconButton
          aria-label='edit-emptying-contact'
          sx={{ maxHeight: '40px', marginY: '-10px' }}
          onClick={() => setModalOpenState(true)}
        >
          <EditIcon color={palette.text.links} />
        </IconButton>
      </Tooltip>
    );
  }

  function renderInvoicingInfo() {
    return (
      <Stack direction='column'>
        <Typography variant='subtitle2' color={palette.text.secondary}>
          {getText('emptying-location-billing-data')}
        </Typography>

        <Box mt={1}>
          <Typography variant='subtitle1'>{billingInfo?.name}</Typography>
          <Typography variant='body1'>{address?.street}</Typography>
          <Typography variant='body1'>
            {address?.postalCode} {address?.postOffice}
          </Typography>
        </Box>
      </Stack>
    );
  }

  function renderInvoicingContacInfo() {
    return (
      <Stack direction='column'>
        <Typography variant='subtitle2' color={palette.text.secondary}>
          {getText('emptying-location-billing-contact-person')}
        </Typography>
        <Box mt={1}>
          <Typography variant='subtitle1'>{contactPerson?.name}</Typography>
          <Typography variant='body1'>{billingInfo?.billingEmail}</Typography>
          <Typography variant='body1'>{billingInfo?.billingPhone} </Typography>
        </Box>
      </Stack>
    );
  }

  return (
    <Stack direction='column' sx={{ border: `1px solid ${palette.info.border}` }} bgcolor={palette.info.background}>
      <Stack direction='row' p={2} justifyContent={'space-between'}>
        {renderInvoicingInfo()}
        {renderEditBillingInfoIconButton()}
        {renderBillingInfoCompanyModal()}
      </Stack>
      <Stack direction='row' p={2} justifyContent={'space-between'}>
        {renderInvoicingContacInfo()}
      </Stack>
    </Stack>
  );
};

export default EmptyingLocationBillingInfo;
