import { useTheme, TableCell, TableRow, Typography, Stack } from '@mui/material';
import { printddMMDate } from '../../util/calendarUtil';
import { ReactComponent as PDFIcon } from '../../assets/icons/file-text.svg';
import { PermitItemInfo } from '../../model';

interface InvoiceRowProps {
  info: PermitItemInfo;
  handleStatus: (status: string) => boolean;
  fetchPdf: (id: string) => void;
}

export const PermitApplicationTableRow = ({ info, handleStatus, fetchPdf }: InvoiceRowProps) => {
  const { palette } = useTheme();

  const cursorPointerStyle = { cursor: 'pointer' };
  const cellStyle = {
    borderBottom: `1px solid ${palette.divider}`,
  };

  const renderStatusRow = (status: string): JSX.Element | null => {
    const currentElementStatus = info.rows[0][1];

    if (!currentElementStatus) return <Stack></Stack>;
    return (
      <Stack>
      <Stack
        style={
          handleStatus(status)
            ? {
                backgroundColor: palette.success.background,
                color: palette.success.main,
                borderRadius: '20px',
                textAlign:'center',
                width: '5rem'
              }
            : {
                backgroundColor: palette.warning.background,
                color: palette.warning.main,
                borderRadius: '20px',
                textAlign:'center',
                width:'10rem'
              }
        }
      >
        <Typography variant='body1' color='textSecondary' fontSize={12} fontWeight={500}>
          {currentElementStatus}
        </Typography>
      </Stack>
      </Stack>

    );
  };

  return (
    <TableRow>
      <TableCell align='left' sx={cellStyle}>
        <Typography variant='body1' fontSize={14} fontWeight={400} color={palette.text.black}>
          {info.header}
        </Typography>
      </TableCell>

      <TableCell align='left' sx={cellStyle}>
        <Typography variant='body1' color='textSecondary' fontSize={14} fontWeight={400}>
          {printddMMDate(info.sentDate)}
        </Typography>
      </TableCell>

      <TableCell align='left' sx={cellStyle}>
        {renderStatusRow(info.status)}
      </TableCell>

      <TableCell align='left' sx={cellStyle}>
        {handleStatus(info.status) && (
          <Stack style={cursorPointerStyle}>
            <PDFIcon color={palette.text.links} onClick={() => fetchPdf(info.permitAppId)} />
          </Stack>
        )}
      </TableCell>
    </TableRow>
  );
};
