import React, { useContext, useEffect, useState, useRef } from 'react';

import styled from 'styled-components';

import { VingoProduct } from '../../../model';

import Header from '../../common/Header';
import ApiContext from '../../../contexts/api-context';
import DialogContext from '../../../contexts/dialog-context';
import { getWasteTypesAndServicesFromContractsCategory } from './order-contract/orderContractLogic';
import { Column, CompactColumn, Row } from '../../common/containers';
import Spinner from '../../common/Spinner';
import colors from '../../../colors';
import { SecondaryHeader } from '../../common/headers';
import FinishOrder from './order-contract/FinishOrder';

type OrderContractProps = {
  match: {
    params: {
      id: string;
      categoryid: string;
    };
  };
};

const WasteTypeItem = styled(Row)`
  background-color: ${colors.contentBackground};
  margin: 1rem 1rem 0px 1rem;
  padding: 0.5rem 1rem;
  border: solid 1px ${colors.contentBorder};
  cursor: pointer;
`;

type Page = 'selectWasteType' | 'finishOrder';

const OrderContract = (props: OrderContractProps): JSX.Element => {
  const api = useContext(ApiContext);
  const showDialog = useContext(DialogContext);

  const [page, setPage] = useState<Page>('selectWasteType');
  const [wasteTypesAndProducts, setWasteTypesAndProducts] = useState<Map<
    string,
    Set<VingoProduct>
  > | null>(null);

  const [selectedWasteType, setSelectedWasteType] = useState<string>('');
  const [availableProducts, setAvailableProducts] = useState<Set<VingoProduct>>(
    new Set()
  );

  const componentIsMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    api
      .fetchCategoryWithProducts(
        props.match.params.id,
        props.match.params.categoryid
      )
      .then((category) => {
        if (componentIsMounted) {
          setWasteTypesAndProducts(
            getWasteTypesAndServicesFromContractsCategory(category)
          );
        }
      })
      .catch((err: unknown) => {
        console.error(err);
        showDialog('error-service-break-title', 'error-service-break-message');
      });
  }, []);

  const selectWasteType = (
    wasteTypeName: string,
    products: Set<VingoProduct>
  ) => {
    setSelectedWasteType(wasteTypeName);
    setAvailableProducts(products);
    setPage('finishOrder');
  };

  const renderWasteTypeSelection = ([wasteTypeName, products]: [
    string,
    Set<VingoProduct>
  ]): JSX.Element => {
    return (
      <WasteTypeItem
        key={wasteTypeName}
        onClick={() => selectWasteType(wasteTypeName, products)}
      >
        <SecondaryHeader>{wasteTypeName}</SecondaryHeader>
      </WasteTypeItem>
    );
  };

  switch (page) {
    case 'selectWasteType':
      return (
        <Column>
          <Header
            headerKey="add-contract-wastetype-header"
            descriptionKey="add-contract-wastetype-desc"
            backButton={true}
          />
          <CompactColumn>
            {wasteTypesAndProducts ? (
              Array.from(wasteTypesAndProducts).map(renderWasteTypeSelection)
            ) : (
              <Spinner />
            )}
          </CompactColumn>
        </Column>
      );
    case 'finishOrder':
      return (
        <FinishOrder
          emptyingId={props.match.params.id}
          wasteTypeName={selectedWasteType}
          products={Array.from(availableProducts)}
          backAction={() => setPage('selectWasteType')}
        />
      );
  }
};

export default OrderContract;
