import React, { Dispatch, SetStateAction, useContext } from 'react';
import { Content } from '../../common/containers';
import { SecondaryHeader } from '../../common/headers';
import TextInput from '../../common/TextInput';
import { LanguageContext } from '../../../contexts/language-context';
import { Validation } from '../../common/input/validation';

type InputProps = {
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  phone: string;
  setPhone: Dispatch<SetStateAction<string>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  isSubmitted: boolean;
  isRequired: boolean;
};

const ContactInfo = (props: InputProps): JSX.Element => {
  const {
    name,
    setName,
    phone,
    setPhone,
    email,
    setEmail,
    isSubmitted,
    isRequired,
  } = props;
  const { getText } = useContext(LanguageContext);

  let requiredValidation: Validation[] = [];
  if (isRequired) {
    requiredValidation.push('required');
  }

  return (
    <Content>
      <SecondaryHeader>{getText('contact-person')}</SecondaryHeader>
      <TextInput
        label="basic-info-name"
        val={name}
        setter={setName}
        validations={requiredValidation}
        showErrors={isSubmitted}
      />
      <TextInput
        label="basic-info-phone"
        val={phone}
        setter={setPhone}
        validations={requiredValidation}
        showErrors={isSubmitted}
      />
      <TextInput
        label="basic-info-email"
        val={email}
        setter={setEmail}
        validations={requiredValidation.concat(['email'])}
        showErrors={isSubmitted}
      />
    </Content>
  );
};

export default ContactInfo;
