import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  FormEvent,
} from 'react';
import { useHistory } from 'react-router-dom';
import ApiContext from '../../../contexts/api-context';
import DialogContext from '../../../contexts/dialog-context';
import { LanguageContext } from '../../../contexts/language-context';
import ApiCallHandler from '../../../util/ApiCallHandler';
import {
  Form,
  FormContent,
  FormTop,
} from '../../common/containers';
import { SecondaryHeader, TertiaryHeader } from '../../common/headers';
import TextInput from '../../common/TextInput';
import OrderButton from '../order/OrderButton';

type AmountProps = { customerNumber: string; position: string; amount: number | null | undefined};

const Amount = (props: AmountProps): JSX.Element => {
  const api = useContext(ApiContext);
  const { getText } = useContext(LanguageContext);
  const showDialog = useContext(DialogContext);
  const history = useHistory();

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>(props.amount != null ? props.amount.toString() : "0");

  const componentIsMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const { customerNumber, position } = props;

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    ApiCallHandler(
      api.changeAmountContract(customerNumber, position, parseFloat(amount)),
      setIsLoading,
      componentIsMounted,
      showDialog,
      'change-contract-message-title',
      'change-contract-message-amount',
      history,
      () => history.goBack()
      );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormTop>
        <SecondaryHeader>
          {getText('change-contract-amount-title')}
        </SecondaryHeader>
      </FormTop>
      <FormContent>
        <TertiaryHeader>
          {getText('change-contract-amount-amount')}
        </TertiaryHeader>

        <TextInput
          val={amount}
          setter={setAmount}
          inputType="number"
          validations={['required', 'gt_zero']}
          showErrors={isSubmitted}
        />
      </FormContent>
      <OrderButton
        label="service-order-confirm-button"
        action={() => setIsSubmitted(true)}
        isLoading={isLoading}
      />
    </Form>
  );
};

export default Amount;
