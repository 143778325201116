import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  FormEvent,
} from 'react';

import { useHistory } from 'react-router-dom';

import ApiContext from '../../../contexts/api-context';
import AuthContext from '../../../contexts/auth-context';
import { LanguageContext } from '../../../contexts/language-context';
import { 
  ProductCategory,
  PickUpOrderContent,
  NewPickUpOrder,
  NewPickUpOrderReq,
  NewPickUpOrderReply,
} from '../../../model';

import DialogContext from '../../../contexts/dialog-context';

import TextInput from '../../common/TextInput';
import VingoButton from '../../common/VingoButton';
import PickUpOrder from './PickUpOrder';
import Header from '../../common/Header';
import {
  SecondaryHeader,
  TertiaryHeader,
} from '../../common/headers';
import {
  Column,
  Form,
  FormContent,
  FormTop,
  CompactColumn,
} from '../../common/containers';
import DateInput from '../../common/DateInput';
import OrderButton from './OrderButton';

type PickUpServiceProps = {
  match: {
    params: {
      id: string;
      categoryid: string;
    };
  };
  location: {
    state: { productCategory: ProductCategory };
  };
};

const PickUpService = (props: PickUpServiceProps): JSX.Element => {
  // const { productCategory } = props.location.state;
  const history = useHistory();
  const customerId = props.match.params.id;
  const categoryId =  props.match.params.categoryid;
  const api = useContext(ApiContext);
  const authService = useContext(AuthContext);
  const showDialog = useContext(DialogContext);
  const { getText } = useContext(LanguageContext);
  const [date, setDate] = useState<Date>(new Date());
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const componentIsMounted = useRef(true);

  const [pickUpOrders, setPickUpOrders] = useState<PickUpOrderContent[]>([]);
  const [extraInfo, setExtraInfo] = useState<string>('');

  const defaultPickUpOrderContent: PickUpOrderContent = {
    id: pickUpOrders.length + 1,
    name: '',
    pickUpDate: date,
    customerId: customerId,
    categoryId: categoryId,
    productGroup: '',
    product: '',
    amount: 0,
    weight: 1,
    };

  const addPickUpOrder = (newPickUpOrder: PickUpOrderContent) => {
    setPickUpOrders([...pickUpOrders, {
      id: newPickUpOrder.id,
      name: newPickUpOrder.name,
      pickUpDate: newPickUpOrder.pickUpDate,
      customerId: newPickUpOrder.customerId,
      categoryId: newPickUpOrder.categoryId,
      productGroup: newPickUpOrder.productGroup,
      product: newPickUpOrder.product,
      amount: newPickUpOrder.amount,
      weight: newPickUpOrder.weight,
      }])
  };

  useEffect(() => {
    addPickUpOrder(defaultPickUpOrderContent);
       componentIsMounted.current = false;
    }, []);

  const pickUpOrderComponents = (): JSX.Element => {
    return (
    <>    
    {pickUpOrders.map((pickUpOrderContent) => (
         <PickUpOrder
           // Keys help React identify which items have changed, are added, or are removed.
           key = {pickUpOrderContent.id}
           id={pickUpOrderContent.id}
           name={pickUpOrderContent.name}
           pickUpDate = {pickUpOrderContent.pickUpDate}
           customerId={pickUpOrderContent.customerId}
           categoryId={pickUpOrderContent.categoryId}
           productGroup = {pickUpOrderContent.productGroup}
           product = {pickUpOrderContent.product}
           amount = {pickUpOrderContent.amount}
           weight = {pickUpOrderContent.weight}
           updatedPickUpOrders={pickUpOrders}
           updatePickUpOrders = {setPickUpOrders}
           />))}
    </>);
  }

  const createPickUpOrdersReq = (): NewPickUpOrderReq  => {
    const newPickUpOrders: NewPickUpOrder[] = pickUpOrders.map(p => convertContentToOrder(p)) 
    const newPickUpOrderReq: NewPickUpOrderReq = {
      products: newPickUpOrders,
      extraInfo: extraInfo,
      orderConfirmation: true,
    }
    return newPickUpOrderReq;
  }

  const convertContentToOrder = (input: PickUpOrderContent): NewPickUpOrder => {
    const order: NewPickUpOrder = {
      name: input.name,
      pickupTime: input.pickUpDate,
      productGuid: input.product,
      amount: input.amount,
      weight: input.weight,
    }
    return order;
  };

  const addPickUpOrders = async (customerId: string, newPickUpOrderReq: NewPickUpOrderReq): Promise<NewPickUpOrderReply> => {
    const result = await api.addPickUpOrders(customerId, newPickUpOrderReq);
    return result;
  };

  const handleError = (err: any) => {
    if (err.statusCode === 401) {
      showDialog('error-unauthorized-title', 'error-unauthorized-message', () =>
        authService.logout()
      );
    } else {
      showDialog('error-service-break-title', 'error-service-break-message');
    }
    console.error(err);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if(isSubmitted) {
      setIsLoading(true);
      addPickUpOrders(customerId, createPickUpOrdersReq())
      .then((result: NewPickUpOrderReply) => {
        console.log('result out' + result);
      if(result?.orderConfirmed === 'Failed')
      {
        showDialog(
          'message-success-generic-title',
          'message-confirmation-failed',
          () => history.goBack()
        );
      } else {
        showDialog(
          'message-success-generic-title',
          'add-new-contract-success-message',
          () => history.goBack()
        );
      }
      })
      .catch((err: any) => {
        handleError(err);
      })
      .finally(() => {
        if (componentIsMounted.current) {
          setIsLoading(false);
        }
      });

      setIsSubmitted(false);
    }
  };
  return (
    <Column style={{ marginBottom: '5rem' }}>
      <Header
        headerKey="service-order-pickup-title"
        descriptionKey="service-order-pickup-description"
        backButton={true}
      ></Header>
      <CompactColumn>
        <Form onSubmit={handleSubmit}>
          <FormTop>
            <SecondaryHeader>{getText('service-order-pickup')}</SecondaryHeader>
          </FormTop>
           <FormContent>
            <TertiaryHeader>
              {getText('service-order-pickup-date')}
            </TertiaryHeader>
            <DateInput
              date={date}
              onChange={(d) => setDate(d)}
              required={true}
              showError={isSubmitted}
            />
          </FormContent>
          <FormContent>
            {pickUpOrderComponents()}
            <VingoButton
               onClick={() => addPickUpOrder(defaultPickUpOrderContent)}>
               {getText('service-order-pickup-add-order')}
            </VingoButton>
          </FormContent>
          <FormContent>
          <TertiaryHeader>
            {getText('service-order-pickup-additional-info')}
          </TertiaryHeader>
          <TextInput
            multiline={true}
            val={extraInfo}
            maxLength={500}
            setter={setExtraInfo}
            showErrors={isSubmitted}
          />
        </FormContent>
           <OrderButton
            label="service-order-button"
            isLoading={isLoading}
            action={() => setIsSubmitted(true)}
          />
        </Form>
      </CompactColumn>
    </Column>
  );
};

export default PickUpService;
