import React, { Dispatch, SetStateAction, useContext } from 'react';
import { LanguageContext } from '../../../contexts/language-context';
import { SecondaryHeader } from '../../common/headers';
import TextInput from '../../common/TextInput';
import { Content } from '../../common/containers';

type UserNameAndPwProps = {
  userName: string;
  setUserName: Dispatch<SetStateAction<string>>;
  password: string;
  setPassword: Dispatch<SetStateAction<string>>;
  confirmedPassword: string;
  setConfirmedPassword: Dispatch<SetStateAction<string>>;
  isSubmitted: boolean;
};

const UserNameAndPw = (props: UserNameAndPwProps): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const {
    userName,
    setUserName,
    password,
    setPassword,
    confirmedPassword,
    setConfirmedPassword,
    isSubmitted,
  } = props;

  return (
    <Content>
      <SecondaryHeader>{getText('username-and-pw')}</SecondaryHeader>
      <TextInput
        label="username"
        val={userName}
        setter={setUserName}
        validations={['username']}
        showErrors={isSubmitted}
      />
      <TextInput
        label="password"
        val={password}
        setter={setPassword}
        inputType="password"
        guideText={getText('pw-info')}
        validations={['password']}
        showErrors={isSubmitted}
      />
      <TextInput
        label="confirm-pw"
        val={confirmedPassword}
        setter={setConfirmedPassword}
        inputType="password"
        validations={[{ equalToValue: password }]}
        showErrors={isSubmitted}
      />
    </Content>
  );
};

export default UserNameAndPw;
