import { Stack } from '@mui/material';
import { Control, FieldErrors } from 'react-hook-form';
import { LocationInfoFormData, WIDTH_DESKTOP, WIDTH_MOBILE } from '../models';
import { LargerThanBreakpoint } from '../../../../util/viewportUtils';
import ControlTextInput from '../../../../components/common-materialui/form/ControlTextInput';
import ControlCheckboxInput from '../../../../components/common-materialui/form/ControlCheckboxInput';

interface Props {
  control: Control<LocationInfoFormData, any>;
  errors: FieldErrors<LocationInfoFormData>;
  customerType: string;
  disabled?: boolean;
}

const BasicInfoSection = ({ control, errors, customerType, disabled }: Props): JSX.Element => {
  const width = LargerThanBreakpoint('md') ? WIDTH_DESKTOP : WIDTH_MOBILE;

  return (
    <Stack direction='column' width={width}>
      <ControlCheckboxInput
        control={control}
        name='useSameEmptyingLocationBasicInfoPerson'
        error={errors.useSameEmptyingLocationBasicInfoPerson}
        label={'add-emptying-location-basic-info-use-same-name-data'}
        validations={[]}
      />
      {customerType === 'private' ? (
        <>
          <ControlTextInput
            control={control}
            name='firstName'
            label='add-emptying-location-basic-info-first-name'
            error={errors.firstName}
            validations={['required']}
            disabled={disabled}
          />
          <ControlTextInput
            control={control}
            name='lastName'
            label='add-emptying-location-basic-info-last-name'
            error={errors.lastName}
            validations={['required']}
            disabled={disabled}
          />
        </>
      ) : (
        <ControlTextInput
          control={control}
          name='companyName'
          label='basic-info-customer-name'
          error={errors.companyName}
          validations={['required']}
          disabled={disabled}
        />
      )}
      <ControlTextInput
        control={control}
        name='address'
        label='add-emptying-location-basic-info-street-address'
        error={errors.address}
        validations={['required']}
        disabled={disabled}
      />
      <ControlTextInput
        control={control}
        name='postCode'
        label='add-emptying-location-basic-info-post-code'
        error={errors.postCode}
        validations={['required', 'post-code']}
        disabled={disabled}
      />
      <ControlTextInput
        control={control}
        name='city'
        label='add-emptying-location-basic-info-city'
        error={errors.city}
        validations={['required']}
        disabled={disabled}
      />
    </Stack>
  );
};

export default BasicInfoSection;
