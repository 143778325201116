import React, { useContext } from 'react';

import { HeaderItemLeft, HeaderItemCenter, HeaderItemRight, HeaderLogo } from '../containers';
import AuthContext from '../../../contexts/auth-context';
import { NavLink } from 'react-router-dom';
import { LanguageContext } from '../../../contexts/language-context';

const DesktopHeader = (): JSX.Element => {
  const authService = useContext(AuthContext);
  const { getText } = useContext(LanguageContext);

  return (
    <>
      <HeaderItemLeft>
        <NavLink className='desktop-menu-link' to='/emptying-infos' activeClassName='active-link'>
          {getText('emptying-infos')}
        </NavLink>
        {authService.isShowInvoicesEnabled() && (
          <NavLink className='desktop-menu-link' to='/invoices' activeClassName='active-link'>
            {getText('invoices')}
          </NavLink>
        )}
        {authService.isCustomerSupportEnabled() && (
          <NavLink className='desktop-menu-link' to='/customer-support' activeClassName='active-link'>
            {getText('customer-service')}
          </NavLink>
        )}
        {authService.isPermitApplicationEnabled() && (
          <NavLink className='desktop-menu-link' to='/permit-application' activeClassName='active-link'>
            {getText('permit-application')}
          </NavLink>
        )}
        {authService.isCompostNotificationEnabled() && (
          <NavLink className='desktop-menu-link' to='/composting-notifications' activeClassName='active-link'>
            {getText('composting-notifications')}
          </NavLink>
        )}
      </HeaderItemLeft>
      <HeaderItemCenter>
        <HeaderLogo src={`${authService.getDomain()}/Company/${authService.getTenantId()}/logo`} alt='Logo' />
      </HeaderItemCenter>
      <HeaderItemRight>
        <NavLink className='desktop-menu-link' to='/account-settings' activeClassName='active-link'>
          {getText('account-settings')}
        </NavLink>
        <NavLink className='desktop-menu-link' to='/logout'>
          {getText('logout')}
        </NavLink>
      </HeaderItemRight>
    </>
  );
};

export default DesktopHeader;
