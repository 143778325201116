import { Stack } from '@mui/material';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { LocationInfoFormData, WIDTH_DESKTOP, WIDTH_MOBILE } from '../models';
import TextInput from '../../../../components/common/TextInput';
import FormErrorField from '../../../../components/common-materialui/form/FormErrorField';
import { validateInput } from '../../../../components/common/input/validation';
import { LargerThanBreakpoint } from '../../../../util/viewportUtils';
import ControlCheckboxInput from '../../../../components/common-materialui/form/ControlCheckboxInput';

interface Props {
  control: Control<LocationInfoFormData, any>;
  errors: FieldErrors<LocationInfoFormData>;
  disabled?: boolean;
}

const PrivateLocationContactSection = ({ control, errors, disabled }: Props): JSX.Element => {
  const width = LargerThanBreakpoint('md') ? WIDTH_DESKTOP : WIDTH_MOBILE;

  return (
    <Stack direction='column' width={width}>
      <ControlCheckboxInput
        control={control}
        name='useSameEmptyingLocationContactPerson'
        error={errors.useSameEmptyingLocationContactPerson}
        label={'add-emptying-location-use-same-contact-data'}
        validations={[]}
      />

      <Controller
        control={control}
        name='contactFirstName'
        render={({ field: { onChange, value } }) => {
          return (
            <TextInput
              val={value}
              setter={onChange}
              label={'add-emptying-location-basic-info-first-name'}
              disabled={disabled && value.trim().length !== 0}
            />
          );
        }}
      />

      <Controller
        control={control}
        name='contactLastName'
        render={({ field: { onChange, value } }) => {
          return (
            <TextInput
              val={value}
              setter={onChange}
              label={'add-emptying-location-basic-info-last-name'}
              disabled={disabled && value.trim().length !== 0}
            />
          );
        }}
      />

      <Controller
        control={control}
        name='contactPhone'
        rules={{
          validate: { checkEmail: (v) => validateInput(v, ['phone'])[0] },
        }}
        render={({ field: { onChange, value } }) => {
          return (
            <TextInput
              val={value}
              setter={onChange}
              label={'add-emptying-location-contact-phone'}
              disabled={disabled && value.trim().length !== 0}
            />
          );
        }}
      />
      <FormErrorField errorMsg={errors.contactPhone?.message} />

      <Controller
        control={control}
        name='contactEmail'
        rules={{
          validate: { checkEmail: (v) => validateInput(v, ['email', 'required'])[0] },
        }}
        render={({ field: { onChange, value } }) => {
          return (
            <TextInput
              val={value}
              setter={onChange}
              label={'add-emptying-location-contact-email'}
              disabled={disabled}
            />
          );
        }}
      />
      <FormErrorField errorMsg={errors.contactEmail?.message} />
    </Stack>
  );
};

export default PrivateLocationContactSection;
