import React, { useContext } from 'react';
import { Column, Content, WideContent } from '../../common/containers';
import Header from '../../common/Header';
import VingoButton from '../../common/VingoButton';
import { LanguageContext } from '../../../contexts/language-context';
import { Product } from '../../../model';
import { SecondaryHeader } from '../../common/headers';
import BasketItem from './BasketItem';
import TotalPrices from './TotalPrices';

type OrderSummaryProps = {
  products: Product[];
  backToShopping: () => void;
};

const Basket = (props: OrderSummaryProps) => {
  const { products, backToShopping } = props;
  const { getText } = useContext(LanguageContext);

  const productsInBasket = products.filter((p) => p.amount > 0);

  return (
    <Column style={{ marginBottom: '4rem' }}>
      <Header
        headerKey={'service-order-thanks'}
        // TODO Tähän ehkä bäkkäriltä palautunut aikaleima?
        //descriptionElem=
        backButton={true}
        backAction={backToShopping}
      ></Header>
      <WideContent>
        Haluatko tilata jotain muuta?
        <VingoButton secondary={true} onClick={backToShopping}>
          {getText('service-order-back-to-shopping')}
        </VingoButton>
      </WideContent>

      <Content>
        <SecondaryHeader>
          {getText('service-order-summary-title')}
        </SecondaryHeader>
        {productsInBasket.map((product, index) => (
          <BasketItem product={product} key={index} />
        ))}
      </Content>
      <TotalPrices products={products} />
    </Column>
  );
};

export default Basket;
