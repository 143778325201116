/**
 * @param props Widget props from react json schema form
 * @returns props.label or props.uiSchema['ui:title']
 */
export function getUiSchemaTitle(props: any) {
  let title = props?.label;
  if (props?.uiSchema['ui:title']) {
    title = props.uiSchema['ui:title'];
  }
  return title;
}
