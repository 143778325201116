import { createTheme } from '@material-ui/core/styles';

import colors from '../../colors';
import { STORED_COMPANY_INFO } from '../../localStorageKeys';
import { CompanyInfo } from '../../service/authService';

const INPUT_BORDER_COLOR = colors.inputBorder;
const INPUT_BG_COLOR = colors.contentBackground;
const INPUT_FOCUSED_COLOR = colors.inputFocused;
const LABEL_TEXT_COLOR = colors.lightLabel;

let buttonColor = colors.primaryButton
const companyString = localStorage.getItem(STORED_COMPANY_INFO);
if (typeof companyString === 'string') {
  try {
    const info: CompanyInfo = JSON.parse(companyString);
    if (info.color) {
      buttonColor = info.color;
    }
  }
  catch {
  }
}

const FormThemeProvider = () => {
  const customTheme = createTheme({
    props: {
      MuiTextField: {
        variant: 'filled',
      },
    },
    overrides: {
      MuiTypography: {
        h5: {
          paddingTop: '0.75rem',
          textDecoration: 'none',
          fontFamily: 'SpaceGrotesk-Medium',
          fontSize: '1.25rem',
          fontStyle: 'normal',
          fontWeight: 500,
        },
        body1: {
          fontFamily: 'SpaceGrotesk-Medium',
          fontSize: '16px',
        },
      },
      MuiFormLabel: {
        root: {
          '&$focused': {
            backgroundColor: INPUT_BG_COLOR,
            color: LABEL_TEXT_COLOR,
          },
        },
      },
      MuiSelect: {
        filled: {
          marginTop: '0.5rem',
          '&:label': {
            font: 'normal normal 0.1rem',
          },
          '&:focus': {
            backgroundColor: INPUT_BG_COLOR,
            borderColor: INPUT_FOCUSED_COLOR,
          },
        },
      },
      MuiFilledInput: {
        root: {
          fontFamily: 'SpaceGrotesk-Regular',
          fontWeight: 500,
          paddingTop: '0',
          paddingLeft: '0',
          paddingBottom: '0',
          overflow: 'hidden',
          borderStyle: 'solid',
          borderWidth: '1px',
          '&': {
            borderRadius: '0 0 0 0',
          },
          borderColor: INPUT_BORDER_COLOR,
          '&:hover': {
            backgroundColor: INPUT_BG_COLOR,
          },
          '&$focused': {
            backgroundColor: INPUT_BG_COLOR,
            borderColor: INPUT_FOCUSED_COLOR,
          },
          backgroundColor: INPUT_BG_COLOR,
        },
        underline: {
          '&&&:before': {
            borderBottom: 'none',
          },
          '&&:after': {
            borderBottom: 'none',
          },
        },
        input: {
          marginTop: '0.5rem',
        },
      },
      MuiInputBase: {
        root: {
          '&&': {
            borderRadius: '0 0 0 0',
          },
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: 'none',
        },
      },
      MuiButton: {
        containedPrimary: {
          backgroundColor: buttonColor,
          '&:hover': {
            backgroundColor: buttonColor,
          },
          width: '100%',
          height: '45px',
          marginTop: '1rem',
          marginBottom: '0.2rem',
          paddingLeft: '0.5rem',
          paddingRight: '0.5rem',
          color: colors.primaryButtonFont,
        },
      },
      MuiGrid: {
        item: {
          '&&&': {
            padding: '0px 8px 0px 8px',
          },
        },
      },
      MuiButtonBase: {
        root: {
          '&.MuiRadio-root': {
            ' & .MuiSvgIcon-root': {
              paddingLeft: '0.5rem',
              height: 15,
              weight: 15,
            },
          },
        },
      },
      MuiCheckbox: {
        root: {
          '& .MuiSvgIcon-root': {
            height: 18,
            weight: 18,
          },
        },
        colorSecondary: {
          '&.Mui-checked': {
            color: 'blue',
          },
        },
      },
    },
  });

  return customTheme;
};

export default FormThemeProvider();
