import React, { useContext, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import fiLocale from 'date-fns/locale/fi';
import svLocale from 'date-fns/locale/sv';
import enLocale from 'date-fns/locale/en-GB';
import FormThemeProvider from '../../components/common/FormThemeProvider';

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { LanguageContext } from '../../contexts/language-context';
import moment from 'moment';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { getUiSchemaTitle } from './utils';

const CustomDateWidget = function (props: any) {
  const { getText } = useContext(LanguageContext);
  const [value, setValue] = React.useState<Date | null>(null);
  const [locale, setLocale] = React.useState<any | null>(null);
  const [label, setLabel] = React.useState<string>(props.label);
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const inputDateFormat: string = 'dd.MM.yyyy';
  const oneYearLater = new Date();
  const [error, setError] = React.useState<string | null>(null);
  oneYearLater.setFullYear(currentDate.getFullYear() + 1);
  const theme = FormThemeProvider;

  const onChange = (event: any) => {
    //Check that event is a date
    if (!moment(event, inputDateFormat, true).isValid()) {
      setValue(event);
      props.onChange(event?.toString());
      return;
    }

    // Validity check, if event is not valid time then it getTime returns NaN
    if (event != null && !Number.isNaN(event.getTime()) && event.getFullYear() > 999) {
      const cleanedDate = new Date(Date.UTC(event.getFullYear(), event.getMonth(), event.getDate()));

      setValue(cleanedDate);
      if (cleanedDate > oneYearLater || cleanedDate < currentDate) {
        props.onChange('');
        return;
      }

      props.onChange(cleanedDate.toISOString().substring(0, 10));
    } else {
      setValue(event);
      props.onChange(event?.toString());
    }
  };

  useEffect(() => {
    let usedLocale = null;
    switch (props.formContext.language) {
      case 'fi':
        usedLocale = fiLocale;
        break;
      case 'sv':
        usedLocale = svLocale;
        break;
      default:
        usedLocale = enLocale;
    }
    setLocale(usedLocale);

    setLabel(getUiSchemaTitle(props));
  }, [props.formContext.formId, props.formContext.language]);

  const handleError = (errorType: string | null) => {
    let text = null;
    switch (errorType) {
      case null:
        break;
      case 'invalidDate':
        text = getText('template-error-date-format-invalid');
        props.onChange('');
        break;
      case 'minDate':
        text = getText('template-error-date-format-too-far-past');
        props.onChange('');
        break;
      case 'maxDate':
        text = getText('template-error-date-format-too-far-future');
        props.onChange('');
        break;
    }

    setError(text);
  };

  const renderTextField = (params: any) => {
    const inputProps = {
      min: { currentDate },
      max: { oneYearLater },
    };

    return (
      <TextField
        id={props.id}
        required={props.required}
        helperText={error}
        inputProps={inputProps}
        {...params}
        // After params to override that value
        error={params.error || props.rawErrors != null}
      />
    );
  };

  return (
    <MuiThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
        <DatePicker
          label={label}
          inputFormat={inputDateFormat}
          value={value}
          maxDate={oneYearLater}
          minDate={currentDate}
          onChange={onChange}
          onError={handleError}
          renderInput={renderTextField}
        />
      </LocalizationProvider>
    </MuiThemeProvider>
  );
};

export default CustomDateWidget;
