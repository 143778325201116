import { Box, Theme } from '@mui/material';
import AuthService from '../../../service/authService';
import { RouteComponentProps } from 'react-router-dom';

export const renderLogo = (authService: AuthService, theme: Theme, history: RouteComponentProps['history']) => {
  return (
    <Box
      onClick={() => history.push('/')}
      component='img'
      src={`${authService.getDomain()}/Company/${authService.getTenantId()}/logo`}
      alt='Logo'
      sx={{
        [theme.breakpoints.up('xs')]: {
          height: 64,
          maxWidth: 230,
        },
        [theme.breakpoints.up('md')]: {
          height: 84,
          maxWidth: 350,
        },
        cursor: 'pointer',
      }}
    />
  );
};
