import { FC, useContext, useState, useEffect } from 'react';
import { UiTexts } from '../model';
import { BreadcrumbBarContext } from './BreadcrumbBarContext';
import { LanguageContext } from './language-context';

export const GetStaticPaths = (getText: (key: keyof UiTexts, params?: string[] | undefined) => string) => {
  const value: PathWithName = {
    'emptying-infos': {
      name: getText('emptying-infos'),
      pathTo: '/emptying-infos',
    },
    invoices: {
      name: getText('invoices'),
      pathTo: '/invoices',
    },
    'account-settings': {
      name: getText('account-settings'),
      pathTo: '/account-settings',
    },
    'user-info': {
      name: getText('account-settings-user-info-title'),
      pathTo: '/account-settings/user-info',
    },
    'general-info': {
      name: getText('account-settings-general-info-title'),
      pathTo: '/account-settings/general-info',
    },
    'login-info': {
      name: getText('account-settings-login-info-title'),
      pathTo: '/account-settings/login-info',
    },
  };
  return value;
};

export interface PathWithName {
  [path: string]: PathInfo;
}

export interface PathInfo {
  name: string;
  pathTo: string;
}

export const BreadcrumbBarContextProvider: FC = ({ children }) => {
  const { getText, lang } = useContext(LanguageContext);
  const [pathsWithNames, setPathsWithNames] = useState<PathWithName>(GetStaticPaths(getText));

  // Needed to show translations correctly when language is changed.
  useEffect(() => {
    setPathsWithNames((x) => Object.assign(x, GetStaticPaths(getText)));
  }, [getText, lang]);

  const addCustomPathName = (info: PathWithName) => {
    setPathsWithNames({ ...pathsWithNames, ...info });
  };

  const updateNameByPath = (newName: string, path: string) => {
    if (pathsWithNames[path] === undefined) {
      return;
    }
    const temp = { ...pathsWithNames };
    temp[path] = {
      name: newName,
      pathTo: temp[path].pathTo,
    };
    setPathsWithNames(temp);
  };

  return (
    <BreadcrumbBarContext.Provider
      value={{
        pathsWithNames,
        updateNameByPath,
        addCustomPathName,
      }}
    >
      {children}
    </BreadcrumbBarContext.Provider>
  );
};
