import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { LanguageContext } from "../../../contexts/language-context";
import { UiTexts } from "../../../model";
import ErrorBox from "./ErrorBox";
import InfoBox from "./InfoBox";

export type InfoBoxElements = {
  imageElement?: JSX.Element;
  headerKey?: keyof UiTexts;
  descriptionKey?: keyof UiTexts;
  extraElements?: JSX.Element;
};

type Props = {
  error: any;
  infoBoxElements?: InfoBoxElements;
  customElements?: JSX.Element;
  children?: ReactNode;
};

function isResourceNotFoundStatus(statusCode: number | undefined) {
  return statusCode !== undefined && [403, 404].includes(statusCode);
}

/**
 * Exception handler.
 *
 * If things are ok then page content (children) is shown.
 *
 * If there is an exception
 *   - http status >= 400
 *   - informative exception
 * Then page content is hidden and exception message is shown.
 *
 * If status is 401 (Unauthorized) then dialog with "Unauthorized action" message is shown and user is redirected to login page.
 * "Resource not found" error is shown on 403 (Forbidden) and 404.
 * "Service is temporarily unavailable." error is shown with dialog and error box when status code >= 500.  (?? Should dialog be opened ??)
 * In other cases "Request could not be executed." message is shown
 *
 * If infoMessage is given as
 *   - MessageKeys then corresponding translations are shown in InfoBox.
 *   - JSX.Element then it is shown as it is without wrapping.
 *
 *
 * Usage example:
 *
 *   const [error, setError] = useState(null);
 *   const [infoMessageKeys, setInfoMessageKeys] = useState<MessageKeys | undefined>(undefined);
 *
 *   api.fetchList().then((list) => {
 *     if (list.length > 0) {
 *       setInfoMessageKeys(undefined);
 *     } else {
 *       setInfoMessageKeys("exception-message-empty-list");
 *     }
 *   }).catch((err: any) => {
 *     setError(err);
 *   });
 *
 * <ExceptionHandler error={error} infoMessage={infoMessageKeys}>
 *   <div>My cool page content</div>
 * </ExceptionHandler>
 *
 *
 * @param error Error from API request.
 * @param infoMessage Optional info message key or element
 * @param customElement Optional element content
 * @returns
 */
const ExceptionHandler = ({
  error,
  infoBoxElements,
  children,
  customElements,
}: Props): JSX.Element => {
  const history = useHistory();
  const { getText } = useContext(LanguageContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [infoElements, setInfoElements] = useState<InfoBoxElements>({});
  const [statusCode, setStatusCode] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (error?.statusCode) {
      setStatusCode(error.statusCode);
    } else {
      setStatusCode(error?.error?.response?.status);
    }
  }, [error]);

  useEffect(() => {
    // Unauthorized
    if (statusCode === 401) {
      setDialogOpen(true);
      setInfoElements({
        headerKey: "error-unauthorized-title",
        descriptionKey: "error-unauthorized-message",
      });
    }
    // Service error
    else if (statusCode && statusCode >= 500) {
      setDialogOpen(true);
      setInfoElements({
        headerKey: "error-service-break-title",
        descriptionKey: "error-service-break-message",
      });
    }
    // No dialog in other cases.
    else if (isResourceNotFoundStatus(statusCode)) {
      setInfoElements({
        headerKey: "error-resource-not-found-title",
        descriptionKey: "error-resource-not-found-message",
      });
    } else {
      setInfoElements({
        headerKey: "error-general-title",
        descriptionKey: "error-general-message",
      });
    }
  }, [statusCode]);

  const handleClose = useCallback(() => {
    setDialogOpen(false);
    // Unauthorized
    if (statusCode === 401) {
      history.replace("/logout");
    }
    // In other cases let's stay on current page
  }, [statusCode]);

  const renderDialog = () => {
    return (
      <Dialog open={dialogOpen} onClose={handleClose}>
        {infoElements.headerKey ? (
          <DialogTitle id="alert-dialog-title">
            <Typography>{getText(infoElements.headerKey)}</Typography>
          </DialogTitle>
        ) : null}

        <DialogContent>
          {infoElements.descriptionKey ? (
            <Typography variant="body1">
              {getText(infoElements.descriptionKey)}
            </Typography>
          ) : null}

          <Button
            sx={{ marginBottom: "0", marginTop: "1rem" }}
            variant="contained"
            onClick={handleClose}
          >
            {getText("dialog-ok")}
          </Button>
        </DialogContent>
      </Dialog>
    );
  };

  const renderInfoMessage = () => {
    if (customElements) {
      return customElements;
    }
    if (infoBoxElements) {
      return (
        <Container>
          <InfoBox
            imageElement={infoBoxElements?.imageElement}
            headerKey={infoBoxElements?.headerKey}
            descriptionKey={infoBoxElements?.descriptionKey}
            extraElements={infoBoxElements?.extraElements}
          ></InfoBox>
        </Container>
      );
    } else {
      return infoBoxElements;
    }
  };

  const renderErrorMessage = () => {
    return (
      <Container>
        <ErrorBox
          headerKey={infoElements.headerKey}
          descriptionKey={infoElements.descriptionKey}
        ></ErrorBox>
      </Container>
    );
  };

  const shouldRenderContent = !statusCode && !infoBoxElements && !customElements;

  return (
    <>
      {renderDialog()}
      {!dialogOpen && infoBoxElements ? renderInfoMessage() : null}
      {!dialogOpen && statusCode ? renderErrorMessage() : null}
      {shouldRenderContent ? children : null}
    </>
  );
};

export default ExceptionHandler;
