import React, { useContext, useState, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '../../../contexts/language-context';
import ApiContext from '../../../contexts/api-context';
import { Content, Form } from '../../common/containers';
import VingoButton from '../../common/VingoButton';
import DialogContext from '../../../contexts/dialog-context';
import AuthContext from '../../../contexts/auth-context';
import TextInput from '../../common/TextInput';
import { SecondaryHeader } from '../../common/headers';
import { BillingInfo, ConsumerEInvoiceType, SaveCompanyBillingInfoReq } from '../../../model';
import ModifyCompanyBillingInfo from './ModifyCompanyBillingInfo';

interface Props {
  billingInfos: BillingInfo;
}

const ModifyCompany = ({ billingInfos }: Props): JSX.Element => {
  const history = useHistory();
  const { getText } = useContext(LanguageContext);
  const auth = useContext(AuthContext);
  const api = useContext(ApiContext);
  const showDialog = useContext(DialogContext);

  const [companyName, setCompanyName] = useState<string>(billingInfos?.name ?? '');

  const [phone, setPhone] = useState<string>(billingInfos?.billingPhone ?? '');
  const [companyId, setCompanyId] = useState<string>(billingInfos?.companyId ?? '');

  const [contactName, setContactName] = useState<string>(billingInfos?.contactPerson?.name ?? '');
  const [contactPhone, setContactPhone] = useState<string>(billingInfos?.contactPerson?.phoneNumber ?? '');
  const [contactEmail, setContactEmail] = useState<string>(billingInfos?.billingEmail ?? '');

  const [streetAddress, setStreetAddress] = useState<string>(billingInfos.address.street);
  const [postCode, setPostCode] = useState<string>(billingInfos.address.postalCode);
  const [postOffice, setPostOffice] = useState<string>(billingInfos.address.postOffice);

  const [billType, setBillType] = useState<ConsumerEInvoiceType>(
    billingInfos?.eInvoicing?.address ? ConsumerEInvoiceType.ConsumerChannel : billingInfos.consumerEInvoiceType
  );

  const [eBillAddress, setEBillAddress] = useState<string>(billingInfos?.eInvoicing?.address ?? '');
  const [broker, setBroker] = useState<string>(billingInfos?.eInvoicing?.operator ?? '');
  const [ovt, setOvt] = useState<string>(billingInfos?.eInvoicing?.ediCode ?? '');

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const readOnly = auth.isReadOnlyBillingAddressEnabled();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    const data: SaveCompanyBillingInfoReq = {
      companyName: companyName,
      phoneNumber: phone,
      billingEmail: contactEmail,
      billingAddress: streetAddress + ',' + postCode + ' ' + postOffice,
      consumerEInvoiceType: billType,
      contactName: contactName,
      eInvoiceInformation: undefined,
      billingContactPhone: contactPhone,
    };

    if (billType === ConsumerEInvoiceType.ConsumerChannel) {
      data.eInvoiceInformation = {
        address: eBillAddress,
        operator: broker,
        ediCode: ovt,
      };
    }

    api
      .saveCompanyBillingInfo(billingInfos.id, data)
      .then((_res) => {
        setIsLoading(false);
        showDialog('message-success-generic-title', 'message-data-saved');
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.statusCode === 401) {
          showDialog('error-unauthorized-title', 'error-unauthorized-message', () => history.replace('/logout'));
        } else {
          showDialog('error-service-break-title', 'error-service-break-message');
        }
      });
  };

  const disableContactName = !auth.isModifyCompanyBillingContactPersonNameEnabled();

  return (
    <Form onSubmit={handleSubmit}>
      <Content>
        <SecondaryHeader>{getText('emptying-address-billing-address')}</SecondaryHeader>
        <TextInput label='basic-info-name' val={companyName} setter={setCompanyName} showErrors={isSubmitted} disabled={readOnly} />
        <TextInput
          label='billing-data-street-address'
          val={streetAddress}
          setter={setStreetAddress}
          showErrors={isSubmitted}
          disabled={readOnly}
        />
        <TextInput
          label='billing-data-post-code'
          val={postCode}
          setter={setPostCode}
          validations={[{ minLength: 5 }]}
          showErrors={isSubmitted}
          disabled={readOnly}
        />
        <TextInput label='billing-data-city' val={postOffice} setter={setPostOffice} showErrors={isSubmitted} disabled={readOnly} />
        <TextInput
          label='basic-info-phone'
          val={phone}
          setter={setPhone}
          validations={['required']}
          showErrors={isSubmitted}
          disabled={readOnly}
        />
        <TextInput
          label='basic-info-company-id'
          val={companyId}
          setter={setCompanyId}
          disabled={true}
          showErrors={isSubmitted}
        />
      </Content>

      <Content>
        <SecondaryHeader>{getText('billing-data-billing-contact-person')}</SecondaryHeader>
        <TextInput
          label='basic-info-name'
          val={contactName}
          setter={setContactName}
          validations={['required']}
          showErrors={isSubmitted}
          disabled={disableContactName || readOnly}
        />
        <TextInput
          label='basic-info-phone'
          val={contactPhone}
          setter={setContactPhone}
          validations={['required']}
          showErrors={isSubmitted}
          disabled={readOnly}
        />
        <TextInput
          label='basic-info-email'
          val={contactEmail}
          setter={setContactEmail}
          validations={['required']}
          showErrors={isSubmitted}
          disabled={readOnly}
        />
      </Content>

      <ModifyCompanyBillingInfo
        billType={billType}
        setBillType={setBillType}
        eBillAddress={eBillAddress}
        setEBillAddress={setEBillAddress}
        broker={broker}
        setBroker={setBroker}
        ovt={ovt}
        setOvt={setOvt}
        emailInvoicing={auth.isAllowRegisterEmailInvoicingEnabled()}
        isSubmitted={isSubmitted}
        readOnly={readOnly}
      />

      {!readOnly && (
        <Content>
          <VingoButton onClick={() => setIsSubmitted(true)} isLoading={isLoading} disabled={readOnly}>
            {getText('user-account-save')}
          </VingoButton>
        </Content>)}
    </Form>
  );
};

export default ModifyCompany;
