import React, { useContext } from 'react';
import { Divider, useTheme, Container, Typography, Stack, Box } from '@mui/material';
import { PermitItemInfo } from '../../model';
import { LanguageContext } from '../../contexts/language-context';
import { ReactComponent as PDFIcon } from '../../assets/icons/file-text.svg';
import { printddMMDate } from '../../util/calendarUtil';

type Props = {
  infos: PermitItemInfo[];
  handleStatus: (status: string) => boolean;
  fetchPdf: (id: string) => any;
};

const PermitApplicationCard = ({ infos, handleStatus, fetchPdf }: Props): JSX.Element => {
  const { palette } = useTheme();
  const { getText } = useContext(LanguageContext);

  const renderPdfIcon = (status: string, id: string) => {
    if (handleStatus(status)) {
      return (
        <React.Fragment>
          <Divider />
          <Stack
            style={{
              cursor: 'pointer',
              color: palette.text.links,
            }}
            onClick={() => fetchPdf(id)}
          >
            <Container
              style={{
                display: 'flex',
                paddingLeft: 0,
                paddingBottom: '0.25rem',
              }}
            >
              <PDFIcon style={{ marginRight: '0.25rem' }}></PDFIcon>
              <Typography alignSelf={'center'} lineHeight={'2rem'} fontSize={16}>
                {getText('permit-application-table-header-permit')}
              </Typography>
            </Container>
          </Stack>
        </React.Fragment>
      );
    }
    return null;
  };

  const render = () => {
    return infos.map((info: PermitItemInfo, index: number) => {
      return (
        <Container
          key={index}
          style={{
            backgroundColor: palette.background.level2,
            alignItems: 'center',
            padding: '0 1.5rem',
            paddingTop: '1rem',
            paddingBottom: '0.2rem',
            border: `solid 1px ${palette.divider}`,
            marginBottom: '0.5rem',
          }}
        >
          <Stack
            fontFamily={'SpaceGrotesk'}
            fontSize='0.875rem'
            fontWeight={500}
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
          >
            {info.rows.map((status: any, rowIndex: number) => {
              return !status[1] ? (
                <Stack key={rowIndex}></Stack>
              ) : (
                <Stack
                  key={rowIndex}
                  style={
                    handleStatus(info.status)
                      ? {
                          backgroundColor: palette.success.background,
                          color: palette.success.main,
                          borderRadius: '11px',
                          marginBottom: '10px',
                        }
                      : {
                          backgroundColor: palette.warning.background,
                          color: palette.warning.main,
                          borderRadius: '11px',
                          marginBottom: '10px',
                        }
                  }
                >
                  <Stack
                    style={{
                      padding: '4px 12px',
                    }}
                  >
                    <Typography fontSize={12} fontWeight={500} textAlign={'center'} alignSelf={'center'}>
                      {status[1]}
                    </Typography>
                  </Stack>
                </Stack>
              );
            })}
            <Stack style={{ padding: '4px 12px' }}>
              <Typography fontSize={14} fontWeight={400}>
                {printddMMDate(info.sentDate)}
              </Typography>
            </Stack>
          </Stack>
          <Box style={{ marginTop: '0.5rem', marginBottom: '1rem' }}>
            <Typography fontFamily={'SpaceGrotesk'} fontSize='1.25rem' fontWeight={500} color={palette.secondary.main}>
              {info.header}
            </Typography>
          </Box>
          {renderPdfIcon(info.status, info.permitAppId)}
        </Container>
      );
    });
  };

  return <Stack direction={'column'}>{render()}</Stack>;
};

export default PermitApplicationCard;
