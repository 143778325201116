import { FC, useCallback, useContext, useMemo } from 'react';
import { Card, CardContent, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import SharedContainerTable from './SharedContainerTable';
import { LanguageContext } from '../../../contexts/language-context';
import { EmptyingInterval, SharedContainerProduct } from '../../../model';
import { useHistory } from 'react-router-dom';
import { getNextEmptyingDate } from './emptyingLocationUtils';
import { Interval } from './ContractCard';

interface Props {
  emptyingId: string;
  contract: SharedContainerProduct;
  sharedContainerInfo: {
    sharedGroupName: string;
    isCorrespondingPerson: boolean;
    correspondingPersonName: string;
  };
  nextEmptying?: Date;
  emptyingIntervals?: EmptyingInterval[];
}

const SharedContractCard: FC<Props> = ({
  emptyingId,
  contract,
  sharedContainerInfo,
  nextEmptying,
  emptyingIntervals,
}): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const { palette, breakpoints } = useTheme();
  const largerThanPhone = useMediaQuery(breakpoints.up('md'));

  const history = useHistory();

  const contractLinkValues: string[] = useMemo(() => {
    if (contract?.identifier) {
      // Should expect value ex. 00-0000001-00_1_20240
      return contract.identifier.split('_');
    }
    return [];
  }, [contract]);

  const handleRoutingToContract = useCallback(() => {
    if (contractLinkValues.length === 0) {
      return;
    }
    history.push(`/emptying-infos/${emptyingId}/contracts/${contractLinkValues[0]}_${contractLinkValues[1]}`);
  }, [history, contractLinkValues, emptyingId]);

  return (
    <Card
      sx={{
        m: 1,
        boxShadow: 0,
        border: `1px solid ${palette.divider}`,
        cursor: 'pointer',
        '&:hover': { backgroundColor: palette.background.level1 },
      }}
      onClick={handleRoutingToContract}
    >
      <CardContent>
        <Grid container direction='column'>
          <Grid item>
            <Typography variant='h6'>{sharedContainerInfo.sharedGroupName}</Typography>
          </Grid>
          <Grid item ml={{ xs: 0, sm: 1, md: 0 }}>
            <Grid container columns={{ xs: 4, md: 8 }} rowSpacing={2} sx={{ marginTop: '5px' }}>
              <Grid item xs={4} md={4}>
                <Stack direction='column'>
                  <Typography variant='body2' color={palette.secondary.mainVariant}>
                    {getText('emptying-group-shared-container-role')}
                  </Typography>
                  <Typography variant='body1'>
                    {sharedContainerInfo.isCorrespondingPerson
                      ? getText('emptying-group-shared-container-role-corresponding')
                      : getText('emptying-group-shared-container-role-participant')}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={4} md={4}>
                <Stack direction='column'>
                  <Typography variant='body2' color={palette.secondary.mainVariant}>
                    {getText('emptying-group-shared-container-responsible-person')}
                  </Typography>
                  <Typography variant='body1'>{sharedContainerInfo.correspondingPersonName}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={4} md={4}>
                <Stack direction='column'>
                  <Typography variant='body2' color={palette.secondary.mainVariant}>
                    {getText('emptying-group-shared-container-product')}
                  </Typography>
                  <Typography variant='body1'>{contract.name}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={4} md={4}>
                <Stack direction='column'>
                  <Typography variant='body2' color={palette.secondary.mainVariant}>
                    {getText('emptying-location-contract-amount')}
                  </Typography>
                  <Typography variant='body1'>
                    {contract.amount} {getText('emptying-location-contract-amount-pcs')}
                  </Typography>
                </Stack>
              </Grid>
              {nextEmptying && (
                <Grid item xs={4} md={4}>
                  <Stack direction='column'>
                    <Typography variant='body2' color={palette.secondary.mainVariant}>
                      {getText('emptying-location-contract-next-collection')}
                    </Typography>
                    <Typography variant='body1'>{getNextEmptyingDate(new Date(nextEmptying))}</Typography>
                  </Stack>
                </Grid>
              )}
              {emptyingIntervals && emptyingIntervals.length > 0 && (
                <Grid item xs={4} md={4}>
                  <Stack direction='column'>
                    <Typography variant='body2' color={palette.secondary.mainVariant}>
                      {getText('emptying-location-contract-interval')}
                    </Typography>
                    {emptyingIntervals.map((interval, index) => (
                      <Interval
                        interval={interval}
                        largerThanPhone={largerThanPhone}
                        key={'contract-interval-' + index}
                      />
                    ))}
                  </Stack>
                </Grid>
              )}
              <Grid item xs={4} md={8}>
                <Stack direction='column'>
                  <SharedContainerTable sharedContainerPartners={contract.partners} />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SharedContractCard;
