import { createTheme } from '@mui/material';
import {
  DEFAULT_BREAKPOINTS,
  FONTWEIGHT_MEDIUM,
  FONTWEIGHT_REGULAR,
  GRID_COLUMN_VALUE,
  USER_DEFINED_BG_COLOR,
  USER_DEFINED_PRIMARY_COLOR,
} from './constants';
import { generateOpacity } from './utils';

export const light = createTheme({
  breakpoints: {
    values: {
      ...DEFAULT_BREAKPOINTS,
    },
  },
  components: {
    // TODO Add "helper text" if it's needed.
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeSmall: {},
        sizeMedium: {},
        sizeLarge: {},
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#E8E8E8',
          },
          '&.Mui-selected.Mui-focusVisible': {
            backgroundColor: '#E8E8E8',
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#f2f2f2',
          },
        },
      },
    },
    MuiCardContent: {
      defaultProps: {
        sx: {
          paddingTop: '24px',
        },
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl',
        disableGutters: true,
        sx: {
          px: { xs: 2, sm: 2, md: 3, lg: 4, xl: 4 },
          py: 2,
        },
      },
    },
    MuiGrid: {
      defaultProps: {
        columns: GRID_COLUMN_VALUE,
        columnSpacing: 2,
        rowSpacing: 1,
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          strokeWidth: '0.094rem',
          blockSize: '1.5rem',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          lineHeight: '1.25rem',
          fontWeight: FONTWEIGHT_REGULAR,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          lineHeight: '1.188rem',
          fontWeight: FONTWEIGHT_REGULAR,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#4373B5',
          textDecorationColor: '#4373B5',
          ':hover': {
            color: '#719BD6',
            textDecorationColor: '#719BD6',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
            borderColor: '#99999f',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.75rem',
          lineHeight: '0.875rem',
          fontWeight: FONTWEIGHT_MEDIUM,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#961932',
        },
        badge: {
          color: '#FFFFFF',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          whiteSpace: 'pre-line',
        },
      },
    },
  },
  palette: {
    background: {
      default: USER_DEFINED_BG_COLOR, // TODO Should be dynamic based on customer
      level1: USER_DEFINED_BG_COLOR, // TODO Should be dynamic based on customer
      level2: '#FFFFFF',
    },
    divider: '#E8E8E8',
    error: {
      main: '#AD7979', //Error-text
      background: '#F3EAEA',
      border: '#EDDEDE',
    },
    info: {
      main: '#8293A7', //Info-text
      background: '#EAEEF3',
      border: '#D8E1EC',
    },
    primary: {
      main: USER_DEFINED_PRIMARY_COLOR,
      mainVariant50: generateOpacity(USER_DEFINED_PRIMARY_COLOR, 0.5),
      mainVariant15: generateOpacity(USER_DEFINED_PRIMARY_COLOR, 0.15),
    },
    secondary: {
      main: '#41414a',
      mainVariant: '#A0A0A5',
    },
    success: {
      main: '#7D9D69', //Success-text
      background: '#E2FAE2',
      border: '#D2E8D6',
    },
    text: {
      primary: '#41414a',
      secondary: '#99999f',
      inverse: '#FFFFFF',
      black: '#000000',
      links: '#4373B5',
    },
    warning: {
      main: '#BBA879', //Warning-text
      background: '#FCF7E4',
      border: '#F1ECD9',
    },
    calendarBadge: {
      calendarSelected: '#377a6c',
      calendarEmptying: '#9ABCB5',
    },
  },
  typography: {
    fontFamily: ['SpaceGrotesk', 'sans-serif'].join(','),
    fontWeightRegular: FONTWEIGHT_REGULAR,
    fontWeightMedium: FONTWEIGHT_MEDIUM,
    body1: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: FONTWEIGHT_REGULAR,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: '1.375rem',
      fontWeight: FONTWEIGHT_REGULAR,
    },
    button: {
      fontSize: '1rem',
      lineHeight: '1rem',
      fontWeight: FONTWEIGHT_REGULAR,
      textTransform: 'none',
    },
    caption: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: FONTWEIGHT_REGULAR,
    },
    h1: {
      fontSize: '4rem',
      lineHeight: '4rem',
      fontWeight: FONTWEIGHT_MEDIUM,
    },
    h2: {
      fontSize: '2.5rem',
      lineHeight: '2.625rem',
      fontWeight: FONTWEIGHT_MEDIUM,
    },
    h3: {
      fontSize: '2rem',
      lineHeight: '2.25rem',
      fontWeight: FONTWEIGHT_MEDIUM,
    },
    h4: {
      fontSize: '1.75rem',
      lineHeight: '2rem',
      fontWeight: FONTWEIGHT_MEDIUM,
    },
    h5: {
      fontSize: '1.375rem',
      lineHeight: '1.625rem',
      fontWeight: FONTWEIGHT_MEDIUM,
    },
    h6: {
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      fontWeight: FONTWEIGHT_MEDIUM,
    },
    overline: {
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      fontWeight: FONTWEIGHT_MEDIUM,
    },
    subtitle1: {
      fontSize: '1rem',
      lineHeight: '1.25rem',
      fontWeight: FONTWEIGHT_MEDIUM,
    },
    subtitle2: {
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
      fontWeight: FONTWEIGHT_MEDIUM,
    },
  },
  shape: {
    borderRadius: 0,
  },
  zIndex: {
    appBar: 10010,
    drawer: 10000,
    modal: 10020,
    snackbar: 10030,
  },
});
