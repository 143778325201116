import React, { useContext, useEffect, useRef, useState } from 'react';
import { LanguageContext } from '../../contexts/language-context';

import { Column, CompactColumn, Row, WideContent } from '../common/containers';
import Header from '../common/Header';
import { SecondaryHeader, SmallHeader } from '../common/headers';
import { BillingInfo, CustomerType } from '../../model';

import TemplateForm from './template/TemplateForm';
import Spinner from '../common/Spinner';
import ApiContext from '../../contexts/api-context';
import DialogContext from '../../contexts/dialog-context';
import DropDown from '../common/DropDown';
import { useHistory } from 'react-router-dom';
import { RenderCustomerTypeSelection } from './register/registerUtils';

const Template = (): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const showDialog = useContext(DialogContext);
  const api = useContext(ApiContext);
  const history = useHistory();
  const [customerType, setCustomerType] = useState<CustomerType>(null);
  const [billingInfo, setBillingInfo] = useState<
    BillingInfo | undefined | null
  >(undefined);

  const [billingInfos, setBillingInfos] = useState<
    BillingInfo[] | undefined | null>(undefined);

  const componentIsMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const handleError = (err: any) => {
    if (err.statusCode === 401) {
      showDialog('error-unauthorized-title', 'error-unauthorized-message', () =>
        history.replace('/logout')
      );
    } else {
      showDialog('error-service-break-title', 'error-service-break-message');
    }
  };

  useEffect(() => {
    Promise.all([
      getBillingInfos(),
    ]).catch((err: any) => {
      handleError(err);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBillingInfos = async (): Promise<void> => {
    const fetchedBillingInfos = await api
      .fetchCustomerBillingInfos()
      .catch((err: any) => {
        handleError(err);
        return null;
      });
    setBillingInfos(fetchedBillingInfos);
    if (componentIsMounted.current) {
      if (fetchedBillingInfos && fetchedBillingInfos.length > 0) {
        setBillingInfo(fetchedBillingInfos[0]);
      } else {
        setBillingInfo(null);
      }
    }
  };

  const selectBillingInfo = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (billingInfos != null && billingInfos.length > 0) {
      setBillingInfo(billingInfos.find(x => x.id === e.target.value));
    }
  };

  const renderContent = () => {
    switch (customerType) {
      case 'private':
      case 'company':
        return (
          <WideContent style={{ marginBottom: '0.5rem' }}>
            <SmallHeader>
              {getText('emptying-address-billing-address')}
            </SmallHeader>
            {renderBillingInfo()}
          </WideContent>);
      default:
        return null;
    }
  };

  const renderForm = () => {
    switch (customerType) {
      case 'private':
        return <TemplateForm templateType={'EmptyingPrivate'} billingInfo={billingInfo} />;
      case 'company':
        return <TemplateForm templateType={'EmptyingCompany'} billingInfo={billingInfo} />;
      default:
        return null;
    }
  };

  const renderBillingInfo = () => {
    if (billingInfo === undefined) {
      return <Spinner />;
    } else {
      if (billingInfos != null && billingInfos?.length > 1) {
        const options = billingInfos.map(x => {
          let y: [string, string];
          y = [x.id, x.name + ", " + x.address.street
            + ", " + x.address.postalCode + ", " + x.address.postOffice];
          return y;
        });
        return (
          <div>
            <DropDown
              value={billingInfo?.id}
              onChange={selectBillingInfo}
              options={options}
            />
          </div>
        );
      }
      else {
        return (
          <div>
            <Row>{billingInfo?.name}</Row>
            <Row>{billingInfo?.address.street}</Row>
            <Row>
              {billingInfo?.address.postalCode} {billingInfo?.address.postOffice}
            </Row>
          </div>
        );
      }
    }
  };

  return (
    <Column style={{ marginBottom: '5rem' }}>
      <Header
        headerKey='emptying-info-header'
        descriptionKey='emptying-info-header-desc'
        backButton={false}
      ></Header>
      <CompactColumn>
        <WideContent style={{ marginBottom: '0.5rem' }}>
          <SecondaryHeader>{getText('emptying-customer-type')}</SecondaryHeader>
          {RenderCustomerTypeSelection('private-customer', 'private', setCustomerType)}
          {RenderCustomerTypeSelection('company-or-community', 'company', setCustomerType)}
        </WideContent>
        {renderContent()}
        {renderForm()}
      </CompactColumn>
    </Column>
  );
};

export default Template;
