import {
  Stack,
  useTheme,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Language, UiTexts } from '../../../model';
import { renderLogo } from './renderUtils';
import { useHistory } from 'react-router-dom';
import { EServiceLanguage } from '../../../service/authService';
import { ReactComponent as MenuIcon } from '../../../assets/icons/menu.svg';
import { LanguageContext } from '../../../contexts/language-context';
import { LanguageSelectionContext } from '../../../contexts/LanguageSelectionContextProvider';
import AuthContext from '../../../contexts/auth-context';

const LogoNavigationLanguageBar = (): JSX.Element => {
  const history = useHistory();
  const theme = useTheme();
  const authService = useContext(AuthContext);

  const {
    availableLanguages,
    getLanguageSelectionText,
    language,
    setLanguage,
  } = useContext(LanguageSelectionContext);

  const { getText } = useContext(LanguageContext);


  const smallerThanLg = useMediaQuery(theme.breakpoints.down('lg'));

  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState<null | HTMLElement>(null);
  const mobileMenuOpen = Boolean(mobileMenuAnchorEl);

  const handleMobileMenuClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMenuAnchorEl(null);
  };

  useEffect(() => {
    if (!smallerThanLg && mobileMenuAnchorEl) {
      setMobileMenuAnchorEl(null);
    }
  }, [smallerThanLg, mobileMenuAnchorEl]);

  useEffect(() => {
    setMobileMenuAnchorEl(null);
  }, [language]);


  const toggleButtonSx = {
    border: 0,
    borderTop: 0,
    borderRadius: 0,
    '&.Mui-selected': {
      backgroundColor: 'white',
      textDecoration: 'underline',
    },
  };

  const handleLanguageChange = (_event: React.MouseEvent<HTMLElement>, selectedLanguage: Language) => {
    if (selectedLanguage) {
      setLanguage(selectedLanguage);
    }
  };

  const mobileLanguageSelectionMenu = () => {
    return (
      <>
        <Stack
          spacing={1}
          direction={'row'}
          onClick={(e) => handleMobileMenuClick(e)}
          sx={{
            cursor: 'pointer',
            alignItems: 'center',
            border: `1px solid ${theme.palette.divider}`,
            padding: 1,
            height: 'fit-content',
            marginRight: 1,
          }}
        >
          <MenuIcon />
          <Typography variant='body1'>{getText(getSelectedLanguageUiTextKey(language))}</Typography>
        </Stack>
        <Menu open={mobileMenuOpen} onClose={handleMobileMenuClose} anchorEl={mobileMenuAnchorEl}>
          {availableLanguages.map((lang) => (
            <MenuItem key={lang} onClick={(e) => handleLanguageChange(e, lang.toLowerCase() as Language)}>
              {getLanguageSelectionText(lang)}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  };

  const renderLanguageSelection = () => {
    if (smallerThanLg) {
      return mobileLanguageSelectionMenu();
    }

    return (
      <Stack direction='row'>
        <ToggleButtonGroup
          orientation='horizontal'
          value={language}
          exclusive
          onChange={handleLanguageChange}
          aria-label='language selection'
        >
          {availableLanguages.map((lang) => (
            <ToggleButton key={lang} value={lang.toLowerCase()} aria-label={lang} sx={toggleButtonSx}>
              {getLanguageSelectionText(lang)}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Stack>
    );
  };

  return (
    <>
      <Stack
        sx={{
          [theme.breakpoints.up('xs')]: {
            height: 80,
            px: 1,
          },
          [theme.breakpoints.up('md')]: {
            height: 100,
            px: 1.5,
          },
          [theme.breakpoints.up('lg')]: {
            height: 100,
            px: 2,
          },
          [theme.breakpoints.up('xl')]: {
            height: 100,
            px: 3,
          },
          pt: 1,
          backgroundColor: theme.palette.background.level2,
        }}
      >
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          {renderLogo(authService, theme, history)}
          {renderLanguageSelection()}
        </Stack>
      </Stack>
    </>
  );
};

/**
 * Get the ui text key for the selected language.
 * Defaults to finnish if given unknown language.
 * @param language Language to get the key for
 * @returns
 */
function getSelectedLanguageUiTextKey(language: Language): keyof UiTexts {
  switch (language) {
    case 'en':
      return 'language-chosen-english';
    case 'fi':
      return 'language-chosen-finnish';
    case 'sv':
      return 'language-chosen-swedish';
    default:
      return 'language-chosen-finnish';
  }
}

/**
 * Get the ui text key for the language.
 * Defaults to finnish if given unknown language.
 * @param language Language to get the key for
 * @returns
 */
export function getLanguageUiTextKey(language: Language | EServiceLanguage): keyof UiTexts {
  switch (language.toLocaleLowerCase()) {
    case 'en':
      return 'language-english';
    case 'fi':
      return 'language-finnish';
    case 'sv':
      return 'language-swedish';
    default:
      return 'language-finnish';
  }
}

export default LogoNavigationLanguageBar;
