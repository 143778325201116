import { useState, useContext } from 'react';
import { Box, Button, FormControlLabel, Radio, Collapse, Typography, Stack } from '@mui/material';
import { LanguageContext } from '../../../../contexts/language-context';
import { VingoProduct } from '../../../../model';

type ContainersCollapsableProps = {
  wasteTypeSelected: Map<string, Set<VingoProduct>> | null;
  setContainerSizeSelected: (product: VingoProduct) => void;
};

const CONSTANTS = {
  MINIMUM_DISPLAYED_ITEMS: 8,
};

const ContainerSizeCollapsable = ({
  wasteTypeSelected,
  setContainerSizeSelected,
}: ContainersCollapsableProps): JSX.Element => {
  const [showAll, setShowAll] = useState<boolean>(false);

  const { getText } = useContext(LanguageContext);

  const allProducts = wasteTypeSelected
    ? Array.from(wasteTypeSelected).flatMap(([_, wasteTypeSet]) => Array.from(wasteTypeSet || []))
    : [];

  const displayedProducts = allProducts.slice(0, CONSTANTS.MINIMUM_DISPLAYED_ITEMS);
  const hiddenProducts = allProducts.slice(CONSTANTS.MINIMUM_DISPLAYED_ITEMS);

  return (
    <Box>
      {displayedProducts.map((product) => {
        if (!product) return null;

        return (
          <Stack direction='column' key={product.id}>
            <FormControlLabel
              control={<Radio />}
              label={product.name}
              value={product.name}
              onChange={() => {
                setContainerSizeSelected(product);
              }}
            />
            {product.infoText && (
              <Typography variant='body2' color='text.secondary' sx={{ marginTop: '-10px', paddingLeft: '31px' }}>
                {product.infoText}
              </Typography>
            )}
          </Stack>
        );
      })}

      <Collapse in={showAll}>
        {hiddenProducts.map((product) => {
          if (!product) return null;

          return (
            <Stack direction='column' key={product.id}>
              <FormControlLabel
                control={<Radio />}
                label={product.name}
                value={product.name}
                onChange={() => {
                  setContainerSizeSelected(product);
                }}
              />
              {product.infoText && (
                <Typography variant='body2' color='text.secondary' sx={{ marginTop: '-10px', paddingLeft: '31px' }}>
                  {product.infoText}
                </Typography>
              )}
            </Stack>
          );
        })}
      </Collapse>

      {allProducts.length > CONSTANTS.MINIMUM_DISPLAYED_ITEMS && (
        <Button onClick={() => setShowAll(!showAll)}>
          {showAll ? getText('order-product-show-less') : getText('order-product-show-more')}
        </Button>
      )}
    </Box>
  );
};

export default ContainerSizeCollapsable;
