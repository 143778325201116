import Divider from '@material-ui/core/Divider';
import { Box, Container } from '@mui/material';
import BreadcrumbBar from './BreadcrumbBar';
import LogoNavigationMenuBar from './LogoNavigationMenuBar';

interface Props {
  content?: JSX.Element;
}

const Header = ({ content }: Props): JSX.Element => {
  return (
    <>
      <Box sx={{ backgroundColor: 'background.level2' }}>
        <LogoNavigationMenuBar />
        <BreadcrumbBar />
        <Container>{content}</Container>
        {content && <Divider />}
      </Box>
    </>
  );
};

export default Header;
