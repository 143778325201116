import { Box, Container, Dialog, Divider, IconButton, Stack, Typography, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import AuthContext from '../../../contexts/auth-context';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import { LanguageContext } from '../../../contexts/language-context';
import { UiTexts } from '../../../model';
import { ReactComponent as LangIcon } from './../../../assets/icons/language.svg';
import { ReactComponent as UserIcon } from './../../../assets/icons/user.svg';
import { ReactComponent as LogoutIcon } from './../../../assets/icons/log-out.svg';
import { Link } from 'react-router-dom';

type Props = {
  open: boolean;
  onClose: () => void;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='left' ref={ref} {...props} />;
});

const MenuDialog = ({ open, onClose }: Props): JSX.Element => {
  const authService = useContext(AuthContext);
  const { getText } = useContext(LanguageContext);
  const theme = useTheme();
  const { palette } = theme;

  const renderMainLink = (linkUrl: string, textKey: keyof UiTexts) => {
    return (
      <Link to={linkUrl}>
        <Typography variant='h5' color={palette.text.primary}>
          {getText(textKey)}
        </Typography>
      </Link>
    );
  };

  const renderLinkWithIcon = (icon: JSX.Element, linkUrl: string, textKey: keyof UiTexts) => {
    return (
      <Stack direction='row' spacing={1}>
        <Typography color={palette.text.secondary}>{icon}</Typography>
        <Link to={linkUrl}>
          <Typography variant='body1' color={palette.text.primary}>
            {getText(textKey)}
          </Typography>
        </Link>
      </Stack>
    );
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{
        width: 300,
        left: 'calc(100% - 300px)',
      }}
    >
      <Container
        sx={{
          ...theme.components?.MuiContainer?.defaultProps?.sx,
          height: '100%',
        }}
      >
        <Stack direction='column' sx={{ height: '100%' }}>
          <Box display='flex' justifyContent='flex-end' p={1} pb={2}>
            <IconButton onClick={onClose} aria-label='close'>
              <CloseIcon />
            </IconButton>
          </Box>
          <Stack direction='column' spacing={1.5} p={1}>
            {renderMainLink('/emptying-infos', 'emptying-infos')}
            {authService.isShowInvoicesEnabled() && renderMainLink('/invoices', 'invoices')}
            {authService.isReportingEnabled() && renderMainLink('/reporting', 'reporting')}
            {authService.isCustomerSupportEnabled() && renderMainLink('/customer-support', 'customer-service')}
            {authService.isPermitApplicationEnabled() && renderMainLink('/permit-application', 'permit-application')}
            {authService.isCompostNotificationEnabled() &&
              renderMainLink('/composting-notifications', 'composting-notifications')}
            <Box py={1}>
              <Divider />
            </Box>
            <Stack direction='row' spacing={1}>
              <Typography color='text.secondary'>
                <LangIcon />
              </Typography>
              <Typography color='text.black'>{getText('selected-language')}</Typography>
            </Stack>
            {renderLinkWithIcon(<UserIcon />, '/account-settings', 'account-settings')}
          </Stack>
          <Box mt='auto' p={1}>
            {renderLinkWithIcon(<LogoutIcon />, '/logout', 'logout')}
          </Box>
        </Stack>
      </Container>
    </Dialog>
  );
};

export default MenuDialog;
