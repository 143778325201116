import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  useMediaQuery,
  useTheme,
  Typography,
  Stack,
  SxProps,
  Theme,
  Link,
} from '@mui/material';
import { Language } from '../../model';
import { LanguageContext } from '../../contexts/language-context';
import defaultLoginBackground from '../../assets/backgrounds/default_asiointi_bg.png';
import LogoNavigationLanguageBar from '../../components/common-materialui/header/LogoNavigationLanguageBar';
import SignIn from './SignIn';
import { generateOpacity } from '../../themes/utils';
import { USER_DEFINED_PRIMARY_COLOR } from '../../themes/constants';

type LoginFormProps = {
  language: Language;
  setLanguage: Dispatch<SetStateAction<Language>>;
};

const LoginPage = (props: LoginFormProps): JSX.Element => {
  const { language } = props;
  const { getText } = useContext(LanguageContext);
  const theme = useTheme();
  const smallerThanLg = useMediaQuery(theme.breakpoints.down('lg'));

  const characterLimit = 125;
  const [showAllTextBox1, setShowAllTextBox1] = useState<boolean>(false);

  const primaryColor5Variant = generateOpacity(USER_DEFINED_PRIMARY_COLOR, 0.05);
  const primaryColor10Variant = generateOpacity(USER_DEFINED_PRIMARY_COLOR, 0.1);
  const primaryColor25Variant = generateOpacity(USER_DEFINED_PRIMARY_COLOR, 0.25);

  const backgroundBoxStyle = {
    backgroundColor: `color-mix(in srgb, #FFFFFF 85%, ${primaryColor10Variant})`,
    borderRadius: 4,
    border: `1px solid color-mix(in srgb, #FFFFFF 50%, ${primaryColor25Variant})`,
    backdropFilter: 'blur(1.5px)',
    maxWidth: '750px',
    minWidth: '200px',
    minHeight: '200px',
    width: '90%',
  };

  const mobileInfoBoxStyle = {
    backgroundColor: primaryColor5Variant,
    border: `1px solid color-mix(in srgb, #FFFFFF 50%, ${primaryColor25Variant})`,
    borderRadius: 4,
    width: '100%',
    minWidth: '200px',
    maxWidth: '450px',
  };

  useEffect(() => {
    if (smallerThanLg) {
      setShowAllTextBox1(false);
    } else {
      setShowAllTextBox1(true);
    }
  }, [smallerThanLg]);

  const renderHeader = () => {
    return <LogoNavigationLanguageBar />;
  };

  const renderShowMoreOrLess = () => {
    if (showAllTextBox1) {
      return <Link onClick={() => setShowAllTextBox1(!showAllTextBox1)}>{'\n' + getText('generic-show-less')}</Link>;
    }
    return <Link onClick={() => setShowAllTextBox1(!showAllTextBox1)}>{'\n' + getText('generic-show-more')}</Link>;
  };

  const renderInfoCardOne = (customSx: SxProps<Theme>) => {
    const typographySx = { whiteSpace: 'pre-line', wordWrap: 'anywhere' };
    return (
      <Box sx={customSx}>
        <Stack direction={'column'} spacing={2} px={4} py={4}>
          <Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'}>
            <Typography sx={typographySx} color={theme.palette.text.black} variant='h2'>
              {getText('login-info-box-1-title')}
            </Typography>
          </Stack>
          <Typography sx={typographySx} color={theme.palette.text.black} variant='body1'>
            {smallerThanLg && !showAllTextBox1
              ? getText('login-info-box-1-text').substring(0, characterLimit) + '...'
              : getText('login-info-box-1-text')}
            {smallerThanLg && renderShowMoreOrLess()}
          </Typography>
        </Stack>
      </Box>
    );
  };

  const renderInfoCardTwo = (customSx: SxProps<Theme>) => {
    const typographySx = { whiteSpace: 'pre-line', wordWrap: 'anywhere' };
    return (
      <Box sx={customSx}>
        <Stack direction={'column'} spacing={2} px={4} py={4}>
          <Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'}>
            <Typography sx={typographySx} color={theme.palette.text.black} variant='h2'>
              {getText('login-info-box-2-title')}
            </Typography>
          </Stack>
          <Typography sx={typographySx} color={theme.palette.text.black} variant='body1'>
            {getText('login-info-box-2-text')}
          </Typography>
        </Stack>
      </Box>
    );
  };

  const formContent = () => (
    <Stack direction={'column'} sx={{ backgroundColor: 'white', width: '100%', minHeight: '100%' }}>
      {renderHeader()}

      <Divider style={{ background: theme.palette.divider }} />
      {smallerThanLg ? (
        <Stack direction={'column'} spacing={5} pt={3} justifyContent={'center'} alignItems={'center'} mx={2}>
          {renderInfoCardOne(mobileInfoBoxStyle)}
        </Stack>
      ) : null}

      <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} mx={2}>
        <SignIn language={language} />
      </Stack>
      {smallerThanLg ? (
        <Stack mb={4} direction={'column'} spacing={5} pt={3} justifyContent={'center'} alignItems={'center'} mx={2}>
          {renderInfoCardTwo(mobileInfoBoxStyle)}
        </Stack>
      ) : null}
    </Stack>
  );

  const bgWithInfoCards = () => {
    return (
      <Box
        sx={{
          backgroundImage: `url(${defaultLoginBackground})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          width: '100%',
          minHeight: '100%',
          filter: 'opacity(0.7)',
          boxShadow: `inset 0 0 0 100vmax ${primaryColor5Variant}`, // Adds user defined color to bg
        }}
      >
        <Stack
          direction={'column'}
          spacing={5}
          pt={{ lg: '10%', xl: '10%' }}
          justifyContent={'center'}
          alignItems={'center'}
        >
          {renderInfoCardOne(backgroundBoxStyle)}
          {renderInfoCardTwo(backgroundBoxStyle)}
        </Stack>
      </Box>
    );
  };

  return (
    <Grid
      display='flex'
      container
      columnSpacing={0}
      rowSpacing={0}
      direction={smallerThanLg ? 'column' : 'row'}
      sx={{ minHeight: '100%', width: '100%', flexFlow: smallerThanLg ? 'column' : 'row' }}
    >
      <Grid
        item
        xs={0}
        md={0}
        lg={7}
        sx={{
          display: { xs: 'none', md: 'none', lg: 'block' },
        }}
      >
        {bgWithInfoCards()}
      </Grid>
      <Grid item xs={12} lg={5} paddingLeft={0}>
        {formContent()}
      </Grid>
    </Grid>
  );
};

export default LoginPage;
