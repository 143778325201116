import styled from 'styled-components';
import colors from '../../colors';

import calenderSvg from '../../assets/calendar.svg';

export const PrimaryButton = styled.button`
  background-color: ${colors.primaryButton};
  border: solid 1px ${colors.primaryButtonBorder};
  width: 100%;
  height: 45px;
  margin-top: 1rem;
  margin-bottom: 0.2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  color: ${colors.primaryButtonFont};
  &:disabled {
    background-color: ${colors.primaryButtonDisabled};
  }
`;

export const CompactButton = styled(PrimaryButton)`
  width: min-content;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  margin-top: 0.2rem;
`;

export const SecondaryButton = styled(PrimaryButton)`
  background-color: ${colors.secondaryButton};
`;

export const MenuButton = styled.button`
  padding: 0.6rem;
  background-color: ${colors.contentBackground};
  border: 0;
`;

export const MenuLinkButton = styled.button`
  font-family: SpaceGrotesk-Medium;
  font-weight: 500;
  font-size: 1.25rem;
  margin-top: 1rem;
  padding: 0;
  background-color: ${colors.contentBackground};
  border: 0;
  text-align: left;
`;

export const LinkButton = styled.button`
  font-family: SpaceGrotesk-Regular;
  font-size: 1rem;
  padding: 0;
  color: ${colors.linkColor};
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  outline: none;
  text-align: left;
  &:hover {
    color: ${colors.linkHover};
  }
`;

export const ArrowLinkButton = styled(LinkButton)`
  font-size: 1.4rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  margin: 0;
  text-align: center;
`;

export const MenuCloseButton = styled.button`
  padding: 0.6rem;
  background-color: ${colors.contentBackground};
  border: 0;
  margin: 2.4rem;
`;

export const LanguageButton = styled.button`
  padding: 0.9rem;
  background-color: ${colors.contentBackground};
  border: 0px;
  font-size: 1rem;
  text-align: left;
  color: ${colors.header};
`;

export const NavButton = styled.button`
  font-family: SpaceGrotesk-Regular, sans-serif;
  background-color: ${colors.contentBackground};
  border: 0px;
  font-size: 1.4rem;
  text-align: left;
  color: ${colors.header};
`;

export const IconButton = styled.button`
  background-color: transparent;
  color: ${colors.secondaryButton};
  margin: 0.2rem 0.2rem 0 0;
  padding: 0.2rem 0 0 0;
  border: 1px;
`;

export const RadioButton = styled.input`
  margin-right: 0.6rem;
`;

export const Checkbox = styled.input`
  margin-right: 0.6rem;
`;

export const AmountChangeButton = styled.button`
  margin: 0;
  padding: 0;
  font-size: 1rem;
  color: ${colors.primaryButtonFont};
  background-color: ${colors.secondaryButton};
  border: solid 1px ${colors.primaryButtonBorder};
  width: 28px;
  height: 28px;
  border-radius: 50%;
  text-align: center;
  &:disabled {
    background-color: ${colors.secondaryButtonDisabled};
  }
`;

export const Select = styled.select`
  border: solid 1px ${colors.inputBorder};
  padding: 1rem 0.6rem;
  background-color: ${colors.contentBackground};
  font-size: 1rem;
  font-family: SpaceGrotesk-Regular;
  width: 100%;
`;

export const SelectCustom = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-position: 95% center;
  background-repeat: no-repeat;
  outline: none;
  border: solid 1px ${colors.inputBorder};
  padding: 1rem 2rem 1rem 1rem;
  background-color: ${colors.contentBackground};
  font-size: 1rem;
  font-family: SpaceGrotesk-Regular;
`;

export const SelectWeek = styled(SelectCustom)`
  background-image: url(${calenderSvg});
  flex-grow: 0.3;
`;
