import React, { useState, useContext, useEffect } from 'react';
import ApiContext from '../../contexts/api-context';

import Header from '../common/Header';
import { Column, CompactColumn } from '../common/containers';
import { Contract } from '../../model';
import Spinner from '../common/Spinner';
import DialogContext from '../../contexts/dialog-context';
import ContractHistoryItem from './emptying-address/ContractHistoryItem';

type ContractHistoryProps = {
  match: {
    params: {
      id: string;
    };
  };
};

const ContractHistory = (props: ContractHistoryProps): JSX.Element => {
  const { match } = props;
  const api = useContext(ApiContext);
  const showDialog = useContext(DialogContext);

  const [contracts, setContracts] = useState<Contract[] | null>(null);

  useEffect(() => {
    const emptyingId = match.params.id;
    api
      .fetchContracts(emptyingId)
      .then((res) => setContracts(res))
      .catch((err: unknown) => {
        console.error(err);
        showDialog('error-service-break-title', 'error-service-break-message');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContracts = () => {
    if (contracts === null) {
      return <Spinner />;
    } else {
      return contracts.map((c) => (
        <ContractHistoryItem
          key={`${c.productCode}-${c.position}`}
          contract={c}
          address=""
        />
      ));
    }
  };

  return (
    <Column>
      <Header
        headerKey="contracts-label"
        descriptionKey="contracts-desc"
        backButton={true}
      ></Header>
      <CompactColumn>{renderContracts()}</CompactColumn>
    </Column>
  );
};

export default ContractHistory;
