import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  FormEvent,
} from 'react';
import { useHistory } from 'react-router-dom';
import ApiContext from '../../../contexts/api-context';
import DialogContext from '../../../contexts/dialog-context';
import { LanguageContext } from '../../../contexts/language-context';
import ApiCallHandler from '../../../util/ApiCallHandler';
import {
  Form,
  FormContent,
  FormTop,
  TextContent,
} from '../../common/containers';
import DateInput from '../../common/DateInput';
import { SecondaryHeader, TertiaryHeader } from '../../common/headers';
import TextInput from '../../common/TextInput';
import OrderButton from '../order/OrderButton';
import { ValidationError } from '../../common/containers';
import { VingoProduct } from '../../../model';

type PauseProps = { customerNumber: string; position: string; product: VingoProduct | null; };

const Pause = (props: PauseProps): JSX.Element => {
  const api = useContext(ApiContext);
  const { getText } = useContext(LanguageContext);
  const showDialog = useContext(DialogContext);
  const history = useHistory();

  let today = setFixedTime(new Date());
  let tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  let maxDate = new Date(today);
  maxDate.setMonth(maxDate.getMonth() + 3);

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(today);
  const [endDate, setEndDate] = useState<Date>(tomorrow);
  const [datesInvalid, setDatesInvalid] = useState<string>('');
  const [additionalInfo, setAdditionalInfo] = useState<string>('');
  const [minDate, setMinDate] = useState<Date>(today);
  const [endMinDate, setEndMinDate] = useState<Date>(tomorrow);
  const [endMaxDate, setEndMaxDate] = useState<Date>(maxDate);

  const componentIsMounted = useRef(true);

  const { customerNumber, position, product } = props;

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    let now: Date = setFixedTime(new Date());
    setStartDate(setFixedTime(startDate));
    setEndDate(setFixedTime(endDate));
    const pauseLengthMonths = ((endDate.getFullYear() - startDate.getFullYear()) * 12)
      + (endDate.getMonth() - startDate.getMonth());
    const pauseLengthDays = endDate.getDate() - startDate.getDate();
    if (startDate.getTime() === endDate.getTime()) {
      setDatesInvalid(getText('validation-equal-start-end-dates'));
    }
    else if (startDate.getTime() > endDate.getTime()) {
      setDatesInvalid(getText('validation-startdate-greater-than-enddate'));
    }
    else if (startDate.getTime() < now.getTime()) {
      setDatesInvalid(getText('validation-startdate-before-now'));
    }
    else if (endDate.getTime() < now.getTime()) {
      setDatesInvalid(getText('validation-enddate-before-now'));
    }
    else if (product?.maxPauseLength != null && (pauseLengthMonths > product?.maxPauseLength ||
      (pauseLengthMonths == product?.maxPauseLength && pauseLengthDays > 0))) {
      setDatesInvalid(getText('validation-enddate-too-far'));
    }
    else {
      setDatesInvalid('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, product]);

  useEffect(() => {
    const currentDate = setFixedTime(new Date());
    const earliestDate = setFixedTime(product?.earliestContractStartDate
      ? new Date(product?.earliestContractStartDate) : currentDate);
    const selectedDate = setFixedTime(startDate);
    const minLimit = earliestDate > currentDate ? earliestDate : currentDate;
    const minDate = minLimit > selectedDate ? minLimit : selectedDate;
    const tommorowDate = new Date(minDate);
    tommorowDate.setDate(tommorowDate.getDate() + 1);
    const futureDate = new Date(minDate);
    futureDate.setMonth(futureDate.getMonth() + (product?.maxPauseLength ?? 3), futureDate.getDate() - 1);
    setMinDate(minLimit);
    setEndMinDate(tommorowDate);
    setEndMaxDate(futureDate);
    if (startDate < minLimit) {
      setStartDate(minLimit);
      setEndDate(tommorowDate);
    }
  }, [startDate, product]);

  function setFixedTime(date: Date): Date {
    date.setHours(0, 0, 0, 0);
    return date;
  }

  const errorMessage = datesInvalid.length > 0 ? (
    <ValidationError>{datesInvalid}</ValidationError>
  ) : null;

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    ApiCallHandler(
      api.pauseContract(customerNumber, position, startDate, endDate, '', additionalInfo),
      setIsLoading,
      componentIsMounted,
      showDialog,
      'change-contract-message-title',
      'change-contract-message-pause',
      history,
      () => history.goBack()
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormTop>
        <SecondaryHeader>
          {getText('change-contract-pause-title')}
        </SecondaryHeader>
      </FormTop>
      <FormContent>
        <TextContent style={{ marginTop: '1rem' }}>
          {getText('change-contract-pause-desc')}
        </TextContent>
        <TertiaryHeader>
          {getText('change-contract-pause-start')}
        </TertiaryHeader>

        <DateInput
          date={startDate}
          onChange={d => setStartDate(setFixedTime(d))}
          showError={isSubmitted}
          minDate={minDate}
        />
        <TertiaryHeader>{getText('change-contract-pause-end')}</TertiaryHeader>
        <DateInput
          date={endDate}
          onChange={d => setEndDate(setFixedTime(d))}
          showError={isSubmitted}
          minDate={endMinDate}
          maxDate={endMaxDate}
        />
        {errorMessage}
      </FormContent>
      {
        /* TODO: When backend is ready for additional info
        <FormContent>
          <TertiaryHeader>
            {getText('change-contract-additional-info')}
          </TertiaryHeader>
          <TextInput
            multiline={true}
            val={additionalInfo}
            maxLength={500}
            setter={setAdditionalInfo}
            placeholder="extra-emptying-additional-info-placeholder"
            showErrors={isSubmitted}
          />
        </FormContent>*/
      }
      <OrderButton
        label="service-order-confirm-button"
        action={() => setIsSubmitted(true)}
        isLoading={isLoading}
        disabled={errorMessage != null}
      />
    </Form>
  );
};

export default Pause;
