import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  FormEvent,
  Dispatch,
  SetStateAction,
} from 'react';
import { useHistory } from 'react-router-dom';

import { ContactMethod, Language } from '../../model';

import { Column, CompactColumn } from '../common/containers';
import LoginHeader from '../common/LoginHeader';
import AuthContext from '../../contexts/auth-context';
import DialogContext from '../../contexts/dialog-context';
import FeedbackForm from './customer-support/FeedbackForm';

type LoginFeedbackProps = {
  language: Language;
  setLanguage: Dispatch<SetStateAction<Language>>;
};

const LoginFeedback = (props: LoginFeedbackProps): JSX.Element => {
  const { language, setLanguage } = props;
  const history = useHistory();
  const authService = useContext(AuthContext);
  const showDialog = useContext(DialogContext);
  const { sendFeedback } = authService;

  const [feedbackText, setFeedbackText] = useState<string>('');
  const [feedbackHeaderText, setFeedbackHeaderText] = useState<string>('');
  const [contactMethod, setContactMethod] = useState<ContactMethod>('email');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [messageCategory, setMessageCategory] = useState<string>('');
  const [messageCategoryId, setMessageCategoryId] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const componentIsMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    sendFeedback(feedbackText, contactMethod, email, phone, messageCategoryId)
      .then(() => {
        showDialog(
          'message-success-generic-title',
          'feedback-sent-message',
          () => history.replace('/login')
        );
      })
      .catch((err: any) => {
        showDialog('error-general-title', 'error-service-break-message');
      })
      .finally(() => {
        if (componentIsMounted.current) {
          setIsLoading(false);
        }
      });
  };

  return (
    <Column>
      <LoginHeader
        headerKey="feedback-header"
        descriptionKey="feedback-header-description"
        language={language}
        setLanguage={setLanguage}
        backButton={true}
      />

      <CompactColumn>
        <FeedbackForm
          feedbackHeaderText={feedbackHeaderText}
          setFeedbackHeaderText={setFeedbackHeaderText}
          feedbackText={feedbackText}
          setFeedbackText={setFeedbackText}
          contactMethod={contactMethod}
          setContactMethod={setContactMethod}
          email={email}
          setEmail={setEmail}
          phone={phone}
          setPhone={setPhone}
          isSubmitted={isSubmitted}
          setIsSubmitted={setIsSubmitted}
          isLoading={isLoading}
          setMessageCategory={setMessageCategory}
          messageCategoryId={messageCategoryId}
          setMessageCategoryId={setMessageCategoryId}
          handleSubmit={handleSubmit}
        />
      </CompactColumn>
    </Column>
  );
};

export default LoginFeedback;
