import React, { useContext, useEffect, useRef, useState } from 'react';
import { LanguageContext } from '../../../../contexts/language-context';
import { BinOwnership } from '../../../../model';
import { ContentRow, FormContent, ValidationError } from '../../../common/containers';
import { TertiaryHeader } from '../../../common/headers';
import { RadioButton } from '../../../common/inputs';

type BinOwnershipSelectionProps = {
  canRent: boolean;
  canBuy: boolean;
  canProvideOwn: boolean;
  canDelivered: boolean;
  binOwnership: BinOwnership | null;
  setValue: (val: BinOwnership | null) => void;
  isSubmitted: boolean;
};

const BinOwnershipSelection = (
  props: BinOwnershipSelectionProps
): JSX.Element => {
  const { canRent, canBuy, canProvideOwn, canDelivered, binOwnership, setValue, isSubmitted } = props;
  const { getText } = useContext(LanguageContext);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const renderOwnershipSelection = (
    id: string,
    value: BinOwnership,
    labelText: string
  ) => (
    <ContentRow>
      <RadioButton
        type="radio"
        name="select-bin-ownership"
        id={id}
        checked={binOwnership === value}
        onChange={() => setValue(value)}
      />
      <label htmlFor={id}>{labelText}</label>
    </ContentRow>
  );

  const checkValue = (value: BinOwnership | null) => {
    switch (value) {
      case 'Buy':
        if (!canBuy) {
          setValue(null);
        }
        break;
      case 'Rent':
        if (!canRent) {
          setValue(null);
        }
        break;
      case 'Owned':
        if (!canProvideOwn) {
          setValue(null);
        }
        break;
      case 'Delivered':
        if (!canDelivered) {
          setValue(null);
        }
        break;
      default:
        setValue(null);
        break;
    }
  };

  useEffect(() => {
    checkValue(binOwnership)
  }, [canRent, canBuy, canProvideOwn, canDelivered]);

  useEffect(() => {
    if (binOwnership == null) {
      const message = getText('validation-required');
      setErrorMessage(message);
    }
    else
    {
      setErrorMessage(null);
    }
  }, [binOwnership]);

  return (
    <FormContent>
      <TertiaryHeader>
        {getText('add-contract-bin-ownership-label')}{' '}
      </TertiaryHeader>
      {canRent
        ? renderOwnershipSelection(
          'rent-bin',
          'Rent',
          getText('add-contract-bin-ownership-rent')
        )
        : null}

      {canBuy
        ? renderOwnershipSelection(
          'buy-bin',
          'Buy',
          getText('add-contract-bin-ownership-buy')
        )
        : null}

      {canProvideOwn
        ? renderOwnershipSelection(
          'owned-bin',
          'Owned',
          getText('add-contract-bin-ownership-owned')
        )
        : null}

      {canDelivered
        ? renderOwnershipSelection(
          'delivered-bin',
          'Delivered',
          getText('add-contract-bin-ownership-delivery')
        )
        : null}
      {isSubmitted && errorMessage != null &&
        <ValidationError>
          {errorMessage}
        </ValidationError>
      }
    </FormContent>
  );
};

export default BinOwnershipSelection;
