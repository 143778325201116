import { useContext } from 'react';
import { Card, CardContent, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { LanguageContext } from '../../../contexts/language-context';
import { convertToCurrentLocaleLang } from '../../../util/numberLocalization';
import { PriceCalculationResult } from '../../../model';

interface RenderPriceProps {
  value?: number;
  excludeUnit?: boolean;
  isWasteManagement?: boolean;
  price?: PriceCalculationResult;
}

interface AdditionalRowRenderProps {
  name: string;
  eventType: string;
  destination: string;
  price?: PriceCalculationResult;
}

interface PriceCardProps {
  price: PriceCalculationResult;
  productName?: string;
  additionalProductCalculations?: AdditionalRowRenderProps[];
  currentNumberOfContainers?: number;
}

const PriceCard = ({
  price,
  productName,
  additionalProductCalculations,
  currentNumberOfContainers,
}: PriceCardProps): JSX.Element => {
  const { palette, breakpoints } = useTheme();
  const { getText, lang } = useContext(LanguageContext);
  const includesVatString = getText('includes-vat');

  const largerThanPhone = useMediaQuery(breakpoints.up('md'));
  const textStyle = { fontSize: largerThanPhone ? '0.875rem' : '1rem' };

  const gridItemStyle = {
    marginTop: '10px',
  };

  function renderPrice(data: RenderPriceProps) {
    let unitText = '';
    if ((!data?.value && data?.value !== 0) || !data?.price) {
      return '';
    }

    if (!data?.excludeUnit) {
      unitText = data?.price?.AmountUnit ? '/' + data?.price?.AmountUnit : '';
    }

    // If it's waste management or additional product, display weight unit when it's weight based
    if (data?.isWasteManagement && !data?.excludeUnit) {
      unitText = data?.price?.IsWeightBased ? '/' + data?.price?.WeightUnit : '/' + data?.price?.AmountUnit;
    }

    return `${convertToCurrentLocaleLang(lang, data.value, undefined, 2)} €${unitText}`;
  }

  function renderTotalPrice(data: RenderPriceProps) {
    let unitText = '';
    if ((!data?.value && data?.value !== 0) || !data?.price) {
      return '';
    }

    // In the waste management price row, should not display total price if its weight based
    if (data?.isWasteManagement && data?.price?.IsWeightBased) {
      return '';
    }

    if (!data?.excludeUnit) {
      unitText = '/' + data?.price?.AmountUnit;
    }

    return `${convertToCurrentLocaleLang(lang, data.value, undefined, 2)} €${unitText}`;
  }

  function renderAmount(value?: number, priceData?: PriceCalculationResult, checkWeightBased?: boolean) {
    if ((!value && value !== 0) || !priceData) {
      return '';
    }

    if (checkWeightBased && priceData?.IsWeightBased) {
      return '';
    }

    return `${value} ${priceData?.AmountUnit ?? ''}`;
  }

  function renderMainProductRow() {
    return (
      <Card sx={{ boxShadow: 0, border: `1px solid ${palette.divider}` }}>
        <CardContent
          sx={{
            paddingLeft: '12px',
          }}
        >
          <Grid container columns={{ xs: 2 }} rowSpacing={2}>
            <Grid item xs={1}>
              <Stack direction='column'>
                <Typography variant='subtitle1'>{getText('order-product-service-header')}</Typography>
                <Typography variant='body1' noWrap sx={textStyle}>
                  {productName + ' '} {includesVatString}
                </Typography>
              </Stack>
            </Grid>
            {renderPrice({ value: price?.TransportUnitPriceIncludingVat, price: price }) && (
              <Grid item xs={1}>
                <Stack direction='column'>
                  <Typography variant='subtitle1'>{getText('order-product-unit-price-header')}</Typography>
                  <Typography variant='body1' noWrap sx={textStyle}>
                    {renderPrice({ value: price?.TransportUnitPriceIncludingVat, price: price })}
                  </Typography>
                </Stack>
              </Grid>
            )}
            {renderAmount(currentNumberOfContainers, price) && (
              <Grid item xs={1} sx={{ ...gridItemStyle }}>
                <Stack direction='column'>
                  <Typography variant='subtitle1'>{getText('order-product-amount-header')}</Typography>
                  <Typography variant='body1' noWrap sx={textStyle}>
                    {renderAmount(currentNumberOfContainers, price)}
                  </Typography>
                </Stack>
              </Grid>
            )}
            {renderTotalPrice({ value: price?.TransportPriceIncludingVat, excludeUnit: true, price: price }) && (
              <Grid item xs={1} sx={{ ...gridItemStyle }}>
                <Stack direction='column'>
                  <Typography variant='subtitle1'>{getText('order-product-total-header')}</Typography>
                  <Typography variant='body1' noWrap sx={textStyle}>
                    {renderTotalPrice({ value: price?.TransportPriceIncludingVat, excludeUnit: true, price: price })}
                  </Typography>
                </Stack>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  }

  function renderWasteManagementRow() {
    return (
      <Card sx={{ boxShadow: 0, border: `1px solid ${palette.divider}` }}>
        <CardContent
          sx={{
            paddingLeft: '12px',
          }}
        >
          <Grid container columns={{ xs: 2 }} rowSpacing={2}>
            <Grid item xs={1}>
              <Stack direction='column'>
                <Typography variant='subtitle1'>{getText('order-product-service-header')}</Typography>
                <Typography variant='body1' noWrap sx={textStyle}>
                  {getText('order-product-row-waste-management') + ' '}
                  {includesVatString}
                </Typography>
              </Stack>
            </Grid>
            {renderPrice({ value: price?.HandlingUnitPriceIncludingVat, isWasteManagement: true, price: price }) && (
              <Grid item xs={1}>
                <Stack direction='column'>
                  <Typography variant='subtitle1'>{getText('order-product-unit-price-header')}</Typography>
                  <Typography variant='body1' noWrap sx={textStyle}>
                    {renderPrice({
                      value: price?.HandlingUnitPriceIncludingVat,
                      isWasteManagement: true,
                      price: price,
                    })}
                  </Typography>
                </Stack>
              </Grid>
            )}
            {renderAmount(currentNumberOfContainers, price, true) && (
              <Grid item xs={1} sx={{ ...gridItemStyle }}>
                <Stack direction='column'>
                  <Typography variant='subtitle1'>{getText('order-product-amount-header')}</Typography>
                  <Typography variant='body1' noWrap sx={textStyle}>
                    {renderAmount(currentNumberOfContainers, price, true)}
                  </Typography>
                </Stack>
              </Grid>
            )}
            {renderTotalPrice({
              value: price?.HandlingPriceIncludingVat,
              excludeUnit: true,
              isWasteManagement: true,
              price: price,
            }) && (
              <Grid item xs={1} sx={{ ...gridItemStyle }}>
                <Stack direction='column'>
                  <Typography variant='subtitle1'>{getText('order-product-total-header')}</Typography>
                  <Typography variant='body1' noWrap sx={textStyle}>
                    {renderTotalPrice({
                      value: price?.HandlingPriceIncludingVat,
                      excludeUnit: true,
                      isWasteManagement: true,
                      price: price,
                    })}
                  </Typography>
                </Stack>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  }

  function renderTotalRow(isWeightBased: boolean) {
    if (isWeightBased) {
      return null;
    }
    return (
      <Card sx={{ boxShadow: 0, border: `1px solid ${palette.divider}` }}>
        <CardContent
          sx={{
            paddingLeft: '12px',
          }}
        >
          <Grid container columns={{ xs: 2 }} rowSpacing={2}>
            <Grid item xs={1}>
              <Stack direction='column'>
                <Typography variant='subtitle1'>{getText('order-product-service-header')}</Typography>
                <Typography variant='subtitle1' noWrap sx={textStyle}>
                  {getText('order-product-total-header') + ' '}
                  {includesVatString}
                </Typography>
              </Stack>
            </Grid>
            {renderTotalPrice({ value: price?.TotalPriceGross, excludeUnit: true, price: price }) && (
              <Grid item xs={1}>
                <Stack direction='column'>
                  <Typography variant='subtitle1'>{getText('order-product-total-header')}</Typography>
                  <Typography variant='subtitle1' noWrap sx={textStyle}>
                    {renderTotalPrice({ value: price?.TotalPriceGross, excludeUnit: true, price: price })}
                  </Typography>
                </Stack>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  }

  function renderAdditionalProductRow(data: AdditionalRowRenderProps, idx: number) {
    return (
      <Card sx={{ boxShadow: 0, border: `1px solid ${palette.divider}` }}>
        <CardContent
          sx={{
            paddingLeft: '12px',
          }}
        >
          <Grid container columns={{ xs: 2 }} rowSpacing={2}>
            <Grid item xs={1}>
              <Stack direction='column'>
                <Typography variant='subtitle1'>{getText('order-product-service-header')}</Typography>
                <Typography variant='body1' noWrap sx={textStyle}>
                  {data.name + ' '} {includesVatString}
                </Typography>
              </Stack>
            </Grid>
            {renderPrice({
              value: data?.price?.TransportUnitPriceIncludingVat,
              isWasteManagement: true,
              price: data?.price,
            }) && (
              <Grid item xs={1}>
                <Stack direction='column'>
                  <Typography variant='subtitle1'>{getText('order-product-unit-price-header')}</Typography>
                  <Typography variant='body1' noWrap sx={textStyle}>
                    {renderPrice({
                      value: data?.price?.TransportUnitPriceIncludingVat,
                      isWasteManagement: true,
                      price: data?.price,
                    })}
                  </Typography>
                </Stack>
              </Grid>
            )}
            {renderAmount(currentNumberOfContainers, data?.price) && (
              <Grid item xs={1} sx={{ ...gridItemStyle }}>
                <Stack direction='column'>
                  <Typography variant='subtitle1'>{getText('order-product-amount-header')}</Typography>
                  <Typography variant='body1' noWrap sx={textStyle}>
                    {renderAmount(currentNumberOfContainers, data?.price)}
                  </Typography>
                </Stack>
              </Grid>
            )}
            {renderTotalPrice({
              value: data?.price?.TransportPriceIncludingVat,
              excludeUnit: true,
              price: data?.price,
            }) && (
              <Grid item xs={1} sx={{ ...gridItemStyle }}>
                <Stack direction='column'>
                  <Typography variant='subtitle1'>{getText('order-product-total-header')}</Typography>
                  <Typography variant='body1' noWrap sx={textStyle}>
                    {renderTotalPrice({
                      value: data?.price?.TransportPriceIncludingVat,
                      excludeUnit: true,
                      price: data?.price,
                    })}
                  </Typography>
                </Stack>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  }
  return (
    <Stack direction={'column'} gap={2}>
      {renderMainProductRow()}
      {renderWasteManagementRow()}
      {renderTotalRow(price?.IsWeightBased ?? false)}
      {additionalProductCalculations?.map((data, idx) => renderAdditionalProductRow(data, idx))}
    </Stack>
  );
};

export default PriceCard;
