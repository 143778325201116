import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthContext from '../contexts/auth-context';

// @ts-ignore
const PrivateRoute = ({ component: Component, ...rest }) => {
  const authService = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        authService.loggedIn() ? (
          <Component {...props} />
        ) : (
          <Redirect to='/login' />
        )
      }
    />
  );
};

export default PrivateRoute;
