import { useContext } from 'react';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { Control, FieldErrors } from 'react-hook-form';
import { LanguageContext } from '../../../../../contexts/language-context';
import ControlTextInput from '../../../../../components/common-materialui/form/ControlTextInput';
import LocationAccess from './LocationAccess';
import { UiTexts } from '../../../../../model';
import NeedForEmptyingClosedTank from './need-for-emptying/NeedForEmptyingClosedTank';
import { LocationAccessForm, ClosedTankForm } from '../models';
import { LargerThanBreakpoint } from '../../../../../util/viewportUtils';

export const defaultClosedTankValues = {
  volume: 0,
  noLimits: false,
  noWinterEmptyings: false,
  locked: false,
  weightLimit: false,
  expections: false,
  other: false,
  weightLimitDetails: '',
  otherDetails: '',
  location: '',
  needForEmptying: {
    weeklyFrequency: '',
    other: '',
  },
};
interface Props {
  control: Control<ClosedTankForm>;
  errors: FieldErrors<ClosedTankForm>;
  formHeader: keyof UiTexts;
}

const ClosedTank = ({ control, errors, formHeader }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const direction = LargerThanBreakpoint('md') ? 'row' : 'column';

  return (
    <Container>
      <Grid container direction={direction} wrap='nowrap' spacing={1} justifyContent={'space-between'}>
        <Grid item xs={12} sm={7} md={8}>
          <Typography variant='h6'>{getText(formHeader)}</Typography>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Stack direction={direction} spacing={2}>
            <Box width={'100%'}>
              <ControlTextInput
                control={control}
                inputType='number'
                name='volume'
                label='closed-tank-volume'
                error={errors.volume}
                validations={['required', 'gt_zero']}
              />
            </Box>
          </Stack>
          <NeedForEmptyingClosedTank control={control} errors={errors} />
          <LocationAccess control={control as unknown as Control<LocationAccessForm>} errors={errors} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ClosedTank;
