import { CategoryWithProducts, VingoProduct } from '../../../../model';

export function getWasteTypesAndServicesFromContractsCategory(
  category: CategoryWithProducts
): Map<string, Set<VingoProduct>> {
  return category.products.reduce(wasteTypeBuilder, new Map());
}

function wasteTypeBuilder(
  wasteTypeMap: Map<string, Set<VingoProduct>>,
  currentProduct: VingoProduct
) {
  const groupName = currentProduct.productGroupName;
  if (groupName) {
    let productSet = wasteTypeMap.get(groupName);
    if (!productSet) {
      productSet = new Set<VingoProduct>(); 
    }
    productSet.add(currentProduct);
    wasteTypeMap.set(groupName, productSet);
  }
  return wasteTypeMap;
}
