import { UseFormReset, UseFormSetValue } from 'react-hook-form';
import { BillingInfo } from '../../../../model';
import { BillingInfoFormData } from '../models';

/**
 *
 * @param billingInfo Billing information object.
 * @returns Undefined if not provided the param, otherwise returns the billing info as a BillingInfoFormData object.
 */
export function convertBillingInfoToFormData(billingInfo: BillingInfo | undefined) {
  if (billingInfo === undefined) {
    return undefined;
  }

  let firstName = billingInfo?.firstName ?? '';
  let lastName = billingInfo?.lastName ?? '';
  let contactFirstName = '';
  let contactLastName =  '';

  if (billingInfo?.name) {
    const items = billingInfo.name.split(' ');
    firstName = items.slice(0, -1).join(' ');
    lastName = items[items.length - 1];
  }

  if (billingInfo?.contactPerson?.name) {
    const contactItems = billingInfo.contactPerson?.name.split(' ');
    contactFirstName = contactItems.slice(0, -1).join(' ');
    contactLastName = contactItems[contactItems.length - 1];
  }

  const companyName = billingInfo?.companyName ?? billingInfo?.name ?? '';

  const formData: BillingInfoFormData = {
    companyName: billingInfo.isCompany ? companyName : '',
    companyCode: billingInfo.isCompany ? billingInfo.companyId : '',
    personalIdentificationNumber: billingInfo.isCompany ? '' : '*********',
    firstName: firstName,
    lastName: lastName,
    address: billingInfo.address.street,
    postCode: billingInfo.address.postalCode,
    city: billingInfo.address.postOffice,
    email: billingInfo?.contactPerson?.email ?? billingInfo?.billingEmail ?? '',
    phoneNumber: billingInfo?.contactPerson?.phoneNumber ?? billingInfo?.billingPhone ?? '',
    consumerEInvoiceType: billingInfo.consumerEInvoiceType,
    emailInvoicing: billingInfo.emailInvoicing,
    contactFirstName: contactFirstName,
    contactLastName: contactLastName,
    billingEmail: billingInfo?.billingEmail ?? '',
    reference: '',
    eInvoiceInformation: billingInfo?.eInvoicing,
  };

  return formData;
}

/**
 * 
 * @param selectedBillingInfo Billing information object, will be converted to BillingInfoFormData and set to the form.
 * @param formSetValue Function from userForm to use as setter.
 * @param formReset Form reset function to clear values and validations.
 * @returns Nothing, mutates the form.
 */
export function setValuesToFormFromBillingInfo(
  selectedBillingInfo: BillingInfo | undefined,
  formSetValue: UseFormSetValue<BillingInfoFormData>,
  formReset: UseFormReset<BillingInfoFormData>
) {
  formReset();
  const convertedObject: BillingInfoFormData | undefined = convertBillingInfoToFormData(selectedBillingInfo);
  if (convertedObject !== undefined) {
    // TODO-XJHLES-1120 is this the right way to do with react form hook?
    Object.entries(convertedObject).forEach(([key, value]) => {
      formSetValue(key as keyof BillingInfoFormData, value);
    });
  }
}
