import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../../../contexts/language-context';
import { UiTexts } from '../../../model';
import { Content, Row } from '../../common/containers';
import { Checkbox } from '../../common/inputs';
import Terms from '../../common/Terms';
import VingoButton from '../../common/VingoButton';

type OrderButtonProps = {
  label: keyof UiTexts;
  action?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
};

const OrderButton = ({
  label,
  action,
  isLoading,
  disabled,
}: OrderButtonProps) => {
  const { getText } = useContext(LanguageContext);

  const [iHaveReadConditions, setIHaveReadConditions] = useState<boolean>(
    false
  );

  return (
    <Content>
      <Row style={{ marginTop: '1.2rem', marginBottom: '0.5rem' }}>
        <Terms
          type="contract"
          iHaveReadConditions={iHaveReadConditions}
          setIHaveReadConditions={setIHaveReadConditions}
        />
      </Row>
      <VingoButton
        onClick={action}
        disabled={!iHaveReadConditions || disabled}
        isLoading={isLoading}
      >
        {getText(label)}
      </VingoButton>
    </Content>
  );
};

export default OrderButton;
