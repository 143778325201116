import React, { useState, useContext, useEffect, useRef } from 'react';

import MuiForm5 from '@rjsf/material-ui';
import { MuiThemeProvider } from '@material-ui/core';
import FormThemeProvider from '../../common/FormThemeProvider';
import { LanguageContext } from '../../../contexts/language-context';
import ApiContext from '../../../contexts/api-context';
import { BillingInfo, TemplateInfo, TemplateType } from '../../../model';
import { Content } from '../../common/containers';
import VingoButton from '../../common/VingoButton';
import Spinner from '../../common/Spinner';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../../contexts/auth-context';
import DialogContext from '../../../contexts/dialog-context';
import AutoPopulateSelect from '../../../util/custom-widgets/AutoPopulateSelect';
import CustomDateWidget from '../../../util/custom-widgets/CustomDateWidget';
import { templateFormErrors } from '../../../util/util';
import flatten from 'flat';
import { fieldConversion } from './SchemaValueConversions';
import { customizeValidator, CustomValidatorOptionsType } from '@rjsf/validator-ajv8';
import CustomCheckboxesWidget from '../../../util/custom-widgets/CustomCheckboxesWidget';
import CustomRadioWidget from '../../../util/custom-widgets/CustomRadioWidget';
import CustomEnumWidget from '../../../util/custom-widgets/CustomEnumWidget';
import CustomNullField from '../../../util/custom-widgets/CustomNullField';
import CustomCheckboxWidget from '../../../util/custom-widgets/CustomCheckboxWidget';
import { TemplatesType } from '@rjsf/utils';
import { ArrayFieldTemplateAddAndRemove } from '../../../util/templateUtils';
import CustomDescriptionField from '../../../util/custom-widgets/CustomDescriptionField';

interface TemplateFormProps {
  templateType: TemplateType;
  billingInfo: BillingInfo | null | undefined;
}

const TemplateForm = (props: TemplateFormProps): JSX.Element => {
  const history = useHistory();
  const theme = FormThemeProvider;
  const { getText, lang } = useContext(LanguageContext);
  const auth = useContext(AuthContext);
  const api = useContext(ApiContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formsData, setFormsData] = useState<object>({});
  const [templateInfo, setTemplateInfo] = useState<TemplateInfo>();
  const showDialog = useContext(DialogContext);

  const submitFormRef = useRef<HTMLButtonElement | null>();
  const [flatBillingInfo, setflatBillingInfo] = useState<object>();

  const getTemplateInfo = async (templateType: TemplateType): Promise<TemplateInfo | null> => {
    return api
      .fetchActiveTemplate(templateType, lang)
      .then((template: TemplateInfo) => {
        return template;
      })
      .catch((err: any) => {
        showDialog('error-service-break-title', 'error-service-break-message');
        history.replace('/emptying-infos');
        return null;
      });
  };

  const initializeTemplates = async () => {
    const info = await getTemplateInfo(props.templateType);
    if (info != null && info.schema) {
      setTemplateInfo(info);
      setFormsData({ templateId: info.id });
    } else {
      //Throw error
      console.error('Schemas failed to load.');
    }
  };

  useEffect(() => {
    initializeTemplates();
  }, [props.templateType]);

  const onSubmit = (data: any) => {
    if (props.billingInfo != null) {
      setIsLoading(true);
      api
        .addEmptyingAccount(props.billingInfo.id, data.formData)
        .then(() => {
          history.replace('/emptying-infos');
          showDialog('emptying-info-send-success-title', 'emptying-info-send-success-message');
        })
        .catch((err: any) => {
          showDialog('error-service-break-title', 'error-service-break-message');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleFormSubmit = (event: any) => {
    event.preventDefault();
    submitFormRef.current?.click();
  };

  const handleChange = (data: any) => {
    setFormsData(data.formData);
  };

  const copyBillingInfo = (event: any) => {
    if (props.billingInfo != null) {
      const flatted: any = flatten(props.billingInfo);
      let cleaned: any = {};
      for (const property in flatted) {
        let value = flatted[property];

        // Special conversion for name field for splitting
        // (can be removed if and when billing-info return value is splitted properly,
        // but even if it is left, it shouldn't broke anything )
        if (property == 'name') {
          if (flatted.isCompany) {
            cleaned['companyName'] = value;
          } else {
            let splittedValue = value.split(' ');
            cleaned['lastName'] = splittedValue[0];
            cleaned['firstName'] = splittedValue[1];
          }

          continue;
        }

        let converted = fieldConversion[property];
        if (converted != null) {
          cleaned[converted] = value;
          continue;
        }
        cleaned[property] = value;
      }

      setflatBillingInfo(cleaned);
      // override existing data, if there is
      let fullData = Object.assign(formsData, cleaned);
      setFormsData(fullData);
    }
  };

  const fields = { NullField: CustomNullField };
  const context = {
    domain: auth.getDomain(),
    tenantId: auth.getTenantId(),
    language: lang,
    formId: templateInfo?.id ?? null,
  };
  
  const customWidgets = {
    date: CustomDateWidget,
    auto: AutoPopulateSelect,
    select: CustomEnumWidget,
    checkboxes: CustomCheckboxesWidget,
    radio: CustomRadioWidget,
    checkbox: CustomCheckboxWidget,
  };

  const templates: Partial<TemplatesType> = {
    ArrayFieldTemplate: ArrayFieldTemplateAddAndRemove,
    DescriptionFieldTemplate: CustomDescriptionField
  };

  const additionalMetaSchemas: CustomValidatorOptionsType['additionalMetaSchemas'] = [{}];
  const customFormats: CustomValidatorOptionsType['customFormats'] = { phone: /^[0+][1-9][\d]*$/ };
  const validator = customizeValidator({ additionalMetaSchemas, customFormats });

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <Content>
          <VingoButton onClick={copyBillingInfo}>{getText('emptying-customer-type-select')}</VingoButton>
        </Content>

        {templateInfo != null ? (
          <Content>
            <MuiForm5
              //key={value.name + index}
              schema={JSON.parse(templateInfo.schema)}
              uiSchema={JSON.parse(templateInfo.uiSchema)}
              onSubmit={onSubmit}
              showErrorList={false}
              noHtml5Validate
              liveValidate={false}
              //@ts-ignore
              templates={templates}
              fields={fields}
              widgets={customWidgets}
              formData={formsData}
              validator={validator}
              transformErrors={(e) => templateFormErrors(e, getText)}
              omitExtraData={true}
              liveOmit={true}
              onChange={handleChange}
              formContext={context}
            >
              <button
                ref={(ref) => {
                  submitFormRef.current = ref;
                }}
                type='submit'
                style={{ display: 'none' }}
              />
            </MuiForm5>
          </Content>
        ) : (
          <Spinner />
        )}
        <Content>
          <VingoButton onClick={handleFormSubmit} isLoading={isLoading}>
            {getText('emptying-info-send')}
          </VingoButton>
        </Content>
      </MuiThemeProvider>
    </>
  );
};

export default TemplateForm;
