import React, { useContext, useEffect } from 'react';
import ApiContext from '../../contexts/api-context';
import { Column, CompactColumn } from '../common/containers';
import Spinner from '../common/Spinner';
import AuthContext from '../../contexts/auth-context';
import { useHistory } from 'react-router-dom';

type LogoutProps = {
};

const Logout = (props: LogoutProps): JSX.Element => {
    const api = useContext(ApiContext);
    const authService = useContext(AuthContext);
    const history = useHistory();

    useEffect(() => {
      RedirectToLogout();
    }, []);

    const RedirectToLogout = () => {
      if (authService.hasSamlSession()) {
        var url = api.getSamlLogoutUrl();
        authService.clearSession();
        window.location.replace(url);
      } else {
        authService.logout();
        history.replace('/login');
      }
    }
   
    return (
        <Column>
          <CompactColumn>
            <Spinner />
          </CompactColumn>
        </Column>
      );
  
}

export default Logout;