import { Button, Container, Divider, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { LanguageContext } from '../../../../../contexts/language-context';
import { LargerThanBreakpoint, SmallerThanBreakpoint } from '../../../../../util/viewportUtils';
import { overlayDialogPadding } from '../../../../../components/common-materialui/OverlayDialog';
import { useForm } from 'react-hook-form';
import { WIDTH_DESKTOP, WIDTH_MOBILE } from './models';
import ControlTextInput from '../../../../../components/common-materialui/form/ControlTextInput';
import ControlDateInput from '../../../../../components/common-materialui/form/ControlDateInput';
import ControlCheckboxInput from '../../../../../components/common-materialui/form/ControlCheckboxInput';
import TermsAndConditions from '../../../../../components/common-materialui/TermsAndConditions';
import ApiContext from '../../../../../contexts/api-context';
import { useMutation } from '@tanstack/react-query';
import { AddExtraEmptyingReq } from '../../../../../model';
import ProgressComponent from '../../../../../components/common-materialui/ProgressComponent';
import { dateInFuture } from '../../../../../util/calendarUtil';

interface OrderAdditionalCollectionProps {
  customerNumber: string;
  position: string;
  amountOfBins?: number;
  extraEmptyingMinDays: number;
  onClose: () => void;
}

const OrderAdditionalCollection = ({
  customerNumber,
  position,
  amountOfBins,
  extraEmptyingMinDays,
  onClose,
}: OrderAdditionalCollectionProps): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const { addExtraEmptying } = useContext(ApiContext);

  const width = LargerThanBreakpoint('md') ? WIDTH_DESKTOP : WIDTH_MOBILE;
  const orientation = SmallerThanBreakpoint('md') ? 'column' : 'row';

  const firstPossibleDate: Date = dateInFuture(extraEmptyingMinDays);

  const defaultAddExtraEmptyingReq: AddExtraEmptyingReq = {
    emptyingDate: firstPossibleDate,
    amount: amountOfBins !== null && amountOfBins !== undefined && amountOfBins >= 1 ? amountOfBins : 1,
    extraInfo: '',
    orderConfirmation: false,
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AddExtraEmptyingReq>({ defaultValues: defaultAddExtraEmptyingReq });

  const { mutateAsync: orderAdditionalCollection, isLoading: orderAdditionalCollectionLoading } = useMutation(
    (data: AddExtraEmptyingReq) => addExtraEmptying(customerNumber, position, data),
    {
      onSuccess: () => onClose(),
    }
  );

  const onAdditionalCollectionSubmit = (data: AddExtraEmptyingReq) => orderAdditionalCollection(data);

  return (
    <>
      <Container>
        <Stack direction={orientation} p={overlayDialogPadding} justifyContent={'space-between'} spacing={1}>
          <Typography variant='h6'>{getText('order-additional-collection-form-header')}</Typography>
          <Stack direction='column' width={width}>
            <ControlDateInput
              control={control}
              name='emptyingDate'
              error={errors.emptyingDate}
              validations={['required']}
              label='order-additional-collection-date'
              minDate={firstPossibleDate}
            />
            <ControlTextInput
              control={control}
              inputType='number'
              name='amount'
              label='order-additional-collection-amount'
              error={errors.amount}
              validations={['required', 'positive-integer', 'gt_zero', 'integer-not-start-zero']}
            />
            <ControlTextInput
              control={control}
              name='extraInfo'
              label='order-additional-collection-info'
              error={errors.extraInfo}
              multiline={true}
              maxLength={500}
              validations={['required']}
            />
            <ControlCheckboxInput
              label={<TermsAndConditions type='contract' />}
              validations={['required']}
              control={control}
              name='orderConfirmation'
              error={errors.orderConfirmation}
            />
          </Stack>
        </Stack>
      </Container>
      <Divider />
      <Container>
        <Stack spacing={1} direction='row' justifyContent={'end'}>
          <Button color='secondary' onClick={onClose}>
            {getText('order-additional-collection-cancel-button')}
          </Button>
          <Button
            variant='contained'
            disabled={orderAdditionalCollectionLoading}
            sx={{ paddingY: 1.75, marginTop: 2 }}
            onClick={(e) => void handleSubmit(onAdditionalCollectionSubmit)(e)}
          >
            {orderAdditionalCollectionLoading ? (
              <ProgressComponent variant='circle' isLoading={orderAdditionalCollectionLoading} />
            ) : (
              <Typography py={1} variant='button'>
                {getText('order-additional-collection-submit-button')}
              </Typography>
            )}
          </Button>
        </Stack>
      </Container>
    </>
  );
};

export default OrderAdditionalCollection;
