import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthContext from '../contexts/auth-context';

/// Same as PrivateRoute, but also requires that development enviroment is set (use it with wip stuff)
// @ts-ignore
const DevelopmentPrivateRoute = ({ component: Component, ...rest }) => {
  const authService = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        authService.loggedIn() && authService.isDevelopmentEnabled() ? (
          <Component {...props} />
        ) : (
          <Redirect to='/login' />
        )
      }
    />
  );
};

export default DevelopmentPrivateRoute;
