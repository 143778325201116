import { AllowedEmptyingInterval, Language, UiTexts } from '../model';
import _ from 'lodash';

export function printIntervalBasedOnTimesWeek(
  getText: (key: keyof UiTexts, params?: string[]) => string,
  interval: number,
  amountPerWeek: number
): string {
  if (interval === 0) {
    return getText('contract-interval-on-demand');
  }

  if (interval === 1) {
    switch (amountPerWeek) {
      case 1:
        return getText('contract-interval-once-a-week');
      case 2:
        return getText('contract-interval-twice-a-week');
      case 3:
        return getText('contract-interval-three-times-a-week');
      case 4:
        return getText('contract-interval-four-times-a-week');
      case 5:
        return getText('contract-interval-five-times-a-week');
      default:
        throw new Error('Invalid amountPerWeek value');
    }
  } else {
    switch (amountPerWeek) {
      case 1:
        return getText('contract-interval-every-x-weeks', [interval.toString()]);
      default:
        return getText('contract-interval-multiple-times-per-week', [amountPerWeek.toString(), interval.toString()]);
    }
  }
}

// Printing emptying interval number as readable text.
export function printInterval(
  getText: (key: keyof UiTexts, params?: string[]) => string,
  interval: number,
  amountPerWeek: number
) {
  if (interval === 0) {
    return getText('contract-interval-on-demand');
  }
  let text = '';
  if (interval === 1) {
    text = `${getText('contract-interval-once-a-week')}`;
  } else if (interval === 2) {
    text = getText('contract-interval-every-second-week');
  } else {
    text = `${getText('contract-interval-multiple-weeks', [interval.toString()])}`;
  }

  if (amountPerWeek > 1) {
    text += `. ${amountPerWeek} ${getText('contract-times-per-week')}`;
  }

  return text;
}

// This can be used as a helper when defining DropDown onChange attribute.
export const selectValue = (setter: (val: string) => void) => (event: React.ChangeEvent<HTMLSelectElement>) => {
  setter(event.target.value);
};

// Finds correct allowed interval from possible options (might need later endWeekNumber as parameter)
export function findAllowedInterval(
  allowedIntervals: AllowedEmptyingInterval[],
  startWeekNumber: number,
  endWeekNumber: number
) {
  // Check that there is no missing weeks in allowed intervals
  const weeks = _.range(1, 54); // range does not include the end value
  let missingWeeks = _.difference(
    weeks,
    allowedIntervals
      .map((x) => {
        if (x.startWeek <= x.endWeek) {
          return _.range(x.startWeek, x.endWeek + 1); // range does not include the end value)
        } else {
          return _.range(x.startWeek, 54).concat(_.range(1, x.endWeek + 1)); // range does not include the end value)
        }
      })
      .flat()
  );

  let wholeYearFilledAllowedIntervals = [...allowedIntervals];

  missingWeeks.forEach((x) => {
    wholeYearFilledAllowedIntervals.push({ startWeek: x, endWeek: x, intervals: [] });
  });

  const foundIntervals = wholeYearFilledAllowedIntervals.filter((x) =>
    checkSingleInterval(x, startWeekNumber, endWeekNumber)
  );

  const intervalSets = foundIntervals.map((x) => x.intervals);
  return _.intersection(...intervalSets) ?? null;
}

// Checks single allowed interval if it is valid for the given start and end week numbers
// NOSONAR
function checkSingleInterval(allowedInterval: AllowedEmptyingInterval, startWeekNumber: number, endWeekNumber: number) {
  // Normal selection case (start week is before end week same year)
  // ex. week 10 - 40
  if (startWeekNumber <= endWeekNumber) {
    // This is case start week and end week are in the same year
    // ex. week 10 - 40
    if (allowedInterval.startWeek <= allowedInterval.endWeek) {
      // Selection is inside the allowed interval
      if (startWeekNumber <= allowedInterval.endWeek && endWeekNumber >= allowedInterval.startWeek) {
        return true;
      }
    }
    // This is case start week is in the old year and end week is in the new year
    // ex. week 40 - 10
    else if (allowedInterval.startWeek > allowedInterval.endWeek) {
      // Valid interval is from start week to end of year
      if (startWeekNumber >= allowedInterval.startWeek) {
        return true;
      }
      // Valid interval is from beginning of the year to end week.
      else if (startWeekNumber <= allowedInterval.endWeek) {
        return true;
      }
      // Selection is inside the allowed interval and the allowed interval is in the old year
      else if (startWeekNumber <= allowedInterval.startWeek && endWeekNumber >= allowedInterval.startWeek) {
        return true;
      }
    }
  }
  // This is case selection end week is in the new year and start week is in the old year
  else if (endWeekNumber < startWeekNumber) {
    // This is case interval start week and end week are in the same year
    if (allowedInterval.startWeek <= allowedInterval.endWeek) {
      // From start week to end of year
      if (startWeekNumber <= allowedInterval.endWeek) {
        return true;
      }
      // Selection starts inside or before the allowed interval and inside the allowed interval
      else if (startWeekNumber > allowedInterval.endWeek && endWeekNumber >= allowedInterval.startWeek) {
        return true;
      }
    }

    // This is case interval start week is in the old year and end week is in the new year
    // In this case the interval and selection are always interlapped (both are effecting over end of year)
    else if (allowedInterval.startWeek > allowedInterval.endWeek) {
      return true;
    }
  }

  return false;
}

// This is for defining specific error messages (translation system) for template errors
export function templateFormErrors(errors: any, getText: (key: keyof UiTexts, params?: string[]) => string) {
  return errors.map((error: any) => {
    let limit: any;
    let pattern: any;
    const property = error.property ? (error.property as string) : '';

    switch (error.name) {
      case 'required':
        error.message = getText('template-error-required');
        break;

      case 'minLength':
        limit = error.params.limit;
        error.message = getText('template-error-min-length', [limit]);
        break;

      case 'minItems':
        limit = error.params.limit;
        error.message = getText('template-error-min-items', [limit]);
        break;

      case 'maxLength':
        limit = error.params.limit;
        error.message = getText('template-error-max-length', [limit]);
        break;

      // Used in NewCompostingNotification for required checkboxes
      // If you check and uncheck checkbox, the value is assigned ("required" met), but false
      // Adding one oneOf true to schema related to them causes this error instead
      case 'oneOf':
        error.message = getText('template-error-required');
        break;

      // oneof throws this also
      case 'const':
        switch (error.params.allowedValue) {
          // Special case for not approved checkbox.
          case true:
            error.message = getText('template-error-checkbox-not-checked');
            break;
        }
        break; 

      case 'pattern':
        // Special case for phone number : pattern matching failed case
        if (property.includes('phone')) {
          error.message = getText('template-error-format-phone');
          break;
        }

        // Special case for numbers only.
        if (error.params.pattern === '^\\d*$') {
          error.message = getText('template-error-numbers-only');
          break;
        }
        pattern = error.params.pattern;
        error.message = getText('template-error-pattern', [pattern]);
        break;

      case 'enum':
        error.message = getText('template-error-enum-value');
        break;

      case 'type':
        switch (error.params.type) {
          case 'integer':
            error.message = getText('template-error-numbers-only');
            break;
          case 'string':
            error.message = getText('template-error-type-string');
            break;
          case 'array':
            error.message = getText('template-error-type-array');
            break;
        }
        break;

        case 'format':
          switch (error.params.format) {
            case 'date':
              error.message = getText('template-error-format-date');
              break;
            case 'email':
              error.message = getText('template-error-format-email');
              break;
            case 'phone':
              error.message = getText('template-error-format-phone');
              break;
          }
          break; 

      case 'minimum':
        limit = error.params.limit;
        error.message = getText('template-error-number-value-too-small', [limit]);
        break;

      case 'maximum':
        limit = error.params.limit;
        error.message = getText('template-error-number-value-too-big', [limit]);
        break;

      case 'additionalProperties':
        error.message = getText('template-error-additional-properties');
        break;
    }

    return error;
  });
}

/**
 * @param lang Two letter language code, ex. fi
 * @returns Capitalized 3 letter variant, ex. FIN
 */
export function get3LetterLanguage(lang: Language) {
  if (lang === 'sv') {
    return 'SWE';
  }
  if (lang === 'en') {
    return 'ENG';
  }
  return 'FIN';
}

/**
 * @param value Value to check
 * @returns True if value is a string and contains only numbers
 */
export function isStringNumeric(value: any): value is string {
  return typeof value === 'string' && /^\d+$/.test(value);
}
