import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthContext from '../contexts/auth-context';
import LoginPage from '../views/login/LoginPage'
import Register from './views/Register';
import { Language } from '../model';
import './App.css';
import ContractHistory from './views/ContractHistory';
import OrderProduct from './views/OrderProduct';
import Stickers from './views/order/Stickers';
import ContractDetails from './views/ContractDetails';
import ExtraEmptying from './views/ExtraEmptying';
import ForgotPassword from '../views/forgot-password/ForgotPassword';
import ForgotUsername from '../views/forgot-username/ForgotUserName';
import ResetPassword from './views/ResetPassword';
import PickUpService from './views/order/PickUpService';
import Dumpster from './views/order/Dumpster';
import ChangeContract from './views/ChangeContract';
import LoginFeedback from './views/LoginFeedback';
import CustomerService from '../views/customer-service/CustomerService';
import Feedback from './views/customer-support/Feedback';
import OrderContract from './views/order/OrderContract';
import Template from './views/Template';
import PermitApplication from '../views/permit-application/PermitApplication';
import BillingInfo from './views/BillingInfo';
import Authenticate from './views/Authenticate';
import RegisterSaml from '../views/register/RegisterSaml';
import LoginSaml from './views/LoginSaml';
import Logout from './views/Logout';
import MyEmptyingLocations from '../views/emptying-locations/MyEmptyingLocations';
import EmptyingLocation from '../views/emptying-locations/emptying-location-item/EmptyingLocation';
import Invoices from '../views/invoices/Invoices';
import Report from '../views/report/Report';
import ModifyEmptyingLocation from './views/modify-emptying-location/ModifyEmptyingLocation';
import ModifyBillingInfo from './views/modify-billing-info/ModifyBillingInfo';
import PrivateRoute from './PrivateRoute';
import DevelopmentPrivateRoute from './DevelopmentPrivateRoute';
import RegisterNew from '../views/register/RegisterNew';
import RegisterExisting from '../views/register/RegisterExisting';
import Contract from '../views/emptying-locations/emptying-location-item/contracts/Contract';
import AccountSettings from '../views/account-settings/AccountSettings';
import EditUserInformation from '../views/account-settings/EditUserInformation';
import EditGeneralInformation from '../views/account-settings/EditGeneralInformation';
import EditLoginInformation from '../views/account-settings/EditLoginInformation';
import CompostingNotifications from '../views/composting-notifications/CompostingNotifications';

type AppProps = {
  language: Language;
  setLanguage: Dispatch<SetStateAction<Language>>;
};

const App = (properties: AppProps): JSX.Element => {
  const authService = useContext(AuthContext);
  const { language, setLanguage } = properties;
  const { loggedIn } = authService;

  const handleError = (err: any) => {
    // Currently doesn't do anything, but prevents error loop if refresh loop throws error
  };

  // Try to refresh login on page load.
  // If it succeeds refresh function schedules next refresh based on new token's expiration time
  // Login function also schedules a refresh based on token expiration.
  useEffect(() => {
    authService.refreshLogin().catch((err: any) => {
      handleError(err);
    });
  }, []);

  return (
    <div className='main-nav'>
      <BrowserRouter basename={window.__RUNTIME_CONFIG__.PUBLIC_URL}>
        <Switch>
          <Route
            path='/login'
            exact
            render={(props) => {
              if (loggedIn()) {
                return <Redirect to='/emptying-infos' />;
              }
              return <LoginPage {...props} language={language} setLanguage={setLanguage} />;
            }}
          />

          <Route
            path='/register'
            exact
            render={(props) => {
              if (loggedIn()) {
                return <Redirect to='/emptying-infos' />;
              }
              return <RegisterNew {...props} language={language} setLanguage={setLanguage} />;
            }}
          />

          <Route
            path='/register/existing'
            exact
            render={(props) => {
              if (loggedIn()) {
                return <Redirect to='/emptying-infos' />;
              }
              return <RegisterExisting {...props} language={language} setLanguage={setLanguage} />;
            }}
          />

          <Route
            path='/registerOld'
            exact
            render={(props) => {
              if (loggedIn()) {
                return <Redirect to='/emptying-infos' />;
              }
              return <Register {...props} language={language} setLanguage={setLanguage} />;
            }}
          />
          {authService.isStrongAuthenticationEnabled() && (
            <Route
              path='/authenticate'
              exact
              render={(props) => {
                return <Authenticate {...props} language={language} />;
              }}
            />
          )}

          <Route
            path='/logout'
            exact
            render={(props) => {
              return <Logout />;
            }}
          />

          <Route
            path='/register-saml/:id'
            exact
            render={(props) => {
              if (loggedIn()) {
                return <Redirect to='/emptying-infos' />;
              }
              const id = props.match.params.id;
              return (
                <RegisterSaml
                  {...props}
                  id={id}
                  nameId={null}
                  sessionIndex={null}
                  language={language}
                  setLanguage={setLanguage} />
              );
            }}
          />

          <Route
            path="/register-saml/:id/:nameid/:sessionindex"
            exact
            render={props => {
              if (loggedIn()) {
                return <Redirect to="/emptying-infos" />;
              }
              const id = props.match.params.id;
              const nameId = props.match.params.nameid;
              const sessionIndex = props.match.params.sessionindex;
              return (
                <RegisterSaml
                  {...props}
                  id={id}
                  nameId={nameId}
                  sessionIndex={sessionIndex}
                  language={language}
                  setLanguage={setLanguage} />
              );
            }}
          />

          <Route
            path='/login-saml/:token/:expiresAt/:nameid/:sessionindex'
            exact
            render={(props) => {
              if (loggedIn()) {
                return <Redirect to='/emptying-infos' />;
              }
              const token = props.match.params.token;
              const expiresAt = props.match.params.expiresAt;
              const nameId = props.match.params.nameid;
              const sessionIndex = props.match.params.sessionindex;
              return (
                <LoginSaml
                  {...props}
                  token={token}
                  expiresAt={expiresAt}
                  nameId={nameId}
                  sessionIndex={sessionIndex}
                  language={language}
                  setLanguage={setLanguage}
                />
              );
            }}
          />

          <Route
            path="/forgot-username"
            exact
            render={props => {
              if (loggedIn()) {
                return <Redirect to="/emptying-infos" />;
              }
              return (
                <ForgotUsername
                  {...props}
                  language={language}
                  setLanguage={setLanguage}
                />
              );
            }}
          />

          <Route
            path="/forgot-password"
            exact
            render={(props) => {
              if (loggedIn()) {
                return <Redirect to='/emptying-infos' />;
              }
              return <ForgotPassword {...props} language={language} setLanguage={setLanguage} />;
            }}
          />

          <Route
            path='/reset-password'
            exact
            render={(props) => {
              if (loggedIn()) {
                return <Redirect to='/emptying-infos' />;
              }
              return <ResetPassword {...props} language={language} setLanguage={setLanguage} />;
            }}
          />

          <Route
            path='/feedback'
            exact
            render={(props) => {
              return <LoginFeedback {...props} language={language} setLanguage={setLanguage} />;
            }}
          />

          <Route
            path='/permit-application'
            exact
            render={(props) => {
              if (loggedIn() && authService.isPermitApplicationEnabled()) {
                return <PermitApplication />;
              } else {
                return <Redirect to='/emptying-infos' />;
              }
            }}
          />

          {authService.isCompostNotificationEnabled() && (
            <Route
              path='/composting-notifications'
              exact
              render={(props) => {
                return <CompostingNotifications />;
              }}
            />
          )}

          {authService.isShowInvoicesEnabled() && <PrivateRoute path='/invoices' exact component={Invoices} />}

          {authService.isReportingEnabled() && <PrivateRoute path='/reporting' exact component={Report} />}

          {authService.isCustomerSupportEnabled() && (
            <PrivateRoute path='/customer-support' exact component={CustomerService} />
          )}
          {authService.isCustomerSupportEnabled() && (
            <PrivateRoute path='/customer-support/feedback' exact component={Feedback} />
          )}
          <PrivateRoute path='/emptying-infos' exact component={MyEmptyingLocations} />

          <PrivateRoute path='/emptying-infos/:id' exact component={EmptyingLocation} />

          <PrivateRoute path='/emptying-infos/:id/modify-billing-info' exact component={ModifyBillingInfo} />

          <PrivateRoute path='/emptying-infos/:id/modify-emptying-location' exact component={ModifyEmptyingLocation} />

          <PrivateRoute path='/emptying-infos/:id/order-product' exact component={OrderProduct} />

          <PrivateRoute path='/emptying-infos/:id/order-product/contract/:categoryid' exact component={OrderContract} />

          <PrivateRoute path='/emptying-infos/:id/order-product/stickers/:categoryid' exact component={Stickers} />

          <PrivateRoute path='/emptying-infos/:id/order-product/pickup/:categoryid' exact component={PickUpService} />
          <PrivateRoute path='/emptying-infos/:id/order-product/dumpster/:categoryid' exact component={Dumpster} />

          {authService.isOrderHistoryEnabled() && (
            <PrivateRoute path='/emptying-infos/:id/contracts' exact component={ContractHistory} />
          )}

          {/* <PrivateRoute path='/user-account' exact component={UserAccount} /> */}

          <PrivateRoute path='/account-settings' exact component={AccountSettings} />

          <PrivateRoute path='/account-settings/user-info' exact component={EditUserInformation} />

          <PrivateRoute path='/account-settings/general-info' exact component={EditGeneralInformation} />

          <PrivateRoute path='/account-settings/login-info' exact component={EditLoginInformation} />

          <PrivateRoute path='/contracts/:customerNumber/:position' exact component={ContractDetails} />

          <PrivateRoute path='/emptying-infos/:id/contracts/:contractPathInfo' exact component={Contract} />

          {authService.isExtraEmptyingEnabled() && (
            <PrivateRoute path='/contracts/:customerNumber/:position/extra-emptying' exact component={ExtraEmptying} />
          )}

          {authService.isModifyServiceEnabled() && (
            <PrivateRoute path='/contracts/:customerNumber/:position/change' exact component={ChangeContract} />
          )}

          {authService.isAddEmptyingAccountEnabled() && (
            <PrivateRoute path='/emptying-info' exact component={Template} />
          )}

          {authService.isAddBillingAccountEnabled() && (
            <DevelopmentPrivateRoute path='/billing-info' exact component={BillingInfo} />
          )}

          <Route
            path='/'
            render={(props) => {
              if (loggedIn()) {
                return <Redirect to='/emptying-infos' />;
              }
              return <Redirect to='/login' />;
            }}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
