import React, { FormEvent, useContext, useState } from 'react';

import { Content, Form, Row } from '../../common/containers';
import { EmptyingInfo, UpdateEmptyingInfoCompanyReq } from '../../../model';
import { SecondaryHeader } from '../../common/headers';
import TextInput from '../../common/TextInput';
import { LanguageContext } from '../../../contexts/language-context';
import { useHistory } from 'react-router-dom';
import ApiContext from '../../../contexts/api-context';
import DialogContext from '../../../contexts/dialog-context';
import VingoButton from '../../common/VingoButton';

import { SecondaryButton } from '../../common/inputs';

interface Props {
  emptyingInfo: EmptyingInfo;
}

const CompanyLocation = ({ emptyingInfo }: Props): JSX.Element => {
  const history = useHistory();
  const { getText } = useContext(LanguageContext);
  const api = useContext(ApiContext);
  const showDialog = useContext(DialogContext);

  const [firstName, setFirstName] = useState<string>(
    emptyingInfo.name.split(' ')[1] ?? ''
  );
  const [lastName, setLastName] = useState<string>(
    emptyingInfo.name.split(' ')[0] ?? ''
  );
  const [streetAddress, setStreetAddress] = useState<string>(
    emptyingInfo.address.street
  );
  const [postCode, setPostCode] = useState<string>(
    emptyingInfo.address.postalCode
  );
  const [postOffice, setPostOffice] = useState<string>(
    emptyingInfo.address.postOffice
  );

  const [contactPerson, setContactPerson] = useState<string>(
    emptyingInfo.contactPerson.name
  );
  const [phoneNumber, setPhoneNumber] = useState<string>(
    emptyingInfo.contactPerson.phoneNumber
  );
  const [email, setEmail] = useState<string>(emptyingInfo.contactPerson.email);

  const [locationContactFirstName, setLocationContactFirstName] =
    useState<string>(
      emptyingInfo.contactPerson?.binLocationContactPerson.split(' ')[1] ?? ''
    );
  const [locationContactLastName, setLocationContactLastName] =
    useState<string>(
      emptyingInfo.contactPerson?.binLocationContactPerson.split(' ')[0] ?? ''
    );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    const data: UpdateEmptyingInfoCompanyReq = {
      locationContactFirstName,
      locationContactLastName,
      contactPerson,
      phoneNumber,
      email,
    };

    api
      .updateEmptyingInfoCompany(emptyingInfo.id, data)
      .then((_res) => {
        setIsLoading(false);
        showDialog('message-success-generic-title', 'message-data-saved', () =>
          history.replace(`/emptying-infos/${emptyingInfo.id}`)
        );
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.statusCode === 401) {
          showDialog(
            'error-unauthorized-title',
            'error-unauthorized-message',
            () => history.replace('/logout')
          );
        } else {
          showDialog(
            'error-service-break-title',
            'error-service-break-message'
          );
        }
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Content>
        <SecondaryHeader>{getText('basic-info')}</SecondaryHeader>
        <TextInput
          label='basic-info-first-name'
          val={firstName}
          setter={setFirstName}
          disabled={true}
          showErrors={isSubmitted}
        />
        <TextInput
          label='basic-info-last-name'
          val={lastName}
          setter={setLastName}
          disabled={true}
          showErrors={isSubmitted}
        />
        <TextInput
          label='billing-data-street-address'
          val={streetAddress}
          setter={setStreetAddress}
          disabled={true}
          showErrors={isSubmitted}
        />
        <TextInput
          label='billing-data-post-code'
          val={postCode}
          setter={setPostCode}
          disabled={true}
          showErrors={isSubmitted}
        />
        <TextInput
          label='billing-data-city'
          val={postOffice}
          setter={setPostOffice}
          disabled={true}
          showErrors={isSubmitted}
        />
        <TextInput
          label='contact-person'
          val={contactPerson}
          setter={setContactPerson}
          validations={['required']}
          showErrors={isSubmitted}
        />
        <TextInput
          label='basic-info-phone'
          val={phoneNumber}
          setter={setPhoneNumber}
          validations={['required']}
          showErrors={isSubmitted}
        />
        <TextInput
          label='basic-info-email'
          val={email}
          setter={setEmail}
          validations={['required', 'email']}
          showErrors={isSubmitted}
        />
        <SecondaryHeader>
          {getText('modify-emptying-info-bin-location-contact')}
        </SecondaryHeader>

        <TextInput
          label='basic-info-first-name'
          val={locationContactFirstName}
          setter={setLocationContactFirstName}
          showErrors={isSubmitted}
        />
        <TextInput
          label='basic-info-last-name'
          val={locationContactLastName}
          setter={setLocationContactLastName}
          showErrors={isSubmitted}
        />
      </Content>
      <Content>
        <Row>
          <VingoButton
            onClick={() => setIsSubmitted(true)}
            isLoading={isLoading}
          >
            {getText('modify-emptying-location-save')}
          </VingoButton>
          <SecondaryButton
            onClick={() =>
              history.replace(`/emptying-infos/${emptyingInfo.id}`)
            }
          >
            {getText('modify-emptying-location-cancel')}
          </SecondaryButton>
        </Row>
      </Content>
    </Form>
  );
};

export default CompanyLocation;
