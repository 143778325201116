import { useReducer, useState } from 'react';
import PropertyWaterSupply from './waste-water-report-wizard/PropertyWaterSupply';
import WasteWaterTreatment from './waste-water-report-wizard/waste-water-treatment/WasteWaterTreatment';
import WasteWaterReportSummary from './waste-water-report-wizard/WasteWaterReportSummary';
import PhaseBar, { Phase } from '../../../components/common-materialui/PhaseBar';
import { ClosedTankForm, HouseHoldWaterInfo, OtherForm,  SepticTankForm, TreatmentPlantForm, WellCollectionState } from './waste-water-report-wizard/models';

const initialState = {
  houseHoldWaterInfo: {
    wcType: '',
    householdWaterSource: '',
    equipments: [],
  },
  wasteWater: [],
  septicTank: {},
  treatmentPlant: {},
};

type WellCollectionAction =
  | { type: 'UPDATE_HOUSEHOLD_WATER_INFO'; payload: Partial<HouseHoldWaterInfo> }
  | { type: 'UPDATE_WASTE_WATER_INFO'; payload: string[] }
  | { type: 'UPDATE_SEPTIC_TANK_INFO'; payload: Partial<SepticTankForm> }
  | { type: 'UPDATE_SEPTIC_TANK_INFO_OPTION_B'; payload: Partial<SepticTankForm> }
  | { type: 'UPDATE_OTHER_INFO'; payload: Partial<OtherForm> }
  | { type: 'UPDATE_CLOSED_TANK_INFO'; payload: Partial<ClosedTankForm> }
  | { type: 'UPDATE_TREATMENT_PLANT_INFO'; payload: Partial<TreatmentPlantForm> }
  | { type: 'RESET_WASTE_WATER_TREATMENT_STATE' }; // Add more action types as needed

const reducer = (state: WellCollectionState, action: WellCollectionAction) => {
  switch (action.type) {
    case 'UPDATE_HOUSEHOLD_WATER_INFO':
      return {
        ...state,
        houseHoldWaterInfo: {
          ...state.houseHoldWaterInfo,
          ...action.payload,
        },
      };
    case 'UPDATE_WASTE_WATER_INFO':
      return {
        ...state,
        wasteWater: action.payload,
      };
    case 'UPDATE_SEPTIC_TANK_INFO':
      return {
        ...state,
        septicTank: action.payload,
      };
    case 'UPDATE_SEPTIC_TANK_INFO_OPTION_B':
      return {
        ...state,
        septicTankOptionB: action.payload,
      };
    case 'UPDATE_OTHER_INFO':
      return {
        ...state,
        other: action.payload,
      };
    case 'UPDATE_CLOSED_TANK_INFO':
      return {
        ...state,
        closedTank: action.payload,
      };
    case 'UPDATE_TREATMENT_PLANT_INFO':
      return {
        ...state,
        treatmentPlant: action.payload,
      };
    case 'RESET_WASTE_WATER_TREATMENT_STATE':
      return {
        ...initialState,
        houseHoldWaterInfo: {
          ...state.houseHoldWaterInfo,
        },
      };
    default:
      return state;
  }
};

const WellCollection = (): JSX.Element => {
  const [currentPhase, setCurrentPhase] = useState<Phase>(Phase.PropertyWaterSupply);

  const phases = [Phase.PropertyWaterSupply, Phase.WasteWaterTreatment, Phase.WasteWaterReportSummary];

  const [wellCollectionState, dispatch] = useReducer(reducer, initialState);

  const updateHouseHoldWaterInfo = (newHouseHoldWaterInfo: HouseHoldWaterInfo) => {
    dispatch({ type: 'UPDATE_HOUSEHOLD_WATER_INFO', payload: newHouseHoldWaterInfo });
  };

  const updateWasterWaterInfo = (values: string[]) => {
    dispatch({ type: 'UPDATE_WASTE_WATER_INFO', payload: values });
  };

  const updateSepticTankInfo = (values: Partial<SepticTankForm>) => {
    dispatch({ type: 'UPDATE_SEPTIC_TANK_INFO', payload: values });
  };

  const updateSepticTankOptionBInfo = (values: Partial<SepticTankForm>) => {
    dispatch({ type: 'UPDATE_SEPTIC_TANK_INFO_OPTION_B', payload: values });
  };

  const resetWasteWaterTreatmentValues = () => {
    dispatch({ type: 'RESET_WASTE_WATER_TREATMENT_STATE' });
  };

  const updateOtherInfo = (values: Partial<OtherForm>) => {
    dispatch({ type: 'UPDATE_OTHER_INFO', payload: values });
  };
  
  const updateClosedTankInfo = (values: Partial<ClosedTankForm>) => {
    dispatch({ type: 'UPDATE_CLOSED_TANK_INFO', payload: values });
  };

  const updateTreatmentPlantInfo = (values: Partial<TreatmentPlantForm>) => {
    dispatch({ type: 'UPDATE_TREATMENT_PLANT_INFO', payload: values });
  };

  const renderPhase = () => {
    switch (currentPhase) {
      case Phase.PropertyWaterSupply:
        return (
          <PropertyWaterSupply
            onNextPhaseChange={goToNextPhase}
            houseHoldWaterInfo={wellCollectionState.houseHoldWaterInfo}
            updateHouseHoldWaterInfo={updateHouseHoldWaterInfo}
            resetWasteWaterTreatmentValues={resetWasteWaterTreatmentValues}
          />
        );
      case Phase.WasteWaterTreatment:
        return (
          <WasteWaterTreatment
            onNextPhaseChange={goToNextPhase}
            onPreviousPhaseChange={goToPreviousPhase}
            wellCollectionInfo={wellCollectionState}
            updateWasteWaterInfo={updateWasterWaterInfo}
            updateSepticTankInfo={updateSepticTankInfo}
            updateSepticTankOptionBInfo={updateSepticTankOptionBInfo}
            updateOtherInfo={updateOtherInfo}
            updateTreatmentPlantInfo={updateTreatmentPlantInfo}
            updateClosedTankInfo={updateClosedTankInfo}
          />
        );
      case Phase.WasteWaterReportSummary:
        return (
          <WasteWaterReportSummary onPreviousPhaseChange={goToPreviousPhase} wellCollectionInfo={wellCollectionState} />
        );
    }
  };

  const handleWizardNavigation = (phaseStep: number): void => {
    const currentPhaseIndex = phases.findIndex((phase) => phase === currentPhase);

    if (currentPhaseIndex < phases.length - 1 || (currentPhaseIndex === phases.length - 1 && phaseStep === -1)) {
      setCurrentPhase(phases[currentPhaseIndex + phaseStep]);
      document.getElementById('phase-bar-element')?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const goToNextPhase = () => {
    handleWizardNavigation(1);
  };

  const goToPreviousPhase = () => {
    handleWizardNavigation(-1);
  };

  return (
    <>
      <PhaseBar phases={phases} currentPhase={currentPhase} setCurrentPhase={setCurrentPhase} />
      {renderPhase()}
    </>
  );
};

export default WellCollection;
