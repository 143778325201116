import { useContext } from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { LanguageContext } from '../../../../contexts/language-context';
import PriceTable from '../../../../components/common-materialui/price-table/PriceTable';
import { PriceCalculationResult } from '../../../../model';

interface Props {
  priceData?: PriceCalculationResult;
  name?: string;
  currentNumberOfContainers?: number;
}

const ContractPriceSection = ({ priceData, name, currentNumberOfContainers }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const theme = useTheme();
  const largerThanPhone = useMediaQuery(theme.breakpoints.up('md'));

  if (!priceData) {
    return <></>;
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.level2,
        paddingX: 4,
        paddingY: 4,
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Grid container direction={largerThanPhone ? 'row' : 'column'} wrap='nowrap'>
        <Grid item xs={5}>
          <Typography marginBottom={3} variant='h5'>
            {getText('service-order-pickup-price')}
          </Typography>
        </Grid>
        <PriceTable price={priceData} productName={name} currentNumberOfContainers={currentNumberOfContainers} />
      </Grid>
    </Box>
  );
};

export default ContractPriceSection;
