import { Box, Typography, useTheme } from "@mui/material";
import { ReactNode, useContext } from "react";
import { LanguageContext } from "../../../contexts/language-context";
import { UiTexts } from "../../../model";

type Props = {
  headerKey?: keyof UiTexts;
  descriptionKey?: keyof UiTexts;
  children?: ReactNode;
};

/**
 * Error box for showing service errors.
 * 
 * All params are optional. You can give only headerKey or descriptionKey.
 * Children elements are always rendered if given.
 * 
 * @param headerKey if given then header text with corresponding translation is shown
 * @param descriptionKey if given then a description is shown
 */
const ErrorBox = (props: Props): JSX.Element => {
  const { headerKey, descriptionKey, children } = props;

  const { getText } = useContext(LanguageContext);
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        px: { xs: 2, sm: 2, md: 3, lg: 3, xl: 3 },
        py: 2,
        color: palette.error.main,
        backgroundColor: palette.error.background,
        border: 1,
        borderColor: palette.error.border,
      }}
    >
      {headerKey ? (
        <Typography variant="h5"> {getText(headerKey)}</Typography>
      ) : null}

      {descriptionKey ? (
        <Typography variant="body1"> {getText(descriptionKey)}</Typography>
      ) : null}

      {children}
    </Box>
  );
};

export default ErrorBox;
