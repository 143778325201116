import { useContext } from 'react';
import { Box, Container, FormControlLabel, Grid, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form';
import { LanguageContext } from '../../../../../contexts/language-context';
import LocationAccess from './LocationAccess';
import ControlTextInput from '../../../../../components/common-materialui/form/ControlTextInput';
import FormErrorField from '../../../../../components/common-materialui/form/FormErrorField';
import { validateInput } from '../../../../../components/common/input/validation';
import GrayWater from './need-for-emptying/GrayWater';
import BlackWater from './need-for-emptying/BlackWater';
import { BlackWaterForm, GrayWaterForm, LocationAccessForm, TreatmentPlantForm } from '../models';
import { UiTexts } from '../../../../../model';
import { LargerThanBreakpoint } from '../../../../../util/viewportUtils';

export const defaultTreatmentPlantValues = {
  volume: 0,
  manufacturer: '',
  model: '',
  emptyingInstructions: '',
  vacuumTruckEmptied: '',
  noLimits: false,
  noWinterEmptyings: false,
  locked: false,
  weightLimit: false,
  expections: false,
  other: false,
  weightLimitDetails: '',
  otherDetails: '',
  location: '',
  grayWater: {
    weeklyFrequency: '',
    other: '',
  },
  blackWater: {
    weeklyFrequency: '',
    other: '',
    residentCount: null,
  },
};
interface Props {
  control: Control<TreatmentPlantForm>;
  errors: FieldErrors<TreatmentPlantForm>;
  formHeader: keyof UiTexts;
  grayWater: boolean;
  blackWater: boolean;
}

const TreatmentPlant = ({ control, errors, formHeader, grayWater, blackWater }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const direction = LargerThanBreakpoint('md') ? 'row' : 'column';
  const vacuumTruckEmptied = useWatch({
    control,
    name: 'vacuumTruckEmptied',
  });

  return (
    <Container>
      <Grid container direction={direction} wrap='nowrap' marginTop={2} spacing={2} justifyContent={'space-between'}>
        <Grid item xs={12} sm={7} md={8}>
          <Typography variant='h6'>{getText(formHeader)}</Typography>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Stack direction={'column'} spacing={2}>
            <Box>
              <ControlTextInput
                control={control}
                inputType='string'
                name='manufacturer'
                label='treatment-plant-manufacturer'
                error={errors.manufacturer}
                validations={['required']}
              />
            </Box>
            <Box>
              <ControlTextInput
                control={control}
                inputType='string'
                name='model'
                label='treatment-plant-model'
                error={errors.model}
                validations={['required']}
              />
            </Box>
            <Box>
              <ControlTextInput
                control={control}
                inputType='string'
                name='emptyingInstructions'
                label='treatment-plant-emptying-guide'
                error={errors.emptyingInstructions}
                validations={['required']}
              />
            </Box>
            <Box>
              <ControlTextInput
                control={control}
                inputType='number'
                name='volume'
                label='treatment-plant-volume'
                error={errors.volume}
                validations={['required', 'gt_zero']}
              />
            </Box>
          </Stack>
          <Stack direction={'column'}>
            <Stack marginTop={2}>
              <Typography variant='h6'>{getText('treatment-plant-vacuum-truck')}</Typography>
            </Stack>
            <Stack>
              <Controller
                control={control}
                name='vacuumTruckEmptied'
                rules={{
                  validate: { validateFunc: (v) => validateInput(v, ['required'])[0] },
                }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <RadioGroup
                      aria-labelledby='radio-group-for-selecting-contract-edit'
                      value={value}
                      onChange={onChange}
                    >
                      <Stack direction={'row'}>
                        <FormControlLabel
                          sx={{ marginRight: 5 }}
                          value={'true'}
                          control={<Radio />}
                          label={getText('treatment-plant-vacuum-yes-options')}
                        />
                        <FormControlLabel
                          sx={{ marginRight: 5 }}
                          value={'false'}
                          control={<Radio />}
                          label={getText('treatment-plant-vacuum-no-options')}
                        />
                      </Stack>
                      <FormErrorField errorMsg={errors.vacuumTruckEmptied?.message} />
                    </RadioGroup>
                  );
                }}
              />
            </Stack>
          </Stack>
          {vacuumTruckEmptied === 'false' && <FormErrorField errorMsg={'treatment-plant-vacuum-info-notice'} />}
          {grayWater && <GrayWater control={control as unknown as Control<GrayWaterForm>} errors={errors} />}
          {blackWater && <BlackWater control={control as unknown as Control<BlackWaterForm>} errors={errors} />}
          <LocationAccess control={control as unknown as Control<LocationAccessForm>} errors={errors} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default TreatmentPlant;
