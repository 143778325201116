import React, { ReactNode, memo, useContext } from 'react';

import styled from 'styled-components';

import Dialog from '@material-ui/core/Dialog';
import colors from '../../colors';
import { CONTENT_MARGIN } from '../../styleConstants';
import VingoButton from './VingoButton';
import { UiTexts } from '../../model';
import { LanguageContext } from '../../contexts/language-context';
import { SecondaryHeader } from './headers';
import { LinkButton } from './inputs';
import { Row } from './containers';

const ButtonRow = styled(Row)`
  flex-direction: row-reverse;
  margin-top: ${CONTENT_MARGIN};
`;

type InputProps = {
  titleKey: keyof UiTexts;
  open: boolean;
  closeAction: () => void;
  children?: ReactNode;

  // If null, primary action will be closeAction and back button will be hidden
  primaryAction?: (() => void) | null;
  // If not given, primary button label will be the default text "Ok"
  buttonLabelKey?: keyof UiTexts;
};

function VingoDialog(props: InputProps) {
  const {
    titleKey,
    buttonLabelKey,
    open,
    closeAction,
    primaryAction,
    children,
  } = props;

  const { getText } = useContext(LanguageContext);

  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: colors.contentBackground,
          boxShadow: 'none',
          borderRadius: 0,
          padding: CONTENT_MARGIN,
          paddingTop: 0,
        },
      }}
      onClose={closeAction}
      aria-labelledby="vingo2-dialog-title"
      open={open}
    >
      <SecondaryHeader id="vingo2-dialog-title">
        {getText(titleKey)}
      </SecondaryHeader>
      {children}
      <ButtonRow>
        <VingoButton
          onClick={primaryAction ?? closeAction}
          compact={true}
          minWidth="120px"
        >
          {buttonLabelKey ? getText(buttonLabelKey) : getText('dialog-ok')}
        </VingoButton>
        {primaryAction ? (
          <LinkButton
            onClick={closeAction}
            style={{ padding: '0px 24px', marginRight: '0.5rem' }}
          >
            {getText('dialog-cancel')}
          </LinkButton>
        ) : null}
      </ButtonRow>
    </Dialog>
  );
}

export default memo(VingoDialog);
