import { FC, MouseEvent, ReactNode, useState } from 'react';
import { Button, Typography, Popover as MaterialUiPopover } from '@mui/material';

interface PopoverProps {
  popoverButton: ReactNode;
  content: string;
}
const Popover: FC<PopoverProps> = ({ popoverButton, content }) => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorElement);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorElement(null);
  };

  return (
    <>
      <Button
        aria-describedby={open ? 'popover' : undefined}
        onClick={handlePopoverOpen}
        sx={{ color: 'text.primary', minWidth: '24px', height: '44px', padding: 0 }}
      >
        {popoverButton}
      </Button>
      <MaterialUiPopover
        open={open}
        onClose={handlePopoverClose}
        anchorEl={anchorElement}
        id='popover'
        disableRestoreFocus
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          height: '80%',
        }}
      >
        <Typography p={2} sx={{ width: '100vw' }}>
          {content}
        </Typography>
      </MaterialUiPopover>
    </>
  );
};

export default Popover;
