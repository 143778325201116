export const PRODUCT_TYPE_CONTRACT = 'Contract';
export const PRODUCT_TYPE_SINGLE_ORDER = 'Order';

export const PRODUCT_FORM_STICKERS = 'stickers';
export const PRODUCT_FORM_PICKUP = 'pickup';
export const PRODUCT_FORM_DUMPSTER = 'dumpster';
export const PRODUCT_FORM_CONTRACT = 'contract';

export const IFRAME_REPORTING_URL = `${window?.__RUNTIME_CONFIG__?.IFRAME_REPORTING_ORIGIN_DOMAIN}/e-services-reporting/`;
export const IFRAME_ACCESS_TOKEN = 'ACCESS_TOKEN';
export const TOKEN_EXPIRATION_GAP_SECONDS = 120;
