import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ReactNode } from 'react';

export interface ValueText {
  value: string;
  text: string;
}

interface Props {
  label?: string;
  items: ValueText[];
  value: string | number;
  onChange: (event: SelectChangeEvent<string | number>, child: ReactNode) => void;
  width?: string;
}

/**
 * Dropdown select component using material ui Select
 *
 * @param label Optional, label to display, if not given simply empty
 * @param items Value-text pairs of items to display, interface ValueText
 * @param value Current value
 * @param onChange Change event
 * @param width Width of the box, default 100%
 */
const DropdownSelect = ({ label, items, value, onChange, width }: Props): JSX.Element => {
  return (
    <FormControl>
      <InputLabel variant='filled'>{label ?? undefined}</InputLabel>
      <Select
        variant='outlined'
        sx={{
          width: width ?? '100%',
          height: '3.5rem',
          marginTop: '0.2rem',
          paddingTop: label && '1rem',
        }}
        value={value}
        onChange={onChange}
      >
        {items.map((i, idx) => {
          return (
            <MenuItem key={idx} value={i.value}>
              {i.text}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default DropdownSelect;
