import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  FormEvent,
} from 'react';
import { useHistory } from 'react-router-dom';

import { LanguageContext } from '../../../contexts/language-context';
import ApiContext from '../../../contexts/api-context';

import { Content, Form } from '../../common/containers';
import PrivateCustomerBasicInfo from '../register/PrivateCustomerBasicInfo';
import PrivateCustomerBillingInfo from '../register/PrivateCustomerBillinInfo';
import VingoButton from '../../common/VingoButton';
import DialogContext from '../../../contexts/dialog-context';
import AuthContext from '../../../contexts/auth-context';
import { ConsumerEInvoiceType } from '../../../model';

const PrivateCustomer = (): JSX.Element => {
  const history = useHistory();
  const { getText } = useContext(LanguageContext);
  const auth = useContext(AuthContext);
  const api = useContext(ApiContext);
  const showDialog = useContext(DialogContext);

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [ssn, setSsn] = useState<string>('');

  const [contactName, setContactName] = useState<string>('');

  const [streetAddress, setStreetAddress] = useState<string>('');
  const [postCode, setPostCode] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [billType, setBillType] = useState<ConsumerEInvoiceType>(ConsumerEInvoiceType.NotInUse);

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const componentIsMounted = useRef(true);
  const [customerNumber, setCustomerNumber] = useState<string>('');
  const [invoiceNumber, setInvoiceNumber] = useState<string>('');

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const renderConditionsAndRegistrationButton = () => {
    return (
      <Content>
        <VingoButton
          onClick={() => setIsSubmitted(true)}
          isLoading={isLoading}
        >
          {getText('billing-info-send')}
        </VingoButton>
      </Content>
    );
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    const params = {
      personalIdentificationNumber: ssn,
      firstName,
      lastName,
      address: streetAddress,
      postCode,
      city,
      email,
      phoneNumber: phone,
      consumerEInvoiceType: billType,
      municipalityCode: null,
      contactName,
      billingEmail: email,
    };

    api.
      addBillingAccountPrivate(params)
      .then(() => {
        auth.refreshLogin();
      })
      .then(() => {
        history.replace('/emptying-infos');
      })
      .catch((err: any) => {
        showDialog('error-service-break-title', 'error-service-break-message');
      })
      .finally(() => {
        if (componentIsMounted.current) {
          setIsLoading(false);
        }
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <PrivateCustomerBasicInfo
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        phone={phone}
        setPhone={setPhone}
        email={email}
        setEmail={setEmail}
        askSsn={true}
        ssn={ssn}
        setSsn={setSsn}
        contactName={contactName}
        setContactName={setContactName}
        isSubmitted={isSubmitted}
      />

      <PrivateCustomerBillingInfo
        streetAddress={streetAddress}
        setStreetAddress={setStreetAddress}
        postCode={postCode}
        setPostCode={setPostCode}
        city={city}
        setCity={setCity}
        billType={billType}
        setBillType={setBillType}
        isSubmitted={isSubmitted}
        readOnly={false}
        emailInvoicing={auth.isAllowRegisterEmailInvoicingEnabled()}
        alreadyCustomer={false}
        customerNumber={customerNumber}
        setCustomerNumber={setCustomerNumber}
        invoiceNumber={invoiceNumber}
        setInvoiceNumber={setInvoiceNumber}
      />

      {renderConditionsAndRegistrationButton()}
    </Form>
  );
};

export default PrivateCustomer;
