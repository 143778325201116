import { Avatar, Grid, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '../../contexts/language-context';
import { UiTexts, UserAccountInfo } from '../../model';
import { ReactComponent as UserInfoIcon } from '../../assets/icons/user.svg';
import { ReactComponent as LoginInfoIcon } from '../../assets/icons/shield.svg';
import { ReactComponent as BillingInfoIcon } from '../../assets/icons/credit-card.svg';
import { ReactComponent as GeneralInfoIcon } from '../../assets/icons/settings.svg';

interface Props {
  screenWiderThanPhone: boolean;
  title: keyof UiTexts;
  description: keyof UiTexts;
  icon: string;
  userAccountInfo?: UserAccountInfo;
}

const getIconFromName = (iconName: string) => {
  switch (iconName) {
    case 'user-info':
      return <UserInfoIcon />;
    case 'login-info':
      return <LoginInfoIcon />;
    case 'billing-info':
      return <BillingInfoIcon />;
    case 'general-info':
      return <GeneralInfoIcon />;
  }
};

export const AccountSettingsCard = ({ screenWiderThanPhone, title, description, icon, userAccountInfo }: Props) => {
  const { palette } = useTheme();
  const { getText } = useContext(LanguageContext);
  const history = useHistory();
  const stackDirection = screenWiderThanPhone ? 'column' : 'row';

  const handleNavigation = useCallback(() => {
    switch (icon) {
      case 'user-info':
        return history.push('/account-settings/user-info');
      case 'login-info':
        return history.push({
          pathname: '/account-settings/login-info',
          state: { userAccountInfo },
        });
      case 'billing-info':
        return history.push('/account-settings/billing-info');
      case 'general-info':
        return history.push('/account-settings/general-info');
    }
  }, [icon]);

  return (
    <Grid item xs={4} onClick={handleNavigation}>
      <Stack
        minHeight='100%'
        direction={stackDirection}
        spacing={2}
        p='1rem'
        border={1}
        borderColor='divider'
        sx={{
          backgroundColor: palette.background.level2,
          transition: 'border-color 0.5s',
          cursor: 'pointer',
          '&:hover': {
            borderColor: palette.secondary.main,
          },
        }}
      >
        <Avatar
          sx={{
            color: palette.primary.main,
            backgroundColor: palette.primary.mainVariant15,
          }}
        >
          {getIconFromName(icon)}
        </Avatar>

        <Stack display='flex' alignItems='left' spacing={0.25}>
          <Typography variant='h6'>{getText(title)}</Typography>
          <Typography variant='body2' color='text.secondary'>
            {getText(description)}
          </Typography>
        </Stack>
      </Stack>
    </Grid>
  );
};
