import React, { useContext } from 'react';
import styled from 'styled-components';

import { LanguageContext } from '../../../../contexts/language-context';
import { PriceCalculationResult, VingoProduct } from '../../../../model';
import { CONTENT_MARGIN } from '../../../../styleConstants';
import { InfoContent, InfoContetAttached } from '../../../common/containers';
import { SecondaryHeader, SmallHeader } from '../../../common/headers';
import { ConvertToCurrentLocale } from '../../../../util/numberLocalization';

type ContractPricesProps = {
  product: VingoProduct;
  calculatedProductPrice: PriceCalculationResult | null;
  canBuy: boolean;
  isWeightBased: boolean;
};

const PriceHeader = styled(SmallHeader)`
  margin-bottom: 0.25rem;
`;

const TotalPriceHeader = styled(PriceHeader)`
  margin-top: ${CONTENT_MARGIN};
`;

const PriceInfoContent = styled(InfoContent)`
  margin-top: ${CONTENT_MARGIN};
`;

const Price = styled.div`
  margin-bottom: ${CONTENT_MARGIN};
`;

const ContractPrices = (props: ContractPricesProps): JSX.Element => {
  const { product, calculatedProductPrice } = props;
  const { getText } = useContext(LanguageContext);

  const defaultTransport = product.price != null ? product.price.transportPriceIncludingVat : 0;
  const defaultHandling = product.price != null ? product.price.handlingPriceIncludingVat : 0;

  const transportPriceIncVat = calculatedProductPrice != null
    ? calculatedProductPrice.TransportPriceIncludingVat
    : defaultTransport;

  const handlingPriceIncVat = calculatedProductPrice != null
    ? calculatedProductPrice.HandlingPriceIncludingVat
    : defaultHandling;

  return (
    <div>
      <PriceInfoContent>
        <SecondaryHeader>{getText('add-contract-price')}</SecondaryHeader>{' '}
        <PriceHeader>{getText('add-contract-price-emptying')}</PriceHeader>
        <Price>{ConvertToCurrentLocale(transportPriceIncVat)}&euro;</Price>
        <PriceHeader>
          {getText('add-contract-price-waste-management')}
        </PriceHeader>
        <Price>{ConvertToCurrentLocale(handlingPriceIncVat)}&euro;</Price>
        {
          props?.canBuy ?
            <>
              <PriceHeader>{getText('add-contract-price-bin')}</PriceHeader>
              <Price>-</Price>
            </>
            :
            null
        }

      </PriceInfoContent>
      {
        props.isWeightBased ?
          null
          :
          <InfoContetAttached>
            <TotalPriceHeader>
              {getText('add-contract-price-total')}
            </TotalPriceHeader>
            <Price>{ConvertToCurrentLocale(calculatedProductPrice?.TotalPriceGross) ?? "-"}
            </Price>
          </InfoContetAttached>
      }

    </div>
  );
};

export default ContractPrices;
