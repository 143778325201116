import React, { useContext } from 'react';

import {
  HeaderItemLeft,
  HeaderItemCenter,
  HeaderItemRight,
  HeaderLogo,
  RightUpperCornerContainer,
} from '../containers';
import BackButton from '../BackButton';
import Menu from '../Menu';
import { ReactComponent as MenuIcon } from '../../../assets/menu.svg';
import AuthContext from '../../../contexts/auth-context';
import { MenuButton } from '../inputs';

type HeaderProps = {
  backButton?: boolean;
  backAction?: () => void;
  menuOpen: boolean;
  setMenuOpen: (open: boolean) => void;
};

const MobileHeader = (props: HeaderProps): JSX.Element => {
  const authService = useContext(AuthContext);

  const { backButton, backAction, menuOpen, setMenuOpen } = props;

  return (
    <>
      <HeaderItemLeft>
        {backButton ? <BackButton onClick={backAction ?? undefined} /> : null}
      </HeaderItemLeft>
      <HeaderItemCenter>
        <HeaderLogo
          src={`${authService.getDomain()}/Company/${authService.getTenantId()}/logo`}
          alt="Logo"
        />
      </HeaderItemCenter>
      <HeaderItemRight>
        <RightUpperCornerContainer>
          <MenuButton onClick={() => setMenuOpen(true)}>
            <MenuIcon />
          </MenuButton>
        </RightUpperCornerContainer>
        <Menu closeMenu={() => setMenuOpen(false)} open={menuOpen} />
      </HeaderItemRight>
    </>
  );
};

export default MobileHeader;
