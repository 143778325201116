import React, {
    useContext,
    useEffect,
    useState,
    useRef,
  } from 'react';

import {
    Column,
    FormContent,
    ColumnTop,
    InfoContetAttached,
    InfoContent,
    CompactColumn,
  } from '../../common/containers';

import {
  SecondaryHeader,
  SmallHeader,
  TertiaryHeader,
} from '../../common/headers';

import TextInput from '../../common/TextInput';
import DropDown from '../../common/DropDown';
import { CONTENT_MARGIN } from '../../../styleConstants';
import ApiContext from '../../../contexts/api-context';
import AuthContext from '../../../contexts/auth-context';
import DialogContext from '../../../contexts/dialog-context';
import { LanguageContext } from '../../../contexts/language-context';
import VingoButton from '../../common/VingoButton';
import { ConvertToCurrentLocale } from '../../../util/numberLocalization'

import { VingoProduct, PriceCalculationRequest, PriceCalculationResult, PickUpOrderContent } from '../../../model';

interface PickUpOrderContentProps extends PickUpOrderContent {
};

type PickUpOrderUpdateProps = {
  updatedPickUpOrders: PickUpOrderContent[];
  updatePickUpOrders(pickUpOrders: PickUpOrderContent[]): void;
}

const PickUpOrder = (props: (PickUpOrderContentProps & PickUpOrderUpdateProps)): JSX.Element => {
    const keyId = props.id;
    const categoryId = props.categoryId;
    const weight = props.weight.toString();
    const isSubmitted = false;
    const { getText } = useContext(LanguageContext);
    const api = useContext(ApiContext);
    const { calculatePrice } = useContext(ApiContext);
    const showDialog = useContext(DialogContext);
    const authService = useContext(AuthContext);

    const componentIsMounted = useRef(true);

    const [amount, setAmount] = useState<string>(props.amount.toString());

    const [vingoProductGroups, setVingoProductGroups] = useState<VingoProduct[] | undefined | null>(undefined);
    const [vingoProductGroup, setVingoProductGroup] = useState<VingoProduct | null>(null);
    const [vingoProducts, setVingoProducts] = useState<VingoProduct[] | undefined | null>(undefined);
    const [vingoProduct, setVingoProduct] = useState<VingoProduct | null>(null);
    const [filteredVingoProducts, setfilteredVingoProducts] = useState<VingoProduct[] | undefined | null>(undefined);

    const [calculatedProductPrice, setCalculatedProductPrice] = useState<PriceCalculationResult | null>(null);

    const deletePickUpOrder = () => {
      const modifiedPickUpOrders = props.updatedPickUpOrders.filter((pickUpOrder) => pickUpOrder.id !== keyId);
      props.updatePickUpOrders(modifiedPickUpOrders);
    };

    const updatePickUpOrderContent = () => {
      const pickUpOrderContent = props.updatedPickUpOrders.find(p => p.id === keyId);
      if(pickUpOrderContent) {
         pickUpOrderContent.name = vingoProduct && vingoProduct.name ? vingoProduct.name : '';
         pickUpOrderContent.amount = Number(amount);
         pickUpOrderContent.product = vingoProduct && vingoProduct.id ? vingoProduct.id : '';
         pickUpOrderContent.productGroup = vingoProductGroup && vingoProductGroup.productGroup ? vingoProductGroup.productGroup : '';
      }
    };

    useEffect(() => {
      return () => {
        componentIsMounted.current = false;
      };
    }, []);

    useEffect(() => {
      const customerId = props.customerId;
      Promise.all([
        getCategoryWithProduct(customerId, categoryId),
      ]).catch((err: any) => {
        handleError(err);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if(vingoProductGroups) {
        const product = vingoProductGroups[0];
        setVingoProductGroup(product);
       }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vingoProductGroups]);

     useEffect(() => {
       if(vingoProducts && vingoProductGroup) {
          const filteredProducts = vingoProducts?.filter(product => product.productGroup === vingoProductGroup?.productGroup);
          setfilteredVingoProducts(filteredProducts);
       }
    }, [vingoProductGroup]);

    useEffect(() => {
      if(filteredVingoProducts) {
        const product = filteredVingoProducts[0];
        setVingoProduct(product);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredVingoProducts]);

    useEffect(() => {
      if(vingoProduct && Number(amount) > 0) {
        const customerId = props.customerId;
        const newPriceCalculationRequest: PriceCalculationRequest = {
          productGuid: vingoProduct?.id,
          customerId: customerId,
          amount: Number(amount),
          weight: Number(weight),
        };
        Promise.all([
          getPriceCalculationResult(newPriceCalculationRequest),
        ]).catch((err: any) => {
          handleError(err);
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vingoProduct, amount]);

    useEffect(() => {
       updatePickUpOrderContent();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amount, vingoProductGroup, vingoProduct]);

    const getCategoryWithProduct = async(customerId: string, categoryId: string): Promise<void> => {
       const categoryProducts = await api.fetchCategoryWithProducts(customerId, categoryId).catch((err: any) => {
        handleError(err);
       });
       if (componentIsMounted.current && categoryProducts) {
        setVingoProductGroups(categoryProducts.products);
        setVingoProducts(categoryProducts.products);
       }
    };

    const getPriceCalculationResult = async (priceCalculationRequest: PriceCalculationRequest): Promise<void> => {
      const result = await calculatePrice(priceCalculationRequest).catch((err: any) => {
        handleError(err);
      });
      if (componentIsMounted.current && result) {
        setCalculatedProductPrice(result);
      }
    };

    const handleError = (err: any) => {
      if (err.statusCode === 401) {
        showDialog('error-unauthorized-title', 'error-unauthorized-message', () =>
          authService.logout()
        );
      } else {
        showDialog('error-service-break-title', 'error-service-break-message');
      }
      console.error(err);
    };
  
    const renderVingoProductGroups = (): JSX.Element => {
      const options: [string, string][] = vingoProductGroups ? vingoProductGroups.map(x => {
        let y: [string, string];
  
        if(x.productGroup && x.productGroupName){
          y = [x.productGroup, x.productGroupName];
        }
        else {
          y = [" "," "];
        }
        return y;
      }) : []
      return(
        <div>
           <DropDown
              value = {vingoProductGroup?.productGroup}
              onChange={handleProductGroupChange}
              options={options}
          />
      </div>
      );
    };
  
    const renderVingoProducts = (): JSX.Element => {
      const options: [string, string][] = filteredVingoProducts ? filteredVingoProducts.map(x => {
        let y: [string, string];
        if(x.id && x.name){
          y = [x.id, x.name];
        }
        else {
          y = [" "," "];
        }
        return y;
      }) : []
      return(
        <div>
           <DropDown
              value={vingoProduct?.id}
              onChange={e => {handleProductChange(e);}}
              options={options}
          />
      </div>
      );
    };

    const handleProductGroupChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newVingoProductGroup: VingoProduct | undefined = vingoProductGroups?.find(x => x.productGroup === e.target.value);
      if(newVingoProductGroup) {
         setVingoProductGroup(newVingoProductGroup);
      }
    };
    
    const handleProductChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newVingoProduct: VingoProduct | undefined = vingoProducts?.find(x => x.id === e.target.value);
      if(newVingoProduct){
         setVingoProduct(newVingoProduct);
      }
    };

    return(
      <Column style={{ marginBottom: '5rem' }}>
      <CompactColumn>
         <ColumnTop>
            <SecondaryHeader>{getText('service-order-pickup')}</SecondaryHeader>
        </ColumnTop>
        <FormContent>
          <TertiaryHeader>
            {getText('service-order-product-group')}
          </TertiaryHeader>
          {renderVingoProductGroups()}
        </FormContent>
        <FormContent>
          <TertiaryHeader>
            {getText('service-order-product-entity')}
          </TertiaryHeader>
          {renderVingoProducts()}
        </FormContent>
        <FormContent>
          <TertiaryHeader>
            {getText('service-order-product-amount')}
          </TertiaryHeader>
          <TextInput
            val={amount}
            inputType="number"
            setter={setAmount}
            showErrors={isSubmitted}
            validations={['required', 'integer', 'gt_zero']}
          />
        </FormContent>
        <InfoContent style={{ marginTop: CONTENT_MARGIN }}>
          <SecondaryHeader>
            {getText('service-order-pickup-price')}
          </SecondaryHeader>
          <SmallHeader>
            {getText('service-order-pickup-price-pickup')}
          </SmallHeader>
          {ConvertToCurrentLocale(calculatedProductPrice?.TransportPrice)}&euro;
          <SmallHeader style={{ marginTop: CONTENT_MARGIN }}>
            {getText('service-order-pickup-price-waste-handling')}
          </SmallHeader>
          {ConvertToCurrentLocale(calculatedProductPrice?.HandlingPrice)}&euro;
          </InfoContent>
          <InfoContetAttached>
          <SmallHeader style={{ marginTop: CONTENT_MARGIN }}>
            {getText('service-order-pickup-price-total')}
          </SmallHeader>
          {ConvertToCurrentLocale(calculatedProductPrice?.TotalPriceNet)}&euro;
        </InfoContetAttached>
        <FormContent>
          <VingoButton onClick={()=>deletePickUpOrder()}>
            {getText('service-order-pickup-remove-order')}
          </VingoButton>
        </FormContent>
      </CompactColumn>
    </Column>
    );
  };

  export default PickUpOrder;

