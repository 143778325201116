import React from 'react';

import { ReactComponent as Loader } from '../../assets/loader.svg';
import colors from '../../colors';
import { SpinnerContainer } from './containers';

const Spinner = () => {
  return (
    <SpinnerContainer>
      <Loader
        width="40"
        height="40"
        className="rotate"
        color={colors.lightLabel}
      />
    </SpinnerContainer>
  );
};

export default Spinner;
