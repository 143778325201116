import React, { Dispatch, SetStateAction, useContext } from 'react';
import { Content, Row, ContentRow } from '../../common/containers';

import VingoButton from '../../common/VingoButton';
import { RadioButton } from '../../common/inputs';
import Terms from '../../common/Terms';
import { UiTexts, CustomerType } from '../../../model';
import { LanguageContext } from '../../../contexts/language-context';
import { Radio, FormControlLabel } from '@mui/material';

export const renderConditionsAndRegistrationButtonContentRow = (
  iHaveReadConditions: boolean,
  isLoading: boolean,
  setIHaveReadConditions: Dispatch<SetStateAction<boolean>>,
  setIsSubmitted: Dispatch<SetStateAction<boolean>>,
  buttonText: string
) => {

  return (
    <Content>
      <Row style={{ marginTop: '1.2rem', marginBottom: '0.5rem' }}>
        <Terms
          type="default"
          iHaveReadConditions={iHaveReadConditions}
          setIHaveReadConditions={setIHaveReadConditions}
        />
      </Row>
      <VingoButton
        disabled={!iHaveReadConditions}
        onClick={() => setIsSubmitted(true)}
        isLoading={isLoading}
      >
        {buttonText}
      </VingoButton>
    </Content>
  );
};

export const RenderCustomerTypeSelection = (
  name: keyof UiTexts,
  custType: CustomerType,
  setCustomerType: Dispatch<SetStateAction<CustomerType>>) => {
  const { getText } = useContext(LanguageContext);
  return (
    <ContentRow>
      <RadioButton
        type="radio"
        name="register-customer-type"
        id={name}
        onChange={() => setCustomerType(custType)}
      />
      <label htmlFor={name}>{getText(name)}</label>
    </ContentRow>
  );
};

export const RenderSelectionCustomerType = (
  name: keyof UiTexts,
  custType: CustomerType) => {
  const { getText } = useContext(LanguageContext);

  return (
    <FormControlLabel
      key={custType}
      value={custType}
      control={<Radio color="primary" />}
      label={getText(name)}
    />
  );
};

export const splitName = (name: string): [firstName: string, lastName: string] => {
  const items = name.split(' ');
  let firstName = items.slice(0, -1).join(' ');
  let lastName = items[items.length - 1];
  return [firstName, lastName];
};