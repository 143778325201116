import React, { useState, useEffect, useMemo, useContext, useCallback } from 'react';
import {
  Box,
  Stack,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  Divider,
  Typography,
  useTheme,
  Button,
  Tooltip,
} from '@mui/material';
import { WeekInterval } from '../../OrderProduct';
import AuthContext from '../../../../../contexts/auth-context';
import { EmptyingInterval, UiTexts } from '../../../../../model';
import { printIntervalBasedOnTimesWeek } from '../../../../../util/util';
import ExtendedAlert from '../../../../../components/common-materialui/status-handling/ExtendedAlert';
import { addWeeks, endOfWeek, format, startOfWeek } from 'date-fns';
import { CONSTANTS, autofillSecondDateRange, calculateAllowedIntervals, handleWeekOverlaps } from './WeekIntervalSelectorUtils';
import { WeeksPerDayToggleButton } from './WeekIntervalTimesPerWeek';

// Strings for the start and end dates of the intervals, e.g. "1.1 - 31.3"
interface IntervalDayStrings {
  summer : {
    start : string,
    end: string,
  },
  winter : {
    start : string,
    end: string,
  }
}
const DefaultIntervalDayStrings : IntervalDayStrings = {
  summer: {
    start: '',
    end: '',
  },
  winter: {
    start: '',
    end: '',
  }
};

interface WeekIntervalSelectorSeasonalRhythmProps {
  allowedIntervals: Array<{ startWeek: number; endWeek: number; intervals: number[]; }>;
  alertErrorMsgFirstInterval: keyof UiTexts | null;
  alertErrorMsgSecondInterval: keyof UiTexts | null;
  getText: (key: keyof UiTexts, params?: string[] | undefined) => string;
  initialIntervals?: EmptyingInterval[]; // optional, used if provided for frequencies etc.
  setAlertErrorMsgFirstInterval: React.Dispatch<React.SetStateAction<keyof UiTexts | null>>;
  setAlertErrorMsgSecondInterval: React.Dispatch<React.SetStateAction<keyof UiTexts | null>>;
  setWeekIntervalFinishOrder: (param: any) => any;
  setWholeYearInterval: React.Dispatch<React.SetStateAction<boolean>>;
  weekIntervalFinishOrder: WeekInterval[];
  wholeYearInterval: boolean;
}

const WeekIntervalSelectorSeasonalRhythm: React.FC<WeekIntervalSelectorSeasonalRhythmProps> = ({
  allowedIntervals,
  alertErrorMsgFirstInterval,
  alertErrorMsgSecondInterval,
  getText,
  initialIntervals,
  setAlertErrorMsgFirstInterval,
  setAlertErrorMsgSecondInterval,
  setWeekIntervalFinishOrder,
  setWholeYearInterval,
  weekIntervalFinishOrder,
  wholeYearInterval,
}) => {

  const authService = useContext(AuthContext);

  // The week numbers are fetched from e-services admin settings now
  const startWeek = useMemo(() => {
    return wholeYearInterval
      ? CONSTANTS.WEEK.START.toString()
      : allowedIntervals?.[0]?.startWeek?.toString() ?? CONSTANTS.WEEK.START.toString();
  }, [wholeYearInterval, allowedIntervals]);

  const endWeek = useMemo(() => {
    return wholeYearInterval
      ? CONSTANTS.WEEK.END.toString()
      : allowedIntervals?.[0]?.endWeek?.toString() ?? CONSTANTS.WEEK.END.toString();
  }, [wholeYearInterval, allowedIntervals]);

  const [secondStartWeek, setSecondStartWeek] = useState(() => {
    return wholeYearInterval ? undefined : allowedIntervals?.[1]?.startWeek?.toString();
  });

  const [secondEndWeek, setSecondEndWeek] = useState(() => {
    return wholeYearInterval ? undefined : allowedIntervals?.[1]?.endWeek?.toString();
  });

  

  // Calculate the strings for the start and end dates of the intervals, e.g. "1.1 - 31.3"
  const [intervalDayStrings, setIntervalDayStrings] = useState<IntervalDayStrings>({ ...DefaultIntervalDayStrings });
  useEffect(() => {
    const newStrings = { ...DefaultIntervalDayStrings }; // make a copy
    const startOfCurrentYear = new Date(new Date().getFullYear(), 0, 1);
    const startOfNextYear = new Date(new Date().getFullYear() + 1, 0, 1);

    if (startWeek && endWeek) {
      // Calculate the date of the Monday/Sunday for the specified week numbers
      newStrings.summer = {
        start: format(startOfWeek(addWeeks(startOfCurrentYear, parseInt(startWeek,10) - 1), { weekStartsOn: 1 }), 'd.M'),
        end:  format(endOfWeek(addWeeks(startOfCurrentYear, parseInt(endWeek,10) - 1), { weekStartsOn: 1 }), 'd.M'),
      };      
    }
    if (secondStartWeek && secondEndWeek) {
      // Check if the second interval endpoints are in the next year
      const startOfYearForWinterStart = (parseInt(secondStartWeek,10) < parseInt(startWeek ?? "1",10)) ? startOfNextYear : startOfCurrentYear;
      const startOfYearForWinterEnd = (parseInt(secondEndWeek,10) < parseInt(startWeek ?? "1",10)) ? startOfNextYear : startOfCurrentYear;

      // Calculate the date of the Monday/Sunday for the specified week numbers
      newStrings.winter = {
        start: format(startOfWeek(addWeeks(startOfYearForWinterStart, parseInt(secondStartWeek,10) - 1), { weekStartsOn: 1 }), 'd.M'),
        end:  format(endOfWeek(addWeeks(startOfYearForWinterEnd, parseInt(secondEndWeek,10) - 1), { weekStartsOn: 1 }), 'd.M'),
      };
    }

    setIntervalDayStrings(newStrings);
  }, [startWeek, endWeek, secondStartWeek, secondEndWeek]);


  const [allowedIntervalsFirstInterval, setAllowedIntervalsFirstInterval] = useState<number[]>([]);
  const [allowedIntervalsSecondInterval, setAllowedIntervalsSecondInterval] = useState<number[]>([]);

  // Frequency of the first interval, e.g. "1" for once per week. Loaded from the saved values if available
  const [firstIntervalRadioValue, setFirstIntervalRadioValue] = useState<string>(
    (initialIntervals?.[0]?.interval ?? 1).toString(),
  );

  const [firstIntervalShowAll, setFirstIntervalShowAll] = useState<boolean>(false);
  const [secondIntervalShowAll, setSecondIntervalShowAll] = useState<boolean>(false);

  // The first interval frequencies to show in the radio list
  const firstIntervals = useMemo(() => {
    if (allowedIntervalsFirstInterval.length === 0) {
      return [];
    }

    let intervals : number[] = [];
    if (firstIntervalShowAll) {
      intervals = allowedIntervalsFirstInterval;
    } else {
       let index = allowedIntervalsFirstInterval.indexOf(Number(firstIntervalRadioValue));

      // If the selected interval is not in the allowed intervals, set the first interval as the first one in the list 
      if (index === -1) {
        setFirstIntervalRadioValue(allowedIntervalsFirstInterval[0].toString());
        index = 0;
      }

      // slice indices : [0, 1, 2, 3]
       const sliceStartIndex = (index >= 4) ? index - 3 : 0; // first included in the slice
       const sliceEndIndex = (index >= 4) ? index + 1: 4; // first not included in the slice
      intervals = allowedIntervalsFirstInterval.slice(sliceStartIndex, sliceEndIndex);
    }

    return intervals;
  }, [allowedIntervalsFirstInterval, firstIntervalRadioValue, firstIntervalShowAll]);

  // Frequency of the second interval, e.g. "1" for once per week. Loaded from the saved values if available
  const [secondIntervalRadioValue, setSecondIntervalRadioValue] = useState<string>(
    (initialIntervals?.[1]?.interval ?? 1).toString(),
  );

  // The second interval frequencies to show in the radio list
  const secondIntervals = useMemo(() => {
    if (allowedIntervalsSecondInterval.length === 0) {
      return [];
    }
    
    let intervals : number[] = [];
    if (secondIntervalShowAll) {
      intervals = allowedIntervalsSecondInterval;
    } else {
       let index = allowedIntervalsSecondInterval.indexOf(Number(secondIntervalRadioValue));

      // If the selected interval is not in the allowed intervals, set the first interval as the first one in the list 
      if (index === -1) {
        setSecondIntervalRadioValue(allowedIntervalsSecondInterval[0].toString());
        index = 0;
      }

      // slice indices : [0, 1, 2, 3]
       const sliceStartIndex = (index >= 4) ? index - 3 : 0; // first included in the slice
       const sliceEndIndex = (index >= 4) ? index + 1: 4; // first not included in the slice
      intervals = allowedIntervalsSecondInterval.slice(sliceStartIndex, sliceEndIndex);
    }

    return intervals;
  }, [allowedIntervalsSecondInterval, secondIntervalRadioValue, secondIntervalShowAll]);

  // Times per week : also used saved values if available
  const [firstTimesPerWeek, setFirstTimesPerWeek] = useState<string>(
    initialIntervals?.[0]?.amountPerWeek?.toString() ?? '1'
  );
  const [secondTimesPerWeek, setSecondTimesPerWeek] = useState<string>(
    initialIntervals?.[1]?.amountPerWeek?.toString() ?? '1'
  );
  const [displayWeeksOverlapsError, setDisplayWeeksOverlapsError] = useState<boolean>(false);

  const { palette } = useTheme();

  useEffect(() => {
    if (!secondStartWeek && !secondEndWeek) {
      if (startWeek && endWeek && !wholeYearInterval)  {
        autofillSecondDateRange({startWeek, endWeek, setSecondStartWeek, setSecondEndWeek});
      }
    }
  }, [startWeek, endWeek, secondStartWeek, secondEndWeek, wholeYearInterval]);

  useEffect(() => {
    const weekIntervalData: WeekInterval[] = [];
    if (startWeek && endWeek) {
      weekIntervalData.push({
        startWeek,
        endWeek,
        timesPerWeek: firstTimesPerWeek,
        interval: firstIntervalRadioValue,
      });
    }

    if (secondStartWeek && secondEndWeek) {
      weekIntervalData.push({
        startWeek: secondStartWeek,
        endWeek: secondEndWeek,
        timesPerWeek: secondTimesPerWeek,
        interval: secondIntervalRadioValue,
      });
    }

    setWeekIntervalFinishOrder(weekIntervalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    endWeek,
    firstIntervalRadioValue,
    secondEndWeek,
    secondIntervalRadioValue,
    firstTimesPerWeek,
    secondTimesPerWeek,
  ]);

  // If first interval is not set before, calculate the allowed intervals for the selected weeks
  useEffect(() => {
    if (allowedIntervals.length > 0 && !startWeek && !endWeek) {

        const allowedIntervalsForSelectedWeeks = calculateAllowedIntervals(CONSTANTS.WEEK.START, CONSTANTS.WEEK.END, allowedIntervals);
        const value = weekIntervalFinishOrder?.[0]?.interval ?? (allowedIntervalsForSelectedWeeks?.[0]?.toString() ?? "");

        setFirstIntervalRadioValue(value);
        if (value === "") {
          setAlertErrorMsgFirstInterval('order-product-emptying-rhythm-not-available-due-to-admin-settings');
        } else {
          setAlertErrorMsgFirstInterval(null);
        }

        setAllowedIntervalsFirstInterval(allowedIntervalsForSelectedWeeks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedIntervals, startWeek, endWeek]);

  // If first interval is set, calculate the allowed intervals for the selected weeks
  useEffect(() => {
    if (startWeek && endWeek) {

      const allowedIntervalsForSelectedWeeks = calculateAllowedIntervals(Number(startWeek), Number(endWeek), allowedIntervals);

      if (allowedIntervalsForSelectedWeeks.length === 0) {
        setAlertErrorMsgFirstInterval('order-product-emptying-rhythm-not-available-due-to-admin-settings');
      } else {
        setAlertErrorMsgFirstInterval(null);
      }
      setAllowedIntervalsFirstInterval(allowedIntervalsForSelectedWeeks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedIntervals, startWeek, endWeek]);

  useEffect(() => {
    if (secondStartWeek && secondEndWeek) {
      const weeksOverlap = handleWeekOverlaps({ 
        startWeek, endWeek, secondStartWeek, secondEndWeek,
        setDisplayWeeksOverlapsError, setSecondStartWeek, 
        setSecondEndWeek, setAllowedIntervalsSecondInterval,
      });

      if (!weeksOverlap) {
        const allowedIntervalsForSelectedWeeks2 = calculateAllowedIntervals(
          Number(secondStartWeek),
          Number(secondEndWeek),
          allowedIntervals,
        );

        if (allowedIntervalsForSelectedWeeks2.length === 0) {
          setAlertErrorMsgSecondInterval('order-product-emptying-rhythm-not-available-due-to-admin-settings');
        } else {
          setAlertErrorMsgSecondInterval(null);
        }

        setAllowedIntervalsSecondInterval(allowedIntervalsForSelectedWeeks2);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedIntervals, secondStartWeek, secondEndWeek]);

  const handleFirstAllowedIntervalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstIntervalRadioValue(event.target.value);
  };

  const handleSecondAllowedIntervalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSecondIntervalRadioValue(event.target.value);
  };

  const winterSummerDisabled = useMemo(() => {
    return allowedIntervals?.length < 2;
  }, [allowedIntervals]);

  const handleTimesPerWeekToggle = (_: any, value: string) => {
    if (value === null) return;
    setFirstTimesPerWeek(value);
  };

  const handleSecondTimesPerWeekToggle = (_: any, value: string) => {
    if (value === null) return;
    setSecondTimesPerWeek(value);
  };

  const renderFirstIntervalBlock = () => {

    // If the allowed frequency list for the first interval are cut, show a button to show all
    const frequenciesCut : boolean = (allowedIntervalsFirstInterval !== undefined) && 
          (allowedIntervalsFirstInterval.length !== firstIntervals?.length);

    return (
      <>      
        <ExtendedAlert open={Boolean(alertErrorMsgFirstInterval)} severity='error' sx={{ margin: 2 }}>
          {alertErrorMsgFirstInterval ? getText(alertErrorMsgFirstInterval) : null}
        </ExtendedAlert>

        <RadioGroup value={firstIntervalRadioValue} onChange={handleFirstAllowedIntervalChange}>
          <FormControl>
            {firstIntervals.map((interval) => (
              <FormControlLabel
                key={interval}
                control={<Radio />}
                label={printIntervalBasedOnTimesWeek(getText, interval, Number(firstTimesPerWeek))}
                value={interval}
              />
            ))}
          </FormControl>
        </RadioGroup>
        {frequenciesCut && ( 
         <Button
          onClick={() => setFirstIntervalShowAll(true)}
          variant='text'
          fullWidth
          sx={{ justifyContent: 'left', color: palette.text.links, paddingLeft: 4}}
         >
            {getText('order-product-show-more') + "..."}
          </Button>
        )}

        <Box marginTop={2}>
          {authService.isEmptyingTimesPerWeekEnabled() && (
            <WeeksPerDayToggleButton getText={getText} onChange={handleTimesPerWeekToggle} value={firstTimesPerWeek} />
          )}
        </Box>

      </>
    );

  }

  const renderSecondIntervalBlock = () => {

    // If the allowed frequency list for the second interval are cut, show a button to show all
    const frequenciesCut : boolean = (allowedIntervalsSecondInterval !== undefined) &&
          (allowedIntervalsSecondInterval.length !== secondIntervals?.length);

    return (
      <>
        <ExtendedAlert open={Boolean(alertErrorMsgSecondInterval)} severity='error' sx={{ margin: 2 }}>
          {alertErrorMsgSecondInterval ? getText(alertErrorMsgSecondInterval) : null}
        </ExtendedAlert>

        <ExtendedAlert open={displayWeeksOverlapsError} severity='error' sx={{ margin: 2 }}>
          {getText('order-product-emptying-rhythm-overlapping-weeks-due-to-admin-settings')}
        </ExtendedAlert>

        <RadioGroup value={secondIntervalRadioValue} onChange={handleSecondAllowedIntervalChange}>
          <FormControl>
            {secondIntervals.map((interval) => (
              <FormControlLabel
                key={interval}
                control={<Radio />}
                label={printIntervalBasedOnTimesWeek(getText, interval, Number(secondTimesPerWeek))}
                value={interval}
              />
            ))}
          </FormControl>
        </RadioGroup>
        {frequenciesCut && ( 
         <Button
          onClick={() => setSecondIntervalShowAll(true)}
          variant='text'
          fullWidth
          sx={{ justifyContent: 'left', color: palette.text.links, paddingLeft: 4}}
         >
            {getText('order-product-show-more') + "..."}
          </Button>
        )}

        <Box marginTop={2}>
          {authService.isEmptyingTimesPerWeekEnabled() && (
            <WeeksPerDayToggleButton getText={getText} onChange={handleSecondTimesPerWeekToggle} value={secondTimesPerWeek} />
          )}
        </Box>

      </>
    );
  };

  const handleWholeYearRadioBtnChange = useCallback(() => {
    //  if changing from summer/winter to whole year, clear the second interval
    if (!wholeYearInterval) {
      setSecondStartWeek(undefined);
      setSecondEndWeek(undefined);
    }

    setWholeYearInterval(!wholeYearInterval);
  }, [wholeYearInterval, setWholeYearInterval]);

  if (!allowedIntervals.length) {
    return (
      <Box>
        <Typography>{getText('order-product-emptying-rhythm-not-available-due-to-admin-settings')}</Typography>
      </Box>
    );
  }

  const renderWholeYearInterval = () => {

    return (
      <>
        <Typography variant='h6'>{getText('order-product-emptying-rhythm-input-label-for-whole-year')}</Typography>

        {renderFirstIntervalBlock()}      
      </>
    );
  }

  const renderSummerAndWinterIntervals = () => {

    let summerDays = '';
    if (intervalDayStrings.summer.start.length > 0 && intervalDayStrings.summer.end.length > 0) {
      summerDays = ` (${intervalDayStrings.summer.start} -  ${intervalDayStrings.summer.end})`;
    }

    let winterDays = '';
    if (intervalDayStrings.winter.start.length > 0 && intervalDayStrings.winter.end.length > 0) {
      winterDays = ` (${intervalDayStrings.winter.start} -  ${intervalDayStrings.winter.end})`;
    }


    return (
      <>
        <Typography variant='h6'>
          {getText('order-product-emptying-rhythm-input-label-for-summer') + summerDays}
        </Typography>
        {renderFirstIntervalBlock()}

        <Box marginTop={2}>
          <Typography variant='h6'>
            {getText('order-product-emptying-rhythm-input-label-for-winter') + winterDays}
          </Typography>
          {renderSecondIntervalBlock()}
        </Box>
      </>

    );
  }

  return (
    <Box>
      <Stack direction='column' marginTop={1}>
        
        <Stack>
          <Typography variant='h6'>{getText('order-product-emptying-rhythm-validity')}</Typography>
            <RadioGroup value={wholeYearInterval} onChange={handleWholeYearRadioBtnChange}>
              <FormControl>
                <FormControlLabel control={<Radio />} label={getText('order-product-emptying-rhythm-validity-whole-year')} value={true} />
                <Tooltip
                  title={winterSummerDisabled ? getText('order-product-emptying-rhythm-validity-winter-summer-disabled') : ''}
                  arrow
                  PopperProps={{ disablePortal: true }} 
                  placement="top"
                >
                  <div>
                    <FormControlLabel 
                      disabled={winterSummerDisabled}
                      control={<Radio />} 
                      label={getText('order-product-emptying-rhythm-validity-winter-summer')} 
                      value={false} 
                    />
                  </div>
                </Tooltip>
              </FormControl>
            </RadioGroup>
        </Stack>

        <Divider style={{ marginTop: '1rem', marginBottom: '1rem' }} />

        {wholeYearInterval && renderWholeYearInterval()}
        {!wholeYearInterval && renderSummerAndWinterIntervals()}

      </Stack>
    </Box>
  );
};

export default WeekIntervalSelectorSeasonalRhythm;
