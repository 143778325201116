import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CustomerType, Language } from '../../../model';
import { splitName } from '../../../components/views/register/registerUtils';
import PhaseBar, { Phase } from '../../../components/common-materialui/PhaseBar';
import CompleteNewRegistration from './CompleteNewRegistration';
import RegisterType from './RegisterType';
import CompleteExistingCustomerRegistration from './CompleteExistingCustomerRegistration';
import CompleteSamlRegistration from './CompleteSamlRegistration';
import ApiContext from '../../../contexts/api-context';

interface Props {
  id: string;
  onClose: () => void;
  language: Language;
  setLanguage: Dispatch<SetStateAction<Language>>;
}

const RegisterSelection = ({ id, onClose, language, setLanguage }: Props): JSX.Element => {
  const api = useContext(ApiContext);
  const [customerType, setCustomerType] = useState<CustomerType>(null);
  const [currentPhase, setCurrentPhase] = useState<Phase>(Phase.SelectRegisterType);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [personSsn, setPersonSsn] = useState<string>('');
  const [, setEmail] = useState<string>('');
  const [identificationId, setIdentificationId] = useState<string>(id);
  const [streetAddress, setStreetAddress] = useState<string>('');
  const [postCode, setPostCode] = useState<string>('');
  const [city, setCity] = useState<string>('');

  useEffect(() => {
    setIdentificationId(id);
    Promise.all([api.fetchIdentifiedPersonInfo(identificationId)])
      .then(([identifiedPersonInfo]) => {
        let [_firstName, _lastName] = splitName(identifiedPersonInfo.name);
        setFirstName(_firstName);
        setLastName(_lastName);
        setPersonSsn(identifiedPersonInfo.personalIdentifier);
        if (identifiedPersonInfo.email != null) {
          setEmail(identifiedPersonInfo.email);
        }
        if (identifiedPersonInfo.address != null) {
          if (identifiedPersonInfo.address.street != null) {
            setStreetAddress(identifiedPersonInfo.address.street);
          }
          if (identifiedPersonInfo.address.postalCode != null) {
            setPostCode(identifiedPersonInfo.address.postalCode);
          }
          if (identifiedPersonInfo.address.postOffice != null) {
            setCity(identifiedPersonInfo.address.postOffice);
          }
        }
      })
      .catch((err) => {
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const phases = useMemo(() => {
    let basePhases = [Phase.SelectRegisterType];
    // Determine the phase to add based on customerType
    let additionalPhase;
    if (customerType === 'new') {
      additionalPhase = Phase.CompleteNewCustomerRegistration;
    } else if (customerType === 'old') {
      additionalPhase = Phase.CompleteExistingCustomerRegistration;
    } else if (customerType === 'old-ssn') {
      additionalPhase = Phase.CompleteExistingCustomerSamlRegistration;
    }

    // Only add the additional phase if it has been determined
    if (additionalPhase) {
      basePhases.push(additionalPhase);
    }
    return basePhases;
  }, [customerType]);

  const handleWizardNavigation = (phaseStep: number): void => {
    const currentPhaseIndex = phases.findIndex((phase) => phase === currentPhase);

    if (currentPhaseIndex < phases.length - 1 || (currentPhaseIndex === phases.length - 1 && phaseStep === -1)) {
      setCurrentPhase(phases[currentPhaseIndex + phaseStep]);
      document.getElementById('phase-bar-element')?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const goToNextPhase = useCallback(() => {
    handleWizardNavigation(1);
  }, [currentPhase, phases]);

  const goToPreviousPhase = useCallback(() => {
    handleWizardNavigation(-1);
  }, [currentPhase, phases]); // Fixed: removed the nested array

  const renderPhaseForm = () => {
    switch (currentPhase) {
      case Phase.SelectRegisterType:
        return (
          <RegisterType
            customerType={customerType}
            setCustomerType={setCustomerType}
            onPhaseChange={goToNextPhase}
            onCancel={onClose} />
        );
      case Phase.CompleteNewCustomerRegistration:
        return (
          <CompleteNewRegistration
            identificationId={id}
            firstName={firstName}
            lastName={lastName}
            streetAddress={streetAddress}
            postCode={postCode}
            city={city}
            customerType={customerType}
            language={language}
            onPreviousPhaseChange={goToPreviousPhase} />
        );
      case Phase.CompleteExistingCustomerRegistration:
        return (
          <CompleteExistingCustomerRegistration
            identificationId={id}
            firstName={firstName}
            lastName={lastName}
            customerType={customerType}
            language={language}
            onPreviousPhaseChange={goToPreviousPhase} />
        );
      case Phase.CompleteExistingCustomerSamlRegistration:
        return (
          <CompleteSamlRegistration
            identificationId={id}
            firstName={firstName}
            lastName={lastName}
            personSsn={personSsn}
            customerType={customerType}
            language={language}
            onPreviousPhaseChange={goToPreviousPhase} />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <PhaseBar phases={phases} currentPhase={currentPhase} setCurrentPhase={setCurrentPhase} />
      {renderPhaseForm()}
    </>
  );
};

export default RegisterSelection;