import React, { Dispatch, SetStateAction, useContext } from 'react';
import { Column, Content, ContentRow } from '../../common/containers';
import { SecondaryHeader } from '../../common/headers';
import TextInput from '../../common/TextInput';
import { LanguageContext } from '../../../contexts/language-context';
import { RadioButton } from '../../common/inputs';
import { ConsumerEInvoiceType } from '../../../model';

type InputProps = {
  streetAddress: string;
  setStreetAddress: Dispatch<SetStateAction<string>>;
  postCode: string;
  setPostCode: Dispatch<SetStateAction<string>>;
  city: string;
  setCity: Dispatch<SetStateAction<string>>;
  billType: ConsumerEInvoiceType;
  setBillType: Dispatch<SetStateAction<ConsumerEInvoiceType>>;
  billingEmail: string;
  setBillingEmail: Dispatch<SetStateAction<string>>;
  eBillAddress: string;
  setEBillAddress: Dispatch<SetStateAction<string>>;
  broker: string;
  setBroker: Dispatch<SetStateAction<string>>;
  ovt: string;
  setOvt: Dispatch<SetStateAction<string>>;
  isSubmitted: boolean;
  readOnly: boolean;
  emailInvoicing: boolean;
};

const CompanyBillingInfo = (props: InputProps): JSX.Element => {
  const {
    streetAddress,
    setStreetAddress,
    postCode,
    setPostCode,
    city,
    setCity,
    billType,
    billingEmail,
    setBillingEmail,
    setBillType,
    eBillAddress,
    setEBillAddress,
    broker,
    setBroker,
    ovt,
    setOvt,
    isSubmitted,
    readOnly,
    emailInvoicing,
  } = props;
  const { getText } = useContext(LanguageContext);

  return (
    <Content>
      <SecondaryHeader>{getText('billing-data')}</SecondaryHeader>
      <TextInput
        label="billing-data-street-address"
        val={streetAddress}
        setter={setStreetAddress}
        validations={['required']}
        showErrors={isSubmitted}
        disabled={readOnly}
      />
      <TextInput
        label="billing-data-post-code"
        val={postCode}
        setter={setPostCode}
        validations={['required', { minLength: 5 }]}
        maxLength={5}
        showErrors={isSubmitted}
        disabled={readOnly}
      />
      <TextInput
        label="billing-data-city"
        val={city}
        setter={setCity}
        validations={['required']}
        maxLength={29}
        showErrors={isSubmitted}
        disabled={readOnly}
      />
      {!readOnly &&
        <ContentRow style={{ marginTop: '0.5rem' }}>
          <RadioButton
            type="radio"
            name="bill-type-selection"
            id="paper-bill"
            checked={billType === ConsumerEInvoiceType.NotInUse}
            onChange={() => setBillType(ConsumerEInvoiceType.NotInUse)}
          />
          <label htmlFor="paper-bill">{getText('paper-bill')}</label>
        </ContentRow>
      }
      {!readOnly && emailInvoicing && <ContentRow>
          <RadioButton
            type="radio"
            name="bill-type-selection"
            id="email-bill"
            checked={billType === ConsumerEInvoiceType.EmailInvoicing}
            onChange={() => setBillType(ConsumerEInvoiceType.EmailInvoicing)}
          />
          <label htmlFor="email-bill">{getText('email-bill')}</label>
        </ContentRow>
      }
      {!readOnly &&
        <ContentRow>
          <RadioButton
            type="radio"
            name="bill-type-selection"
            id="e-bill"
            checked={billType === ConsumerEInvoiceType.ConsumerChannel}
            onChange={() => setBillType(ConsumerEInvoiceType.ConsumerChannel)}
          />
          <label htmlFor="e-bill">{getText('e-bill')}</label>
        </ContentRow>
      }

      {billType === ConsumerEInvoiceType.EmailInvoicing ? (
        <Column>
          <TextInput
            label="billing-email"
            val={billingEmail}
            setter={setBillingEmail}
            validations={['email']}
            showErrors={isSubmitted}
          />
        </Column>
      ) : null}

      {billType === ConsumerEInvoiceType.ConsumerChannel ? (
        <Column>
          <TextInput
            label="e-bill-address"
            val={eBillAddress}
            setter={setEBillAddress}
            validations={['required']}
            showErrors={isSubmitted}
          />
          <TextInput
            label="broker"
            val={broker}
            setter={setBroker}
            validations={['required']}
            showErrors={isSubmitted}
          />
          <TextInput label="ovt" val={ovt} setter={setOvt} />
        </Column>
      ) : null}
    </Content>
  );
};

export default CompanyBillingInfo;
