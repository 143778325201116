import { Box, Button, Card, CardContent, Grid, Stack, Typography, useTheme } from '@mui/material';
import { FC, Fragment, useContext, useEffect, useState } from 'react';
import { ContractInfo, PriceCalculationRequest, PriceCalculationResult } from '../../../model';

import { LanguageContext } from '../../../contexts/language-context';
import { printInterval } from './emptyingLocationUtils';
import ApiContext from '../../../contexts/api-context';
import { useMutation } from '@tanstack/react-query';
import OrderProductPrice from './order-product-wizard/OrderProductPrice';

interface Props {
  contract: ContractInfo;
  emptyingId: string;
  handleShowApproveDialog: (customerNumber: string, position: number) => void;
  handleShowRejectDialog: (customerNumber: string, position: number) => void;
}

const WaitingContractCard: FC<Props> = ({
  contract,
  emptyingId,
  handleShowApproveDialog,
  handleShowRejectDialog,
}): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const { palette } = useTheme();

  const { calculatePrice } = useContext(ApiContext);

  const [priceResponseData, setPriceResponseData] = useState<PriceCalculationResult>();

  const {
    mutate: calculatePriceMutation,
    error: priceCalculationError,
    isLoading: calculatePriceMutationIsLoading,
  } = useMutation((newPriceCalculationRequest: PriceCalculationRequest) => calculatePrice(newPriceCalculationRequest));

  useEffect(() => {
    if (contract.amount > 0) {
      const newPriceCalculationRequest: PriceCalculationRequest = {
        productGuid: contract?.productGuid,
        customerId: emptyingId,
        amount: contract.amount,
        weight: 1,
      };

      calculatePriceMutation(newPriceCalculationRequest, {
        onError: (err) => {
          console.log(err);
        },
        onSuccess: (data) => {
          setPriceResponseData(data);
        },
      });
    }
  }, [calculatePriceMutation, contract, emptyingId]);

  function isAllowedToApproveOrReject() {
    if (!contract.sharedContainer) {
      return true;
    }
    return contract?.correspondingPersonId === emptyingId;
  }

  return (
    <Card sx={{ margin: 1, boxShadow: 0, border: `1px solid ${palette.divider}` }}>
      <CardContent>
        <Grid container direction='column'>
          <Grid item>
            <Typography variant='h6'>{contract.name}</Typography>
          </Grid>
          <Grid item ml={{ xs: 0, sm: 1, md: 3 }}>
            <Grid container columns={{ xs: 4, md: 8 }}>
              {contract.emptyingIntervals.length > 0 && !contract.readonlyContract && (
                <Grid item xs={4}>
                  <Stack direction='column'>
                    <Typography variant='body2' color={palette.secondary.mainVariant}>
                      {getText('emptying-location-contract-waiting-emptying-rhythm')}
                    </Typography>
                    {contract.emptyingIntervals.map((interval, idx) => (
                      <Box key={'waiting-interval-' + contract.position + '-' + idx}>
                        <Typography variant='body1'>
                          {printInterval(getText, interval.interval, interval.amountPerWeek)}
                        </Typography>
                      </Box>
                    ))}
                  </Stack>
                </Grid>
              )}
              <Grid item xs={4}>
                <Stack direction='column'>
                  <Typography variant='body2' color={palette.secondary.mainVariant}>
                    {getText('emptying-location-contract-amount')}
                  </Typography>
                  <Typography variant='body1'>
                    {contract.amount} {getText('emptying-location-contract-amount-pcs')}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item m={1.5} xs={4} md={8}>
            <OrderProductPrice
              price={priceResponseData}
              productName={contract.name}
              currentNumberOfContainers={contract.amount}
              emptyingId={emptyingId}
            />
          </Grid>
          <Grid item>
            <Box display='flex' justifyContent='flex-end'>
              {isAllowedToApproveOrReject() && (
                <Fragment>
                  {' '}
                  <Button
                    size='large'
                    variant='contained'
                    onClick={() => handleShowApproveDialog(contract.customerId, contract.position)}
                    sx={{ marginRight: '1rem', marginBottom: '0' }}
                  >
                    {getText('emptying-location-contract-waiting-approve')}
                  </Button>
                  <Button
                    size='large'
                    variant='outlined'
                    onClick={() => handleShowRejectDialog(contract.customerId, contract.position)}
                    sx={{ marginRight: '1rem', marginBottom: '0' }}
                  >
                    {getText('emptying-location-reject-contract-dialog-title')}
                  </Button>
                </Fragment>
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default WaitingContractCard;
