import { useCallback, useMemo, useState } from 'react';
import BillingInfoForm from './BillingInfoForm';
import EmptyingLocationForm from './EmptyingLocationForm';
import Summary from './Summary';
import { BillingInfoFormData, LocationInfoFormData } from './models';
import { useForm } from 'react-hook-form';
import { BillingInfo, ConsumerEInvoiceType, CommonDetails, OnlineBroker } from '../../../model';
import PhaseBar, { Phase } from '../../../components/common-materialui/PhaseBar';
import { EmptyingLocationContextProvider } from './provider/EmptyingLocationContextProvider';

interface Props {
  onClose: () => void;
  billingInfos?: BillingInfo[];
  brokers?: OnlineBroker[];
}

const defaultBillingFormValues = {
  companyName: '',
  companyCode: '',
  personalIdentificationNumber: '',
  firstName: '',
  lastName: '',
  address: '',
  postCode: '',
  city: '',
  email: '',
  billingEmail: '',
  phoneNumber: '',
  consumerEInvoiceType: ConsumerEInvoiceType.NotInUse,
  emailInvoicing: false,
  contactFirstName: '',
  contactLastName: '',
  reference: '',
  eInvoiceInformation: undefined,
  useSameBillingContactPerson: false,
};

const defaultLocationFormValues = {
  firstName: '',
  lastName: '',
  address: '',
  postCode: '',
  city: '',
  contactFirstName: '',
  contactLastName: '',
  contactPhone: '',
  contactEmail: '',
  area: '',
  conurbation: '',
  permanentBuildingId: '',
  buildingUsageType: '',
  buildingType: '',
  population: '',
  propertyId: '',
  referenceNumber: '',
};

const defaultCommonDetails = {
  companyName: '',
  firstName: '',
  lastName: '',
  address: '',
  postCode: '',
  city: '',
  contactFirstName: '',
  contactLastName: '',
  phoneNumber: '',
  email: '',
};

const AddEmptyingLocation = ({ onClose, billingInfos, brokers }: Props): JSX.Element => {
  const [currentPhase, setCurrentPhase] = useState<Phase>(Phase.Billing);

  const phases = useMemo(() => [Phase.Billing, Phase.Location, Phase.Summary], []);

  const [customerType, setCustomerType] = useState<'private' | 'company'>('private');

  const billingInfoForm = useForm<BillingInfoFormData>({ defaultValues: defaultBillingFormValues });
  const locationInfoForm = useForm<LocationInfoFormData>({ defaultValues: defaultLocationFormValues });

  const [billingId, setBillingId] = useState<string>('');

  const [commonDetails, setCommonDetails] = useState<CommonDetails>(defaultCommonDetails);

  const updateCommonDetails = (updatedDetails: Partial<CommonDetails>) => {
    setCommonDetails((prevDetails) => ({
      ...prevDetails,
      ...updatedDetails,
    }));
  };

  const goToNextPhase = useCallback(() => {
    const currentPhaseIndex = phases.findIndex((phase) => phase === currentPhase);
    if (currentPhaseIndex < phases.length - 1) {
      setCurrentPhase(phases[currentPhaseIndex + 1]);
      // TODO: Better solution for scrolling to top of form?
      document.getElementById('phase-bar-element')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentPhase, phases]);

  const renderPhaseForm = () => {
    switch (currentPhase) {
      case Phase.Billing:
        return (
          <BillingInfoForm
            form={billingInfoForm}
            onPhaseChange={goToNextPhase}
            onCancel={onClose}
            customerType={customerType}
            setCustomerType={setCustomerType}
            billingId={billingId}
            setBillingId={setBillingId}
            billingInfos={billingInfos}
            updateCommonDetails={updateCommonDetails}
            brokers={brokers}
          />
        );
      case Phase.Location:
        return (
          <EmptyingLocationForm
            billingId={billingId}
            form={locationInfoForm}
            onPhaseChange={goToNextPhase}
            onCancel={onClose}
            customerType={customerType}
            commonDetails={commonDetails}
          />
        );
      case Phase.Summary:
        return (
          <Summary
            onClose={onClose}
            customerType={customerType}
            billingInfoFormData={billingInfoForm.getValues()}
            locationInfoFormData={locationInfoForm.getValues()}
          />
        );
      default:
        return null;
    }
  };

  return (
    <EmptyingLocationContextProvider>
      <PhaseBar phases={phases} currentPhase={currentPhase} setCurrentPhase={setCurrentPhase} />
      {renderPhaseForm()}
    </EmptyingLocationContextProvider>
  );
};

export default AddEmptyingLocation;
