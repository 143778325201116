export const CONSTANTS = {
  WEEK: {
    START: 1,
    END: 53,
  },
  DAYS_PER_WEEK: [1, 2, 3, 4, 5],
};

const listAllWeeks = (): number[] => {
  return Array.from({ length: CONSTANTS.WEEK.END - CONSTANTS.WEEK.START + 1 }, (_, i) => i + CONSTANTS.WEEK.START);
}

interface ListIntervalWeeksProps {
  startWeek: number,
  endWeek: number,
  intervals?: number[], // not required, but some data structures have it
}

export const listIntervalWeeks = (interval: ListIntervalWeeksProps): number[] => {
  const weeks = [];
  if (interval.startWeek <= interval.endWeek) {
    for (let i = interval.startWeek; i <= interval.endWeek; i++) {
      weeks.push(i);
    }
  } else {
    for (let i = interval.startWeek; i <= CONSTANTS.WEEK.END; i++) {
      weeks.push(i);
    }
    for (let i = CONSTANTS.WEEK.START; i <= interval.endWeek; i++) {
      weeks.push(i);
    }
  }
  return weeks;
};

interface AllowedInterval {
  startWeek: number;
  endWeek: number;
  intervals: number[];
}

const findMissingWeeks = (
  allowedIntervals: AllowedInterval[],
): number[] => {

  const allWeeks = listAllWeeks();

  const allowedWeeks = allowedIntervals.reduce((acc : number[], interval) => {
    const weeks = listIntervalWeeks(interval);
    return acc.concat(weeks);
  }, []);

  const missingWeeks = allWeeks.filter((week) => !allowedWeeks.includes(week));

  return missingWeeks;
}

export const calculateAllowedIntervals = (
  startWeek: number,
  endWeek: number,
  allowedIntervals: AllowedInterval[],
): number[] => {
  
  // frequencies of the allowed intervals (once per week, twice per week, etc.)
  let overlappingIntervals: number[] = [];

  // Check that there is no missing weeks in allowed intervals
  // If there are missing weeks in the user set interval, return an empty array of allowed interval frequencies
  const missingWeeks = findMissingWeeks(allowedIntervals);
  const intervalWeeks = listIntervalWeeks({ startWeek, endWeek });
  const missingWeekMatch : boolean = intervalWeeks.some((week) => missingWeeks.includes(week));
  if (missingWeekMatch) {
    return [];
  }

  allowedIntervals.forEach((interval) => {
    const allowedWeeks = listIntervalWeeks(interval);
    const isOverlapping : boolean = allowedWeeks.some((week) => intervalWeeks.includes(week));

    if (isOverlapping) {
      let currentIntervals = [...interval.intervals];

      if (overlappingIntervals.length === 0) {
        overlappingIntervals = currentIntervals;
      } else {
        // Intersect overlappingIntervals with the current interval's intervals
        overlappingIntervals = overlappingIntervals.filter((x) => currentIntervals.includes(x));
      }
    }
  });

  // Return the overlapping intervals (frequencies)
  return overlappingIntervals;
};

interface AutofillSecondDateRangeProps {
  startWeek: string,
  endWeek: string,
  setSecondStartWeek: (value: React.SetStateAction<string | undefined>) => void,
  setSecondEndWeek: (value: React.SetStateAction<string | undefined>) => void,
}

export const autofillSecondDateRange = (props: AutofillSecondDateRangeProps): void => {
  const { startWeek, endWeek, setSecondStartWeek, setSecondEndWeek } = props;
  const firstStartWeek: number = Number(startWeek);
  const firstEndWeek: number = Number(endWeek);
  let secondStart: number;
  let secondEnd: number;

  if (firstEndWeek < CONSTANTS.WEEK.END) {
    secondStart = firstEndWeek + 1;
  } else {
    secondStart = 1;
  }

  if (firstStartWeek > secondStart) {
    secondEnd = firstStartWeek - 1;
  } else {
    if (firstStartWeek === 1) {
      secondEnd = CONSTANTS.WEEK.END;
    } else {
      secondEnd = firstStartWeek - 1;
    }
  }

  setSecondStartWeek(secondStart.toString());
  setSecondEndWeek(secondEnd.toString());
};

interface HandleWeekOverlapsProps {
  startWeek: string | undefined,
  endWeek: string | undefined,
  secondStartWeek: string | undefined,
  secondEndWeek: string | undefined,
  setDisplayWeeksOverlapsError: (value: React.SetStateAction<boolean>) => void,
  setSecondStartWeek: (value: React.SetStateAction<string | undefined>) => void,
  setSecondEndWeek: (value: React.SetStateAction<string | undefined>) => void,
  setAllowedIntervalsSecondInterval: (value: React.SetStateAction<number[]>) => void,
}

export const handleWeekOverlaps = (props: HandleWeekOverlapsProps): boolean => {
  const { 
    startWeek, endWeek, secondStartWeek, secondEndWeek, 
    setDisplayWeeksOverlapsError, setSecondStartWeek, 
    setSecondEndWeek, setAllowedIntervalsSecondInterval, } = props;

  if (startWeek && endWeek && secondStartWeek && secondEndWeek) {

    const firstWeeks = listIntervalWeeks({ startWeek: Number(startWeek), endWeek: Number(endWeek) });
    const secondWeeks = listIntervalWeeks({ startWeek: Number(secondStartWeek), endWeek: Number(secondEndWeek) });
    const isOverlapping : boolean = firstWeeks.some((week) => secondWeeks.includes(week));

    if (!isOverlapping) {
      setDisplayWeeksOverlapsError(false);
      return false;
    } else {
      setSecondStartWeek(undefined);
      setSecondEndWeek(undefined);
      setAllowedIntervalsSecondInterval([]);
      setDisplayWeeksOverlapsError(true);
      return true;
    }
  }

  setDisplayWeeksOverlapsError(false);
  return false;
};