import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Content, ContentRow } from '../../common/containers';
import { SecondaryHeader } from '../../common/headers';
import TextInput from '../../common/TextInput';
import { LanguageContext } from '../../../contexts/language-context';
import { RadioButton } from '../../common/inputs';
import { ConsumerEInvoiceType } from '../../../model';

interface Props {
  eBillAddress: string;
  billType: ConsumerEInvoiceType;
  setBillType: React.Dispatch<React.SetStateAction<ConsumerEInvoiceType>>;
  setEBillAddress: React.Dispatch<React.SetStateAction<string>>;
  broker: string;
  setBroker: React.Dispatch<React.SetStateAction<string>>;
  ovt: string;
  setOvt: React.Dispatch<React.SetStateAction<string>>;
  emailInvoicing: boolean;
  readOnly: boolean | undefined;
  isSubmitted: boolean | undefined;
}

const ModifyCompanyBillingInfo = ({
  billType,
  setBillType,
  eBillAddress,
  setEBillAddress,
  broker,
  setBroker,
  ovt,
  setOvt,
  emailInvoicing,
  readOnly,
  isSubmitted
}: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const [renderEBillInfo, setRenderEBillInfo] = useState<boolean>(false);

  useEffect(() => {
    if (billType === ConsumerEInvoiceType.ConsumerChannel) {
      setRenderEBillInfo(true);
    }
    else {
      setRenderEBillInfo(false);
    }
  }, [billType]);

  return (
    <Content>
      <SecondaryHeader>{getText("billing-data-billing-method")}</SecondaryHeader>
      <ContentRow style={{ marginTop: '0.5rem' }}>
        <RadioButton
          type="radio"
          name="bill-type-selection"
          id="paper-bill"
          checked={billType === ConsumerEInvoiceType.NotInUse}
          onChange={() => setBillType(ConsumerEInvoiceType.NotInUse)}
          disabled={readOnly}
        />
        <label htmlFor="paper-bill">{getText('paper-bill')}</label>
      </ContentRow>

      {emailInvoicing &&
        <ContentRow>
          <RadioButton
            type="radio"
            name="bill-type-selection"
            id="email-bill"
            checked={billType === ConsumerEInvoiceType.EmailInvoicing}
            onChange={() => setBillType(ConsumerEInvoiceType.EmailInvoicing)}
            disabled={readOnly}
          />
          <label htmlFor="email-bill">{getText('email-bill')}</label>
        </ContentRow>
      }
      <ContentRow>
        <RadioButton
          type="radio"
          name="bill-type-selection"
          id="e-bill"
          checked={billType === ConsumerEInvoiceType.ConsumerChannel}
          onChange={() => setBillType(ConsumerEInvoiceType.ConsumerChannel)}
          disabled={readOnly}
        />
        <label htmlFor="e-bill">{getText('e-bill')}</label>
      </ContentRow>
      {
        renderEBillInfo &&
        <>
          <TextInput
            label="e-bill-address"
            val={eBillAddress}
            setter={setEBillAddress}
            validations={['required']}
            showErrors={isSubmitted}
            disabled={readOnly}
          />

          <TextInput
            label="broker"
            val={broker}
            setter={setBroker}
            validations={['required']}
            showErrors={isSubmitted}
            disabled={readOnly}
          />

          <TextInput
            label="ovt"
            val={ovt}
            setter={setOvt}
            validations={['required']}
            showErrors={isSubmitted}
            disabled={readOnly}
          />
        </>
      }

    </Content>
  );
};

export default ModifyCompanyBillingInfo;
