import React, { useCallback, useContext, useState } from "react";
import { InvoiceInfo } from "../../model";
import { Box, Card, CardContent, Divider, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { getInvoiceItemColor, handleInvoiceCurrency } from "./invoicesUtils";
import { printddMMDate } from "../../util/calendarUtil";
import ApiService from "../../service/apiService";
import { ReactComponent as FileIcon } from "../../assets/icons/file-text.svg";
import { LanguageContext } from "../../contexts/language-context";
import ProgressComponent from "../../components/common-materialui/ProgressComponent";

interface InvoiceCardProps {
  info: InvoiceInfo;
  api: ApiService;
  setFetchErrorState: React.Dispatch<React.SetStateAction<boolean>>;
}
export const InvoiceCard = ({ info, api, setFetchErrorState }: InvoiceCardProps) => {
  const { palette } = useTheme();
  const { lang, getText } = useContext(LanguageContext);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  function fetchPdf() {
    api
      .fetchInvoicePdf(info.invoiceNumber)
      .then((pdf) => {
        setIsFetching(false);
        const url = URL.createObjectURL(pdf);
        window.open(url, '_blank', 'noopener,noreferrer');
      })
      .catch((_err: any) => {
        setIsFetching(false);
        setFetchErrorState(true);
      });
  };

  const handleClick = useCallback(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchPdf();
    }
  }, [isFetching]);

  return (
    <React.Fragment key={info.customerNumber.toString() + info.invoiceNumber.toString()}>
      <Card sx={{ boxShadow: 0, border: `1px solid ${palette.divider}` }}>
        <CardContent sx={{
          borderLeft: `4px solid ${getInvoiceItemColor(info, palette)}`,
          paddingLeft: "12px"
        }}>
          <Stack direction={"row"} justifyContent={"space-between"} px={1} mb={2} mt={1}>
            <Typography variant="h5">
              {
                handleInvoiceCurrency(lang, info.amountOpen, info.currency)
              }
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {printddMMDate(info.invoiceDate)}
            </Typography>
          </Stack>
          <Stack px={1}>

            <Box>
              <Typography variant="body2" color="textSecondary" display={"inline"}>
                {getText("invoice-item-due-date") + " "}
              </Typography>
              <Typography variant="body2" color="textPrimary" display={"inline"}>
                {printddMMDate(info.dueDate)}
              </Typography>
            </Box>

            <Box>
              <Typography variant="body2" color="textSecondary" display={"inline"}>
                {getText("invoice-item-invoice-number") + " "}
              </Typography>
              <Typography variant="body2" color="textPrimary" display={"inline"}>
                {info.invoiceNumber}
              </Typography>
            </Box>

            <Box>
              <Typography variant="body2" color="textSecondary" display={"inline"}>
                {getText("invoice-item-invoice-amount-completed") + " "}
              </Typography>
              <Typography variant="body2" color="textPrimary" display={"inline"}>
                {
                  handleInvoiceCurrency(lang, info.amountCompleted, info.currency)
                }
              </Typography>
            </Box>
          </Stack>
        </CardContent>

        <Divider />

        <ProgressComponent isLoading={isFetching} />

        <CardContent sx={{ "&:last-child": { paddingBottom: "16px" } }}>
          <IconButton onClick={handleClick} sx={{ borderRadius: 0 }}>
            <FileIcon color={palette.text.links} />
            <Typography variant="button" color={palette.text.links} ml={1}>
              {getText('invoice-item-invoice-pdf')}
            </Typography>
          </IconButton>

        </CardContent>
      </Card>

    </React.Fragment>
  );
};