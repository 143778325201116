import { FC, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Card, CardContent, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { getNextEmptyingDate } from './emptyingLocationUtils';
import { LanguageContext } from '../../../contexts/language-context';
import { ContractInfo, EmptyingInterval } from '../../../model';
import { printIntervalStartAndEndDates } from '../../../util/calendarUtil';
import { printIntervalBasedOnTimesWeek } from '../../../util/util';

interface Props {
  contract: ContractInfo;
  emptyingId: string;
}

const ContractCard: FC<Props> = ({ contract, emptyingId }) => {
  const { palette } = useTheme();
  const history = useHistory();

  const handleRoutingToContract = useCallback(() => {
    history.push(`/emptying-infos/${emptyingId}/contracts/${contract.customerId}_${contract.position}`);
  }, []);

  return (
    <Card
      sx={{
        m: 1,
        boxShadow: 0,
        border: `1px solid ${palette.divider}`,
        cursor: 'pointer',
        '&:hover': { backgroundColor: palette.background.level1 },
      }}
      onClick={handleRoutingToContract}
    >
      <CardContent>
        <Grid container direction='column'>
          <Grid item>
            <Typography variant='h6'>{contract.name}</Typography>
          </Grid>
          <Grid item ml={{ xs: 0, sm: 1, md: 3 }}>
            <ContractContent emptyingId={emptyingId} contract={contract} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const ContractContent: FC<Props> = ({ contract }) => {
  const { getText } = useContext(LanguageContext);
  const theme = useTheme();
  const { palette } = useTheme();
  const largerThanPhone = useMediaQuery(theme.breakpoints.up('md'));
  const binAmountMdColumns = contract.nextEmptying ? 4 : 8;

  return (
    <Grid container columns={{ xs: 4, md: 8 }} rowSpacing={2}>
      {contract.nextEmptying ? (
        <Grid item xs={4}>
          <Stack direction='column'>
            <Typography variant='body2' color={palette.secondary.mainVariant}>
              {getText('emptying-location-contract-next-collection')}
            </Typography>
            <Typography variant='body1'>{getNextEmptyingDate(contract.nextEmptying)}</Typography>
          </Stack>
        </Grid>
      ) : null}

      <Grid item xs={4} md={binAmountMdColumns}>
        <Stack direction='column'>
          <Typography variant='body2' color={palette.secondary.mainVariant}>
            {getText('emptying-location-contract-amount')}
          </Typography>
          <Typography variant='body1'>
            {contract.amount} {getText('emptying-location-contract-amount-pcs')}
          </Typography>
        </Stack>
      </Grid>
      {contract.emptyingIntervals.length > 0 && !contract.readonlyContract && (
        <Grid item xs={4}>
          <Stack direction='column'>
            <Typography variant='body2' color={palette.secondary.mainVariant}>
              {getText('emptying-location-contract-interval')}
            </Typography>
            {contract.emptyingIntervals.map((interval, index) => (
              <Interval
                interval={interval}
                largerThanPhone={largerThanPhone}
                key={'contract-interval-' + contract.position.toString() + '-' + index}
              />
            ))}
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};
interface IntervalProps {
  interval: EmptyingInterval;
  largerThanPhone: boolean;
}
export const Interval: FC<IntervalProps> = ({ interval, largerThanPhone }) => {
  const { getText } = useContext(LanguageContext);
  const stackDirection = largerThanPhone ? 'row' : 'column';

  const intervalText = printIntervalBasedOnTimesWeek(getText, interval.interval, interval.amountPerWeek);

  return (
    <Stack direction={stackDirection}>
      <Box mr={0.5}>
        <Typography noWrap={largerThanPhone}>{`${intervalText} ${printIntervalStartAndEndDates(
          new Date().getFullYear(),
          interval.startWeek,
          interval.endWeek
        )}`}</Typography>
      </Box>
    </Stack>
  );
};

export default ContractCard;
