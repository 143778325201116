import { Box, Button, Container, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Header from '../../components/common-materialui/header/Header';
import { useContext, useMemo } from 'react';
import { LanguageContext } from '../../contexts/language-context';
import { useMutation, useQuery } from '@tanstack/react-query';
import ApiContext from '../../contexts/api-context';
import { UserAccountInfo } from '../../model';
import ProgressComponent from '../../components/common-materialui/ProgressComponent';
import ExceptionHandler from '../../components/common-materialui/status-handling/ExceptionHandler';
import ControlTextInput from '../../components/common-materialui/form/ControlTextInput';
import { useForm } from 'react-hook-form';
import { LargerThanBreakpoint, SmallerThanBreakpoint } from '../../util/viewportUtils';
import { WIDTH_DESKTOP, WIDTH_MOBILE } from './models';
import { useHistory } from 'react-router-dom';
import ContentLoadingHandler from '../../components/common-materialui/status-handling/ContentLoadingHandler';

const EditUserInformation = (): JSX.Element => {
  const history = useHistory();
  const { palette, breakpoints } = useTheme();
  const { getText } = useContext(LanguageContext);
  const { fetchUserAccountInfo, saveUserAccountInfo } = useContext(ApiContext);

  const largerThanPhone = useMediaQuery(breakpoints.up('md'));
  const headerStackDirection = largerThanPhone ? 'row' : 'column';

  const {
    isLoading,
    data: userAccountInfo,
    error,
  } = useQuery<UserAccountInfo>(['user-account-info'], () => fetchUserAccountInfo());

  const { mutateAsync: updateUserAccountInfo, isLoading: updateUserAccountInfoIsLoading } = useMutation(
    (data: UserAccountInfo) => {
      // Remove whitespaces from phone number
      const modifiedData = { ...data, phoneNumber: data.phoneNumber.replace(/\s/g, '')};
      return saveUserAccountInfo(modifiedData);
    },
    {
      onSuccess: () => history.replace('/account-settings'),
    }
  );

  const width = LargerThanBreakpoint('md') ? WIDTH_DESKTOP : WIDTH_MOBILE;
  const orientation = SmallerThanBreakpoint('md') ? 'column' : 'row';

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UserAccountInfo>({
    values: useMemo(() => {
      return userAccountInfo;
    }, [userAccountInfo]),
  });

  const headerContent = () => {
    return (
      <Stack
        spacing={3}
        justifyContent='space-between'
        direction={headerStackDirection}
        marginTop={largerThanPhone ? 1 : 0.75}
        marginBottom={largerThanPhone ? 3 : 2}
      >
        <Typography variant='h2'>
          {getText('account-settings-edit-user-info-header')}
          <Typography variant='body1' mt={1}>
            {getText('account-settings-edit-user-info-guide-text')}
          </Typography>
        </Typography>
      </Stack>
    );
  };

  const renderSkeleton = () => {
    return (
      <>
        <Skeleton variant='rectangular' height={300} width={'100%'}></Skeleton>
      </>
    );
  };

  return (
    <>
      <Header content={headerContent()} />
      <ProgressComponent isLoading={isLoading} />
      <ExceptionHandler error={error}>
        <ContentLoadingHandler isLoading={isLoading} skeleton={renderSkeleton()}>
          <Container>
            <Stack
              direction={orientation}
              justifyContent={'space-between'}
              spacing={2}
              p='1rem'
              border={1}
              borderColor='divider'
              sx={{
                backgroundColor: palette.background.level2,
              }}
            >
              <Typography variant='h6'>{getText('account-settings-edit-user-info-form-header')}</Typography>
              <Stack direction='column' width={width}>
                <ControlTextInput
                  control={control}
                  name='firstName'
                  label='basic-info-first-name'
                  error={errors.firstName}
                  validations={['required']}
                />
                <ControlTextInput
                  control={control}
                  name='lastName'
                  label='basic-info-last-name'
                  error={errors.lastName}
                  validations={['required']}
                />
                <ControlTextInput
                  control={control}
                  name='phoneNumber'
                  label='basic-info-phone'
                  error={errors.phoneNumber}
                  validations={['required', 'phone']}
                />
                <ControlTextInput
                  control={control}
                  name='email'
                  label='basic-info-email'
                  error={errors.email}
                  validations={['required', 'email']}
                />
              </Stack>
            </Stack>
          </Container>
          <Container>
            <Stack direction='row' justifyContent='flex-end' spacing={2} paddingX={3} marginBottom={3}>
              <Box>
                <Button size='large' color='secondary' onClick={() => history.replace('/account-settings')}>
                  {getText('dialog-cancel')}
                </Button>
              </Box>
              <Box>
                <Button
                  variant='contained'
                  size='large'
                  disabled={updateUserAccountInfoIsLoading}
                  onClick={handleSubmit((e) => updateUserAccountInfo(e))}
                >
                  {getText('dialog-save')}
                </Button>
              </Box>
            </Stack>
          </Container>
        </ContentLoadingHandler>
      </ExceptionHandler>
    </>
  );
};

export default EditUserInformation;
