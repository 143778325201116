import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  FormEvent,
} from 'react';
import { useHistory } from 'react-router-dom';
import ApiContext from '../../../contexts/api-context';
import DialogContext from '../../../contexts/dialog-context';
import { Address, ContactMethod } from '../../../model';
import { Column, CompactColumn } from '../../common/containers';
import Header from '../../common/Header';
import FeedbackForm from './FeedbackForm';

const Feedback = (): JSX.Element => {
  const history = useHistory();
  const api = useContext(ApiContext);
  const showDialog = useContext(DialogContext);

  const [emptyingInfoId, setEmptyingInfoId] = useState<string>('');
  const [emptyingName, setEmptyingName] = useState<string>('');
  const [emptyingAddress, setEmptyingAddress] = useState<Address>({
    street: "",
    postOffice: "",
    postalCode: "",
  });
  const [feedbackHeaderText, setFeedbackHeaderText] = useState<string>('');
  const [feedbackText, setFeedbackText] = useState<string>('');
  const [messageCategory, setMessageCategory] = useState<string>('');
  const [messageCategoryId, setMessageCategoryId] = useState<string>('');
  const [contactMethod, setContactMethod] = useState<ContactMethod>('email');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const componentIsMounted = useRef(true);

  const selectEmptyingInfo = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setEmptyingInfoId(e.target.value);
  };

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const isCurrentContactMethod = (value: string) => {
    if(value === contactMethod){
      return true;
    }
    return false;
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    const feedbackInput = {
      customerNumber: emptyingInfoId,
      header: feedbackHeaderText,
      message: feedbackText,
      email: isCurrentContactMethod('email') ? email : undefined,
      phone: isCurrentContactMethod('phone') ? phone : undefined,
      feedbackCategoryId: messageCategoryId,
      messageCategory: messageCategory,
      name: emptyingName,
      address: emptyingAddress,
    };
    api
      .sendFeedback(feedbackInput)
      .then(() => {
        showDialog(
          'message-success-generic-title',
          'feedback-sent-message',
          () => history.replace('/customer-support')
        );
      })
      .catch((err: any) => {
        if (err.statusCode === 401) {
          showDialog(
            'error-unauthorized-title',
            'error-unauthorized-message',
            () => history.replace('/logout')
          );
        }
        console.error(err);
        showDialog('error-service-break-title', 'error-service-break-message');
      })
      .finally(() => {
        if (componentIsMounted.current) {
          setIsLoading(false);
        }
      });
  };

  return (
    <Column>
      <Header
        headerKey="feedback-header"
        descriptionKey="feedback-header-description"
        backButton={true}
      />
      <CompactColumn>
        <FeedbackForm
          emptyingInfoId={emptyingInfoId}
          setEmptyingInfoId={setEmptyingInfoId}
          setEmptyingName={setEmptyingName}
          setEmptyingAddress={setEmptyingAddress}
          selectEmptyingInfo={selectEmptyingInfo}
          feedbackHeaderText={feedbackHeaderText}
          setFeedbackHeaderText={setFeedbackHeaderText}
          feedbackText={feedbackText}
          setFeedbackText={setFeedbackText}
          contactMethod={contactMethod}
          setContactMethod={setContactMethod}
          email={email}
          setEmail={setEmail}
          phone={phone}
          setPhone={setPhone}
          isSubmitted={isSubmitted}
          setIsSubmitted={setIsSubmitted}
          isLoading={isLoading}
          messageCategoryId={messageCategoryId}
          setMessageCategoryId={setMessageCategoryId}
          setMessageCategory={setMessageCategory}
          handleSubmit={handleSubmit}
        />
      </CompactColumn>
    </Column>
  );
};

export default Feedback;
