import { Address, ContractInfo, ContractStatus, UiTexts, Order, SharedContainerProduct } from '../../../model';

/**
 * Prints address in fortmat: "Katu 2, 00200 Helsinki".
 * @param address
 * @returns address string or null if given address is undefined.
 */
export const printAddress = (address?: Address): string | null => {
  if (address) {
    return `${address.street}, ${address.postalCode} ${address.postOffice}`;
  }
  return null;
};

export type ContractData = {
  contractsWaitingForApproval: ContractInfo[];
  continuingContracts: ContractInfo[];
};

/**
 * Filters and splits contracts to
 *  - contracts waiting for approval
 *  - approved continuing contracts
 *  - approved shared container contracts
 * And returns only on going contracts.
 * @param contracts Unfiltered contracts from backend
 * @returns ContractData
 */
export const getOnGoingContractData = (contracts?: ContractInfo[]): ContractData => {
  const continuing = getApprovedContracts(contracts);
  const waiting = getContractsByStatuses(['Waiting'], contracts);
  return {
    contractsWaitingForApproval: getOnGoingContracts(waiting),
    continuingContracts: getOnGoingContracts(continuing),
  };
};

export type ServiceListState = {
  showList: boolean;
  itemCount: number | null;
};

/**
 * Returns item count and show/hide state for a service list (contracts or orders)
 *
 * If service list is empty -> list should be hidden.
 * If screen width is small (mobile) -> item count should be hidden
 * @param serviceList
 * @param screenLargerThanPhone
 * @returns Service list container state. itemCount as null if count should be hidden.
 */
export const getServiceListContainerState = (
  serviceList: ContractInfo[] | Order[] | SharedContainerProduct[],
  screenLargerThanPhone: boolean
): ServiceListState => {
  return {
    showList: serviceList.length > 0,
    itemCount: screenLargerThanPhone ? serviceList.length : null,
  };
};

/**
 * Printing emptying interval number.
 * Copied base solution from ols implementation (src/util/util.ts).
 * @param getText translator function
 * @param interval interval number
 * @param amountPerWeek emptying amount per week
 * @returns Interval number as descriptive readable string.
 */
export const printInterval = (
  getText: (key: keyof UiTexts, params?: string[]) => string,
  interval: number,
  amountPerWeek: number
): string => {
  if (interval === 0) {
    return getText('contract-interval-on-demand');
  }
  if (interval === 1) {
    if (amountPerWeek > 1) {
      return `${amountPerWeek} ${getText('contract-interval-times-per-week')}`;
    } else {
      return getText('contract-interval-once-a-week');
    }
  } else {
    switch (amountPerWeek) {
      case 1:
        return getText('contract-interval-every-x-weeks', [interval.toString()]);
      default:
        return getText('contract-interval-multiple-times-per-week', [amountPerWeek.toString(), interval.toString()]);
    }
  }
};

// TODO: Move to a more general util file if needed in other places too.
// Is this ok format also when selected language is en or sv?
export const getNextEmptyingDate = (date: Date): string => {
  return new Intl.DateTimeFormat('fi-FI').format(new Date(date));
};

// Private helper functions.
const getApprovedContracts = (contractInfos?: ContractInfo[]): ContractInfo[] => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const approvedContracts = getContractsByStatuses(['Unspecified', 'Approved'], contractInfos);

  return approvedContracts.filter((c) => !c.sharedContainer);
};

const getContractsByStatuses = (statuses: ContractStatus[], contracts?: ContractInfo[]): ContractInfo[] =>
  contracts ? contracts.filter((c) => statuses.includes(c.status)) : [];

const getOnGoingContracts = (contracts: ContractInfo[]): ContractInfo[] => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  return contracts.filter((c) => c.endDate == null || new Date(c.endDate).getTime() > currentDate.getTime());
};
