import React, { useContext } from 'react';
import {
  Column,
  CompactColumn,
  Content,
  RowWideContent,
} from '../../common/containers';
import Header from '../../common/Header';
import VingoButton from '../../common/VingoButton';
import OrderButton from './OrderButton';
import { LanguageContext } from '../../../contexts/language-context';
import { Product } from '../../../model';
import { SecondaryHeader } from '../../common/headers';
import BasketItem from './BasketItem';
import TotalPrices from './TotalPrices';
import { device } from '../../common/devices';
import styled from 'styled-components';

const StickersButtonWrapper = styled.div`
  @media ${device.desktop} {
    min-width: 15rem;
    margin-bottom: 1rem;
  }
  @media ${device.mobile} {
    width: 100%;
  }
`;

type BasketProps = {
  products: Product[];
  addProductAmount: (id: string, addedAmount: number) => void;
  removeProductFromBasket: (id: string) => void;
  backToShopping: () => void;
  gotoSummaryPage: () => void;
};

const Basket = (props: BasketProps) => {
  const {
    products,
    addProductAmount,
    removeProductFromBasket,
    backToShopping,
    gotoSummaryPage,
  } = props;
  const { getText } = useContext(LanguageContext);

  const productsInBasket = products.filter((p) => p.amount > 0);

  return (
    <Column style={{ marginBottom: '4rem' }}>
      <Header
        headerKey="service-order-basket"
        descriptionKey="service-order-basket-description"
        backButton={true}
        backAction={backToShopping}
      ></Header>
      <RowWideContent>
        {getText('service-order-products-in-basket', [
          productsInBasket.length.toString(),
        ])}
        <StickersButtonWrapper>
          <VingoButton secondary={true} onClick={backToShopping}>
            {getText('service-order-continue-shopping')}
          </VingoButton>
        </StickersButtonWrapper>
      </RowWideContent>
      <CompactColumn>
        <Content>
          <SecondaryHeader>{getText('service-order-basket')}</SecondaryHeader>
          {productsInBasket.map((product, index) => (
            <BasketItem
              product={product}
              addProductAmount={addProductAmount}
              removeProductFromBasket={removeProductFromBasket}
              key={index}
            />
          ))}
        </Content>
        <TotalPrices products={products} />
        <OrderButton label="service-order-button" action={gotoSummaryPage} />
      </CompactColumn>
    </Column>
  );
};

export default Basket;
