import React from 'react';
import {
  BasketItemAmount,
  BasketItemContent,
  BasketItemName,
  BasketProductImage,
  Row,
  SpacedRow,
} from '../../common/containers';
import { Product } from '../../../model';
import { ReactComponent as TrashIcon } from '../../../assets/trash-2.svg';
import { AmountChangeButton, IconButton } from '../../common/inputs';
import { ConvertToCurrentLocale } from '../../../util/numberLocalization'

type BasketItemProps = {
  product: Product;
  addProductAmount?: (id: string, addedAmount: number) => void;
  removeProductFromBasket?: (id: string) => void;
};

const BasketItem = (props: BasketItemProps) => {
  const { product, addProductAmount, removeProductFromBasket } = props;

  return (
    <Row style={{ marginBottom: '1rem' }}>
      <BasketProductImage />
      <BasketItemContent>
        <SpacedRow>
          <BasketItemName>{`${product.name}, ${product.info}`}</BasketItemName>
          {removeProductFromBasket ? (
            <IconButton onClick={() => removeProductFromBasket(product.id)}>
              <TrashIcon />
            </IconButton>
          ) : null}
        </SpacedRow>
        <span>
          {product.amount} x {ConvertToCurrentLocale(product.price,2)} &euro;
        </span>

        {addProductAmount ? (
          <Row style={{ marginTop: '0.5rem' }}>
            <AmountChangeButton
              disabled={product.amount === 1}
              onClick={() => addProductAmount(product.id, -1)}
            >
              &#65293;
            </AmountChangeButton>
            <BasketItemAmount>{product.amount}</BasketItemAmount>
            <AmountChangeButton onClick={() => addProductAmount(product.id, 1)}>
              &#65291;
            </AmountChangeButton>
          </Row>
        ) : null}
      </BasketItemContent>
    </Row>
  );
};

export default BasketItem;
