import React from 'react';
import { Box, Stack, useTheme, Typography, Divider, Container, useMediaQuery } from '@mui/material';
import { useContext } from 'react';
import { LanguageContext } from '../../contexts/language-context';
import { UiTexts } from '../../model';

export enum Phase {
  Billing = 'Billing',
  Location = 'Location',
  Summary = 'Summary',
  SelectProduct = 'SelectProduct',
  FinishOrder = 'FinishOrder',
  PropertyWaterSupply = 'PropertyWaterSupply',
  WasteWaterTreatment = 'WasteWaterTreatment',
  WasteWaterReportSummary = 'WasteWaterReportSummary',
  SelectRegisterType = 'SelectRegisterType',
  CompleteNewCustomerRegistration = 'CompleteNewCustomerRegistration',
  CompleteExistingCustomerRegistration = 'CompleteExistingCustomerRegistration',
  CompleteExistingCustomerSamlRegistration = 'CompleteExistingCustomerSamlRegistration',
}

const getPhaseNameUiTextKey = (phase: Phase): keyof UiTexts => {
  switch (phase) {
    case Phase.Billing:
      return 'add-emptying-location-billing-data';
    case Phase.Location:
      return 'add-emptying-location-data';
    case Phase.Summary:
      return 'add-emptying-location-summary';
    case Phase.SelectProduct:
      return 'emptying-location-order-product-select-phase';
    case Phase.FinishOrder:
      return 'emptying-location-order-product-finish-phase';
    case Phase.PropertyWaterSupply:
      return 'well-collection-property-water-supply';
    case Phase.WasteWaterTreatment:
      return 'well-collection-waste-water-treatment';
    case Phase.WasteWaterReportSummary:
      return 'well-collection-waste-water-report-summary';
    case Phase.SelectRegisterType:
      return 'select-register-type';
    case Phase.CompleteNewCustomerRegistration:
      return 'complete-registration';
    case Phase.CompleteExistingCustomerRegistration:
      return 'complete-registration';
    case Phase.CompleteExistingCustomerSamlRegistration:
      return 'complete-registration';
  }
};

interface Props {
  phases: Phase[];
  currentPhase: Phase;
  setCurrentPhase: (value: React.SetStateAction<Phase>) => void;
}

const PhaseBar = ({ phases, currentPhase, setCurrentPhase }: Props): JSX.Element => {
  const theme = useTheme();
  const { palette } = useTheme();
  const { getText } = useContext(LanguageContext);
  const largerThanPhone = useMediaQuery(theme.breakpoints.up('md'));

  const currentPhaseIndex = phases.findIndex((phase) => phase === currentPhase);

  const renderWideScreen = () => {
    return (
      <Stack id='phase-bar-element' pt={2} pb={2} direction='row' alignItems='center' spacing={1}>
        {phases.map((phase, index) => (
          <React.Fragment key={index}>
            <PhaseCircle
              value={index + 1}
              strongColor={currentPhase === phase}
              isCompleted={currentPhaseIndex > index}
            />
            <PhaseTitle phase={phase} />
            {index + 1 < phases.length && <PhaseDivider />}
          </React.Fragment>
        ))}
      </Stack>
    );
  };

  const renderMobile = () => {
    return (
      <Stack id='phase-bar-element' pt={2} pb={2} direction='row' alignItems='center' spacing={0.5}>
        <Typography variant='subtitle1'>
          {getText('phase-bar-step')} {phases.findIndex((phase) => phase === currentPhase) + 1}/3:{' '}
          {getText(getPhaseNameUiTextKey(currentPhase))}
        </Typography>
      </Stack>
    );
  };

  return (
    <>
      <Divider />
      <Box bgcolor={palette.background.level1}>
        <Container sx={{ ...theme.components?.MuiContainer?.defaultProps?.sx, py: 0 }}>
          {largerThanPhone ? renderWideScreen() : renderMobile()}
        </Container>
      </Box>
      <Divider />
    </>
  );
};

interface PhaseCircleProps {
  value: number;
  strongColor: boolean;
  isCompleted: boolean;
}

const PhaseCircle = ({ value, strongColor, isCompleted }: PhaseCircleProps) => {
  const { palette } = useTheme();

  const bgColor = strongColor ? palette.primary.main : palette.primary.mainVariant15;
  const textColor = strongColor ? palette.text.inverse : palette.primary.main;

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      bgcolor={bgColor}
      minHeight='28px'
      minWidth='28px'
      borderRadius='14px'
    >
      {isCompleted ? <CheckedMark /> : <Typography color={textColor}>{value.toString()}</Typography>}
    </Box>
  );
};

const PhaseTitle = ({ phase }: { phase: Phase; }): JSX.Element => {
  const { getText } = useContext(LanguageContext);

  return (
    <Typography variant='subtitle1' noWrap>
      {getText(getPhaseNameUiTextKey(phase))}
    </Typography>
  );
};

const CheckedMark = (): JSX.Element => {
  return (
    <svg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M13 1.5L4.75 9.75L1 6' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

const PhaseDivider = (): JSX.Element => {
  return (
    <Box px={1}>
      <svg width='6' height='12' viewBox='0 0 6 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M0.75 10.5L5.25 6L0.75 1.5'
          stroke='#99999F'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Box>
  );
};

export default PhaseBar;
