import React, { useState } from 'react';
import { Product, ProductCategory } from '../../../model';
import Shopping from './Shopping';
import Basket from './Basket';
import OrderSummary from './OrderSummary';

// TODO fetch products from backend
// TODO Change Product type definition to match that data

type StickersProps = {
  match: {
    params: {
      id: string;
    };
  };
  location: {
    state: { productCategory: ProductCategory };
  };
};

const testProducts: Product[] = [
  {
    id: '21wqwdqwd',
    name: 'Biojäte tarra',
    info: 'koko 21x21cm',
    price: 15.5,
    amount: 0,
    alv: 1.7,
  },
  {
    id: 'gdf3w33',
    name: 'Tarra kartonki',
    info: 'koko 25x25cm',
    price: 20,
    amount: 0,
    alv: 2,
  },
  {
    id: 'qwertyui23456',
    name: 'Tarra',
    info: 'koko 5x5cm',
    price: 3.9,
    amount: 0,
    alv: 0.5,
  },
  {
    id: '21wqwewewdqwd',
    name: 'Biojäte tarra',
    info: 'koko 21x21cm',
    price: 15.5,
    amount: 0,
    alv: 1.7,
  },
  {
    id: 'gdf3wewew3ee3',
    name: 'Tarra kartonki',
    info: 'koko 25x25cm',
    price: 20,
    amount: 0,
    alv: 2,
  },
  {
    id: 'qwqertyui23we456',
    name: 'Tarra',
    info: 'koko 5x5cm',
    price: 3.9,
    amount: 0,
    alv: 0.5,
  },
  {
    id: '21whhhhhqwdqwd',
    name: 'Biojäte tarra',
    info: 'koko 21x21cm',
    price: 15.5,
    amount: 0,
    alv: 1.7,
  },
  {
    id: 'gdfqggrr3w33',
    name: 'Tarra kartonki',
    info: 'koko 25x25cm',
    price: 20,
    amount: 0,
    alv: 2,
  },
  {
    id: 'ewewqwertyui23456',
    name: 'Tarra',
    info: 'koko 5x5cm',
    price: 3.9,
    amount: 0,
    alv: 0.5,
  },
];

type Page = 'shopping' | 'basket' | 'summary';

const Stickers = (props: StickersProps) => {
  const { productCategory } = props.location.state;

  const [page, setPage] = useState<Page>('shopping');
  const [products, setProducts] = useState<Product[]>(testProducts);

  const addProductAmount = (id: string, addedAmount: number) => {
    const newProducts: Product[] = products.map((p) => {
      if (p.id === id) {
        p.amount = p.amount + addedAmount;
      }
      return p;
    });
    setProducts(newProducts);
  };

  const setProductAmountToZero = (id: string): void => {
    const newProducts: Product[] = products.map((p) => {
      if (p.id === id) {
        p.amount = 0;
      }
      return p;
    });
    setProducts(newProducts);
  };

  const clearBasket = () => {
    const newProducts: Product[] = products.map((p) => {
      p.amount = 0;
      return p;
    });
    setProducts(newProducts);
  };

  switch (page) {
    case 'shopping':
      return (
        <Shopping
          productCategory={productCategory}
          products={products}
          addProductAmount={addProductAmount}
          gotoBasket={() => setPage('basket')}
        />
      );

    case 'basket':
      return (
        <Basket
          products={products}
          addProductAmount={addProductAmount}
          removeProductFromBasket={setProductAmountToZero}
          backToShopping={() => setPage('shopping')}
          gotoSummaryPage={() => setPage('summary')}
        />
      );
    case 'summary':
      return (
        <OrderSummary
          products={products}
          backToShopping={() => {
            clearBasket();
            setPage('shopping');
          }}
        />
      );
  }
};

export default Stickers;
