import styled from 'styled-components';
import colors from '../../colors';
import { CONTENT_MARGIN } from '../../styleConstants';

export const PrimaryHeader = styled.h1`
  font-family: SpaceGrotesk-Medium;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 500;
  margin-top: 0rem;
  color: ${colors.header};
`;

export const SecondaryHeader = styled.h2`
  font-family: SpaceGrotesk-Medium;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  color: ${colors.secondaryHeader};
  overflow-wrap: anywhere;
`;

export const TertiaryHeader = styled.h3`
  font-family: SpaceGrotesk-Medium;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  color: ${colors.secondaryHeader};
`;

export const SmallHeader = styled.h4`
  font-family: SpaceGrotesk-Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  color: ${colors.lightLabel};
  margin-top: 0;
  margin-bottom: 0;
  overflow-wrap: anywhere;
`;


