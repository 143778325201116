import { Container } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  isLoading: boolean;
  skeleton: JSX.Element;
  children: ReactNode;
  containerEnabled?: boolean;
};

/**
 * 
 * @param isLoading Loading state, returns content if false.
 * @param skeleton Loading elements to return.
 * @param children Content to return.
 * @param containerEnabled  (optional) if \<Container\> tags are returned, default true
 */

const ContentLoadingHandler = ({ isLoading, skeleton, children, containerEnabled }: Props): JSX.Element => {
  const containerTagsEnabled = containerEnabled ?? true;

  function renderContent() {
    if (!isLoading) {
      return children;
    }
    return skeleton;
  }

  return (
    <>
      {
        containerTagsEnabled
          ?
          <Container>
            {renderContent()}
          </Container>
          :
          renderContent()
      }
    </>
  );
};
export default ContentLoadingHandler;;