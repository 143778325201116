import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { CompactButton, PrimaryButton } from './inputs';
import colors from '../../colors';
import { ReactComponent as Loader } from '../../assets/loader.svg';
import AuthContext from '../../contexts/auth-context';

type VingoButtonProps = {
  onClick?: any;
  disabled?: boolean;
  isLoading?: boolean;
  compact?: boolean;
  minWidth?: string;
  secondary?: boolean;
  children: ReactNode;
};

const VingoButton = (props: VingoButtonProps): JSX.Element => {

  const authService = useContext(AuthContext);
  const [buttonColor, setButtonColor] = useState<string | undefined>();
  const {
    onClick,
    disabled,
    isLoading,
    compact,
    minWidth,
    secondary,
    children,
  } = props;

  const isDisabled = disabled || isLoading;

  useEffect(() => {
    let color = colors.primaryButton;
    if (secondary) {
      color = colors.secondaryButton;
      if (isDisabled) {
        color = color + '88';
      }
      setButtonColor(color);
    }
    else {
      authService.getCompanyInfo().then((companyInfo) => {
        if (companyInfo?.color) {
          color = companyInfo.color;
        }
        if (isDisabled) {
          color = color + '88';
        }
        setButtonColor(color);
      });
    }
  }, [authService, isDisabled, secondary]);

  if (!buttonColor) {
    return <></>; // to prevent color flickering
  }

  let style = {
    backgroundColor: buttonColor,
    ...(minWidth ? { minWidth } : {}),
  };


  if (compact) {
    return (
      <CompactButton style={style} onClick={onClick} disabled={disabled}>
        {children}
      </CompactButton>
    );
  }
  return (
    <PrimaryButton style={style} onClick={onClick} disabled={isDisabled}>
      {isLoading ? (
        <Loader
          width="24"
          height="24"
          className="rotate"
          color={colors.primaryButtonFont}
        />
      ) : (
        children
      )}
    </PrimaryButton>
  );
};

export default VingoButton;
