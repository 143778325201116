import React, { useContext, useState, ReactNode, useEffect } from 'react';

import { LanguageContext } from '../../contexts/language-context';
import { PrimaryHeader } from './headers';
import { HeaderContainer, Row, HeaderDescription, PrimaryHeaderNote } from './containers';
import { UiTexts } from '../../model';
import MobileHeader from './header-top/MobileHeader';
import DesktopHeader from './header-top/DesktopHeader';
import { rawSize } from './devices';

type HeaderProps = {
  headerKey?: keyof UiTexts;
  descriptionKey?: keyof UiTexts;
  headerElement?: JSX.Element;
  descriptionElement?: JSX.Element;
  backButton?: boolean;
  backAction?: () => void;
  children?: ReactNode;
  noteKey?: keyof UiTexts;
  noteElement?: JSX.Element;
};

const Header = (props: HeaderProps): JSX.Element => {
  const { getText } = useContext(LanguageContext);

  const {
    headerKey,
    descriptionKey,
    headerElement,
    descriptionElement,
    backButton,
    backAction,
    children,
    noteKey,
    noteElement
  } = props;
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  function getWindowDimensions() {
    const { innerWidth: dWidth, innerHeight: dHeight } = window;
    return {
      dWidth,
      dHeight,
    };
  }

  function isDesktop(): boolean {
    return windowDimensions.dWidth > rawSize.mobile;
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <HeaderContainer>
      <Row>
        {isDesktop() ? (
          <DesktopHeader />
        ) : (
          <MobileHeader
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            backButton={backButton}
            backAction={backAction}
          />
        )}
      </Row>
      <PrimaryHeaderNote>
        {noteKey ? getText(noteKey) : noteElement}
      </PrimaryHeaderNote>
      <PrimaryHeader>
        {headerKey ? getText(headerKey) : headerElement}
      </PrimaryHeader>
      <HeaderDescription>
        {descriptionKey ? getText(descriptionKey) : descriptionElement}
      </HeaderDescription>
      {children}
    </HeaderContainer>
  );
};

export default Header;
