import styled from 'styled-components';
import colors from '../../colors';
import {
  MENU_TRANSPARENT_PART_WIDTH,
  MENU_TRANSITION,
  CONTENT_MARGIN,
} from '../../styleConstants';
import { device } from './devices';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

`;

export const SpacedRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
`;

export const IntervalRow = styled(Row)`
  align-items: center;
  margin-bottom: 1rem;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const HalfScreenColumn = styled(Column)`
  width: 50vw;
`;

export const RightUpperCornerContainer = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  text-align: right;
`;

export const MenuMask = styled(Row)`
  position: fixed;
  right: calc(0px - ${CONTENT_MARGIN});
  top: calc(0px - ${CONTENT_MARGIN});
  height: calc(100vh + ${CONTENT_MARGIN});
  width: calc(100vw + ${CONTENT_MARGIN});
  transition: ${MENU_TRANSITION};
`;

export const MenuContent = styled(Column)`
  font-weight: bold;
  padding-top: 5rem;
  padding-left: 1.5rem;
  background-color: ${colors.contentBackground};
  width: calc(100vw - ${MENU_TRANSPARENT_PART_WIDTH});
  transform: translateX(100%);
  transition: ${MENU_TRANSITION};
`;

export const MenuLowerPart = styled(Column)`
  justify-content: flex-end;
  height: 100%;
  padding-bottom: 50px;
`;

export const HeaderContainer = styled(Column)`
  background-color: ${colors.contentBackground};
  padding: ${CONTENT_MARGIN};
  border-bottom: solid 1px #e8e8e8;
  margin-bottom: 0rem;

  @media ${device.desktop}{
    padding-left: 10rem;
    padding-right: 10rem;
  }
  @media ${device.mobile}{
    padding-left: ${CONTENT_MARGIN};
    padding-right: ${CONTENT_MARGIN};
  }
`;

export const HeaderItem = styled.div`
  display: flex;
  flex-basis: calc(100% / 3);
  flex-grow: 0;
`;

export const HeaderItemLeft = styled(HeaderItem)`
  justify-content: flex-start;

`;

export const HeaderItemCenter = styled(HeaderItem)`
  justify-content: center;
  max-height: 54px;
  overflow-y: visible;
`;

export const HeaderItemRight = styled(HeaderItem)`
  justify-content: flex-end;
`;

export const HeaderLogo = styled.img`
  @media ${device.desktop}{
    max-height: 108px;
    min-height: 108px;
    min-width: 108px;
  }
  @media ${device.mobile}{
    max-height: 54px;
    min-height: 54px;
    min-width: 54px;
  }
  
  max-width: 100%;
  margin-right: 1rem;
`;

export const Languages = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 50px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
`;

export const HeaderDescription = styled.div`
  color: ${colors.secondaryHeader};
  padding-bottom: 1rem;
`;

export const WideContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.contentBackground};
  padding-top: 1rem;
  padding-left: ${CONTENT_MARGIN};
  padding-right: ${CONTENT_MARGIN};
  padding-bottom: 1rem;
  border-bottom: solid 1px ${colors.contentBorder};
`;

export const MainWideContent = styled(WideContent)`
  @media ${device.desktop}{
    padding-left: 10rem;
    padding-right: 10rem;
  }
  @media ${device.mobile}{
    padding-left: ${CONTENT_MARGIN};
    padding-right: ${CONTENT_MARGIN};

  }
`;

export const RowWideContent = styled(MainWideContent)`
  @media ${device.desktop} {
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
  }
  @media ${device.mobile} {
    flex-direction: column;
    margin-left: 0;
  }
`;

export const Content = styled(WideContent)`
  padding-top: 0;
  margin-top: ${CONTENT_MARGIN};
  margin-bottom: 0;
  margin-left: ${CONTENT_MARGIN};
  margin-right: ${CONTENT_MARGIN};
  border: solid 1px ${colors.contentBorder};
`;

export const ObsoliteContent = styled(WideContent)`
  padding-top: 0;
  margin-top: ${CONTENT_MARGIN};
  margin-bottom: 0;
  margin-left: ${CONTENT_MARGIN};
  margin-right: ${CONTENT_MARGIN};
  border: solid 1px ${colors.contentBorder};
  background: #dddddd;
`;

export const AttachedContent = styled(Content)`
  border-top: 0;
  margin-top: 0;
  padding-bottom: 0.15rem;
`;

export const ContentNoBackground = styled(WideContent)`
  background-color: rgba(0, 0, 0, 0);
  border: none;
`;

export const ContentRow = styled.span`
  padding-bottom: 0.5rem;
`;

export const TextContent = styled.div`
  color: ${colors.textContent};
`;

export const FeedbackTextContent = styled(TextContent)`
  margin-bottom: 1.8rem;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const FormTop = styled(Content)`
  margin-top: 1rem;
  margin-bottom: 0;
  padding-bottom: 0;
`;

export const ColumnTop = styled(Content)`
  margin-top: 1rem;
  margin-bottom: 0;
  padding-bottom: 0;
`;

export const FormContent = styled(Content)`
  margin-top: 0;
  border-top: 0;
  margin-bottom: 0;
`;

export const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.infoContentBackground};
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  margin-left: ${CONTENT_MARGIN};
  margin-right: ${CONTENT_MARGIN};
  border: solid 1px ${colors.infoContentBorder};
`;

export const InfoContetAttached = styled(InfoContent)`
  border-top: 0;
`;

export const OrderRowItem = styled.div`
  font-size: 0.875rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
`;

export const StatusInfo = styled.div<{orderStatus?: string}>`
  font-size: 0.875rem;
  color: ${colors.statusInfoText};
  background-color: ${props => props.orderStatus === 'Done' ? colors.statusInfoDone : colors.statusInfo};
  border: solid 1px ${props => props.orderStatus === 'Done' ? colors.statusInfoDoneBorder : colors.statusInfoBorder};
  padding: 0.4rem 0 0.4rem 0.9rem;
  margin-bottom: 0.6rem;
`;

export const EmptyingInfoHeader = styled.div`
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  color: ${colors.lightLabel};
`;

export const EmptyingInfoName = styled.div`
font-family: SpaceGrotesk-Regular;
font-size: 0.875rem;
font-style: normal;
font-weight: 500;
color: ${colors.lightLabel};
margin-top: 0;
margin-bottom: 0;
`;

export const EmptyingInfoAddres = styled.div`
  color: ${colors.secondaryHeader};
  font-size: 1.25rem;
`;

export const SearchBar = styled.div`
  margin-top: 0.5rem;
  margin-left: ${CONTENT_MARGIN};
  margin-right: ${CONTENT_MARGIN};
`;

export const SpinnerContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
`;

export const ProductCategoryItem = styled.div`
  background-color: ${colors.contentBackground};
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: solid 1px ${colors.contentBorder};
`;

export const ProductContent = styled(Content)`
  max-width: 311px;
  margin-top: ${CONTENT_MARGIN};
  margin-bottom: 0;
`;

export const ProductImageContainer = styled.div`
  position: relative;
  min-height: 6.3rem;
`;

export const ProductImageInfo = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: calc(100% - 2rem);
  padding: 1rem;
  background-color: ${colors.infoContentBackground};
  border: solid 1px ${colors.infoContentBackground};
`;

export const ProductImage = styled.img`
  width: 100%;
  max-width: 311px;
  margin-top: 1rem;
  height: auto;
  background-color: ${colors.bodyBackground};
  border: solid 1px ${colors.contentBorder};
`;

export const BasketProductImage = styled.img`
  min-width: 80px;
  max-width: 80px;
  min-height: 80px;
  max-height: 80px;
  border: solid 1px ${colors.contentBorder};
`;

export const ProductInfo = styled.div`
  font-size: 1rem;
  color: ${colors.lightLabel};
  margin-bottom: 0.2rem;
`;

export const ProductPrice = styled.div`
  font-size: 1.25rem;
  color: ${colors.secondaryHeader};
`;

export const ProductPriceInfo = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 0.875rem;
  color: ${colors.lightLabel};
  margin-left: 0.35rem;
  padding-bottom: 0.1rem;
`;

export const BasketItemContent = styled(Column)`
  margin-left: ${CONTENT_MARGIN};
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const BasketItemName = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 0.5rem;
  color: ${colors.lightLabel};
`;

export const BasketItemAmount = styled.div`
  padding: 0.25rem 0.75rem;
  text-align: center;
  width: 2rem;
`;

export const BasketTotalPrice = styled.div`
  font-size: 1.25rem;
`;

export const ValidationError = styled.div`
  background-color: ${colors.errorBackground};
  color: ${colors.errorText};
  padding: 0.4rem;
  font-size: 0.8rem;
  margin-top: 0.4rem;
`;


export const CompactColumn = styled(Column)`
  max-width: 540px;
  width: 100%;
  align-self: center;
`;

export const PrimaryHeaderNote = styled.div`
    font-family: SpaceGrotesk-Medium;
    //font-size: 1.875rem;
    font-style: normal;
    font-weight: 500;
    margin-top: 2rem;
`;
