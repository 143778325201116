import { TableCell, TableRow, Typography, useTheme } from '@mui/material';
import { EmptyingInfo, FeedbackInfo } from '../../model';
import { printddMMDate } from '../../util/calendarUtil';

interface CustomerServiceFeedbackRowProps {
  feedback: FeedbackInfo;
  locations: EmptyingInfo[] | null | undefined;
}

export const CustomerServiceFeedbackRow = ({ feedback, locations }: CustomerServiceFeedbackRowProps) => {
  const { palette } = useTheme();

  const cellStyle = {
    borderBottom: `1px solid ${palette.divider}`,
  };

  const currentLocation = locations?.find((location: EmptyingInfo) => location.id === feedback.emptyingInfoId);

  return (
    <TableRow>
      <TableCell
        align='left'
        sx={{
          ...cellStyle,
          paddingLeft: '10px',
        }}
      >
        <Typography variant='body1'>{feedback.header}</Typography>
      </TableCell>

      <TableCell align='left' sx={cellStyle}>
        <Typography
          variant='body1'
          color='textSecondary'
          noWrap
          sx={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '15rem' }}
        >
          {feedback.message}
        </Typography>
      </TableCell>

      <TableCell align='left' sx={cellStyle}>
        <Typography variant='body1' color='textSecondary'>
          {currentLocation && `${currentLocation.address.street} ${currentLocation.address.postOffice}`}
        </Typography>
      </TableCell>

      <TableCell align='left' sx={cellStyle}>
        <Typography variant='body1' color='textSecondary'>
          {printddMMDate(feedback.date)}
        </Typography>
      </TableCell>
    </TableRow>
  );
};
