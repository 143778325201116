import { useContext } from 'react';
import { Control, FieldErrors, useWatch } from 'react-hook-form';
import { Stack } from '@mui/material';
import { LanguageContext } from '../../../../../../contexts/language-context';
import ControlDropdownInput from '../../../../../../components/common-materialui/form/ControlDropdownInput';
import ControlTextInput from '../../../../../../components/common-materialui/form/ControlTextInput';
import { GrayWaterForm, WeeklyFrequencyEnum } from '../../models';

interface Props {
  control: Control<GrayWaterForm, any>;
  errors: FieldErrors<GrayWaterForm>;
}

const GrayWater = ({ control, errors }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);

  const weeklyFrequencyOption = useWatch({
    control,
    name: 'grayWater.weeklyFrequency',
  });

  const weeklyFrequency = [
    { text: getText('need-for-emptying-weekly-frequency-year'), value: WeeklyFrequencyEnum.EveryYear },
    {
      text: getText('need-for-emptying-weekly-frequency-two-year'),
      value: WeeklyFrequencyEnum.EverySecondYear,
    },
    {
      text: getText('need-for-emptying-weekly-frequency-three-year'),
      value: WeeklyFrequencyEnum.EveryThirdyear,
    },
    {
      text: getText('need-for-emptying-weekly-frequency-other'),
      value: WeeklyFrequencyEnum.Other,
    },
  ];

  return (
    <Stack direction={'column'}>
      <ControlDropdownInput
        control={control}
        error={errors?.grayWater?.weeklyFrequency}
        validations={['required']}
        dropdownItems={weeklyFrequency}
        label={'need-for-emptying-weekly-frequency-dropdown-label'}
        name={'grayWater.weeklyFrequency'}
      />
      {weeklyFrequencyOption === WeeklyFrequencyEnum.Other && (
        <ControlTextInput
          control={control}
          error={errors?.grayWater?.other}
          label='need-for-emptying-other-input-label'
          name='grayWater.other'
          validations={['required']}
        />
      )}
    </Stack>
  );
};

export default GrayWater;
