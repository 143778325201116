import React from 'react';
import { UiTexts } from '../model';

const ApiCallHandler = (
  apiMethod: Promise<any>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>> | null,
  componentIsMounted: React.MutableRefObject<boolean> | null,
  showDialog: any,
  successDialogTitle: keyof UiTexts ,
  successDialogText: keyof UiTexts,
  runAfterSuccess: any | null,
  history: any
): any | void => {

  apiMethod
    .then(() => {
      if (runAfterSuccess != null) {
        showDialog(
          successDialogTitle,
          successDialogText,
          runAfterSuccess
        );
      }
      else {
        showDialog(
          successDialogTitle,
          successDialogText,
        );
      }
    })
    .catch((err: any) => {
      if (err.statusCode === 401) {
        showDialog(
          'error-unauthorized-title',
          'error-unauthorized-message',
          () => history.replace('/logout')
        );
      }
      else {
        showDialog("error-service-break-title", "error-service-break-message");
      }
    })
    .finally(() => {
      if (componentIsMounted != null && componentIsMounted.current && setIsLoading != null) {
        setIsLoading(false);
      }
    });
};

export default ApiCallHandler;
