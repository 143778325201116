import { useContext, useEffect, useState } from 'react';
import { Stack, Container, Box, Button, Divider, Typography } from '@mui/material';
import { LanguageContext } from '../../../../contexts/language-context';
import { overlayDialogPadding } from '../../../../components/common-materialui/OverlayDialog';
import { LargerThanBreakpoint } from '../../../../util/viewportUtils';
import { WIDTH_DESKTOP, WIDTH_MOBILE } from '../../../account-settings/models';
import ControlDropdownInput from '../../../../components/common-materialui/form/ControlDropdownInput';
import ControlCheckboxInput from '../../../../components/common-materialui/form/ControlCheckboxInput';
import { useForm, useWatch } from 'react-hook-form';
import {
  HouseHoldWaterInfo,
  HouseHoldWaterInfoFormState,
  WellCollectionEquipment,
  WellCollectionHouseholdWaterSource,
  WellCollectionWCType,
} from './models';
import _ from 'lodash';

interface Props {
  onNextPhaseChange: () => void;
  houseHoldWaterInfo: HouseHoldWaterInfo;
  updateHouseHoldWaterInfo: (newHouseHoldWaterInfo: HouseHoldWaterInfo) => void;
  resetWasteWaterTreatmentValues: () => void;
}

const defaultPropertyWaterSupplyFormValues = {
  wcType: '',
  householdWaterSource: '',
  equipments: { shower: false, dishwasher: false, washingMachine: false },
};

const getObjectValuesAsEnum = <T extends Record<string, boolean>>(
  obj: T,
  enumType: Record<string, string>
): string[] => {
  return Object.keys(obj)
    .filter((key) => obj[key as keyof T])
    .map((key) => enumType[key.charAt(0).toUpperCase() + key.slice(1)]);
};

const getEnumAsObject = <T extends string>(enumValues: T[]): Record<T, boolean> => {
  const enumObject: Record<T, boolean> = {} as Record<T, boolean>;
  enumValues.forEach((value) => {
    const lowercaseKey = value.charAt(0).toLowerCase() + value.slice(1);
    enumObject[lowercaseKey as T] = true;
  });
  return enumObject;
};

const PropertyWaterSupply = ({
  onNextPhaseChange,
  houseHoldWaterInfo,
  updateHouseHoldWaterInfo,
  resetWasteWaterTreatmentValues,
}: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const orientation = LargerThanBreakpoint('md') ? 'row' : 'column';
  const titleTypographyVariant = LargerThanBreakpoint('lg') ? 'h5' : 'h6';
  const width = LargerThanBreakpoint('md') ? WIDTH_DESKTOP : WIDTH_MOBILE;
  const [houseHoldWaterInfoFormData, setHouseHoldWaterInfoFormData] = useState<HouseHoldWaterInfoFormState>(
    defaultPropertyWaterSupplyFormValues
  );

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: houseHoldWaterInfoFormData });

  const wcTypeOption = useWatch({
    control,
    name: 'wcType',
  });

  const householdWaterSourceOption = useWatch({
    control,
    name: 'householdWaterSource',
  });

  const wcTypeItems = [
    { text: getText('well-collection-property-water-supply-water-wc'), value: WellCollectionWCType.WaterWC },
    {
      text: getText('well-collection-property-water-supply-non-water-wc'),
      value: WellCollectionWCType.DryCompostFreezingOrBurning,
    },
  ];

  const householdWaterSourceItems = [
    {
      text: getText('well-collection-property-water-supply-household-water-pressure'),
      value: WellCollectionHouseholdWaterSource.PressureWater,
    },
    wcTypeOption !== 'WaterWC'
      ? {
          text: getText('well-collection-property-water-supply-household-water-carried'),
          value: WellCollectionHouseholdWaterSource.CarriedWater,
        }
      : undefined,
  ];

  useEffect(() => {
    if (wcTypeOption === 'WaterWC') {
      setValue('householdWaterSource', WellCollectionHouseholdWaterSource.PressureWater);
    }
  }, [wcTypeOption, setValue]);

  useEffect(() => {
    const equipmentsFormData = getEnumAsObject(houseHoldWaterInfo.equipments);

    setHouseHoldWaterInfoFormData(() => ({
      wcType: houseHoldWaterInfo.wcType,
      householdWaterSource: houseHoldWaterInfo.householdWaterSource,
      equipments: equipmentsFormData,
    }));
  }, [houseHoldWaterInfo]);

  useEffect(() => {
    reset(houseHoldWaterInfoFormData);
  }, [houseHoldWaterInfoFormData, reset]);

  const handleNext = () => {
    const { wcType, householdWaterSource, equipments } = getValues();

    const equipmentArray = getObjectValuesAsEnum(equipments, WellCollectionEquipment);

    const newHouseHoldWaterInfo = {
      wcType: wcType,
      householdWaterSource: householdWaterSource,
      equipments: equipmentArray,
    };
    updateHouseHoldWaterInfo(newHouseHoldWaterInfo);
    if (
      wcType !== houseHoldWaterInfo.wcType ||
      householdWaterSourceOption !== houseHoldWaterInfo.householdWaterSource
    ) {
      resetWasteWaterTreatmentValues();
    }
    onNextPhaseChange();
  };

  return (
    <Stack direction={'column'}>
      <Container>
        <Stack p={overlayDialogPadding} direction={orientation} spacing={1} justifyContent={'space-between'}>
          <Typography variant={titleTypographyVariant}>
            {getText('well-collection-property-water-supply-title')}
          </Typography>
          <Stack direction='column' width={width} gap={2}>
            <ControlDropdownInput
              control={control}
              error={errors.wcType}
              label={'well-collection-property-water-supply-wc-type'}
              validations={['required']}
              dropdownItems={wcTypeItems}
              name={'wcType'}
            />
            <ControlDropdownInput
              control={control}
              error={errors.householdWaterSource}
              label={'well-collection-property-water-supply-household-water-source'}
              validations={['required']}
              dropdownItems={_.compact(householdWaterSourceItems)}
              name={'householdWaterSource'}
            />
            <Stack direction='column' width={width}>
              <Typography variant='subtitle1'>
                {getText('well-collection-property-water-supply-equipment-level')}
              </Typography>
              <ControlCheckboxInput
                control={control}
                error={errors.equipments?.shower}
                label={'well-collection-property-water-supply-equipment-shower'}
                validations={[]}
                name={'equipments.shower'}
              />
              <ControlCheckboxInput
                control={control}
                error={errors.equipments?.dishwasher}
                label={'well-collection-property-water-supply-equipment-dishwasher'}
                validations={[]}
                name={'equipments.dishwasher'}
              />
              <ControlCheckboxInput
                control={control}
                error={errors.equipments?.washingMachine}
                label={'well-collection-property-water-supply-equipment-washing-machine'}
                validations={[]}
                name={'equipments.washingMachine'}
              />
            </Stack>
          </Stack>
        </Stack>
      </Container>
      <Divider />
      <Container>
        <Stack direction='row' justifyContent='flex-end' spacing={2} paddingX={3} marginBottom={3}>
          <Box>
            <Button variant='contained' size='large' onClick={handleSubmit(() => handleNext())}>
              {getText('dialog-next')}
            </Button>
          </Box>
        </Stack>
      </Container>
    </Stack>
  );
};

export default PropertyWaterSupply;
