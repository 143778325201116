import { AllInvoicingMethodTypes, BillingInfo, ConsumerEInvoiceType, EInvoicing, ExtendedInvoiceMethodType } from "../model";

export interface InvoicingMethod {
  invoicingMethodType: AllInvoicingMethodTypes;
  isChangeAllowed: boolean;
}

function isCompanyChangeAllowed(consumerEInvoiceType: ConsumerEInvoiceType): boolean {
  return consumerEInvoiceType !== ConsumerEInvoiceType.ConsumerChannel;
}

function getCompanyInvoicingMethod(billingInfo: BillingInfo): AllInvoicingMethodTypes {
  if (billingInfo.consumerEInvoiceType === ConsumerEInvoiceType.NotInUse) {
    return validateEInvoiceInformation(billingInfo.eInvoicing)
      ? ExtendedInvoiceMethodType.OnlineInvoicing
      : ExtendedInvoiceMethodType.PaperBillInvoicing;
  }
  return billingInfo.consumerEInvoiceType;
}

function isPrivateChangeAllowed(consumerEInvoiceType: ConsumerEInvoiceType): boolean {
  return ![ConsumerEInvoiceType.DirectInvoicing, ConsumerEInvoiceType.ConsumerChannel, ConsumerEInvoiceType.KivraInvoicing].includes(consumerEInvoiceType);
}

function getPrivateInvoicingMethod(consumerEInvoiceType: ConsumerEInvoiceType): AllInvoicingMethodTypes {
  return consumerEInvoiceType === ConsumerEInvoiceType.NotInUse
    ? ExtendedInvoiceMethodType.PaperBillInvoicing
    : consumerEInvoiceType;
}

// This method determines the invoicing method based on the billing info.
// ExtendedInvoiceMethodType enum values exists only in the UI and they are
// meant only for internal use in UI.
export function determineInvoicingMethod(billingInfo: BillingInfo): InvoicingMethod {
  const invoicingMethod: InvoicingMethod = {
    invoicingMethodType: ConsumerEInvoiceType.Unknown,
    isChangeAllowed: true,
  };

  if (billingInfo) {
    invoicingMethod.invoicingMethodType = billingInfo.consumerEInvoiceType;

    if (billingInfo.isCompany) {
      invoicingMethod.isChangeAllowed = isCompanyChangeAllowed(billingInfo.consumerEInvoiceType);
      invoicingMethod.invoicingMethodType = getCompanyInvoicingMethod(billingInfo);
    } else {
      invoicingMethod.isChangeAllowed = isPrivateChangeAllowed(billingInfo.consumerEInvoiceType);
      invoicingMethod.invoicingMethodType = getPrivateInvoicingMethod(billingInfo.consumerEInvoiceType);
    }
  }

  return invoicingMethod;
}

// This method converts the ExtendedInvoiceMethodType to ConsumerEInvoiceType.
// In Pervasive there exists no ExtendedInvoiceMethodType, so we need to convert
// it to ConsumerEInvoiceType
export function convertExtendedInvoicingMethod(
  invoicingMethodType: AllInvoicingMethodTypes): ConsumerEInvoiceType {

  if (invoicingMethodType === ExtendedInvoiceMethodType.OnlineInvoicing ||
    invoicingMethodType === ExtendedInvoiceMethodType.PaperBillInvoicing) {
    return ConsumerEInvoiceType.NotInUse;
  }

  return invoicingMethodType;
}

export function validateEInvoiceInformation(eInvoiceInformation: EInvoicing | null | undefined): boolean {
  if (!eInvoiceInformation) {
    // eInvoiceInformation is null or undefined
    return false;
  }

  const { address, operator, ediCode } = eInvoiceInformation;

  if (!address || !operator || !ediCode) {
    // One or more properties in eInvoiceInformation are empty
    return false;
  }

  return true; // Seems that eInvoiceInformation is valid
}