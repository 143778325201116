import { ReactElement } from "react";
import { Paper, Stack, Table, TableContainer, useMediaQuery, useTheme } from "@mui/material";
import { DataTableBody } from "./DataTableBody";
import { DataTableHeader } from "./DataTableHeader";
import { AligntmentStrings, CustomHeaderProps, Row, TableHeaderProps } from "./interfaces";

interface TableProps<T> {
  cards: JSX.Element[];
  customHeaders?: CustomHeaderProps[];
  customRows?: JSX.Element[];
  headers?: TableHeaderProps[];
  rows?: T[];
}
/**
 * @cards Cards to be used when viewport is mobile size
 * @customHeaders Optional, will be used instead of headers if defined
 * @customRows Optional, Custom TableRows with TableCells, will be used instead of rows if defined
 * @headers Optional, generates tablecells from params defined here
 * @rows Optional, row data, requires unique rId param to index with, rId won't be displayed
 * @returns Table, cards, or empty react.fragment if neither headers or rows are defined
 */
export const DataTable = <T extends Row>({
  cards,
  customHeaders,
  customRows,
  headers,
  rows
}: TableProps<T>): ReactElement | null => {
  let alignments: AligntmentStrings[] = [];
  let rowKeys: string[] = [];
  const theme = useTheme();
  const largerThanPhone = useMediaQuery(theme.breakpoints.up("md"));

  if (!headers && !customHeaders) {
    return <></>;
  }

  if (!rows && !customRows) {
    return <></>;
  }

  if (rows) {
    rowKeys = Object.keys(rows[0]);
  }

  if (headers) {
    alignments = headers.map(h => h.alignment);
  }

  if (customHeaders) {
    alignments = customHeaders.map(h => h.options.columnAlignment);
  }

  return (
    <>
      {
        largerThanPhone
          ?
          <TableContainer
            component={Paper}
          >
            <Table aria-label="data-table">
              <DataTableHeader
                customHeaders={customHeaders}
                headers={headers}
              />
              <DataTableBody
                alignments={alignments}
                rowKeys={rowKeys}
                rows={rows}
                customRows={customRows}
              />
            </Table>
          </TableContainer>
          :
          <Stack spacing={1}>
            {cards}
          </Stack>
      }
    </>
  );
};
