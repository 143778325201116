import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  FormEvent,
} from 'react';
import { useHistory } from 'react-router-dom';
import ApiContext from '../../../contexts/api-context';
import DialogContext from '../../../contexts/dialog-context';
import { LanguageContext } from '../../../contexts/language-context';
import { VingoProduct } from '../../../model';
import ApiCallHandler from '../../../util/ApiCallHandler';
import { Form, FormContent, FormTop } from '../../common/containers';
import DateInput from '../../common/DateInput';
import { SecondaryHeader, TertiaryHeader } from '../../common/headers';
import OrderButton from '../order/OrderButton';

type EndProps = { customerNumber: string; position: string; product: VingoProduct | null; };

const End = (props: EndProps): JSX.Element => {
  const api = useContext(ApiContext);
  const { getText } = useContext(LanguageContext);
  const showDialog = useContext(DialogContext);
  const history = useHistory();

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [minDate, setMinDate] = useState<Date>(new Date());

  const componentIsMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const { customerNumber, position, product } = props;

  useEffect(() => {
    if (product?.earliestContractStartDate != null) {
      const earliestDate = new Date(product.earliestContractStartDate);
      setMinDate(earliestDate);
      if (endDate < earliestDate) {
        setEndDate(earliestDate);
      }
    }
  }, [product]);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    ApiCallHandler(
      api.endContract(customerNumber, position, endDate),
      setIsLoading,
      componentIsMounted,
      showDialog,
      'change-contract-message-title',
      'change-contract-message-ending',
      history,
      () => history.goBack()
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormTop>
        <SecondaryHeader>
          {getText('change-contract-end-title')}
        </SecondaryHeader>
      </FormTop>
      <FormContent>
        <TertiaryHeader>{getText('change-contract-end-date')}</TertiaryHeader>
        <DateInput
          date={endDate}
          onChange={d => setEndDate(d)}
          showError={isSubmitted}
          minDate={minDate}
        />
      </FormContent>

      <OrderButton
        label="change-contract-end-button"
        action={() => setIsSubmitted(true)}
        isLoading={isLoading}
      />
    </Form>
  );
};

export default End;
