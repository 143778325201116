import { ReactNode, useState, FC, MouseEvent } from 'react';
import { Button, Menu as MaterialUiMenu, MenuItem } from '@mui/material';

type MenuItems = { label: string; onClick: () => void }[];

interface MoreActionsMenuProps {
  menuButton: ReactNode;
  menuItems: MenuItems;
}

const MoreActionsMenu: FC<MoreActionsMenuProps> = ({ menuButton, menuItems }) => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorElement);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ color: 'text.primary', minWidth: '24px', height: '44px', padding: 0 }}
      >
        {menuButton}
      </Button>
      <MaterialUiMenu anchorEl={anchorElement} open={open} onClose={handleClose}>
        {menuItems.map((menuItem, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              menuItem.onClick();
              handleClose();
            }}
          >
            {menuItem.label}
          </MenuItem>
        ))}
      </MaterialUiMenu>
    </>
  );
};

export default MoreActionsMenu;
