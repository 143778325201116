import { useContext } from 'react';
import { Stack } from '@mui/material';
import { Control, FieldErrors, useWatch } from 'react-hook-form';
import { LanguageContext } from '../../../../../../contexts/language-context';
import ControlDropdownInput from '../../../../../../components/common-materialui/form/ControlDropdownInput';
import ControlTextInput from '../../../../../../components/common-materialui/form/ControlTextInput';
import { ClosedTankForm, WeeklyFrequencyEnum } from '../../models';

interface Props {
  control: Control<ClosedTankForm, any>;
  errors: FieldErrors<ClosedTankForm>;
}

const NeedForEmptyingClosedTank = ({ control, errors }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);

  const weeklyFrequency = [
    {
      text: getText('need-for-emptying-closed-tank-weekly-frequency-on-demand'),
      value: WeeklyFrequencyEnum.OnDemand,
    },
    {
      text: getText('need-for-emptying-weekly-frequency-other'),
      value: WeeklyFrequencyEnum.Other,
    },
  ];

  const weeklyFrequencyOptions = useWatch({
    control,
    name: 'needForEmptying.weeklyFrequency',
  });

  return (
    <Stack direction={'column'}>
      <ControlDropdownInput
        control={control}
        error={errors.needForEmptying?.weeklyFrequency}
        validations={['required']}
        dropdownItems={weeklyFrequency}
        label={'need-for-emptying-weekly-frequency-dropdown-label'}
        name='needForEmptying.weeklyFrequency'
      />
      {weeklyFrequencyOptions === WeeklyFrequencyEnum.Other && (
        <ControlTextInput
          control={control}
          error={errors.needForEmptying?.other}
          label='need-for-emptying-closed-tank-other-input-label'
          name='needForEmptying.other'
          validations={['required']}
        />
      )}
    </Stack>
  );
};

export default NeedForEmptyingClosedTank;
