import React, { useContext } from 'react';
import styled from 'styled-components';
import { LanguageContext } from '../../../contexts/language-context';
import { Contract } from '../../../model';
import { printDate } from '../../../util/calendarUtil';
import { Content, ObsoliteContent, Row, TextContent } from '../../common/containers';
import { SecondaryHeader, SmallHeader } from '../../common/headers';

const SmallDateHeader = styled(SmallHeader)`
  margin-top: 1rem;
`;

const AmountRow = styled(Row)`
  font-family: SpaceGrotesk-Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  align-items: center;
`;

type ContractHistoryItemProps = {
  contract: Contract;
  address: string;
};

const ContractHistoryItem = (props: ContractHistoryItemProps): JSX.Element => {
  const { contract, address } = props;
  const { getText } = useContext(LanguageContext);

  const renderNextEmptying = (nextEmptying: Date) => {
    return (
      <Row>
        <SmallDateHeader>
          {printDate(new Date(contract.nextEmptying))}
        </SmallDateHeader>
      </Row>
    );
  };

  const renderAmountOfBins = () => {
    return (
      <AmountRow>
        <SmallHeader>{getText('contract-amount-of-bins')}</SmallHeader>
        &nbsp;
        {contract.amount}
      </AmountRow>
    );
  };

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  let RenderedContent = Content;
  if (contract.endDate != null && new Date(contract.endDate).getTime() <= currentDate.getTime()) {
    RenderedContent = ObsoliteContent
  }

  return (
    <RenderedContent>
      <TextContent>
        {contract.nextEmptying
          ? renderNextEmptying(contract.nextEmptying)
          : null}
        <SecondaryHeader>{contract.name}</SecondaryHeader>
        {renderAmountOfBins()}
      </TextContent>
    </RenderedContent>
  );
};

export default ContractHistoryItem;
