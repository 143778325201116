import React, {
  FormEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import ApiContext from '../../../../contexts/api-context';
import DialogContext from '../../../../contexts/dialog-context';
import { LanguageContext } from '../../../../contexts/language-context';
import {
  BinOwnership,
  IntervalData,
  NewEmptyingInterval,
  PriceCalculationRequest,
  PriceCalculationResult,
  VingoProduct,
} from '../../../../model';
import {
  doWeekIntervalsOverlap,
  getWeekNumber,
  tomorrow,
} from '../../../../util/calendarUtil';
import {
  Column,
  CompactColumn,
  Content,
  ContentRow,
  Form,
  FormContent,
  FormTop,
  ProductInfo,
} from '../../../common/containers';
import DateInput from '../../../common/DateInput';
import Header from '../../../common/Header';
import { SecondaryHeader, TertiaryHeader } from '../../../common/headers';
import { RadioButton } from '../../../common/inputs';
import Spinner from '../../../common/Spinner';
import TextInput from '../../../common/TextInput';
import OrderButton from '../OrderButton';
import BinOwnershipSelection from './BinOwnershipSelection';
import ContractPrices from './ContractPrices';
import IntervalInput from './IntervalInput';

type FinishOrderProps = {
  emptyingId: string;
  wasteTypeName: string;
  products: VingoProduct[];
  backAction: () => void;
};

const emptyIntervalData = {
  startWeekDate: null,
  endWeekDate: null,
  interval: null,
  amountPerWeek: 1,
};

function getDefaultBinOwnership(product: VingoProduct): BinOwnership | null {
  if (product.canRent) return 'Rent';
  if (product.canBuy) return 'Buy';
  if (product.canProvideOwn) return 'Owned';
  if (product.canDelivered) return 'Delivered';
  return null;
}

const FinishOrder = (props: FinishOrderProps): JSX.Element => {
  const history = useHistory();
  const api = useContext(ApiContext);
  const { calculatePrice } = useContext(ApiContext);
  const { getText } = useContext(LanguageContext);
  const showDialog = useContext(DialogContext);
  const { emptyingId, wasteTypeName, products, backAction } = props;
  const [contractStartDate, setContractStartDate] = useState<Date>(tomorrow());
  const [minDate, setMinDate] = useState<Date>(tomorrow());
  const [selectedProduct, setSelectedProduct] = useState<VingoProduct>(
    products[0]
  );
  const [amount, setAmount] = useState<string>('1');
  const [weight, setWeight] = useState<string>('1');
  const [binOwnership, setBinOwnership] = useState<BinOwnership | null>(
    getDefaultBinOwnership(products[0])
  );
  const [intervals, setIntervals] = useState<IntervalData[]>([
    emptyIntervalData,
    emptyIntervalData,
  ]);

  const [iWantAnotherInterval, setIWantAnotherInterval] = useState<boolean>(
    false
  );

  const [additionalInfo, setAdditionalInfo] = useState<string>('');
  const [keyCode, setKeyCode] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [calculatedProductPrice, setCalculatedProductPrice] = useState<PriceCalculationResult | null>(null);
  const componentIsMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (selectedProduct?.earliestContractStartDate != null) {
      const earliestDate = new Date(selectedProduct.earliestContractStartDate);
      setMinDate(earliestDate);
      if (contractStartDate < earliestDate) {
        setContractStartDate(earliestDate);
      }
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (selectedProduct && Number(amount) > 0) {
      const customerId = props.emptyingId;
      const newPriceCalculationRequest: PriceCalculationRequest = {
        productGuid: selectedProduct?.id,
        customerId: customerId,
        amount: Number(amount),
        weight: Number(weight),
      };

      Promise.all([
        getPriceCalculationResult(newPriceCalculationRequest),
      ]).catch((err: any) => {
        handleError(err);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct, amount]);

  const intervalDataToReqList = (): NewEmptyingInterval[] => {
    let intervalReqList = intervals.map((interval) => ({
      startWeek: getWeekNumber(interval.startWeekDate ?? new Date()),
      endWeek: getWeekNumber(interval.endWeekDate ?? new Date()),
      interval: interval.interval ?? -99,
      amountPerWeek: interval.amountPerWeek
    }));
    // For now only 2 intervals are allowed.
    // intervals list has 2 initialized elements.
    // So let's drop the other one if user has given only one interval
    if (!iWantAnotherInterval) {
      intervalReqList.splice(1, 1);
    }
    return intervalReqList;
  };

  const handleSubmit = (event: FormEvent) => {
    // Prevent sending if binOwnership is not set
    if (binOwnership == null) {
      event.preventDefault();
      return;
    }

    setIsLoading(true);
    event.preventDefault();

    const productOrder = {
      products: [
        {
          name: selectedProduct.name,
          amount: parseInt(amount),
          productGuid: selectedProduct.id,
          emptyingIntervals: intervalDataToReqList(),
          binOwnership: binOwnership,
          startingDate: contractStartDate,
          key: keyCode,
          comment: additionalInfo
        },
      ],
    };

    api
      .addNewContract(emptyingId, productOrder)
      .then(() => {
        showDialog(
          'message-success-generic-title',
          'add-new-contract-success-message',
          () => history.goBack()
        );
      })
      .catch((err: unknown) => {
        showDialog(
          'customer-service-contact-us-title',
          'add-new-contract-failed-message',
          () => history.goBack()
        );
      })
      .finally(() => {
        if (componentIsMounted.current) {
          setIsLoading(false);
        }
      });
  };

  const getPriceCalculationResult = async (priceCalculationRequest: PriceCalculationRequest): Promise<void> => {
    const result = await calculatePrice(priceCalculationRequest);

    if (componentIsMounted.current && result) {
      setCalculatedProductPrice(result);
    }
  };

  const handleError = (err: any) => {
    if (err.statusCode === 401) {
      showDialog('error-unauthorized-title', 'error-unauthorized-message', () =>
        history.replace('/logout')
      );
    } else {
      showDialog('error-service-break-title', 'error-service-break-message');
    }
  };


  const selectProduct = (product: VingoProduct): void => {
    // If user has given interval data and after that changes product.
    // Then new product might have different allowed intervals.
    // So given interval data should be cleared or validity checked.
    // Interval validy data check is done in child component.
    setSelectedProduct(product);
  };

  // Updates intervals list item with given update function at given index position.
  const updateInterval = (
    index: number,
    updater: (interval: IntervalData) => IntervalData
  ): void => {
    const updatedIntervals = intervals.map((interval, i) => {
      if (index === i) {
        return updater(interval);
      } else {
        return interval;
      }
    });
    setIntervals(updatedIntervals);
  };

  /* If user should be able to add more than 2 intervals
   these functions can be used to add and remove interval items
  const addNewInterval = (): void => {
    setIntervals(intervals.concat([emptyIntervalData]));
  };

  const deleteInterval = (index: number): void => {
    if (intervals.length < 2) {
      setIntervals([emptyIntervalData]);
    } else {
      const newIntervals = [...intervals];
      newIntervals.splice(index, 1);
      setIntervals(newIntervals);
    }
  };
  */

  const renderProductSelection = (product: VingoProduct): JSX.Element => {
    return (
      <ContentRow key={product.id} style={{ marginTop: '0.5rem' }}>
        <RadioButton
          type="radio"
          name="product-selection"
          id={product.id}
          checked={product.id === selectedProduct.id}
          onChange={() => selectProduct(product)}
        />
        <label htmlFor={product.id}>{product.name}</label>
        <ProductInfo style={{ marginLeft: '1.5rem' }}>{product.infoText}</ProductInfo>
      </ContentRow>
    );
  };

  const intervalOverlap: boolean =
    intervals[0].startWeekDate !== null &&
    intervals[0].endWeekDate !== null &&
    intervals[1].startWeekDate !== null &&
    intervals[1].endWeekDate !== null &&
    iWantAnotherInterval &&
    doWeekIntervalsOverlap(
      getWeekNumber(intervals[0].startWeekDate),
      getWeekNumber(intervals[0].endWeekDate),
      getWeekNumber(intervals[1].startWeekDate),
      getWeekNumber(intervals[1].endWeekDate)
    );

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.match(/^[0-9]+$/) != null && Number(e.target.value) > 0) {
      setAmount(e.target.value);
    }
  };

  return (
    <Column>
      <Header
        headerKey="add-contract-finish-order"
        descriptionKey="add-contract-finish-order-desc"
        backButton={true}
        backAction={backAction}
      />
      <CompactColumn>
        <Form onSubmit={handleSubmit}>
          <FormTop>
            <SecondaryHeader>{wasteTypeName}</SecondaryHeader>
          </FormTop>
          <FormContent>
            <TertiaryHeader>
              {getText('add-contract-start-date')}
            </TertiaryHeader>
            <DateInput
              date={contractStartDate}
              required={true}
              onChange={(d) => setContractStartDate(d)}
              showError={isSubmitted}
              minDate={minDate}
            />
          </FormContent>
          <FormContent>
            <TertiaryHeader>
              {getText('add-contract-service-title')}
            </TertiaryHeader>
            {products.map(renderProductSelection)}
          </FormContent>
          <FormContent>
            <TertiaryHeader>
              {getText('add-contract-bin-amount')}
            </TertiaryHeader>
            <TextInput
              val={amount}
              inputType="number"
              minValue={1}
              setter={setAmount}
              onChange={e => { handleAmountChange(e); }}
              showErrors={isSubmitted}
              validations={['required', 'integer', 'gt_zero']}
            />
          </FormContent>
          <FormContent>
            <TertiaryHeader>{getText('add-contract-interval')}</TertiaryHeader>

            {selectedProduct.allowedEmptyingIntervals ? (
              <IntervalInput
                allowedIntervals={selectedProduct.allowedEmptyingIntervals}
                intervals={intervals}
                updateInterval={updateInterval}
                iWantAnotherInterval={iWantAnotherInterval}
                setIWantAnotherInterval={setIWantAnotherInterval}
                intervalOverlap={intervalOverlap}
                isSubmitted={isSubmitted}
              />
            ) : null}
          </FormContent>
          <BinOwnershipSelection
            binOwnership={binOwnership}
            canRent={selectedProduct.canRent}
            canBuy={selectedProduct.canBuy}
            canProvideOwn={selectedProduct.canProvideOwn}
            canDelivered={selectedProduct.canDelivered}
            setValue={(v) => setBinOwnership(v)}
            isSubmitted={isSubmitted}
          />

          <Content>
            <SecondaryHeader>
              {getText('add-contract-for-driver')}
            </SecondaryHeader>
            <TextInput
              label="add-contract-for-driver-key-code"
              val={keyCode}
              setter={setKeyCode}
            />
            <TertiaryHeader>
              {getText('add-contract-for-driver-info')}
            </TertiaryHeader>
            <TextInput
              multiline={true}
              val={additionalInfo}
              maxLength={500}
              setter={setAdditionalInfo}
              showErrors={isSubmitted}
            />
          </Content>

          <ContractPrices
            product={selectedProduct}
            calculatedProductPrice={calculatedProductPrice}
            canBuy={selectedProduct.canBuy}
            isWeightBased={calculatedProductPrice?.IsWeightBased ?? false}
          />

          <OrderButton
            label="service-order-confirm-button"
            action={() => setIsSubmitted(true)}
            isLoading={isLoading}
            disabled={intervalOverlap || Number(amount) < 0}
          />
        </Form>
      </CompactColumn>
    </Column>
  );
};

export default FinishOrder;
