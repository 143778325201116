import React, { forwardRef, useContext, useRef } from 'react';

import styled from 'styled-components';

import fiLocale from 'date-fns/locale/fi';
import svLocale from 'date-fns/locale/sv';
import enLocale from 'date-fns/locale/en-GB';

import ReactDatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './DateInput.css';

import { LanguageContext } from '../../contexts/language-context';
import { ValidationError } from './containers';
import colors from '../../colors';
import { getStartOfWeek, getWeekNumber } from '../../util/calendarUtil';
import { EmptyingEvent, UiTexts } from '../../model';
import { Tooltip } from '@material-ui/core';
import DateField from './DateField';

// Weekpicker helper function for getting all week days of selected week.
function getSelectedWeekdays(isWeekSelect: boolean | undefined, date: Date | null): Date[] {
  let selectedWeekDays: Date[] = [];

  if (isWeekSelect && date) {
    const weekStart = getStartOfWeek(date);

    for (let i = 0; i < 7; i++) {
      const nextDay = new Date(weekStart);
      nextDay.setDate(nextDay.getDate() + i);
      selectedWeekDays.push(nextDay);
    }
  }
  return selectedWeekDays;
}

function getEmptyingEvents(emptyingEvents: EmptyingEvent[] | undefined): EmptyingEvent[] {
  let emptyingEventsFixed: EmptyingEvent[] = [];
  if (emptyingEvents) {
    for (let i = 0; i < emptyingEvents.length; i++) {
      let tmpDate = new Date(emptyingEvents[i].emptyingDate);
      let date = new Date(tmpDate.getTime() - tmpDate.getTimezoneOffset() * 60000);
      let emptyingEvent: EmptyingEvent = {
        description: emptyingEvents[i].description,
        eventType: emptyingEvents[i].eventType,
        emptyingDate: date,
      };

      emptyingEventsFixed.push(emptyingEvent);
    }
  }

  return emptyingEventsFixed;
}

type DateInputProps = {
  date: Date | null;
  onChange: (date: Date) => void;
  required?: boolean;
  showError?: boolean;
  minDate?: Date;
  maxDate?: Date;
  weekSelect?: boolean;
  placeholderText?: string;
  inlineCalendar?: boolean;
  emptyingEvents?: EmptyingEvent[];
  hideYear?: boolean;
  label?: keyof UiTexts;
};

const StyledDatePicker = styled(ReactDatePicker)`
  font-family: SpaceGrotesk-Regular;
  font-size: 1rem;
  width: 100%;
  min-width: 100%;
  padding: 1rem;
  border-radius: 0;
  border: solid 1px ${colors.inputBorder};
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
`;

const DateInput = (props: DateInputProps): JSX.Element => {
  const {
    date,
    onChange,
    required,
    showError,
    minDate,
    maxDate,
    weekSelect,
    placeholderText,
    inlineCalendar,
    emptyingEvents,
    hideYear,
    label,
  } = props;
  const { lang, getText, publicHolidays } = useContext(LanguageContext);

  let locale = null;
  switch (lang) {
    case 'fi':
      locale = fiLocale;
      break;
    case 'sv':
      locale = svLocale;
      break;
    default:
      locale = enLocale;
  }

  const errorMessage =
    showError && required && date === null ? <ValidationError>{getText('validation-required')}</ValidationError> : null;

  const selectedWeekDays: Date[] = getSelectedWeekdays(weekSelect, date);

  const emptyingEventsFixed = getEmptyingEvents(emptyingEvents);

  const renderDayContents = (_day: any, dateToRender: Date) => {
    const tooltipText = getTooltipText(dateToRender);
    if (tooltipText === '') {
      return <span>{dateToRender.getDate()}</span>;
    } else {
      return (
        <Tooltip title={tooltipText} arrow>
          <span>{dateToRender.getDate()}</span>
        </Tooltip>
      );
    }
  };

  function getTooltipText(toolTipDate: Date) {
    let currentEvents = emptyingEventsFixed?.filter((x) => {
      let emptyingDate = new Date(x.emptyingDate);
      return (
        emptyingDate.getDate() == toolTipDate.getDate() &&
        emptyingDate.getFullYear() == toolTipDate.getFullYear() &&
        emptyingDate.getMonth() == toolTipDate.getMonth()
      );
    });

    if (currentEvents == null) {
      return '';
    }

    const description = currentEvents.find((x) => x.description != null && x.description != '')?.description ?? '';
    return description;
  }

  const highlightDates = [
    {
      'react-datepicker__day--holidays': publicHolidays,
    },
    {
      'react-datepicker__day--of-selected-week': selectedWeekDays,
    },
    {
      'react-datepicker__day--highlighted':
        emptyingEventsFixed.filter((x) => x.eventType === 'Successful').map((x) => x.emptyingDate) ?? [],
    },
    {
      'react-datepicker__day-missed--highlighted':
        emptyingEventsFixed.filter((x) => x.eventType === 'Missed').map((x) => x.emptyingDate) ?? [],
    },
    {
      'react-datepicker__day-future--highlighted': emptyingEventsFixed.filter(x => x.eventType === 'Planned').map(x => x.emptyingDate) ?? [],
    },
  ];

  const onWeekChange = (date: Date) => {
    if (minDate != null && date < minDate && getWeekNumber(date) != getWeekNumber(minDate)) {
      return;
    }
    if (maxDate != null && date > maxDate && getWeekNumber(date) != getWeekNumber(maxDate)) {
      return;
    }

    // These checkups are for keeping week calendar focus on right year if user presses week number.
    // Previous check already maked sure that week number is within valid range
    if (minDate != null && date < minDate) {
      date = minDate;
    }

    if (maxDate != null && date > maxDate) {
      date = maxDate;
    }

    onChange(date);
  };

  const CustomInput = forwardRef((props: any, ref) => {
    return <DateField {...props} ref={ref} label={label} />;
  });

  const inputRef = useRef(null);

  return (
    <div>
      <StyledDatePicker
        fixedHeight
        inline={inlineCalendar}
        readOnly={inlineCalendar}
        calendarClassName={weekSelect ? 'week-picker' : ''}
        selected={date}
        onChange={onChange}
        onWeekSelect={weekSelect ? (d) => onWeekChange(d) : undefined}
        locale={locale}
        dateFormat={weekSelect ? 'w' : 'd.M.yyyy'}
        showWeekNumbers
        minDate={minDate}
        maxDate={maxDate}
        required={required}
        showMonthDropdown
        showYearDropdown={hideYear ? false : true}
        dateFormatCalendar={hideYear ? 'LLLL' : undefined}
        // @ts-ignore
        highlightDates={highlightDates}
        placeholderText={placeholderText}
        // -- These are for making component more mobile friendly
        // TODO: decide if it's better to leave them out in desktop view.
        // Leaving them out propably increases accessability.
        withPortal={!inlineCalendar}
        // This prevents keyboard showing in mobile devices and text input being focused behind the popper.
        onFocus={(e) => (e.target.readOnly = true)}
        renderDayContents={renderDayContents}
        customInput={<CustomInput inputRef={inputRef} />}
      />
      {errorMessage}
    </div>
  );
};

export default DateInput;
