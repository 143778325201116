export default class HttpError extends Error {
  statusCode: number;
  response: any;
  constructor(statusCode: number, message: string, response?: any) {
    super(message);
    this.response = response ?? '';
    this.name = 'HttpError';
    this.statusCode = statusCode;
  }
}
