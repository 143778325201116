import { useContext } from 'react';
import { CustomerType, ConsumerEInvoiceType, Language, convertToLanguage } from '../../../model';
import { Box, Button, Container, Divider, Stack, Typography, useTheme } from '@mui/material';
import { LanguageContext } from '../../../contexts/language-context';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
  DisabledStates,
  RegisterFormData,
  RenderBasicInformationSection,
  renderUsernamePasswordSection,
} from '../sharedRegisterSections';
import AuthContext from '../../../contexts/auth-context';
import { useMutation } from '@tanstack/react-query';
import { IdentifiedPersonRegistrationParams } from '../../../service/authService';
import ProgressComponent from '../../../components/common-materialui/ProgressComponent';
import { getAlertWithErrorMessages } from '../../../components/common-materialui/status-handling/httpErrorUtils';

interface Props {
  identificationId: string;
  firstName: string;
  lastName: string;
  streetAddress: string;
  postCode: string;
  city: string;
  customerType: CustomerType | null;
  language: Language;
  onPreviousPhaseChange: () => void;
}

const CompleteNewRegistration = ({
  identificationId,
  firstName,
  lastName,
  streetAddress,
  postCode,
  city,
  customerType,
  onPreviousPhaseChange }: Props): JSX.Element => {
  const { palette } = useTheme();
  const history = useHistory();
  const { getText, lang } = useContext(LanguageContext);
  const { logIn, registerIdentifiedPerson } = useContext(AuthContext);

  const methods = useForm<RegisterFormData>(
    {
      defaultValues: {
        firstName: firstName,
        lastName: lastName,
        language: convertToLanguage(lang),
      },
    },
  );
  const { control, handleSubmit, formState: { errors } } = methods;

  const currentUsername = useWatch({
    control,
    name: 'username',
  });

  const currentPassword = useWatch({
    control,
    name: 'password',
  });

  const disabledBasicInformation: DisabledStates = {
    firstName: !(!firstName),
    lastName: !(!lastName),
  };

  const {
    mutate: loginAfterRegister,
    isLoading: loginAfterRegisterLoading,
    error: loginAfterRegisterError,
    reset: loginAfterRegisterReset,
  } = useMutation(() => logIn(currentUsername, currentPassword), {
    onSuccess: () => history.push('/emptying-infos'),
  });

  const {
    mutate: register,
    isLoading: registerLoading,
    error: registerError,
    reset: registerReset,
  } = useMutation((data: IdentifiedPersonRegistrationParams) => registerIdentifiedPerson(data), {
    onSuccess: () => loginAfterRegister(),
  });

  function mapAndRegister(data: RegisterFormData) {
    const inputData: IdentifiedPersonRegistrationParams = {
      identificationId: identificationId,
      phone: data.phoneNumber,
      email: data.email,
      userName: data.username,
      password: data.password,
      language: data.language,
    };
    register(inputData);
  }

  function renderHeader() {
    return (
      <Stack spacing={2}>
        <Typography variant='h3'>{getText('login-info-create-account')}</Typography>
        <Typography variant={'body2'} color={palette.text.primary}>
          {getText('register-new-account-description')}
        </Typography>
      </Stack>
    );
  }

  function clearErrors() {
    registerReset();
    loginAfterRegisterReset();
  }

  function renderSendButtonAndLink() {
    return (
      <Container>
        <Stack direction='row' justifyContent='flex-end' spacing={2} paddingX={3} marginBottom={3}>
          {registerError || loginAfterRegisterError
            ? getAlertWithErrorMessages(registerError || loginAfterRegisterError, getText, clearErrors)
            : null}
          <Box>
            <Button size='large' color='secondary' onClick={onPreviousPhaseChange}>
              {getText('dialog-back')}
            </Button>
          </Box>
          <Box>
            <Button
              variant='contained'
              size='large'
              onClick={(e) => void handleSubmit(mapAndRegister)(e)}
              disabled={
                customerType === null ||
                customerType.length === 0 ||
                registerLoading ||
                loginAfterRegisterLoading}
            >
              {registerLoading || loginAfterRegisterLoading ? (
                <ProgressComponent variant='circle' isLoading={registerLoading || loginAfterRegisterLoading} />
              ) : (
                getText('create-account')
              )}
            </Button>
          </Box>
        </Stack>
      </Container>
    );
  }

  return (
    <FormProvider {...methods}>
      <Container>
        <Box
          sx={{
            backgroundColor: palette.background.level2,
            padding: 3,
            maxWidth: '515px',
            margin: 'auto',
            border: `none`,
            ///border: `1px solid ${palette.divider}`,
          }}
        >
          <Stack spacing={4}>
            {renderHeader()}
            <RenderBasicInformationSection 
              getText={getText} 
              titleKey='basic-info' 
              disabledStates={disabledBasicInformation}
            />
            <Divider />
            {renderUsernamePasswordSection(control, errors, getText, currentPassword)}
          </Stack>
        </Box>
      </Container>
      {renderSendButtonAndLink()}
    </FormProvider>
  );
};

export default CompleteNewRegistration;
