import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  FormEvent,
} from 'react';
import { useHistory } from 'react-router-dom';

import { LanguageContext } from '../../../contexts/language-context';
import AuthContext from '../../../contexts/auth-context';

import { Content, Form} from '../../common/containers';

import CompanyBasicInfo from '../register/CompanyBasicInfo';
import ContactInfo from '../register/ContactInfo';
import CompanyBillingInfo from '../register/CompanyBillingInfo';
import { ConsumerEInvoiceType } from '../../../model';
import { CompanyRegistrationParams } from '../../../service/authService';
import VingoButton from '../../common/VingoButton';
import DialogContext from '../../../contexts/dialog-context';

const Company = (): JSX.Element => {
  const history = useHistory();
  const { getText } = useContext(LanguageContext);
  const authService = useContext(AuthContext);
  const showDialog = useContext(DialogContext);

  const [companyName, setCompanyName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [companyId, setCompanyId] = useState<string>('');

  const [contactName, setContactName] = useState<string>('');
  const [contactPhone, setContactPhone] = useState<string>('');
  const [contactEmail, setContactEmail] = useState<string>('');

  const [userName, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmedPassword, setConfirmedPassword] = useState<string>('');

  const [streetAddress, setStreetAddress] = useState<string>('');
  const [postCode, setPostCode] = useState<string>('');
  const [city, setCity] = useState<string>('');

  const [billType, setBillType] = useState<ConsumerEInvoiceType>(ConsumerEInvoiceType.NotInUse);
  const [billingEmail, setBillingEmail] = useState<string>('');
  const [eBillAddress, setEBillAddress] = useState<string>('');
  const [broker, setBroker] = useState<string>('');
  const [ovt, setOvt] = useState<string>('');
  const [iHaveReadConditions, setIHaveReadConditions] = useState<boolean>(
    false
  );
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const componentIsMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const renderConditionsAndRegistrationButton = () => {
    return (
      <Content>
        <VingoButton
          onClick={() => setIsSubmitted(true)}
          isLoading={isLoading}
        >
          {getText('billing-info-send')}
        </VingoButton>
      </Content>
    );
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    const { logIn, registerCompanyCustomer } = authService;

    let params: CompanyRegistrationParams = {
      companyName: companyName,
      companyPhone: phone,
      companyId,
      contactName,
      contactPhone,
      contactEmail,
      userName,
      password,
      streetAddress,
      postCode,
      city,
      consumerEInvoiceType: billType,
    };

    if (billType === ConsumerEInvoiceType.EmailInvoicing) {
      params.billingEmail = billingEmail;
    }

    if (billType === ConsumerEInvoiceType.ConsumerChannel) {
      params.eBillData = {
        address: eBillAddress,
        operator: broker,
        ediCode: ovt,
      };
    }

    registerCompanyCustomer(params)
      .then(() => {
        return logIn(userName, password);
      })
      .then(() => {
        history.replace('/emptying-infos');
      })
      .catch((err: any) => {
        console.error(err);
        showDialog('error-service-break-title', 'error-service-break-message');
      })
      .finally(() => {
        if (componentIsMounted.current) {
          setIsLoading(false);
        }
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <CompanyBasicInfo
        companyName={companyName}
        setCompanyName={setCompanyName}
        phone={phone}
        setPhone={setPhone}
        companyId={companyId}
        setCompanyId={setCompanyId}
        isSubmitted={isSubmitted}
      />
      <ContactInfo
        name={contactName}
        setName={setContactName}
        phone={contactPhone}
        setPhone={setContactPhone}
        email={contactEmail}
        setEmail={setContactEmail}
        isSubmitted={isSubmitted}
        isRequired={true}
      />
      <CompanyBillingInfo
        streetAddress={streetAddress}
        setStreetAddress={setStreetAddress}
        postCode={postCode}
        setPostCode={setPostCode}
        city={city}
        setCity={setCity}
        billType={billType}
        billingEmail={billingEmail}
        setBillingEmail={setBillingEmail}
        setBillType={setBillType}
        eBillAddress={eBillAddress}
        setEBillAddress={setEBillAddress}
        broker={broker}
        setBroker={setBroker}
        ovt={ovt}
        setOvt={setOvt}
        isSubmitted={isSubmitted}
        readOnly={false}
        emailInvoicing={authService.isAllowRegisterEmailInvoicingEnabled()}
      />

      {renderConditionsAndRegistrationButton()}
    </Form>
  );
};

export default Company;
