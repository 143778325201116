import { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { Control, FieldErrors } from 'react-hook-form';
import ControlCheckboxInput from '../../../../../components/common-materialui/form/ControlCheckboxInput';
import {
  WasteWaterOptions,
  WasteWaterOptionsEnum,
  WasteWaterTreatmentOptionsForm,
  WellCollectionHouseholdWaterSource,
  WellCollectionState,
  WellCollectionWCType,
} from '../models';

export const defaultPropertyWaterSupplyFormValues = {
  A: false,
  B: false,
  C: false,
  D: false,
  E: false,
  F: false,
  G: false,
};

const getOptions = (input: WellCollectionState): WasteWaterOptions => {
  const {
    houseHoldWaterInfo: { wcType, householdWaterSource },
  } = input;
  if (wcType === WellCollectionWCType.WaterWC) {
    return {
      A: {
        blackList: [WasteWaterOptionsEnum.C, WasteWaterOptionsEnum.G],
        value: WasteWaterOptionsEnum.A,
        label: 'waste-water-treatment-option-A',
      },
      B: {
        blackList: [WasteWaterOptionsEnum.C, WasteWaterOptionsEnum.D, WasteWaterOptionsEnum.E, WasteWaterOptionsEnum.F],
        value: WasteWaterOptionsEnum.B,
        label: 'waste-water-treatment-option-B',
      },
      C: {
        blackList: [
          WasteWaterOptionsEnum.A,
          WasteWaterOptionsEnum.B,
          WasteWaterOptionsEnum.D,
          WasteWaterOptionsEnum.E,
          WasteWaterOptionsEnum.G,
        ],
        value: WasteWaterOptionsEnum.C,
        label: 'waste-water-treatment-option-C',
      },
      D: {
        blackList: [WasteWaterOptionsEnum.B, WasteWaterOptionsEnum.C, WasteWaterOptionsEnum.E, WasteWaterOptionsEnum.F],
        value: WasteWaterOptionsEnum.D,
        label: 'waste-water-treatment-option-D',
      },
      E: {
        blackList: [WasteWaterOptionsEnum.B, WasteWaterOptionsEnum.D, WasteWaterOptionsEnum.F],
        value: WasteWaterOptionsEnum.E,
        label: 'waste-water-treatment-option-E',
      },
      F: {
        blackList: [WasteWaterOptionsEnum.B, WasteWaterOptionsEnum.D, WasteWaterOptionsEnum.E, WasteWaterOptionsEnum.G],
        value: WasteWaterOptionsEnum.F,
        label: 'waste-water-treatment-option-F',
      },
      G: {
        blackList: [WasteWaterOptionsEnum.A, WasteWaterOptionsEnum.C],
        value: WasteWaterOptionsEnum.G,
        label: 'waste-water-treatment-option-G',
      },
    };
  } else {
    if (householdWaterSource === WellCollectionHouseholdWaterSource.PressureWater) {
      return {
        A: {
          blackList: [WasteWaterOptionsEnum.C, WasteWaterOptionsEnum.G],
          value: WasteWaterOptionsEnum.A,
          label: 'waste-water-treatment-option-A',
        },
        C: {
          blackList: [
            WasteWaterOptionsEnum.A,
            WasteWaterOptionsEnum.B,
            WasteWaterOptionsEnum.D,
            WasteWaterOptionsEnum.E,
          ],
          value: WasteWaterOptionsEnum.C,
          label: 'waste-water-treatment-option-C',
        },
        G: {
          blackList: [WasteWaterOptionsEnum.A, WasteWaterOptionsEnum.C],
          value: WasteWaterOptionsEnum.G,
          label: 'waste-water-treatment-option-G',
        },
      };
    } else {
      return {
        G: {
          blackList: [WasteWaterOptionsEnum.A, WasteWaterOptionsEnum.C],
          value: WasteWaterOptionsEnum.G,
          label: 'waste-water-treatment-option-G',
        },
      };
    }
  }
};

const getAvailableOptions = (input: WellCollectionState) => {
  const {
    houseHoldWaterInfo: { wcType, householdWaterSource },
  } = input;
  if (wcType === WellCollectionWCType.WaterWC) {
    return [
      WasteWaterOptionsEnum.A,
      WasteWaterOptionsEnum.B,
      WasteWaterOptionsEnum.C,
      WasteWaterOptionsEnum.D,
      WasteWaterOptionsEnum.E,
      WasteWaterOptionsEnum.F,
      WasteWaterOptionsEnum.G,
    ];
  } else {
    if (householdWaterSource === WellCollectionHouseholdWaterSource.PressureWater) {
      return [WasteWaterOptionsEnum.A, WasteWaterOptionsEnum.C, WasteWaterOptionsEnum.G];
    } else {
      return [WasteWaterOptionsEnum.G];
    }
  }
};

interface Props {
  wellCollectionInfo: WellCollectionState;
  control: Control<WasteWaterTreatmentOptionsForm>;
  errors: FieldErrors<WasteWaterTreatmentOptionsForm>;
  dirtyFields: Partial<Record<keyof WasteWaterTreatmentOptionsForm, boolean>>;
  maxNumberOfSelection: number;
  setErrorMessage: (input: string | null) => void;
}

const WasteWaterTreatmentOptions = ({
  wellCollectionInfo,
  control,
  errors,
  dirtyFields,
  maxNumberOfSelection,
  setErrorMessage,
}: Props): JSX.Element => {
  const data = getOptions(wellCollectionInfo);
  const [disabledOptions, setDisabledOptions] = useState<string[]>([]);
  const availableOptions = getAvailableOptions(wellCollectionInfo);

  useEffect(() => {
    //update disabled options based on user selection
    const checkedOptions = Object.keys(dirtyFields);

    if (checkedOptions.length === 1 && maxNumberOfSelection !== 1) {
      setDisabledOptions(data[checkedOptions[0]].blackList);
    } else if (checkedOptions.length >= maxNumberOfSelection) {
      setErrorMessage(null);
      const remainingOptions = availableOptions.filter((option) => !checkedOptions.includes(option));
      setDisabledOptions(remainingOptions);
    } else if (checkedOptions.length === 0) {
      setErrorMessage(null);
      setDisabledOptions([]);
    }
  }, [
    dirtyFields.A,
    dirtyFields.B,
    dirtyFields.C,
    dirtyFields.D,
    dirtyFields.E,
    dirtyFields.F,
    dirtyFields.G,
    dirtyFields,
  ]);

  return (
    <Stack direction={'column'}>
      {Object.values(data).map((option) => (
        <ControlCheckboxInput
          control={control}
          error={errors[option.value]}
          label={option.label}
          validations={[]}
          name={option.value}
          disabled={disabledOptions.includes(option.value)}
          key={`${option.value}+${option.label}`}
        />
      ))}
    </Stack>
  );
};

export default WasteWaterTreatmentOptions;
