import { FC, useContext } from 'react';
import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form';
import { FormControlLabel, FormLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { SmallerThanBreakpoint } from '../../../../util/viewportUtils';
import { LanguageContext } from '../../../../contexts/language-context';
import { ConsumerEInvoiceType, ExtendedInvoiceMethodType, OnlineBroker } from '../../../../model';
import ControlDropdownInput from '../../../../components/common-materialui/form/ControlDropdownInput';
import ControlTextInput from '../../../../components/common-materialui/form/ControlTextInput';
import { BillingInfoFormData } from '../models';

interface Props {
  control: Control<BillingInfoFormData, any>;
  errors: FieldErrors<BillingInfoFormData>;
  disabled: boolean;
  brokers?: OnlineBroker[];
}
const CompanyBillingInfoAddressSection: FC<Props> = ({ control, errors, disabled, brokers }) => {
  const { getText } = useContext(LanguageContext);
  const stackWidth = SmallerThanBreakpoint('md') ? '100%' : '50%';
  const consumerEInvoiceTypeWatch = useWatch({ control, name: 'consumerEInvoiceType' });
  const brokerItems = Array.isArray(brokers) ?
    brokers.map(broker => ({ value: broker.code, text: broker.name })) : [];

  return (
    <Stack sx={{ width: stackWidth }}>
      <ControlTextInput
        control={control}
        name='companyName'
        label='basic-info-customer-name'
        error={errors.companyName}
        validations={['required']}
        disabled={disabled}
      />
      <ControlTextInput
        control={control}
        name='companyCode'
        label='basic-info-company-id'
        error={errors.companyCode}
        validations={['required', 'company-id']}
        disabled={disabled}
      />
      <ControlTextInput
        control={control}
        name='address'
        label='billing-data-street-address'
        error={errors.address}
        validations={['required']}
        disabled={disabled}
      />
      <ControlTextInput
        control={control}
        name='postCode'
        label='billing-data-post-code'
        error={errors.postCode}
        validations={['required', 'post-code']}
        disabled={disabled}
      />
      <ControlTextInput
        control={control}
        name='city'
        label='billing-data-city'
        error={errors.city}
        validations={['required']}
        disabled={disabled}
      />
      <Controller
        control={control}
        name='consumerEInvoiceType'
        render={({ field: { onBlur, onChange, value } }) => {
          return (
            <Stack style={{ marginTop: '20px' }}>
              <FormLabel id='billing-data-billing-method-question'>
                {getText('billing-data-billing-method-question')}
              </FormLabel>
              <RadioGroup
                value={value}
                onBlur={onBlur}
                onChange={(e) => {
                  onChange(e);
                }}
              >
                <FormControlLabel
                  control={<Radio disabled={disabled} />}
                  label={getText('billing-data-billing-method-paper')}
                  value={ExtendedInvoiceMethodType.PaperBillInvoicing}
                />
                <FormControlLabel
                  control={<Radio disabled={disabled} />}
                  label={getText('billing-data-billing-method-email')}
                  value={ConsumerEInvoiceType.EmailInvoicing}
                />
                <FormControlLabel
                  control={<Radio disabled={disabled} />}
                  label={getText('billing-data-billing-method-e-invoice')}
                  value={ExtendedInvoiceMethodType.OnlineInvoicing}
                />
              </RadioGroup>
            </Stack>
          );
        }}
      />
      {consumerEInvoiceTypeWatch === ConsumerEInvoiceType.EmailInvoicing ? (
        <ControlTextInput
          control={control}
          name='billingEmail'
          label='billing-email'
          error={errors.billingEmail}
          validations={['required', 'email']}
          disabled={disabled}
        />
      ) : null}
      {consumerEInvoiceTypeWatch === ExtendedInvoiceMethodType.OnlineInvoicing ? (
        <>
          <ControlTextInput
            control={control}
            name='eInvoiceInformation.address'
            label='e-bill-address'
            error={errors.eInvoiceInformation?.address}
            validations={['required']}
            disabled={disabled}
          />
          <ControlDropdownInput
            control={control}
            name='eInvoiceInformation.operator'
            label='broker'
            error={errors.eInvoiceInformation?.operator}
            validations={['required']}
            dropdownItems={brokerItems}
            disabled={disabled}
          />
          <ControlTextInput
            control={control}
            name='eInvoiceInformation.ediCode'
            label='ovt'
            error={errors.eInvoiceInformation?.ediCode}
            validations={['required']}
            disabled={disabled}
          />
        </>
      ) : null}
    </Stack>
  );
};

export default CompanyBillingInfoAddressSection;
