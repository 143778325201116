import React, { useMemo, useContext } from 'react';

import { WeekInterval } from '../../OrderProduct';
import AuthContext from '../../../../../contexts/auth-context';
import { EmptyingInterval, UiTexts } from '../../../../../model';
import WeekIntervalSelectorSeasonalRhythm from './WeekIntervalSelectorSeasonalRhythm';
import WeekIntervalSelectorWeeklyRhythm from './WeekIntervalSelectorWeeklyRhythm';


export interface WeekIntervalSelectorProps {
  allowedIntervals: Array<{ startWeek: number; endWeek: number; intervals: number[]; }>;
  alertErrorMsgFirstInterval: keyof UiTexts | null;
  alertErrorMsgSecondInterval: keyof UiTexts | null;
  getText: (key: keyof UiTexts, params?: string[] | undefined) => string;
  initialIntervals?: EmptyingInterval[]; // optional, used if provided for frequencies etc.
  setAlertErrorMsgFirstInterval: React.Dispatch<React.SetStateAction<keyof UiTexts | null>>;
  setAlertErrorMsgSecondInterval: React.Dispatch<React.SetStateAction<keyof UiTexts | null>>;
  setWeekIntervalFinishOrder: (param: any) => any;
  setSingleInterval: React.Dispatch<React.SetStateAction<boolean>>;
  singleInterval: boolean;
  weekIntervalFinishOrder: WeekInterval[];
}

const WeekIntervalSelector: React.FC<WeekIntervalSelectorProps> = ({
  allowedIntervals,
  alertErrorMsgFirstInterval,
  alertErrorMsgSecondInterval,
  getText,
  initialIntervals,
  setAlertErrorMsgFirstInterval,
  setAlertErrorMsgSecondInterval,
  setWeekIntervalFinishOrder,
  setSingleInterval,
  singleInterval,
  weekIntervalFinishOrder,
}) => {

  const authService = useContext(AuthContext);
  const isSeasonalRhythmEnabled = useMemo(() => {
    return authService.isSeasonalRhythmEnabled();
  }, [authService]);

  return (
    isSeasonalRhythmEnabled ? 
    (
      <WeekIntervalSelectorSeasonalRhythm
        allowedIntervals={allowedIntervals}
        alertErrorMsgFirstInterval={alertErrorMsgFirstInterval}
        alertErrorMsgSecondInterval={alertErrorMsgSecondInterval}
        getText={getText}
        initialIntervals={initialIntervals}
        setAlertErrorMsgFirstInterval={setAlertErrorMsgFirstInterval}
        setAlertErrorMsgSecondInterval={setAlertErrorMsgSecondInterval}
        setWeekIntervalFinishOrder={setWeekIntervalFinishOrder}
        setWholeYearInterval={setSingleInterval}
        weekIntervalFinishOrder={weekIntervalFinishOrder}
        wholeYearInterval={singleInterval}
      />
    )
    :
    (
      <WeekIntervalSelectorWeeklyRhythm
        allowedIntervals={allowedIntervals}
        alertErrorMsgFirstInterval={alertErrorMsgFirstInterval}
        alertErrorMsgSecondInterval={alertErrorMsgSecondInterval}
        getText={getText}
        initialIntervals={initialIntervals}
        setAlertErrorMsgFirstInterval={setAlertErrorMsgFirstInterval}
        setAlertErrorMsgSecondInterval={setAlertErrorMsgSecondInterval}
        setSingleInterval={setSingleInterval}
        setWeekIntervalFinishOrder={setWeekIntervalFinishOrder}
        singleInterval={singleInterval}
        weekIntervalFinishOrder={weekIntervalFinishOrder}
      />
    )
  );
};

export default WeekIntervalSelector;
