import { Box, Card, CardContent, Chip, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, useContext } from 'react';
import { Order } from '../../../model';

import { LanguageContext } from '../../../contexts/language-context';
import { getNextEmptyingDate } from './emptyingLocationUtils';

interface Props {
  order: Order;
}

const OrderCard: FC<Props> = ({ order }): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const theme = useTheme();
  const { palette } = useTheme();
  const smallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));
  const headerDirection = smallerThanSm ? 'column' : 'row';
  const headerJustify = smallerThanSm ? 'flex-start' : 'flex-end';

  return (
    <Card sx={{ m: 1, boxShadow: 0, border: `1px solid ${palette.divider}` }}>
      <CardContent>
        <Grid container columns={{ xs: 4, md: 8 }} rowSpacing={2}>
          <Grid item xs={4} md={8}>
            <Stack direction={headerDirection} spacing={1}>
              <Typography variant='h6'>{order.orderNumber}</Typography>
              <Box width='100%' display='flex' justifyContent={headerJustify}>
                <StatusChip status={order.orderStatus}/>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack direction='column'>
              <Typography variant='body2' color={palette.secondary.mainVariant}>
                {getText('emptying-location-order-date')}
              </Typography>
              <Typography variant='body1'>{getNextEmptyingDate(order.orderDate)}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack direction='column'>
              <Typography variant='body2' color={palette.secondary.mainVariant}>
                {getText('emptying-location-order-requested-date')}
              </Typography>
              <Typography variant='body1'>{getNextEmptyingDate(order.requestedDate)}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack direction='column'>
              <Typography variant='body2' color={palette.secondary.mainVariant}>
                {getText('emptying-location-order-info')}
              </Typography>
              {order.orderRows.map((row, index) => (
                <Box key={'order-info-' + order.orderNumber + index}>
                  <Typography variant='body1'>
                    {row.amount} x {row.contractName}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const StatusChip: FC<{ status: string }> = ({ status }) => {
  const { getText } = useContext(LanguageContext);
  const { palette } = useTheme();

  switch (status) {
    case '1':
    case 'Saved':
      return (
        <Chip
          sx={{ color: palette.warning.main, backgroundColor: palette.warning.background }}
          label={getText('emptying-location-order-status-received')}
        />
      );
    case '2':
    case 'Approved':
      return (
        <Chip
          sx={{ color: palette.warning.main, backgroundColor: palette.warning.background }}
          label={getText('emptying-location-order-status-processed')}
        />
      );
    case '3':
    case 'Done':
      return (
        <Chip
          sx={{ color: palette.success.main, backgroundColor: palette.success.background }}
          label={getText('emptying-location-order-status-done')}
        />
      );
    default: 
      return null;
  }
};

export default OrderCard;
