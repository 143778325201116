import React, { useContext } from "react";
import { IconButton } from "../components/common/inputs";
import VingoButton from "../components/common/VingoButton";
import { ReactComponent as TrashIcon } from '../assets/trash-2.svg';
import { LanguageContext } from "../contexts/language-context";
import { ArrayFieldTemplateProps } from "@rjsf/utils";

export function ArrayFieldTemplateAddAndRemove(props: ArrayFieldTemplateProps) {
  const { getText } = useContext(LanguageContext);
  
  return (
    <React.Fragment>
      {props.items.map((element: any) =>
        <div>
          {element.children}
          {
            element.hasRemove &&
            <IconButton type="button" style={{ marginLeft: '0.5rem', minWidth: '90%' }}>
              <TrashIcon onClick={element.onDropIndexClick(element.index)} />
            </IconButton>
          }
        </div>
      )}
      {props.canAdd && <VingoButton onClick={props.onAddClick}>{getText('array-add-button')}</VingoButton>}
    </React.Fragment>
  );
}