export default function HandleError(err: any,
  showDialog: any,
  authService: any) {
  if (err.statusCode === 401) {
    showDialog(
      'error-unauthorized-title',
      'error-unauthorized-message',
      () => authService.logout()
    );
  } else if (err?.response?.type === '/problems/e-services-users/account-invoice-number-mismatch') {
    showDialog('error-register-invoice-not-found-title', 'error-register-invoice-not-found-message');
  } else if (err?.response?.type === "/problems/identity-operation-failed") {
    showDialog('error-general-message', 'error-identity-operation-failed');
  } else if (err?.response?.type === "/problems/user/claim") {
    showDialog('error-register-user-account-already-registered-title', 'error-register-user-account-already-registered-message');
  } else if (err?.response?.type === "/problems/user/billing-account-expired") {
    showDialog('error-billing-account-expired-title', 'error-billing-account-expired-message');
  } else if (err?.response?.type === "/problems/e-services-users/no-connectable-accounts-found") {
    showDialog('no-connectable-accounts-found-title', 'no-connectable-accounts-found-message');
  } else {
    showDialog('error-service-break-title', 'error-service-break-message');
  }
}
