import { Dispatch, SetStateAction, useContext } from 'react';
import { Language } from '../../model';
import LogoNavigationLanguageBar from '../../components/common-materialui/header/LogoNavigationLanguageBar';
import { Box, Button, Container, Divider, Link, Stack, Typography, useTheme } from '@mui/material';
import { LanguageContext } from '../../contexts/language-context';
import ControlTextInput from '../../components/common-materialui/form/ControlTextInput';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import {
  RegisterFormData,
  RegisterFormDataInitialValues,
  RenderBasicInformationSection,
  renderUsernamePasswordSection,
} from './sharedRegisterSections';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../contexts/auth-context';
import { useMutation } from '@tanstack/react-query';
import { ExistingCustomerConnectParams } from '../../service/authService';
import ProgressComponent from '../../components/common-materialui/ProgressComponent';
import { getAlertWithErrorMessages } from '../../components/common-materialui/status-handling/httpErrorUtils';

interface Props {
  language: Language;
  setLanguage: Dispatch<SetStateAction<Language>>;
}

const RegisterExisting = ({ language, setLanguage }: Props): JSX.Element => {
  const { palette } = useTheme();
  const { getText } = useContext(LanguageContext);
  const { logIn, registerExistingAccount } = useContext(AuthContext);
  const history = useHistory();
  const methods = useForm<RegisterFormData>({ defaultValues: RegisterFormDataInitialValues });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const currentUsername = useWatch({
    control,
    name: 'username',
  });

  const currentPassword = useWatch({
    control,
    name: 'password',
  });

  const termsOfUse = useWatch({
    control,
    name: 'termsOfUse',
  });

  const {
    mutate: loginAfterRegister,
    isLoading: loginAfterRegisterLoading,
    error: loginAfterRegisterError,
    reset: loginAfterRegisterReset,
  } = useMutation(() => logIn(currentUsername, currentPassword), {
    onSuccess: () => history.push('/emptying-infos'),
  });

  const {
    mutate: register,
    isLoading: registerLoading,
    error: registerError,
    reset: registerReset,
  } = useMutation((data: ExistingCustomerConnectParams) => registerExistingAccount(data), {
    onSuccess: () => loginAfterRegister(),
  });

  function mapAndRegister(data: RegisterFormData) {
    if (data.invoicingNumber && data.customerNumber) {
      const inputData: ExistingCustomerConnectParams = {
        invoiceNumber: data.invoicingNumber,
        customerNumber: data.customerNumber,
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        email: data.email,
        userName: data.username,
        password: data.password,
        language: data.language,
      };
      register(inputData);
    }
  }

  function renderHeader() {
    return (
      <Stack spacing={2}>
        <Typography variant='h3'>{getText('login-info-create-account-existing')}</Typography>
        <Typography variant={'body2'} color={palette.text.primary}>
          {getText('register-existing-account-description')}
        </Typography>
      </Stack>
    );
  }

  function renderCustomershipInformationSection() {
    return (
      <Stack spacing={0}>
        <Typography mb={2} variant='h5'>
          {getText('register-customership-information')}
        </Typography>
        <ControlTextInput
          control={control}
          name='invoicingNumber'
          label='basic-info-invoice-number'
          error={errors.invoicingNumber}
          validations={['required', 'integer']}
        />
        <ControlTextInput
          control={control}
          name='customerNumber'
          label='basic-info-customer-number'
          error={errors.customerNumber}
          validations={['required']}
        />
      </Stack>
    );
  }

  function clearErrors() {
    registerReset();
    loginAfterRegisterReset();
  }

  function renderSendButtonAndLink() {
    return (
      <Stack spacing={3} justifyContent={'center'} textAlign={'center'}>
        {registerError || loginAfterRegisterError
          ? getAlertWithErrorMessages(registerError || loginAfterRegisterError, getText, clearErrors)
          : null}
        <Button
          variant='contained'
          disabled={registerLoading || loginAfterRegisterLoading || !termsOfUse}
          onClick={(e) => void handleSubmit(mapAndRegister)(e)}
        >
          {registerLoading || loginAfterRegisterLoading ? (
            <ProgressComponent variant='circle' isLoading={registerLoading || loginAfterRegisterLoading} />
          ) : (
            <Typography py={1} variant='button'>
              {getText('create-user')}
            </Typography>
          )}
        </Button>
        <Link component='button' onClick={() => history.push('/')}>
          {getText('forgot-password-return-to-login-link')}
        </Link>
      </Stack>
    );
  }

  return (
    <FormProvider {...methods}>
      <LogoNavigationLanguageBar />
      <Container>
        <Box
          sx={{
            backgroundColor: palette.background.level2,
            padding: 3,
            maxWidth: '515px',
            margin: 'auto',
            border: `1px solid ${palette.divider}`,
          }}
        >
          <Stack spacing={4}>
            {renderHeader()}
            {renderCustomershipInformationSection()}
            <Divider />
            <RenderBasicInformationSection getText={getText} titleKey='basic-info-modify' />
            {renderUsernamePasswordSection(control, errors, getText, currentPassword)}
            {renderSendButtonAndLink()}
          </Stack>
        </Box>
      </Container>
    </FormProvider>
  );
};

export default RegisterExisting;
