import { createContext } from 'react';
import AuthService from '../service/authService';

const dummyAuthService = new AuthService(
  {
    domain: '',
    tenantId: '',
    language: 'fi',
    environment: {},
  });

const AuthContext = createContext<AuthService>(dummyAuthService);

export default AuthContext;
