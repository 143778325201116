import { FC, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { Language, convertToLanguage } from '../model';
import AuthService, { EServiceLanguage } from '../service/authService';

// this should be merged with LanguageContextProvider

interface LanguageSelectionContextInputProps {
  authService: AuthService;
  language: Language;
  setLanguage: React.Dispatch<React.SetStateAction<Language>>;
};

interface LanguageSelectionContextProps {
  availableLanguages: EServiceLanguage[];
  language: Language;
  setLanguage: React.Dispatch<React.SetStateAction<Language>>;
  getLanguageSelectionText: (language: Language | EServiceLanguage) => string;
  getLanguageSelectionBasicFormText: (language: Language | EServiceLanguage) => string;
};

export const LanguageSelectionContext = createContext<LanguageSelectionContextProps>({} as LanguageSelectionContextProps);

export const LanguageSelectionContextProvider: FC<LanguageSelectionContextInputProps> = ({
  authService, 
  language,
  setLanguage, 
  children,
}) => {

  // Set up available languages
  const [loadedLanguages, setLoadedLanguages] = useState<EServiceLanguage[]>([]);

  useEffect(() => {
    authService.getCompanyInfo().then((companyInfo) => {
      setLoadedLanguages(companyInfo?.eServiceLanguages ?? []);
    });
  }, [authService]);
  // If selectableLanguages is empty, use all languages
  const availableLanguages = useMemo(() => {
    return loadedLanguages.length > 0 ? loadedLanguages : Object.values(EServiceLanguage);
  }, [loadedLanguages]);

  // If the language current used by the user is removed from the selectable languages, change the language to first available
  useEffect(() => {
    // Type conversion from EServiceLanguage to Language
    const languages = availableLanguages.map((lang) => convertToLanguage(lang));
    if (!languages.includes(language)) {
      setLanguage(languages[0]);
    }
  }, [availableLanguages, language, setLanguage]);

  /** Get the text string for each language.
 *  Each string should be in the language of the key:
 * - en: English
 * - fi: Suomeksi
 * - sv: På svenska
 * @param language Language to get the text for
 * @returns Text for the language (string)
 * */
  const getLanguageSelectionText = useCallback((language: Language | EServiceLanguage): string => {
    switch (language.toLocaleLowerCase()) {
      case 'en':
        return 'English';
      case 'sv':
        return 'På svenska';
      case 'fi':
      default:
        return 'Suomeksi';
    }
  }, []);

/** Get the text string for each language.
 * Each string should be in the language of the key:
 * - en: English
 * - fi: Suomi
 * - sv: Svenska
 * @param language Language to get the text for
 * @returns Text for the language (string)
 * */
const getLanguageSelectionBasicFormText = useCallback((language: Language | EServiceLanguage) : string => {
  switch (language.toLocaleLowerCase()) {
    case 'en':
      return 'English';
    case 'sv':
      return 'Svenska';
    case 'fi':
    default:
      return 'Suomi';
  };
}, []);

  const memoizedValue = useMemo(() => ({ 
    availableLanguages,
    getLanguageSelectionText,
    getLanguageSelectionBasicFormText,
    language, 
    setLanguage, 
  }), [
    availableLanguages,
    getLanguageSelectionText,
    getLanguageSelectionBasicFormText,
    language, 
    setLanguage,
  ]);


  return (
    <LanguageSelectionContext.Provider value={memoizedValue}>
      {children}
    </LanguageSelectionContext.Provider>
  );
}