import { FC, useContext } from 'react';
import { Stack, TableCell, TableRow, Typography, useTheme } from '@mui/material';
import SharedContainerCard from './SharedContainerCard';
import { DataTable } from '../../../components/common-materialui/data-table/DataTable';
import { TableHeaderProps } from '../../../components/common-materialui/data-table/interfaces';
import { LanguageContext } from '../../../contexts/language-context';
import { SharedContainerPartner, UiTexts } from '../../../model';

interface Props {
  sharedContainerPartners: SharedContainerPartner[];
}
interface SharedContainerRowProps {
  partner: SharedContainerPartner;
}

function sharedContainerTableTableHeaders(getText: (key: keyof UiTexts, params?: string[] | undefined) => string) {
  const headers: TableHeaderProps[] = [
    {
      id: 'shareholders',
      label: getText('emptying-group-shared-container-shareholders-table-header'),
      alignment: 'left',
    },
    {
      id: 'address',
      label: getText('emptying-group-shared-container-address-table-header'),
      alignment: 'left',
    },
    {
      id: 'share',
      label: getText('emptying-group-shared-container-share-table-header'),
      alignment: 'left',
    },
    {
      id: 'weighing',
      label: getText('emptying-group-shared-container-weighing-table-header'),
      alignment: 'left',
    },
  ];

  return headers;
}

const SharedContainerRow: FC<SharedContainerRowProps> = ({ partner }) => {
  const { palette } = useTheme();

  const cellStyle = {
    borderBottom: `1px solid ${palette.divider}`,
  };

  return (
    <TableRow>
      <TableCell align='left' sx={cellStyle}>
        <Typography variant='body1'>{partner.name}</Typography>
      </TableCell>
      <TableCell align='left' sx={cellStyle}>
        <Typography variant='body1'>{partner.address.streetAddress}</Typography>
      </TableCell>
      <TableCell align='left' sx={cellStyle}>
        <Typography variant='body1' color='textSecondary'>
          {partner.percentage === 0 ? partner.percentage : `${partner.percentage} %`}
        </Typography>
      </TableCell>
      <TableCell align='left' sx={cellStyle}>
        <Typography variant='body1' color='textSecondary'>
          {partner.emphasis}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

const renderRows = (partners: SharedContainerPartner[]) => {
  return partners.map((partner) => {
    return (
      <SharedContainerRow
        partner={partner}
        key={`shared-container-partner-${partner.name}-${partner.address.postalCode}`}
      />
    );
  });
};

const renderCards = (partners: SharedContainerPartner[]): JSX.Element[] => {
  return partners.map((partner) => {
    return (
      <SharedContainerCard
        partner={partner}
        key={`shared-container-partner-${partner.name}-${partner.address.postalCode}`}
      />
    );
  });
};

const SharedContainerTable: FC<Props> = ({ sharedContainerPartners }): JSX.Element => {
  const { getText } = useContext(LanguageContext);

  return (
    <Stack style={{ marginTop: '1rem' }}>
      <DataTable
        headers={sharedContainerTableTableHeaders(getText)}
        customRows={renderRows(sharedContainerPartners)}
        cards={renderCards(sharedContainerPartners)}
      />
    </Stack>
  );
};

export default SharedContainerTable;
