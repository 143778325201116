import React, {
  useContext,
  FormEvent,
  SetStateAction,
  Dispatch,
  useState,
  useEffect,
  useRef,
} from "react";
import { useHistory } from "react-router-dom";
import ApiContext from "../../../contexts/api-context";
import AuthContext from "../../../contexts/auth-context";
import DialogContext from "../../../contexts/dialog-context";
import { LanguageContext } from "../../../contexts/language-context";
import { Address, ContactMethod, EmptyingInfo } from "../../../model";
import { Content, ContentRow, Form } from "../../common/containers";
import DropDown from "../../common/DropDown";
import { SecondaryHeader, TertiaryHeader } from "../../common/headers";
import { RadioButton } from "../../common/inputs";
import TextInput from "../../common/TextInput";
import VingoButton from "../../common/VingoButton";

type FeedbackFormProps = {
  feedbackHeaderText: string;
  setFeedbackHeaderText: Dispatch<SetStateAction<string>>;
  feedbackText: string;
  setFeedbackText: Dispatch<SetStateAction<string>>;
  contactMethod: ContactMethod;
  setContactMethod: Dispatch<SetStateAction<ContactMethod>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  phone: string;
  setPhone: Dispatch<SetStateAction<string>>;
  emptyingInfoId?: string;
  setEmptyingInfoId?: Dispatch<SetStateAction<string>>;
  setEmptyingName?: Dispatch<SetStateAction<string>>;
  setEmptyingAddress?: Dispatch<SetStateAction<Address>>;
  selectEmptyingInfo?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  isSubmitted: boolean;
  setIsSubmitted: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  setMessageCategory: Dispatch<SetStateAction<string>>;
  messageCategoryId: string;
  setMessageCategoryId: Dispatch<SetStateAction<string>>;
  handleSubmit: (event: FormEvent) => void;
};

const FeedbackForm = (props: FeedbackFormProps): JSX.Element => {
  const {
    feedbackHeaderText,
    setFeedbackHeaderText,
    feedbackText,
    setFeedbackText,
    contactMethod,
    setContactMethod,
    email,
    setEmail,
    phone,
    setPhone,
    emptyingInfoId,
    setEmptyingInfoId,
    selectEmptyingInfo,
    setEmptyingName,
    setEmptyingAddress,
    isSubmitted,
    setIsSubmitted,
    isLoading,
    setMessageCategory,
    messageCategoryId,
    setMessageCategoryId,
    handleSubmit,
  } = props;

  const api = useContext(ApiContext);
  const { getText, lang } = useContext(LanguageContext);
  const showDialog = useContext(DialogContext);
  const authService = useContext(AuthContext);
  const history = useHistory();

  const componentMounted = useRef(true);

  const [emptyingInfosOptions, setEmptyingInfosOptions] = useState<[string, string][]>([]);
  const [emptyingInfos, setEmptyingInfos] = useState<EmptyingInfo[]>([]);
  const [messageCategories, setMessageCategories] = useState<[string, string][]>([]);

  const handleError = (err: any) => {
    if (componentMounted.current) {
      if (err.statusCode === 401) {
        showDialog(
          'error-unauthorized-title',
          'error-unauthorized-message',
          () => history.replace('/logout')
        );
      }
      else {
        showDialog("error-service-break-title", "error-service-break-message");
      }
    }
  };

  useEffect(() => {
    if (selectEmptyingInfo) {
      getEmptyingInfos().catch((err: any) => {
        handleError(err);
      });
    }

    getMessageCategories().catch((err: any) => {
      handleError(err);
    });

    return () => {
      componentMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEmptyingInfos = () => {
    return api.fetchEmptyingInfos().then((infos) => {
      if (componentMounted.current) {
        setEmptyingInfos(infos);
        setEmptyingInfosOptions(infos.map((info) => [info.id, info.name + ", "
          + info.address?.street + ", " + info.address?.postalCode + " " + info.address?.postOffice]));
        if (infos.length > 0 && setEmptyingInfoId) {
          setEmptyingInfoId(infos[0].id);
        }
      }
    });
  };

  // Used for filtering white space only values away from message categories list
  const validateMessageCategory = (categoryName: string): boolean => {
    if (!categoryName.replace(/\s/g, '').length) {
      return false;
    }
    return true;
  };

  const getMessageCategories = () => {
    return api.fetchMessageCategories(authService.getTenantId(), lang)
      .then((categories) => setMessageCategories(
        categories
          .filter(c => validateMessageCategory(c.name))
          .map(c => [c.id, c.name]))
      );
  };

  useEffect(() => {
    if (messageCategoryId && messageCategories.length > 0) {
      const category = messageCategories.find(x => x[0] === messageCategoryId) ?? "";
      setMessageCategory(category[1]);
    }
  }, [messageCategoryId]);

  /*
  If message categories are found, assign first value in selection as messageCategory
  */
  useEffect(() => {
    if (messageCategories) {
      setMessageCategoryId(messageCategories.flat()[0]);
    }
  }, [messageCategories]);

  useEffect(() => {
    if (emptyingInfoId && emptyingInfos.length > 0) {
      const info = emptyingInfos.find(x => x.id === emptyingInfoId);
      if (info && setEmptyingName && setEmptyingAddress) {
        setEmptyingName(info.name);
        setEmptyingAddress(info.address);
      }
    }
  }, [emptyingInfoId]);

  const selectMessageCategory = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    if (e.target.value && setMessageCategoryId) {
      //Set value/"MessageCategory.Id"
      setMessageCategoryId(e.target.value);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Content>
        <SecondaryHeader>{getText("feedback")}</SecondaryHeader>
        {selectEmptyingInfo ? (
          <>
            <TertiaryHeader>{getText("feedback-emptying-info")}</TertiaryHeader>
            <DropDown
              required={true}
              value={emptyingInfoId}
              onChange={selectEmptyingInfo}
              options={emptyingInfosOptions}
            />
          </>
        ) : null}

        <TertiaryHeader>
          {getText('feedback-message-category')}
        </TertiaryHeader>

        <DropDown
          value={messageCategoryId}
          onChange={selectMessageCategory}
          options={messageCategories}
          required={true}
          showError={isSubmitted}
        />

        <TextInput
          label="feedback-message-header"
          val={feedbackHeaderText}
          setter={setFeedbackHeaderText}
          maxLength={255}
        />
        <TextInput
          label="feedback-message"
          val={feedbackText}
          setter={setFeedbackText}
          multiline={true}
          validations={["required"]}
          showErrors={isSubmitted}
        />
      </Content>
      <Content>
        <SecondaryHeader>{getText("feedback-contact-method")}</SecondaryHeader>

        <ContentRow style={{ marginTop: "0.5rem" }}>
          <RadioButton
            type="radio"
            name="contact-type-selection"
            id="contact-phone"
            checked={contactMethod === "phone"}
            onChange={() => setContactMethod("phone")}
          />
          <label htmlFor="contact-phone">
            {getText("feedback-contact-method-phone")}
          </label>
        </ContentRow>
        <ContentRow>
          <RadioButton
            type="radio"
            name="contact-type-selection"
            id="contact-email"
            checked={contactMethod === "email"}
            onChange={() => setContactMethod("email")}
          />
          <label htmlFor="contact-email">
            {getText("feedback-contact-method-email")}
          </label>
        </ContentRow>
        <ContentRow>
          <RadioButton
            type="radio"
            name="contact-type-selection"
            id="contact-none"
            checked={contactMethod === "none"}
            onChange={() => setContactMethod("none")}
          />
          <label htmlFor="contact-none">
            {getText("feedback-contact-method-none")}
          </label>
        </ContentRow>
        <TextInput
          label="feedback-email"
          val={email}
          setter={setEmail}
          validations={contactMethod === "email" ? ["required", "email"] : ["email"]}
          showErrors={isSubmitted}
        />
        <TextInput
          label="feedback-phone"
          val={phone}
          setter={setPhone}
          validations={contactMethod === "phone" ? ["required"] : []}
          showErrors={isSubmitted}
        />
      </Content>
      <Content>
        <VingoButton isLoading={isLoading} onClick={() => setIsSubmitted(true)}>
          {getText("feedback-send-button")}
        </VingoButton>
      </Content>
    </Form>
  );
};

export default FeedbackForm;
