import React, { useContext, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MenuContent, MenuLowerPart, MenuMask, RightUpperCornerContainer } from '../common/containers';

import AuthContext from '../../contexts/auth-context';
import { LanguageContext } from '../../contexts/language-context';
import { ReactComponent as LogoutSvg } from '../../assets/log-out.svg';
import { ReactComponent as UserSvg } from '../../assets/user.svg';
import { MenuCloseButton, MenuLinkButton } from './inputs';
import { ReactComponent as XIcon } from '../../assets/x.svg';

import { MENU_TRANSPARENT_PART_WIDTH } from '../../styleConstants';
import colors from '../../colors';

type MenuProps = {
  closeMenu: () => void;
  open: boolean;
};

const Menu = (props: MenuProps) => {
  const authService = useContext(AuthContext);
  const ref = useRef<HTMLDivElement>(null);
  const { getText } = useContext(LanguageContext);

  const { closeMenu, open } = props;

  useEffect(() => {
    // Close menu when transparent part is clicked.
    if (open) {
      const onBodyClick = (event: any) => {
        if (!ref.current?.contains(event.target)) {
          closeMenu();
        }
      };
      document.body.addEventListener('click', onBodyClick);
      return () => {
        document.body.removeEventListener('click', onBodyClick);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  let maskStyle = {
    zIndex: -1,
    backgroundColor: colors.menuClosedTransparentPart,
  };
  let contentStyle = {
    transform: `translateX(calc(100% + ${MENU_TRANSPARENT_PART_WIDTH}))`,
    overflow: 'scroll',
  };

  if (open) {
    maskStyle['zIndex'] = 9999;
    maskStyle['backgroundColor'] = colors.menuTransparentPart;
    contentStyle['transform'] = `translateX(calc(0% + ${MENU_TRANSPARENT_PART_WIDTH}))`;
  }

  return (
    <MenuMask style={maskStyle}>
      <MenuContent ref={ref} style={contentStyle}>
        <RightUpperCornerContainer>
          <MenuCloseButton onClick={() => closeMenu()}>
            <XIcon />
          </MenuCloseButton>
        </RightUpperCornerContainer>
        <Link className='menu-link' to='/emptying-infos' onClick={() => closeMenu()}>
          {getText('emptying-infos')}
        </Link>
        {authService.isShowInvoicesEnabled() && (
          <Link className='menu-link' to='/invoices' onClick={() => closeMenu()}>
            {getText('invoices')}
          </Link>
        )}
        {authService.isCustomerSupportEnabled() && (
          <Link className='menu-link' to='/customer-support' onClick={() => closeMenu()}>
            {getText('customer-service')}
          </Link>
        )}
        {authService.isPermitApplicationEnabled() && (
          <Link className='menu-link' to='/permit-application' onClick={() => closeMenu()}>
            {getText('permit-application')}
          </Link>
        )}

        {authService.isCompostNotificationEnabled() && (
          <Link className='menu-link' to='/composting-notifications' onClick={() => closeMenu()}>
            {getText('composting-notifications')}
          </Link>
        )}

        <MenuLowerPart>
          <Link className='menu-link' to='/account-settings' onClick={() => closeMenu()}>
            <UserSvg /> &nbsp; {getText('account-settings')}
          </Link>
          <Link className='menu-link' to='/logout' onClick={() => closeMenu()}>
            <LogoutSvg /> &nbsp; {getText('logout')}
          </Link>
        </MenuLowerPart>
      </MenuContent>
    </MenuMask>
  );
};
export default Menu;
