import { Language, MessageCategory, EnvironmentSettings, ConsumerEInvoiceType, AllInvoicingMethodTypes } from '../model';

import { TOKEN_EXPIRATION_GAP_SECONDS } from '../constants';
import {
  STORED_AUTH_TOKEN,
  STORED_AUTH_SAMLNAMEID,
  STORED_AUTH_SESSIONINDEX,
  STORED_COMPANY_INFO,
} from '../localStorageKeys';
import HttpError from '../util/HttpError';

// Backend returns this type of data
export type AuthToken = {
  token: string;
  expiresAt: Date;
};

export type RegisterInputParams = {
  firstName?: string;
  lastName?: string;
  companyName?: string;

  phoneNumber: string;
  email: string;

  userName: string;
  password: string;
  language: Language;
};

export type PrivateCustomerRegistrationParams = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  socialSecurityNumber?: string;
  contactName?: string;
  userName: string;
  password: string;
  streetAddress?: string;
  postCode?: string;
  city?: string;
  emailInvoice?: boolean;
  language: Language;
  consumerEInvoiceType: ConsumerEInvoiceType;
};

export type ExistingCustomerConnectParams = {
  invoiceNumber: number;
  customerNumber: string;
  userName: string;
  password: string;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  language: string;
};

export type ExistingCustomerSamlConnectParams = {
  identificationId: string;
  userName: string;
  password: string;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  language: string;
};

export type IdentifiedPersonRegistrationParams = {
  identificationId: string;
  phone: string;
  email: string;
  userName: string;
  password: string;
  language: string;
};

export type IdentifiedExistingCustomerConnectParams = {
  invoiceNumber: number;
  customerNumber: string;
  identificationId: string;
  userName: string;
  password: string;
  email: string;
  phoneNumber: string;
  language: string;
};

export type IdentifiedExistingCustomerConnectSsnParams = {
  identificationId: string;
  userName: string;
  password: string;
  email: string;
  phoneNumber: string;
  language: string;
};

export type ResetPasswordParams = {
  email: string;
  userName: string;
  token: string;
  password: string;
};

export type CompanyRegistrationParams = {
  companyName: string;
  companyPhone: string;
  companyId: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  userName: string;
  password: string;
  streetAddress: string;
  postCode: string;
  city: string;
  consumerEInvoiceType: ConsumerEInvoiceType;
  billingEmail?: string;
  eBillData?: EBillData;
};

// Same as BillingInfoInput Dto in Customers
export interface BillingInfoInput {
  personalIdentificationNumber?: string;
  companyName?: string;
  companyCode?: string;
  billingEmail: string;
  firstName?: string;
  lastName?: string;
  address?: string;
  postCode?: string;
  city?: string;
  language?: string;
  countryCode?: string;
  phoneNumber?: string;
  consumerEInvoiceType: AllInvoicingMethodTypes;
  emailInvoicing: boolean;
  contactName?: string;
  email?: string | null;
  reference?: string;
  eInvoiceInformation?: EBillData;
  municipalityCode?: string;
}

export interface BillingInfoReply {
  billingAccountNumber: string;
}

// This needs to match Dto structure in Customers
export interface EmptyingLocationInfoInput {
  firstName?: string;
  lastName?: string;
  companyName?: string;
  address: string;
  postCode: string;
  city: string;
  contactPersonFirstName: string;
  contactPersonLastName: string;
  phoneNumber: string;
  email: string;
  municipalityCode: string;
  conurbation: string;
  buildingId: string;
  operationCode: string;
  buildingClassificationCode: string;
  residentCount: string;
  realEstateId: string;
  contractDate: Date;
  referenceId: string;
  apartmentCount: string;
}

type EBillData = {
  address: string;
  operator: string;
  ediCode: string;
};

export enum EServiceLanguage {
  Fi = 'Fi',
  Sv = 'Sv',
  En = 'En',
}

export type CompanyInfo = {
  name?: string;
  color?: string;
  eServiceLanguages: EServiceLanguage[];
};

// Auth service parameters
type AuthServiceProps = {
  domain: string;
  tenantId: string;
  language: Language;
  environment?: EnvironmentSettings;
};

/**
 * Auth Service
 * Provides functions for connecting to backend endpoints.
 *
 * Functions for making requests that don't need login (eg. register, login)
 * are found here.
 */
export default class AuthService {
  private props: AuthServiceProps;

  constructor(params: AuthServiceProps) {
    this.props = params;
  }

  getDomain = (): string => this.props.domain;
  getTenantId = (): string => this.props.tenantId;

  getCompanyInfo = async (): Promise<CompanyInfo | null> => {
    let result: CompanyInfo | null = null;
    // Fetch to get latest.
    result = await this.fetchCompanyInfo()
      .then((info) => {
        if (info) {
          return info;
        }
      })
      .catch((err) => {
        return null;
      });
    if (!result) {
      // Some reason fetch failed, try to get from local storage.
      const companyString = localStorage.getItem(STORED_COMPANY_INFO);
      if (typeof companyString === 'string') {
        try {
          result = JSON.parse(companyString);
        } catch { }
      }
    }

    return result;
  };

  private getUnAuthorizedRequestHeaders = () => {
    return {
      'Content-Type': 'application/json;charset=utf-8',
      'Accept-Language': this.props.language,
      'Tenant-Id': this.props.tenantId,
    };
  };

  private scheduleLoginRefresh = (expiresInSeconds: number) => {
    if (expiresInSeconds) {
      setTimeout(() => {
        this.refreshLogin();
      }, expiresInSeconds * 1000);
    }
  };

  /**
   * Logs user in and schedules a login refresh based on fetched token's expriation time.
   * @param userName
   * @param password
   */
  logIn = async (userName: string, password: string): Promise<void> => {
    const authToken = await this.fetchToken(userName, password);
    this.setToken(authToken);
    const expiresIn = this.authTokenExpiresIn(authToken.expiresAt);
    this.scheduleLoginRefresh(expiresIn - TOKEN_EXPIRATION_GAP_SECONDS);
  };

  logInWithToken = async (token: string, expiresAt: Date, nameId: string, sessionIndex: string): Promise<void> => {
    const authToken: AuthToken = { token, expiresAt };
    this.setToken(authToken);
    this.setSamlSession(nameId, sessionIndex);
    const expiresIn = this.authTokenExpiresIn(authToken.expiresAt);
    this.scheduleLoginRefresh(expiresIn - TOKEN_EXPIRATION_GAP_SECONDS);
  };

  clearSession = (): void => {
    localStorage.removeItem(STORED_AUTH_TOKEN);
    localStorage.removeItem(STORED_AUTH_SAMLNAMEID);
    localStorage.removeItem(STORED_AUTH_SESSIONINDEX);
  };

  logout = (): void => {
    this.backendLockout();
    this.clearSession();
  };

  hasSamlSession = (): boolean => {
    return (
      localStorage.getItem(STORED_AUTH_SAMLNAMEID) != null && localStorage.getItem(STORED_AUTH_SESSIONINDEX) != null
    );
  };

  loggedIn = () => {
    return !!this.getToken() && this.isTokenValid();
  };

  isPermitApplicationEnabled = () => {
    if (this.props.environment?.PermitApplication) {
      return true;
    } else {
      return false;
    }
  };

  isDevelopmentEnabled = () => {
    if (this.props.environment?.Development) {
      return true;
    } else {
      return false;
    }
  };

  isCustomerSupportEnabled = () => {
    if (this.props.environment?.CustomerSupport) {
      return true;
    } else {
      return false;
    }
  };

  isCompostNotificationEnabled = () => {
    if (this.props.environment?.CompostNotification) {
      return true;
    } else {
      return false;
    }
  };

  isAddEmptyingAccountEnabled = () => {
    if (this.props.environment?.AddEmptyingLocation) {
      return true;
    } else {
      return false;
    }
  };

  isAddBillingAccountEnabled = () => {
    if (this.props.environment?.AddBillingLocation) {
      return true;
    } else {
      return false;
    }
  };

  isReadOnlyBillingAddressEnabled = () => {
    if (this.props.environment?.ReadOnlyBillingAddress) {
      return true;
    } else {
      return false;
    }
  };

  isStrongAuthenticationEnabled = () => {
    if (this.props.environment?.StrongAuthentication) {
      return true;
    } else {
      return false;
    }
  };

  isExtraEmptyingEnabled = () => {
    if (this.props.environment?.ExtraEmptying) {
      return true;
    } else {
      return false;
    }
  };

  isModifyServiceEnabled = () => {
    if (this.props.environment?.ModifyService) {
      return true;
    } else {
      return false;
    }
  };

  isModifyEmptyingAccountEnabled = () => {
    if (this.props.environment?.ModifyEmptyingLocation) {
      return true;
    } else {
      return false;
    }
  };

  isModifyCompanyBillingContactPersonNameEnabled = () => {
    if (this.props.environment?.ModifyCompanyBillingContactPersonName) {
      return true;
    } else {
      return false;
    }
  };

  isAddServiceEnabled = () => {
    if (this.props.environment?.AddService) {
      return true;
    } else {
      return false;
    }
  };

  isShowInvoicesEnabled = () => {
    if (this.props.environment?.ShowInvoices) {
      return true;
    } else {
      return false;
    }
  };

  isShowInvoicePictureEnabled = () => {
    if (this.props.environment?.ShowInvoicePicture) {
      return true;
    } else {
      return false;
    }
  };

  isAllowRegisterPrivateEnabled = () => {
    if (this.props.environment?.RegisterPrivate) {
      return true;
    } else {
      return false;
    }
  };

  isAllowRegisterCompanyEnabled = () => {
    if (this.props.environment?.RegisterCompany) {
      return true;
    } else {
      return false;
    }
  };

  isAllowRegisterEmailInvoicingEnabled = () => {
    if (this.props.environment?.RegisterEmailInvoicing) {
      return true;
    } else {
      return false;
    }
  };

  isModifyEmptyingIntervalEnabled = () => {
    if (this.props.environment?.ModifyEmptyingInterval) {
      return true;
    } else {
      return false;
    }
  };

  isOrderHistoryEnabled = () => {
    if (this.props.environment?.ShowOrderHistory) {
      return true;
    } else {
      return false;
    }
  };

  isOrderExtraInformationEnabled = () => {
    if (this.props.environment?.ShowOrderExtraInformation) {
      return true;
    } else {
      return false;
    }
  };

  isEmptyingTimesPerWeekEnabled = () => {
    if (this.props.environment?.ShowEmptyingTimesPerWeek) {
      return true;
    } else {
      return false;
    }
  };

  isReportingEnabled = () => {
    if (this.props.environment?.ReportingEnabled) {
      return true;
    } else {
      return false;
    }
  };

  isCreateNewCustomerEnabled = () => {
    if (this.props.environment?.CreateNewCustomer) {
      return true;
    } else {
      return false;
    }
  };

  isCreateExistingCustomerEnabled = () => {
    if (this.props.environment?.CreateExistingCustomer) {
      return true;
    } else {
      return false;
    }
  };

  isWellCollectionEnabled = () => {
    if (this.props.environment?.WellCollectionEnabled) {
      return true;
    } else {
      return false;
    }
  };

  isSeasonalRhythmEnabled = () => {
    if (this.props.environment?.SeasonalRhythm) {
      return true;
    } else {
      return false;
    }
  };

  // Refreshes token if user is logged in.
  // And schedule new refresh based on new token expiration time.
  refreshLogin = async (): Promise<void> => {
    if (this.loggedIn()) {
      const newToken: AuthToken = await this.fetch('Users/refresh-login', {
        method: 'POST',
      });
      this.setToken(newToken);
      this.scheduleLoginRefresh(this.authTokenExpiresIn(newToken.expiresAt) - TOKEN_EXPIRATION_GAP_SECONDS);
    }
  };

  registerIdentifiedPerson = (params: IdentifiedPersonRegistrationParams): Promise<void> =>
    this.fetch(
      `Users/register/identifiedperson`,
      {
        method: 'POST',
        body: JSON.stringify({
          identificationId: params.identificationId,
          userName: params.userName,
          password: params.password,
          email: params.email,
          phoneNumber: params.phone,
          language: params.language?.toLocaleLowerCase()
        }),
      },
      this.getUnAuthorizedRequestHeaders()
    );

  connectExistingIdentifiedCustomer = (params: IdentifiedExistingCustomerConnectParams): Promise<void> =>
    this.fetch(
      `Users/register-existing-account/identifiedperson`,
      {
        method: 'POST',
        body: JSON.stringify({
          invoiceNumber: params.invoiceNumber,
          customerNumber: params.customerNumber,
          identificationId: params.identificationId,
          userName: params.userName,
          password: params.password,
          email: params.email,
          phoneNumber: params.phoneNumber,
          language: params.language?.toLocaleLowerCase()
        }),
      },
      this.getUnAuthorizedRequestHeaders()
    );

  connectExistingIdentifiedCustomerWithSsn = (params: IdentifiedExistingCustomerConnectSsnParams): Promise<void> =>
    this.fetch(
      `Users/register-existing-account/identifiedperson-ssn`,
      {
        method: 'POST',
        body: JSON.stringify({
          identificationId: params.identificationId,
          userName: params.userName,
          password: params.password,
          email: params.email,
          phoneNumber: params.phoneNumber,
          language: params.language?.toLocaleLowerCase(),
        }),
      },
      this.getUnAuthorizedRequestHeaders()
    );

  registerPrivateCustomer = (params: PrivateCustomerRegistrationParams): Promise<void> =>
    this.fetch(
      `Users/register/private`,
      {
        method: 'POST',
        body: JSON.stringify({
          userName: params.userName,
          password: params.password,
          email: params.email,
          phoneNumber: params.phone,
          personalIdentificationNumber: params.socialSecurityNumber,
          firstName: params.firstName,
          lastName: params.lastName,
          contactPerson: params.contactName,
          address: params.streetAddress,
          postCode: params.postCode,
          city: params.city,
          consumerEInvoiceType: params.consumerEInvoiceType,
        }),
      },
      this.getUnAuthorizedRequestHeaders()
    );

  registerCompanyCustomer = (params: CompanyRegistrationParams): Promise<void> =>
    this.fetch(
      `Users/register/company`,
      {
        method: 'POST',
        body: JSON.stringify({
          companyId: params.companyId,
          contactName: params.contactName,
          contactPhoneNumber: params.contactPhone,
          billingEmail: params.billingEmail,
          eInvoiceInformation: params.eBillData
            ? {
              address: params.eBillData.address,
              operator: params.eBillData.operator,
              ediCode: params.eBillData.ediCode,
            }
            : null,
          userName: params.userName,
          password: params.password,
          email: params.contactEmail,
          phoneNumber: params.companyPhone,
          companyName: params.companyName,
          address: params.streetAddress,
          postCode: params.postCode,
          city: params.city,
          consumerEInvoiceType: params.consumerEInvoiceType,
        }),
      },
      this.getUnAuthorizedRequestHeaders()
    );

  registerAccount = (params: RegisterInputParams): Promise<void> =>
    this.fetch(
      `Users/register`,
      {
        method: 'POST',
        body: JSON.stringify({
          userName: params.userName,
          password: params.password,
          email: params.email,
          phoneNumber: params.phoneNumber,
          language: params.language?.toLocaleLowerCase(),
          firstName: params?.firstName ?? undefined,
          lastName: params?.lastName ?? undefined,
          companyName: params?.companyName ?? undefined,
        }),
      },
      this.getUnAuthorizedRequestHeaders()
    );

  registerExistingAccount = (params: ExistingCustomerConnectParams): Promise<void> =>
    this.fetch(
      `Users/register-existing-account`,
      {
        method: 'POST',
        body: JSON.stringify({
          invoiceNumber: params.invoiceNumber,
          customerNumber: params.customerNumber,
          userName: params.userName,
          password: params.password,
          email: params.email,
          phoneNumber: params.phoneNumber,
          firstName: params.firstName,
          lastName: params.lastName,
          language: params.language?.toLocaleLowerCase(),
        }),
      },
      this.getUnAuthorizedRequestHeaders()
    );

  addBillingAccount = (params: BillingInfoInput): Promise<BillingInfoReply> =>
    this.fetch(
      `Users/account/billing-infos`,
      {
        method: 'POST',
        body: JSON.stringify({
          personalIdentificationNumber: params.personalIdentificationNumber,
          companyName: params.companyName,
          companyCode: params.companyCode,
          firstName: params.firstName,
          lastName: params.lastName,
          address: params.address,
          postCode: params.postCode,
          city: params.city,
          language: params.language,
          countryCode: params.countryCode,
          email: params.email,
          phoneNumber: params.phoneNumber,
          consumerEInvoiceType: params.consumerEInvoiceType,
          emailInvoicing: params.emailInvoicing,
          contactName: params.contactName,
          billingEmail: params.billingEmail,
          reference: params.reference,
          eInvoiceInformation: params.eInvoiceInformation,
        }),
      },
      this.getUnAuthorizedRequestHeaders()
    );

  addEmptyingLocation = (billingId: string, params: EmptyingLocationInfoInput): Promise<void> =>
    this.fetch(
      `customers/billing-infos/${billingId}/emptying-location`,
      {
        method: 'POST',
        body: JSON.stringify(params),
      },
      this.getUnAuthorizedRequestHeaders()
    );

  // TODO change correct endpoint url and body when backend is ready
  sendFeedback = (
    feedbackText: string,
    feedbackMethod: string,
    email: string,
    phone: string,
    feedbackCategoryId: string
  ) =>
    this.fetch(
      'customers/feedback',
      {
        method: 'POST',
        body: JSON.stringify({ email, phone, feedbackText, feedbackMethod, feedbackCategoryId }),
      },
      this.getUnAuthorizedRequestHeaders()
    );

  fetchMessageCategories = (languageCode: string): Promise<MessageCategory[]> =>
    this.fetch(
      `Company/${this.getTenantId()}/feedback-categories/${languageCode}`,
      {
        method: 'GET',
      },
      this.getUnAuthorizedRequestHeaders()
    );

  forgotUsername = (email: string) =>
    this.fetch(
      `Users/forgot-username?email=${email}`,
      {
        method: 'POST',
      },
      this.getUnAuthorizedRequestHeaders()
    );

  forgotPassword = (email: string, username: string) =>
    this.fetch(
      'Users/forgot-password',
      {
        method: 'POST',
        body: JSON.stringify({ email, username }),
      },
      this.getUnAuthorizedRequestHeaders()
    );

  resetPassword = (params: ResetPasswordParams) =>
    this.fetch(
      'Users/reset-password',
      {
        method: 'POST',
        body: JSON.stringify({ ...params }),
      },
      this.getUnAuthorizedRequestHeaders()
    );

  fetchCompanyInfo = () => {
    return this.fetch(
      `Company/${this.props.tenantId}`,
      {
        method: 'GET',
      },
      {}
    );
  };

  fetchUiTexts = () => {
    return this.fetch(
      `Company/${this.props.tenantId}/ui-texts`,
      {
        method: 'GET',
      },
      {}
    );
  };

  // TODO fetch public holidays from endpoint unauthorized endpoint when endpoint changed to not needed login.
  fetchHolidays = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const holidayValue = 'Holiday';
    /*return this.fetch(
      `/Customers/restricted-dates-by-type/${holidayValue}`,
      {
        method: 'GET',
      },
      this.getUnAuthorizedRequestHeaders()
    ).then(results => {
      // Filter to return only list of dates.
    });*/
    return Promise.resolve([]);
  };

  fetchIsDuplicatePersonalIdentifier = (id: string) =>
    this.fetch(
      `Users/is-duplicate?id=${id}`,
      {
        method: 'GET',
      },
      this.getUnAuthorizedRequestHeaders()
    );

  fetchIdentificationInfo = (identificationId: string) =>
    this.fetch(
      `Identification/identification-info?id=${identificationId}`,
      {
        method: 'GET',
      },
      this.getUnAuthorizedRequestHeaders()
    );

  getSamlAuthenticationUrl = () => {
    return `${this.getDomain()}/Identification/saml/authenticate/${this.props.tenantId}`;
  };

  getSamlLogoutUrl = () => {
    const samlNameIdentifierAsBase64: string = localStorage.getItem(STORED_AUTH_SAMLNAMEID) ?? '';
    const sessionIndex: string = localStorage.getItem(STORED_AUTH_SESSIONINDEX) ?? '';
    return `${this.getDomain()}/Identification/logout?nameid=${samlNameIdentifierAsBase64}&sessionindex=${sessionIndex}&tenantid=${this.getTenantId()}&language=${this.props.language
      }`;
  };

  getToken = (): AuthToken | null => {
    const tokenStr = localStorage.getItem(STORED_AUTH_TOKEN);
    if (typeof tokenStr === 'string') {
      try {
        return JSON.parse(tokenStr);
      } catch (error) {
        console.error('Error parsing tokenStr:', error);
      }
    }
    return null;
  };

  fetch = (
    url: string,
    options: {},
    headers: {} = {
      'Content-Type': 'application/json;charset=utf-8',
      'Accept-Language': this.props.language,
    }
  ) => {
    const fullUrl = `${this.props.domain}/${url}`;
    let queryHeaders = headers;

    //Setting Authorization header
    if (this.loggedIn()) {
      const token = this.getToken()?.token;
      queryHeaders = {
        ...headers,
        Authorization: `Vingo-e-services ${token}`,
      };
    }

    const queryOptions = {
      ...options,
      headers: queryHeaders,
    };

    return fetch(fullUrl, queryOptions).then(this.checkStatus).then(this.checkContent);
  };

  fetchFile = (
    url: string,
    options: {
      typeOfFile: string;
      method: string;
      body?: string;
    },
    headers: {} = {
      'Content-Type': 'application/json;charset=utf-8',
      'Accept-Language': this.props.language,
    }
  ) => {
    const fullUrl = `${this.props.domain}/${url}`;
    let queryHeaders = headers;

    const contentType: string = 'application/' + options.typeOfFile;

    //Setting Authorization and file related headers
    if (this.loggedIn()) {
      const token = this.getToken()?.token;
      queryHeaders = {
        ...headers,
        Authorization: `Vingo-e-services ${token}`,
        Accept: contentType,
      };
    }

    const queryOptions = {
      ...options,
      headers: queryHeaders,
    };

    return fetch(fullUrl, queryOptions)
      .then(this.checkStatus)
      .then((rsp) => {
        return this.checkFileContent(rsp, contentType);
      });
  };

  private isTokenValid = (): boolean => {
    const authToken = this.getToken();
    if (!authToken) {
      return false;
    }

    return this.authTokenExpiresIn(authToken.expiresAt) > 0;
  };

  private fetchToken = (userName: string, password: string): Promise<AuthToken> =>
    this.fetch(
      `Users/login`,
      {
        method: 'POST',
        body: JSON.stringify({ userName, password }),
      },
      this.getUnAuthorizedRequestHeaders()
    );

  private backendLockout = (): Promise<void> =>
    this.fetch('Users/logout', {
      method: 'POST',
    });

  private setToken = (authToken: AuthToken): void => {
    localStorage.setItem(STORED_AUTH_TOKEN, JSON.stringify(authToken));
  };

  setSamlSession = (nameId: string, sessionIndex: string): void => {
    localStorage.setItem(STORED_AUTH_SAMLNAMEID, nameId);
    localStorage.setItem(STORED_AUTH_SESSIONINDEX, sessionIndex);
  };

  /// How many seconds token is valid
  private authTokenExpiresIn = (expiresAt: Date): number => {
    let expDate: Date = new Date(expiresAt);
    let now: number = new Date().getTime();
    return (expDate.getTime() - now) / 1000;
  };

  private checkStatus = async (response: Response) => {
    // Raises an error in case response status is not a success
    if (response.ok) {
      return response;
    }

    let jsonError: any = { status: response.status, title: response.statusText };
    try {
      jsonError = await response.json();
    } catch (fail) {
      // Body is null
    }

    if (response.status === 401 || response.status === 403) {
      const error = new HttpError(response.status, 'unauthorized', jsonError);
      throw error;
    }

    const error = new HttpError(jsonError.status, `${jsonError.title}\n\n${JSON.stringify(jsonError)}`, jsonError);
    throw error;
  };

  private checkContent = async (response: Response) => {
    try {
      const responseJson = await response.json();
      return responseJson;
    } catch (error) {
      // Response is ok, but has no json content.
      return 'OK';
    }
  };

  private checkFileContent = async (response: Response, contentType: string) => {
    const responseBlob: Blob = await response.blob();
    if (responseBlob.size === 0) {
      throw new HttpError(500, 'size of the file 0');
    }
    if (responseBlob.type !== contentType) {
      throw new HttpError(412, 'preconditions failed');
    }
    return responseBlob;
  };
}
