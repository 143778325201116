import { Dispatch, SetStateAction, useContext } from 'react';
import { Language, convertToLanguage } from '../../model';
import { Box, Button, Container, Divider, Link, Stack, Typography, useTheme } from '@mui/material';
import LogoNavigationLanguageBar from '../../components/common-materialui/header/LogoNavigationLanguageBar';
import { LanguageContext } from '../../contexts/language-context';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
  RegisterFormData,
  RegisterFormDataInitialValues,
  RenderBasicInformationSection,
  renderUsernamePasswordSection,
} from './sharedRegisterSections';
import AuthContext from '../../contexts/auth-context';
import { useMutation } from '@tanstack/react-query';
import { RegisterInputParams } from '../../service/authService';
import ProgressComponent from '../../components/common-materialui/ProgressComponent';
import { getAlertWithErrorMessages } from '../../components/common-materialui/status-handling/httpErrorUtils';

interface Props {
  language: Language;
  setLanguage: Dispatch<SetStateAction<Language>>;
}

const RegisterNew = ({ language, setLanguage }: Props): JSX.Element => {
  const { palette } = useTheme();
  const history = useHistory();
  const { getText } = useContext(LanguageContext);
  const { logIn, registerAccount } = useContext(AuthContext);

  const methods = useForm<RegisterFormData>({ defaultValues: RegisterFormDataInitialValues });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const currentUsername = useWatch({
    control,
    name: 'username',
  });

  const currentPassword = useWatch({
    control,
    name: 'password',
  });

  const termsOfUse = useWatch({
    control,
    name: 'termsOfUse',
  });

  const {
    mutate: loginAfterRegister,
    isLoading: loginAfterRegisterLoading,
    error: loginAfterRegisterError,
    reset: loginAfterRegisterReset,
  } = useMutation(() => logIn(currentUsername, currentPassword), {
    onSuccess: () => history.push('/emptying-infos'),
  });

  const {
    mutate: register,
    isLoading: registerLoading,
    error: registerError,
    reset: registerReset,
  } = useMutation((data: RegisterInputParams) => registerAccount(data), {
    onSuccess: () => loginAfterRegister(),
  });

  function mapAndRegister(data: RegisterFormData) {
    const inputData: RegisterInputParams = {
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber.replace(/\s/g, ''), // Remove whitespaces
      email: data.email,
      userName: data.username,
      password: data.password,
      language: convertToLanguage(data.language),
    };
    register(inputData);
  }

  function renderHeader() {
    return (
      <Stack spacing={2}>
        <Typography variant='h3'>{getText('login-info-create-account')}</Typography>
        <Typography variant={'body2'} color={palette.text.primary}>
          {getText('register-new-account-description')}
        </Typography>
      </Stack>
    );
  }

  function clearErrors() {
    registerReset();
    loginAfterRegisterReset();
  }

  function renderSendButtonAndLink() {
    return (
      <Stack spacing={3} justifyContent={'center'} textAlign={'center'}>
        {registerError || loginAfterRegisterError
          ? getAlertWithErrorMessages(registerError || loginAfterRegisterError, getText, clearErrors)
          : null}
        <Button
          variant='contained'
          disabled={registerLoading || loginAfterRegisterLoading || !termsOfUse}
          onClick={(e) => void handleSubmit(mapAndRegister)(e)}
        >
          {registerLoading || loginAfterRegisterLoading ? (
            <ProgressComponent variant='circle' isLoading={registerLoading || loginAfterRegisterLoading} />
          ) : (
            <Typography py={1} variant='button'>
              {getText('create-account')}
            </Typography>
          )}
        </Button>

        <Link component='button' onClick={() => history.push('/')}>
          {getText('forgot-password-return-to-login-link')}
        </Link>
      </Stack>
    );
  }

  return (
    <FormProvider {...methods}>
      <LogoNavigationLanguageBar />
      <Container>
        <Box
          sx={{
            backgroundColor: palette.background.level2,
            padding: 3,
            maxWidth: '515px',
            margin: 'auto',
            border: `1px solid ${palette.divider}`,
          }}
        >
          <Stack spacing={4}>
            {renderHeader()}
            <RenderBasicInformationSection getText={getText} titleKey='basic-info' />
            <Divider />
            {renderUsernamePasswordSection(control, errors, getText, currentPassword)}
            {renderSendButtonAndLink()}
          </Stack>
        </Box>
      </Container>
    </FormProvider>
  );
};

export default RegisterNew;
