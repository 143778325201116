import React, { useContext, useEffect, useState } from 'react';
import ApiContext from '../../contexts/api-context';
import { Language } from '../../model';
import { Column, CompactColumn } from '../common/containers';
import Spinner from '../common/Spinner';


type AuthenticateProps = {
    language: Language;
};

const Authenticate = (props: AuthenticateProps): JSX.Element => {
    const api = useContext(ApiContext);
    const language = props.language;

    useEffect(() => {
        RedirectToLogin();
    }, []);

    const RedirectToLogin = () => {
        var url = api.getSamlAuthenticationUrl() + '?language=' + language
        window.location.replace(url);
    }
   
    return (
        <Column>
          <CompactColumn>
            <Spinner />
          </CompactColumn>
        </Column>
      );
  
}

export default Authenticate;