import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React from 'react';
import { CONSTANTS } from './WeekIntervalSelectorUtils';
import { UiTexts } from '../../../../../model';

interface WeeksPerDayToggleButtonProps {
  getText: (key: keyof UiTexts, params?: string[] | undefined) => string;
  onChange: (event: React.MouseEvent<HTMLElement>, value: string) => void;
  value: string;
}

export const WeeksPerDayToggleButton = ({ getText, onChange, value }: WeeksPerDayToggleButtonProps) => (
  <>
    <Typography variant='h6'>{getText('order-product-emptying-rhythm-times-a-week-label')}</Typography>
    <ToggleButtonGroup
      orientation='horizontal'
      value={value}
      exclusive
      onChange={onChange}
      aria-label='Times per week selection'
    >
      {CONSTANTS.DAYS_PER_WEEK.map((number) => (
        <ToggleButton key={number} value={number.toString()} aria-label={number.toString()}>
          {number}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  </>
);