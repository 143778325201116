import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { LanguageContext } from '../../../contexts/language-context';
import { findAllowedInterval, printInterval } from '../../../util/util';
import { Column, Row, IntervalRow } from '../../common/containers';
import DateInput from '../../common/DateInput';
import DropDown from '../../common/DropDown';

import { ReactComponent as TrashIcon } from '../../../assets/trash-2.svg';
import { IconButton } from '../../common/inputs';
import { AllowedEmptyingInterval } from '../../../model';
import { getWeekNumber } from '../../../util/calendarUtil';
import moment from 'moment';
import _ from 'lodash';
import AuthContext from '../../../contexts/auth-context';

type IntervalItemProps = {
  start: Date | null;
  end: Date | null;
  setStart: (date: Date) => void;
  setEnd: (date: Date) => void;
  allowedIntervals: AllowedEmptyingInterval[];
  intervalValue: string;
  selectIntervalFunc: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  updateInterval: (e: string) => void;
  isSubmitted: boolean;
  deleteAction?: () => void;
  displayDateInputs: boolean;
  amountPerWeek: string;
  setAmountPerWeek: (e: string) => void;
  isWholeYear?: boolean;
};

const Dash = styled.div`
  padding-left: 0.25rem;
  padding-right: 0.25rem;
`;

const MarginRow = styled(Row)`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const IntervalItem = (props: IntervalItemProps): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const authService = useContext(AuthContext);
  const {
    start,
    end,
    setStart,
    setEnd,
    allowedIntervals,
    intervalValue,
    selectIntervalFunc,
    updateInterval,
    isSubmitted,
    deleteAction,
    displayDateInputs,
    setAmountPerWeek,
    isWholeYear
  } = props;

  const buildIntervalOption = (interval: number): [string, string] => {
    return [interval.toString(), printInterval(getText, interval, 1)];
  };

  const getValidAllowedIntervals = (
    startDate: Date | null,
    endDate: Date | null,
    intervals: AllowedEmptyingInterval[] | null) => {
    let validAllowedIntervals: number[] = [];

    if (isWholeYear && intervals) {
      validAllowedIntervals = findAllowedInterval(intervals, 1, 53);
    }
    else if (startDate != null && intervals != null && endDate != null) {
      const startWeekNumber = getWeekNumber(startDate);
      const endWeekNumber = getWeekNumber(endDate);
      const allowedInterval = findAllowedInterval(intervals, startWeekNumber, endWeekNumber)
        ?? [];
      validAllowedIntervals = allowedInterval;
    }

    return validAllowedIntervals;
  }

  const year = 2026;

  let initialAllowedIntervals: number[] =
    getValidAllowedIntervals(props.start, props.end, props.allowedIntervals);

  const min = new Date(year, 0, 1, 0, 0, 0, 0);

  const [possibleIntervals, setPossibleIntervals] = useState<number[]>(initialAllowedIntervals);
  const [minDate, setMinDate] = useState<Date>(min);
  const [maxStartDate, setMaxStartDate] = useState<Date>(new Date(year, 11, 31, 0, 0, 0, 0));
  const [minEndDate, setMinEndDate] = useState<Date>(min);
  const [maxDate, setMaxDate] = useState<Date>(new Date(year, 11, 31, 0, 0, 0, 0));
  const amountPerWeekOptions: [string, string][] = [
    ["1", "1"],
    ["2", "2"],
    ["3", "3"],
    ["4", "4"],
    ["5", "5"],
  ];

  useEffect(() => {
    if (initialAllowedIntervals.findIndex(x => x.toString() == props.intervalValue) == -1) {
      const currentIntervalValid =
        initialAllowedIntervals.length > 0 ? initialAllowedIntervals[0].toString() : "";
      props.updateInterval(currentIntervalValid);
    }
  }, []);

  useEffect(() => {
    const validIntervals = getValidAllowedIntervals(props.start, props.end, allowedIntervals)
    setPossibleIntervals(validIntervals);

    if (validIntervals.findIndex(x => x.toString() == props.intervalValue) == -1) {
      const currentIntervalValid =
        validIntervals.length > 0 ? validIntervals[0].toString() : "";
      updateInterval(currentIntervalValid);
    }

  }, [allowedIntervals, isWholeYear, start, end]);

  const delectActionElement = deleteAction ? (
    <IconButton type="button" style={{ marginLeft: '0.5rem' }}>
      <TrashIcon onClick={deleteAction} />
    </IconButton>
  ) : null;

  useEffect(() => {
    if (start != null) {
      let maxDate = new Date(year, 11, 31);
      const weekNumber = moment(start).isoWeek();
      const minEndDate = min;

      if (weekNumber != 1) {
        maxDate = moment().isoWeekYear(start.getFullYear() + 1).isoWeek(weekNumber - 1).isoWeekday(7).toDate();
        maxDate.setHours(0, 0, 0, 0);
      }

      setMinEndDate(minEndDate);
      setMaxDate(maxDate);
    }
  }, [start]);

  return (
    <Column>
      {
        displayDateInputs &&
        <IntervalRow>
          <DateInput
            date={start}
            onChange={x => { setStart(x); }}
            required={true}
            showError={isSubmitted}
            weekSelect
            placeholderText={getText('change-contract-interval-start')}
            minDate={minDate}
            maxDate={maxStartDate}
            hideYear={true}
          />
          <Dash>&#x2011;</Dash>
          <DateInput
            date={end}
            onChange={x => { setEnd(x) }}
            required={true}
            showError={isSubmitted}
            weekSelect
            placeholderText={getText('change-contract-interval-end')}
            minDate={minEndDate}
            maxDate={maxDate}
            hideYear={true}
          />
          {delectActionElement}
        </IntervalRow>
      }

      <Row>
        <DropDown
          value={intervalValue}
          onChange={selectIntervalFunc}
          options={possibleIntervals.length > 0
            ? possibleIntervals.map(buildIntervalOption)
            : initialAllowedIntervals.map(buildIntervalOption)}
        />
      </Row>
      {authService.isEmptyingTimesPerWeekEnabled() &&
        <>
          <MarginRow>
            {getText('add-contract-amount-per-week')}
          </MarginRow>
          <Row>
            <DropDown
              value={props.amountPerWeek}
              onChange={(e) => setAmountPerWeek(e.target.value)}
              options={amountPerWeekOptions}
            />
            </Row>
        </>
      }
    </Column>
  );
};

export default IntervalItem;
