import { Alert, AlertTitle } from '@mui/material';
import { UiTexts } from '../../../model';
import HttpError from '../../../util/HttpError';

export interface UiTextErrorKeys {
  title: keyof UiTexts;
  message: keyof UiTexts;
}

export function getErrorMessageKeys(errorObject: any): UiTextErrorKeys {
  const httpErrorObject = errorObject as HttpError;

  if (httpErrorObject) {
    if (httpErrorObject.statusCode === 401) {
      return {
        title: 'error-unauthorized-title',
        message: 'error-unauthorized-message',
      };
    } else if (httpErrorObject?.response?.type === '/problems/e-services-users/account-invoice-number-mismatch') {
      return {
        title: 'error-register-invoice-not-found-title',
        message: 'error-register-invoice-not-found-message',
      };
    } else if (httpErrorObject?.response?.type === '/problems/identity-operation-failed') {
      return {
        title: 'error-general-message',
        message: 'error-identity-operation-failed',
      };
    } else if (httpErrorObject?.response?.type === '/problems/user/claim') {
      return {
        title: 'error-register-user-account-already-registered-title',
        message: 'error-register-user-account-already-registered-message',
      };
    } else if (httpErrorObject?.response?.type === '/problems/e-services-users/no-connectable-accounts-found') {
      return {
        title: 'no-connectable-accounts-found-title',
        message: 'no-connectable-accounts-found-message',
      };
    }
  }
  //Unknown, return generic
  return {
    title: 'error-service-break-title',
    message: 'error-service-break-message',
  };
}

/**
 * 
 * @param errorObject Error object, for example { error } from usequery 
 * @param getText getText function
 * @param closeFunction function that will happen when x is pressed
 * @returns MUI Alert component with alert title
 */
export function getAlertWithErrorMessages(
  errorObject: any,
  getText: (key: keyof UiTexts, params?: string[] | undefined) => string,
  closeFunction: () => void
) {
  const errorKeys = getErrorMessageKeys(errorObject);
  return (
    <Alert severity='error' onClose={closeFunction}>
      <AlertTitle>{getText(errorKeys.title)}</AlertTitle>
      {getText(errorKeys.message)}
    </Alert>
  );
}
