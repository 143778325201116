import React, { useContext, useEffect, useState } from 'react';

import { Column, CompactColumn } from '../../common/containers';
import Header from '../../common/Header';
import { BillingInfo } from '../../../model';
import { useHistory, useLocation } from 'react-router-dom';
import ApiContext from '../../../contexts/api-context';
import DialogContext from '../../../contexts/dialog-context';
import ModifyPrivate from './ModifyPrivate';
import ModifyCompany from './ModifyCompany';
import Spinner from '../../common/Spinner';

const ModifyBillingInfo = (): JSX.Element => {
  const [billingInfo, setBillingInfo] = useState<BillingInfo | undefined | null>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const api = useContext(ApiContext);
  const showDialog = useContext(DialogContext);
  const { state } = useLocation();
  const history = useHistory();

  const handleError = (err: any) => {
    if (err.statusCode === 401) {
      showDialog('error-unauthorized-title', 'error-unauthorized-message', () =>
        history.replace('/logout')
      );
    } else {
      showDialog('error-service-break-title', 'error-service-break-message');
    }
  };

  const getBillingInfo = async (emptyingId: string): Promise<void> => {
    setIsLoading(true);
    const billingInfos = await api
      .fetchEmptyingBillingInfo(emptyingId)
      .then((infos) => { setIsLoading(false); return infos; })
      .catch((err: any) => {
        handleError(err);
        setIsLoading(false);
        return null;
      });

    if (billingInfos && billingInfos.length > 0) {
      setBillingInfo(billingInfos[0]);
    } else {
      setBillingInfo(null);
    }
  };

  useEffect(() => {
    const stateInfo = state as any;
    if (stateInfo?.emptyingId) {
      getBillingInfo(stateInfo.emptyingId);
    }
  }, []);

  const renderContent = () => {
    switch (billingInfo?.isCompany) {
      case false:
        return <ModifyPrivate billingInfos={billingInfo} />;
      case true:
        return <ModifyCompany billingInfos={billingInfo} />;
      default:
        return null;
    }
  };

  return (
    <Column style={{ marginBottom: '5rem' }}>
      <Header
        headerKey='modify-billing-info-header'
        descriptionKey='billing-info-header-desc'
        backButton={false}
      ></Header>
      <CompactColumn>
        {
          isLoading
            ?
            <Spinner />
            :
            billingInfo ? renderContent() : null
        }

      </CompactColumn>
    </Column>
  );
};

export default ModifyBillingInfo;
