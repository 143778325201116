import React, { useContext } from 'react';
import {
  Column,
  ProductContent,
  ProductImage,
  ProductImageContainer,
  ProductImageInfo,
  ProductInfo,
  ProductPrice,
  ProductPriceInfo,
  Row,
  SpacedRow,
  TextContent,
} from '../../common/containers';

import styled from 'styled-components';

import { ReactComponent as ShoppingCartIcon } from '../../../assets/shopping-cart.svg';
import VingoButton from '../../common/VingoButton';
import { LanguageContext } from '../../../contexts/language-context';
import { LinkButton } from '../../common/inputs';
import { TertiaryHeader } from '../../common/headers';
import { ConvertToCurrentLocale } from '../../../util/numberLocalization'

type InputProps = {
  productId: string;
  imageSrc?: string;
  name: string;
  info: string;
  price: number;
  amount: number;
  addProductAmount: (id: string, addedAmount: number) => void;
  gotoBasket: any;
};

const ProductName = styled(TertiaryHeader)`
  margin-bottom: 0.25rem;
`;

const ProductWithPicture = (props: InputProps) => {
  const {
    productId,
    imageSrc,
    name,
    info,
    price,
    amount,
    addProductAmount,
    gotoBasket,
  } = props;
  const { getText } = useContext(LanguageContext);

  const isInBasket = amount > 0;

  return (
    <ProductContent>
      <ProductImageContainer>
        <ProductImage src="Eioo" />
        {isInBasket ? (
          <ProductImageInfo>
            <Column>
              <TextContent>Tuote lisätty ostoskoriin</TextContent>
              <LinkButton onClick={gotoBasket} style={{ marginTop: '0.5rem' }}>
                {getText('service-order-goto-basket')} &rarr;
              </LinkButton>
            </Column>
          </ProductImageInfo>
        ) : null}
      </ProductImageContainer>
      <ProductName>{name}</ProductName>
      <ProductInfo>{info}</ProductInfo>
      <SpacedRow style={{ paddingTop: '0.5rem', maxWidth: '311px' }}>
        <Row>
          <ProductPrice>{ConvertToCurrentLocale(price, 2)}&euro;</ProductPrice>
          <ProductPriceInfo>
            {getText('service-order-alv-included')}
          </ProductPriceInfo>
        </Row>
        <Row>
          <VingoButton
            disabled={isInBasket}
            compact={true}
            onClick={() => addProductAmount(productId, 1)}
          >
            <ShoppingCartIcon />
          </VingoButton>
        </Row>
      </SpacedRow>
    </ProductContent>
  );
};

export default ProductWithPicture;
