import React, { useContext, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ApiContext from '../../contexts/api-context';
import DialogContext from '../../contexts/dialog-context';
import { LanguageContext } from '../../contexts/language-context';
import { ContractInfo, UiTexts, VingoProduct } from '../../model';
import {
  Column,
  CompactColumn,
  Content,
  ContentRow,
} from '../common/containers';
import Header from '../common/Header';
import { SecondaryHeader } from '../common/headers';
import { RadioButton } from '../common/inputs';
import End from './change-contract/End';
import Pause from './change-contract/Pause';
import Interval from './change-contract/Interval';
import Amount from './change-contract/Amount';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../contexts/auth-context';
import Spinner from '../common/Spinner';

type ChangeType = 'bins' | 'interval' | 'pause' | 'end' | null;

type ChangeContractProps = {
  match: {
    params: {
      customerNumber: string;
      position: string;
    };
  };
  location: {
    state: { name: string, address: string };
  };
};

const ChangeContract = (props: ChangeContractProps): JSX.Element => {
  const {
    match: {
      params: { customerNumber, position },
    },
  } = props;
  const { name, address } = props.location.state ?? { name: "", address: ""};

  const authService = useContext(AuthContext);

  const api = useContext(ApiContext);
  const { getText } = useContext(LanguageContext);
  const showDialog = useContext(DialogContext);
  const history = useHistory();

  const [changeType, setChangeType] = useState<ChangeType>(null);
  const [contract, setContract] = useState<ContractInfo | null>(null);
  const [category, setCategory] = useState<VingoProduct | null>(null);

  const componentIsMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const handleError = (err: any) => {
    if (err.statusCode === 401) {
      showDialog(
        'error-unauthorized-title',
        'error-unauthorized-message',
        () => history.replace('/logout')
      );
    }
    else {
      console.error(err);
      showDialog("error-service-break-title", "error-service-break-message");
    }
  };

  useEffect(() => {
    let productGuid: string;
    // Get all required datas.
    api.fetchContractDetails(customerNumber, parseInt(position))
    .then((contractDetails) => {
      productGuid = contractDetails.productGuid;
      if (componentIsMounted.current) {
        setContract(contractDetails);
      }
      // Fetch contract rules
      api.fetchProductRules(customerNumber, productGuid)
        .then((productRule) => {
          setCategory(productRule);
        })
        .catch((err: any) => {
          handleError(err);
        });
    })
    .catch((err: any) => {
      handleError(err);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSelection = (name: keyof UiTexts, type: ChangeType) => {
    return (
      <ContentRow>
        <RadioButton
          type="radio"
          name="register-customer-type"
          id={name}
          onChange={() => setChangeType(type)}
        />
        <label htmlFor={name}>{getText(name)}</label>
      </ContentRow>
    );
  };

  const renderOptions = () => {
    switch (changeType) {
      case 'bins':
        return <Amount customerNumber={customerNumber} position={position} amount={contract?.amount} />;
      case 'interval':
        if (category == null){
          return <Spinner />
        }
    
        return <Interval customerNumber={customerNumber} position={position}
          contractInfo={contract} product={category} />;
      case 'pause':
        if (category == null) {
          return <Spinner />
        }

        return <Pause customerNumber={customerNumber} position={position} product={category}/>;
      case 'end':
        if (category == null) {
          return <Spinner />
        }

        return <End customerNumber={customerNumber} position={position} product={category}/>;
      default:
        return null;
    }
  };

  const renderContent = () => {
    if (contract === null) {
      return <Spinner />;
    } else {
      return (
        <CompactColumn>
          <Content>
            <SecondaryHeader>
              {getText('change-contract-change-what')}
            </SecondaryHeader>
            {renderSelection('change-contract-change-bins', 'bins')}
            {authService.isModifyEmptyingIntervalEnabled() && renderSelection('change-contract-change-interval', 'interval')}
            {renderSelection('change-contract-change-pause', 'pause')}
            {renderSelection('change-contract-change-ending', 'end')}
          </Content>
          {renderOptions()}
        </CompactColumn>
      );
    }
  };

  return (
    <Column>
      <Header
        headerKey="contract-details-change-contract"
        descriptionElement={
          <AddressAndCategory>
            <div>{name && address ? name + ', ' + address : ''}</div>
            <div>{contract?.name}</div>
          </AddressAndCategory>
        }
        backButton={true}
      ></Header>
      {renderContent()}
    </Column>
  );
};

const AddressAndCategory = styled(Column)`
  gap: 1rem;
`;

export default ChangeContract;
