import React, { Dispatch, SetStateAction, useContext } from 'react';
import { Content, Row } from '../../common/containers';
import { SecondaryHeader } from '../../common/headers';
import TextInput from '../../common/TextInput';
import { LanguageContext } from '../../../contexts/language-context';
import { Checkbox } from '../../common/inputs';
import ExistingCustomerConnect from './ExistingCustomerConnect';
import { ConsumerEInvoiceType } from '../../../model';

type InputProps = {
  streetAddress: string;
  setStreetAddress: Dispatch<SetStateAction<string>>;
  postCode: string;
  setPostCode: Dispatch<SetStateAction<string>>;
  city: string;
  setCity: Dispatch<SetStateAction<string>>;
  billType: ConsumerEInvoiceType;
  setBillType: Dispatch<SetStateAction<ConsumerEInvoiceType>>;
  isSubmitted: boolean;
  readOnly: boolean;
  emailInvoicing: boolean;
  alreadyCustomer: boolean;
  customerNumber: string;
  setCustomerNumber: Dispatch<SetStateAction<string>>;
  invoiceNumber: string;
  setInvoiceNumber: Dispatch<SetStateAction<string>>;
};

const PrivateCustomerBillingInfo = (props: InputProps): JSX.Element => {
  const {
    customerNumber,
    setCustomerNumber,
    invoiceNumber,
    setInvoiceNumber,
    streetAddress,
    setStreetAddress,
    postCode,
    setPostCode,
    city,
    setCity,
    billType,
    setBillType,
    isSubmitted,
    readOnly,
    emailInvoicing,
    alreadyCustomer,
  } = props;
  const { getText } = useContext(LanguageContext);

  const renderConnect = () => {
    if (alreadyCustomer) {
      return (
        <ExistingCustomerConnect
          customerNumber={customerNumber}
          setCustomerNumber={setCustomerNumber}
          invoiceNumber={invoiceNumber}
          setInvoiceNumber={setInvoiceNumber}
        />
      );
    }
  };

  return (
    <Content>
      <SecondaryHeader>{getText('billing-data')}</SecondaryHeader>
      {renderConnect()}
      <TextInput
        label='billing-data-street-address'
        val={streetAddress}
        setter={setStreetAddress}
        validations={['required']}
        showErrors={isSubmitted}
        disabled={readOnly}
      />
      <TextInput
        label='billing-data-post-code'
        val={postCode}
        setter={setPostCode}
        validations={['required', { minLength: 5 }]}
        maxLength={5}
        showErrors={isSubmitted}
        disabled={readOnly}
      />
      <TextInput
        label='billing-data-city'
        val={city}
        setter={setCity}
        validations={['required']}
        maxLength={29}
        showErrors={isSubmitted}
        disabled={readOnly}
      />
      {emailInvoicing && (
        <Row style={{ marginTop: '1.2rem', marginBottom: '0.5rem' }}>
          <Checkbox
            type='checkbox'
            id='email-invoice'
            checked={billType === ConsumerEInvoiceType.EmailInvoicing}
            onChange={() =>
              setBillType(
                billType === ConsumerEInvoiceType.EmailInvoicing
                  ? ConsumerEInvoiceType.NotInUse
                  : ConsumerEInvoiceType.EmailInvoicing
              )
            }
          />
          <label htmlFor='email-invoice'>{getText('email-bill')}</label>
        </Row>
      )}
    </Content>
  );
};

export default PrivateCustomerBillingInfo;
