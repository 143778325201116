import React, { useContext, useEffect, useState } from 'react';

import { Column, CompactColumn } from '../../common/containers';
import Header from '../../common/Header';
import { EmptyingInfo } from '../../../model';
import CompanyLocation from './CompanyLocation';
import PrivateLocation from './PrivateLocation';
import Spinner from '../../common/Spinner';
import ApiContext from '../../../contexts/api-context';
import DialogContext from '../../../contexts/dialog-context';
import { useHistory } from 'react-router-dom';

type Props = {
  match: {
    params: {
      id: string;
    };
  };
};

const ModifyEmptyingLocation = ({ match: { params } }: Props): JSX.Element => {
  const [emptyingInfo, setEmptyingInfo] = useState<EmptyingInfo | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const api = useContext(ApiContext);
  const showDialog = useContext(DialogContext);
  const history = useHistory();

  const handleError = (err: any) => {
    if (err.statusCode === 401) {
      showDialog('error-unauthorized-title', 'error-unauthorized-message', () =>
        history.replace('/logout')
      );
    } else {
      showDialog('error-service-break-title', 'error-service-break-message');
    }
  };

  const getEmptyingInfo = async (emptyingId: string): Promise<void> => {
    setIsLoading(true);
    api
      .fetchEmptyingInfo(emptyingId)
      .then((info) => {
        setEmptyingInfo(info);
        setIsLoading(false);
      })
      .catch((err: any) => {
        handleError(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getEmptyingInfo(params.id);
  }, []);

  const renderContent = () => {
    switch (emptyingInfo?.isCompany) {
      case true:
        return <CompanyLocation emptyingInfo={emptyingInfo} />;
      case false:
        return <PrivateLocation emptyingInfo={emptyingInfo} />;
      default:
        return null;
    }
  };

  const content = emptyingInfo ? renderContent() : null

  return (
    <Column style={{ marginBottom: '5rem' }}>
      <Header
        headerKey='modify-emptying-location-header'
        backButton={false}
      ></Header>
      <CompactColumn>
        {isLoading ? <Spinner /> : content}
      </CompactColumn>
    </Column>
  );
};

export default ModifyEmptyingLocation;
