import { CircularProgress, IconButton, TableCell, TableRow, Typography, useTheme } from "@mui/material";
import { InvoiceInfo } from "../../model";
import { printddMMDate } from "../../util/calendarUtil";
import { ReactComponent as FileIcon } from "../../assets/icons/file-text.svg";
import ApiService from "../../service/apiService";
import { getInvoiceItemColor, handleInvoiceCurrency } from "./invoicesUtils";
import { useCallback, useContext, useState } from "react";
import { LanguageContext } from "../../contexts/language-context";
import ProgressComponent from "../../components/common-materialui/ProgressComponent";

interface InvoiceRowProps {
  info: InvoiceInfo;
  api: ApiService;
  setFetchErrorState: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InvoiceRow = ({ info, api, setFetchErrorState }: InvoiceRowProps) => {
  const { palette } = useTheme();
  const { lang } = useContext(LanguageContext);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const cellStyle = {
    borderBottom: `1px solid ${palette.divider}`,
  };

  function fetchPdf() {
    api
      .fetchInvoicePdf(info.invoiceNumber)
      .then((pdf) => {
        setIsFetching(false);
        const url = URL.createObjectURL(pdf);
        window.open(url, '_blank', 'noopener,noreferrer');
      })
      .catch((_err: any) => {
        setIsFetching(false);
        setFetchErrorState(true);
      });
  };

  const handleClick = useCallback(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchPdf();
    }
  }, [isFetching]);

  return (
    <TableRow>
      <TableCell align="left" sx={{
        ...cellStyle,
        borderLeft: `8px solid ${getInvoiceItemColor(info, palette)}`,
        paddingLeft: "10px",
      }}>
        <Typography variant="body1">
          {info.invoiceNumber}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={cellStyle}>
        <Typography variant="body1" color="textSecondary">
          {printddMMDate(info.invoiceDate)}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={cellStyle}>
        <Typography variant="body1" color="textSecondary">
          {printddMMDate(info.dueDate)}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={cellStyle}>
        <Typography variant="body1" color="textSecondary">
          {handleInvoiceCurrency(lang, info.amountOpen, info.currency)}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={cellStyle}>
        <Typography variant="body1" color="textSecondary">
          {handleInvoiceCurrency(lang, info.amountCompleted, info.currency)}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={cellStyle}>
        <Typography variant="body1" color="textSecondary">
          {printddMMDate(info.paymentDate)}
        </Typography>
      </TableCell>

      <TableCell align="center" sx={cellStyle}>

        <IconButton onClick={handleClick}>
          <FileIcon color={palette.text.links} />
        </IconButton>
        
        <ProgressComponent
          isLoading={isFetching}
          variant="circle"
          size={"40px"}
          sx={{ marginLeft: "-40px", marginBottom: "-16px" }}
        />

      </TableCell>

    </TableRow>
  );
};