import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';
import DateInput from '../../../components/common/DateInput';

interface Props {
  start: Date | null;
  end: Date | null;
  setStart: (d: Date) => void;
  setEnd: (d: Date) => void;
}

const OrderDateFilter: FC<Props> = ({ start, end, setStart, setEnd }) => {
  const theme = useTheme();
  const largerThanPhone = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid
      columnSpacing={0}
      rowSpacing={{ xs: 1, md: 0 }}
      container
      p={1}
      columns={{ xs: 4, md: 27 }}
      alignItems='center'
    >
      <Grid item xs={4} md={13}>
        <DateInput date={start} onChange={setStart} maxDate={end ?? undefined} />
      </Grid>
      {largerThanPhone ? (
        <Grid item xs={1}>
          <Box display='flex' justifyContent='center'>
            <Typography variant='body1'> - </Typography>
          </Box>
        </Grid>
      ) : null}
      <Grid item xs={4} md={13}>
        <DateInput date={end} onChange={setEnd} />
      </Grid>
    </Grid>
  );
};

export default OrderDateFilter;
