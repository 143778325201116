import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Header from '../../components/common-materialui/header/Header';
import { useContext, useState } from 'react';
import { LanguageContext } from '../../contexts/language-context';
import { useMutation } from '@tanstack/react-query';
import ApiContext from '../../contexts/api-context';
import { Language, UserLanguageUpdateInput } from '../../model';
import { LargerThanBreakpoint, SmallerThanBreakpoint } from '../../util/viewportUtils';
import { WIDTH_DESKTOP, WIDTH_MOBILE } from './models';
import { useHistory } from 'react-router-dom';
import { getAlertWithErrorMessages } from '../../components/common-materialui/status-handling/httpErrorUtils';

const EditGeneralInformation = (): JSX.Element => {
  const history = useHistory();
  const { palette, breakpoints } = useTheme();
  const { getText } = useContext(LanguageContext);
  const { updateUserLanguage } = useContext(ApiContext);

  const largerThanPhone = useMediaQuery(breakpoints.up('md'));
  const headerStackDirection = largerThanPhone ? 'row' : 'column';

  const [userAccountLanguage, setUserAccountLanguage] = useState<Language>('en');

  const {
    mutateAsync: updateLanguage,
    isLoading: updateUserAccountInfoIsLoading,
    error: updateUserAccountInfoError,
    reset: updateUserAccountInfoReset,
  } = useMutation((data: UserLanguageUpdateInput) => updateUserLanguage(data), {
    onSuccess: () => history.replace('/account-settings'),
  });

  const width = LargerThanBreakpoint('md') ? WIDTH_DESKTOP : WIDTH_MOBILE;
  const orientation = SmallerThanBreakpoint('md') ? 'column' : 'row';

  const headerContent = () => {
    return (
      <Stack
        spacing={3}
        justifyContent='space-between'
        direction={headerStackDirection}
        marginTop={largerThanPhone ? 1 : 0.75}
        marginBottom={largerThanPhone ? 3 : 2}
      >
        <Typography variant='h2'>
          {getText('account-settings-edit-general-info-header')}
          <Typography variant='body1' mt={1}>
            {getText('account-settings-edit-general-info-guide-text')}
          </Typography>
        </Typography>
      </Stack>
    );
  };

  return (
    <>
      <Header content={headerContent()} />
      <Container>
        <Stack
          direction={orientation}
          justifyContent={'space-between'}
          spacing={2}
          p='1rem'
          border={1}
          borderColor='divider'
          sx={{
            backgroundColor: palette.background.level2,
          }}
        >
          <Typography variant='h6'>{getText('account-settings-edit-general-info-form-header')}</Typography>
          <Stack direction='column' width={width}>
            <Typography variant='subtitle1'>
              {getText('account-settings-edit-general-info-form-options-header')}
            </Typography>
            <FormControl>
              <RadioGroup
                aria-labelledby='radio-group-for-selecting-user-account-language'
                value={userAccountLanguage}
                onChange={(e) => setUserAccountLanguage(e.target.value as Language)}
              >
                <FormControlLabel value='en' control={<Radio />} label={getText('language-english')} />
                <FormControlLabel value='fi' control={<Radio />} label={getText('language-finnish')} />
                <FormControlLabel value='sv' control={<Radio />} label={getText('language-swedish')} />
              </RadioGroup>
            </FormControl>
          </Stack>
        </Stack>
      </Container>
      <Container>
        <Stack direction='row' justifyContent='flex-end' spacing={2} paddingX={3} marginBottom={3}>
          {updateUserAccountInfoError
            ? getAlertWithErrorMessages(updateUserAccountInfoError, getText, updateUserAccountInfoReset)
            : null}
          <Box>
            <Button size='large' color='secondary' onClick={() => history.replace('/account-settings')}>
              {getText('dialog-cancel')}
            </Button>
          </Box>
          <Box>
            <Button
              variant='contained'
              size='large'
              disabled={updateUserAccountInfoIsLoading}
              onClick={() => updateLanguage({ language: userAccountLanguage })}
            >
              {getText('dialog-save')}
            </Button>
          </Box>
        </Stack>
      </Container>
    </>
  );
};

export default EditGeneralInformation;
