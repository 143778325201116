import { FC, useContext } from 'react';
import { Card, CardContent, Grid, Stack, Typography, useTheme } from '@mui/material';
import { LanguageContext } from '../../../contexts/language-context';
import { SharedContainerPartner } from '../../../model';

interface Props {
  partner: SharedContainerPartner;
}
const SharedContainerCard: FC<Props> = ({ partner }) => {
  const { palette } = useTheme();
  const { getText } = useContext(LanguageContext);

  const gridItemStyle = {
    marginTop: '10px',
  };

  const textOverflowStyle = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  };

  return (
    <>
      <Card sx={{ boxShadow: 0, border: `1px solid ${palette.divider}` }}>
        <CardContent
          sx={{
            paddingLeft: '12px',
          }}
        >
          <Grid container columns={{ xs: 2 }} rowSpacing={2}>
            <Grid item xs={1}>
              <Stack direction='column'>
                <Typography variant='body2' color={palette.secondary.mainVariant}>
                  {getText('emptying-group-shared-container-shareholders-table-header')}
                </Typography>
                <Typography variant='body1' sx={textOverflowStyle}>
                  {partner.name}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <Stack direction='column'>
                <Typography variant='body2' color={palette.secondary.mainVariant}>
                  {getText('emptying-group-shared-container-share-table-header')}
                </Typography>
                <Typography variant='body1'>
                  {partner.percentage === 0 ? partner.percentage : `${partner.percentage} %`}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={1} sx={{ ...gridItemStyle }}>
              <Stack direction='column'>
                <Typography variant='body2' color={palette.secondary.mainVariant}>
                  {getText('emptying-group-shared-container-address-table-header')}
                </Typography>
                <Typography variant='body1' sx={textOverflowStyle}>
                  {partner.address.streetAddress}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={1} sx={{ ...gridItemStyle }}>
              <Stack direction='column'>
                <Typography variant='body2' color={palette.secondary.mainVariant}>
                  {getText('emptying-group-shared-container-weighing-table-header')}
                </Typography>
                <Typography variant='body1'>{partner.emphasis}</Typography>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default SharedContainerCard;
