import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  FormEvent,
} from 'react';
import { useHistory } from 'react-router-dom';

import { LanguageContext } from '../../../contexts/language-context';
import AuthContext from '../../../contexts/auth-context';

import { Form } from '../../common/containers';

import PrivateCustomerBasicInfo from './PrivateCustomerBasicInfo';
import PrivateCustomerBillingInfo from './PrivateCustomerBillinInfo';
import UserNameAndPw from './UserNameAndPw';
import DialogContext from '../../../contexts/dialog-context';
import HandleError from '../errors/HandleError';

import { renderConditionsAndRegistrationButtonContentRow } from './registerUtils';
import { ConsumerEInvoiceType, Language } from '../../../model';

const PrivateCustomer = (): JSX.Element => {
  const history = useHistory();
  const { getText } = useContext(LanguageContext);
  const authService = useContext(AuthContext);
  const showDialog = useContext(DialogContext);

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [ssn, setSsn] = useState<string>('');

  const [userName, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmedPassword, setConfirmedPassword] = useState<string>('');

  const [contactName, setContactName] = useState<string>('');

  const [streetAddress, setStreetAddress] = useState<string>('');
  const [postCode, setPostCode] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [billType, setBillType] = useState<ConsumerEInvoiceType>(ConsumerEInvoiceType.NotInUse);

  const [iHaveReadConditions, setIHaveReadConditions] = useState<boolean>(
    false
  );

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const componentIsMounted = useRef(true);
  const [customerNumber, setCustomerNumber] = useState<string>('');
  const [invoiceNumber, setInvoiceNumber] = useState<string>('');

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    const { logIn, registerPrivateCustomer } = authService;

    const params = {
      firstName,
      lastName,
      phone,
      email,
      socialSecurityNumber: ssn,
      contactName,
      userName,
      password,
      streetAddress,
      postCode,
      city,
      language: 'fi' as Language,
      consumerEInvoiceType: billType,
    };
    registerPrivateCustomer(params)
      .then(() => {
        return logIn(userName, password);
      })
      .then(() => {
        history.replace('/emptying-infos');
      })
      .catch((err: any) => {
        HandleError(err, showDialog, authService);
      })
      .finally(() => {
        if (componentIsMounted.current) {
          setIsLoading(false);
        }
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <PrivateCustomerBasicInfo
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        phone={phone}
        setPhone={setPhone}
        email={email}
        setEmail={setEmail}
        askSsn={true}
        ssn={ssn}
        setSsn={setSsn}
        contactName={contactName}
        setContactName={setContactName}
        isSubmitted={isSubmitted}
      />

      <UserNameAndPw
        userName={userName}
        setUserName={setUserName}
        password={password}
        setPassword={setPassword}
        confirmedPassword={confirmedPassword}
        setConfirmedPassword={setConfirmedPassword}
        isSubmitted={isSubmitted}
      />
      <PrivateCustomerBillingInfo
        streetAddress={streetAddress}
        setStreetAddress={setStreetAddress}
        postCode={postCode}
        setPostCode={setPostCode}
        city={city}
        setCity={setCity}
        billType={billType}
        setBillType={setBillType}
        isSubmitted={isSubmitted}
        readOnly={false}
        emailInvoicing={authService.isAllowRegisterEmailInvoicingEnabled()}
        alreadyCustomer={false}
        customerNumber={customerNumber}
        setCustomerNumber={setCustomerNumber}
        invoiceNumber={invoiceNumber}
        setInvoiceNumber={setInvoiceNumber}
      />
      {renderConditionsAndRegistrationButtonContentRow(
        iHaveReadConditions,
        isLoading,
        setIHaveReadConditions,
        setIsSubmitted,
        getText('register'))}
    </Form>
  );
};

export default PrivateCustomer;
