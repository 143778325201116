//Define device sizes for media query use in styled components

export const rawSize = {
  mobile: 900,
  desktop: 7000,
};

export const size = {
  mobile: `${rawSize.mobile}px`,
  desktop: `${rawSize.desktop}px`,
};

export const device = {
  mobile: `(max-width: ${size.mobile})`,
  desktop: `(max-width: ${size.desktop})`,
};
