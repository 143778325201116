import { useContext, useState } from 'react';
import { LanguageContext } from '../../contexts/language-context';
import { UiTexts } from '../../model';
import CustomMessageDialog from '../common/CustomMessageDialog';
import { Link } from '@mui/material';

interface Props {
  type: 'contract' | 'terms-of-use' | 'terms-of-service';
}

const TermsAndConditions = ({ type }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const [dialogState, setDialogState] = useState<boolean>(false);
  const [titleKey, setTitleKey] = useState<keyof UiTexts>('terms-of-use-title');
  const [message, setMessage] = useState<keyof UiTexts>('terms-of-use-text');

  const GetConditionsTypeText = () => {
    if (type === 'contract') {
      return getText('contract-conditions');
    }
    if (type === 'terms-of-use') {
      return getText('terms-of-use-title');
    }

    if (type === 'terms-of-service') {
      return getText('terms-of-service-link');
    }

    return getText('conditions');
  };

  const termDialogue = (e: React.MouseEvent) => {
    e.preventDefault();

    if (type === 'contract' || type === 'terms-of-service') {
      setTitleKey('terms-of-service-title');
      setMessage('terms-of-service-text');
    }

    if (type === 'terms-of-use') {
      setTitleKey('terms-of-use-title');
      setMessage('terms-of-use-text');
    }

    setDialogState(true);
  };

  return (
    <>
      <span>
        {getText('i-have-read')}
        &nbsp;
        <Link href='' onClick={(e) => termDialogue(e)}>
          {GetConditionsTypeText()}
        </Link>
      </span>
      {dialogState ? (
        <CustomMessageDialog
          openState={dialogState}
          openStateHandler={setDialogState}
          titleKey={titleKey}
          message={message}
        />
      ) : null}
    </>
  );
};

export default TermsAndConditions;
