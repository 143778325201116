import { Stack } from '@mui/material';
import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form';
import { BillingInfoFormData, WIDTH_DESKTOP, WIDTH_MOBILE } from '../models';
import TextInput from '../../../../components/common/TextInput';
import FormErrorField from '../../../../components/common-materialui/form/FormErrorField';
import { validateInput } from '../../../../components/common/input/validation';
import { LargerThanBreakpoint } from '../../../../util/viewportUtils';
import ControlCheckboxInput from '../../../../components/common-materialui/form/ControlCheckboxInput';
import { ConsumerEInvoiceType } from '../../../../model';

interface Props {
  control: Control<BillingInfoFormData, any>;
  errors: FieldErrors<BillingInfoFormData>;
  disabled: boolean;
  customerType: string;
}

const PrivateBillingContactSection = ({ control, errors, disabled, customerType }: Props): JSX.Element => {
  const width = LargerThanBreakpoint('md') ? WIDTH_DESKTOP : WIDTH_MOBILE;

  const useSameBillingContactPerson = useWatch({
    control,
    name: 'useSameBillingContactPerson',
  });

  const consumerEInvoice = useWatch({
    control,
    name: 'consumerEInvoiceType',
  });

  return (
    <Stack direction='column' width={width}>
      {customerType !== 'company' && !disabled && (
        <ControlCheckboxInput
          control={control}
          name='useSameBillingContactPerson'
          error={errors.useSameBillingContactPerson}
          label={'billing-data-billing-contact-person-use-same-data'}
          validations={[]}
        />
      )}
      <Controller
        control={control}
        name='contactFirstName'
        render={({ field: { onChange, value } }) => {
          return (
            <TextInput
              disabled={disabled || useSameBillingContactPerson}
              val={value}
              setter={onChange}
              label={'basic-info-first-name'}
            />
          );
        }}
      />
      <Controller
        control={control}
        name='contactLastName'
        render={({ field: { onChange, value } }) => {
          return (
            <TextInput
              disabled={disabled || useSameBillingContactPerson}
              val={value}
              setter={onChange}
              label={'basic-info-last-name'}
            />
          );
        }}
      />
      <Controller
        control={control}
        name='phoneNumber'
        rules={{
          validate: { checkEmail: (v) => validateInput(v, ['phone'])[0] },
        }}
        render={({ field: { onChange, value } }) => {
          return <TextInput disabled={disabled} val={value} setter={onChange} label={'basic-info-phone'} />;
        }}
      />
      <FormErrorField errorMsg={errors.phoneNumber?.message} />

      {consumerEInvoice === ConsumerEInvoiceType.EmailInvoicing ? null : (
        <Controller
          control={control}
          name='billingEmail'
          rules={{
            validate: { checkEmail: (v) => validateInput(v, ['email', 'required'])[0] },
          }}
          render={({ field: { onChange, value } }) => {
            return <TextInput disabled={disabled} val={value} setter={onChange} label={'billing-email'} />;
          }}
        />
      )}

      <FormErrorField errorMsg={errors.billingEmail?.message} />
    </Stack>
  );
};

export default PrivateBillingContactSection;
