import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography, Container, Stack, Badge, Divider, useTheme, useMediaQuery } from '@mui/material';
import ResponsiveCalendar from '../../../../components/common-materialui/ResponsiveCalendar';
import { LanguageContext } from '../../../../contexts/language-context';
import { EmptyingEvent, EmptyingEventEnum } from '../../../../model';
import { UiTexts } from '../../../../model';
import { printDateHoursMinutes, printddMMDate } from '../../../../util/calendarUtil';

interface ContractCalendarProps {
  events: EmptyingEvent[];
  nextEmptying?: Date | undefined;
}

interface BadgeContentAndStyle {
  badgeContent: string;
  backgroundColor: 'primary' | 'warning';
}

const generalCalendarStyle = {
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  justifyContent: 'center',
  alignItems: 'center',
};

const ContractCalendar = ({ events, nextEmptying }: ContractCalendarProps) => {
  const [popoverEvent, setPopoverEvent] = useState<EmptyingEvent[] | Date>([]);

  const theme = useTheme();
  const { palette } = useTheme();
  const { getText } = useContext(LanguageContext);
  const [emptyingEvents, setEmptyingEvents] = useState<{
    isSuccesful: EmptyingEvent[] | [];
    isMissed: EmptyingEvent[] | [];
    isFeedback: EmptyingEvent[] | [];
    isPlanned: EmptyingEvent[] | [];
  }>({ isFeedback: [], isMissed: [], isPlanned: [], isSuccesful: [] });

  const largerThanPhone = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    const isSuccesful: EmptyingEvent[] = [];
    const isMissed: EmptyingEvent[] = [];
    const isFeedback: EmptyingEvent[] = [];
    const isPlanned: EmptyingEvent[] = [];

    events.forEach((event) => {
      const eventWithDate: EmptyingEvent = {
        ...event,
        emptyingDate: new Date(event.emptyingDate),
      };

      if (event.eventType === EmptyingEventEnum.Successful) {
        event.description ? isFeedback.push(eventWithDate) : isSuccesful.push(eventWithDate);
      } else if (event.eventType === EmptyingEventEnum.Missed) isMissed.push(eventWithDate);
      else if (event.eventType === EmptyingEventEnum.Planned) isPlanned.push(eventWithDate);
      return dateToISOString(eventWithDate?.emptyingDate);
    });

    setEmptyingEvents({
      isSuccesful,
      isMissed,
      isFeedback,
      isPlanned,
    });
  }, [events]);

  const getEmptyingEventsDates = (date: Date) => {
    const { isSuccesful, isMissed, isFeedback, isPlanned } = emptyingEvents;

    const missedDay = isMissed?.find(
      (day: EmptyingEvent) => dateToISOString(day.emptyingDate) === dateToISOString(date)
    );
    const feedbackDay = isFeedback?.find(
      (day: EmptyingEvent) => dateToISOString(day.emptyingDate) === dateToISOString(date)
    );

    const plannedDay = isPlanned?.find(
      (day: EmptyingEvent) => dateToISOString(day.emptyingDate) === dateToISOString(date)
    );

    const succesfulDay = isSuccesful?.find(
      (day: EmptyingEvent) => dateToISOString(day.emptyingDate) === dateToISOString(date)
    );

    return {
      missedDay,
      feedbackDay,
      plannedDay,
      succesfulDay,
    };
  };

  const getStatusTranslations = (eventType: EmptyingEventEnum): keyof UiTexts => {
    switch (eventType) {
      case EmptyingEventEnum.Successful:
        return 'contract-calendar-popover-successful-status';
      case EmptyingEventEnum.Missed:
        return 'contract-calendar-popover-missed-status';
      case EmptyingEventEnum.Planned:
        return 'contract-calendar-popover-planned-status';
    }
  };

  const badgeContentAndStyle = (dayStatus: 'missed' | 'feedback'): BadgeContentAndStyle | undefined => {
    return dayStatus === 'missed'
      ? { badgeContent: 'x', backgroundColor: 'primary' }
      : { badgeContent: '!', backgroundColor: 'warning' };
  };

  const dateToISOString = (date: Date | undefined): string | undefined => date?.toISOString().split('T')[0];

  const getBackgroundColor = (feedbackDay: EmptyingEvent | undefined,
    missedDay: EmptyingEvent | undefined ,
    plannedDay: EmptyingEvent | undefined,
    succesfulDay: EmptyingEvent | undefined) => {
    if (plannedDay && !missedDay && !feedbackDay && !succesfulDay) {
      return palette.calendarBadge.calendarEmptying;
    } else if (missedDay || feedbackDay || succesfulDay) {
      return palette.calendarBadge.calendarSelected;
    }
  };

  const renderDayContents = (day: number, date: Date): JSX.Element => {
    let dayStatusBadge;

    const { feedbackDay, missedDay, plannedDay, succesfulDay } = getEmptyingEventsDates(date);

    const isBulletDate = feedbackDay || missedDay || plannedDay || succesfulDay;

    if (missedDay && feedbackDay) {
      const missedDayTimestamp = missedDay.emptyingDate.getTime();
      const feedbackDayTimestamp = feedbackDay.emptyingDate.getTime();
      if (missedDayTimestamp >= feedbackDayTimestamp) {
        dayStatusBadge = badgeContentAndStyle('missed');
      } else {
        dayStatusBadge = badgeContentAndStyle('feedback');
      }
    } else if (missedDay) {
      dayStatusBadge = badgeContentAndStyle('missed');
    } else if (feedbackDay) {
      dayStatusBadge = badgeContentAndStyle('feedback');
    }

    return (
      <>
        {dateToISOString(nextEmptying) === dateToISOString(date) ? (
          <Badge
            sx={{
              ...generalCalendarStyle,
              backgroundColor: palette.calendarBadge.calendarEmptying,
            }}
          >
            <Box style={{ color: palette.text.inverse }}>{day}</Box>
          </Badge>
        ) : (
          <>
            {isBulletDate ? (
              <Badge
                badgeContent={dayStatusBadge?.badgeContent}
                color={dayStatusBadge?.backgroundColor}
                sx={{
                  ...generalCalendarStyle,
                  backgroundColor: getBackgroundColor(feedbackDay, missedDay, plannedDay, succesfulDay),
                }}
              >
                <Box style={{ color: palette.text.inverse }}>{day}</Box>
              </Badge>
            ) : (
              <Badge sx={{ width: '40px', height: '40px', justifyContent: 'center', alignItems: 'center' }}>
                <Box>{day}</Box>
              </Badge>
            )}
          </>
        )}
      </>
    );
  };

  const getLatestTimestamp = (firstDate: EmptyingEvent, secondDate: EmptyingEvent) => {
    const firstDateTimestamp = firstDate.emptyingDate.getTime();
    const secondDateTimestamp = secondDate.emptyingDate.getTime();

    if (firstDateTimestamp >= secondDateTimestamp) {
      return firstDate;
    } else {
      return secondDate;
    }
  };

  const handleMissedDay = ({
    missedDay,
    succesfulDay,
    feedbackDay,
  }: {
    missedDay: EmptyingEvent;
    succesfulDay?: EmptyingEvent;
    feedbackDay?: EmptyingEvent;
  }) => {
    if (feedbackDay) {
      const latestEvent = getLatestTimestamp(missedDay, feedbackDay);
      return latestEvent;
    }
    if (succesfulDay) {
      const latestEvent = getLatestTimestamp(missedDay, succesfulDay);
      return latestEvent;
    }
    return missedDay;
  };

  const handlePlannedDay = ({
    plannedDay,
    missedDay,
    succesfulDay,
    feedbackDay,
  }: {
    plannedDay: EmptyingEvent;
    missedDay?: EmptyingEvent;
    succesfulDay?: EmptyingEvent;
    feedbackDay?: EmptyingEvent;
  }) => {
    const event = handleEvent({ missedDay, succesfulDay, feedbackDay });

    if (event) {
      return event;
    }

    return plannedDay;
  };

  const handleEvent = ({
    missedDay,
    succesfulDay,
    feedbackDay,
  }: {
    missedDay?: EmptyingEvent;
    succesfulDay?: EmptyingEvent;
    feedbackDay?: EmptyingEvent;
  }) => {
    if (missedDay) {
      const event = handleMissedDay({ missedDay, succesfulDay, feedbackDay });
      return event;
    }
    if (feedbackDay) {
      return feedbackDay;
    }
    if (succesfulDay) {
      return succesfulDay;
    }
  };

  const handleOnChange = (selectedDate: Date) => {
    if (nextEmptying?.toLocaleDateString() === selectedDate.toLocaleDateString()) {
      setPopoverEvent(nextEmptying);
      return;
    }

    const { missedDay, plannedDay, feedbackDay, succesfulDay } = getEmptyingEventsDates(selectedDate);

    if (!plannedDay) {
      const event = handleEvent({ missedDay, succesfulDay, feedbackDay });
      if (event) {
        setPopoverEvent([event]);
        return;
      }
    }

    if (plannedDay) {
      const event = handlePlannedDay({ missedDay, plannedDay, feedbackDay, succesfulDay });
      setPopoverEvent([event]);
      return;
    }
  };

  const renderNextEmptyingPopover = (event: Date): JSX.Element => (
    <Container>
      <Stack justifyContent={'space-between'} spacing={1}>
        <Typography variant='subtitle1' color='text.secondary' fontWeight={400}>
          {getText('contract-calendar-popover-date-and-time')}
        </Typography>
        <Typography variant='h6' fontWeight={400}>{`${printddMMDate(event || null)} ${getText(
          'contract-calendar-popover-at'
        )} ${printDateHoursMinutes(event)}`}</Typography>
        <Typography variant='subtitle1' color='text.secondary' fontWeight={400}>
          {getText('contract-calendar-popover-status')}
        </Typography>
        <Typography variant='h6' fontWeight={400}>
          {getText('contract-calendar-popover-next-status')}
        </Typography>
      </Stack>
    </Container>
  );

  const renderPopover = (): JSX.Element => {
    return popoverEvent instanceof Date ? (
      renderNextEmptyingPopover(popoverEvent)
    ) : (
      <React.Fragment>
        {popoverEvent.map((content: EmptyingEvent, id: number) => (
          <Box key={id}>
            {id ? <Divider /> : null}
            <Container>
              <Stack justifyContent={'space-between'} spacing={1}>
                <Typography variant='subtitle1' color='text.secondary' fontWeight={400}>
                  {getText('contract-calendar-popover-date-and-time')}
                </Typography>
                <Typography variant='h6' fontWeight={400}>{`${printddMMDate(content?.emptyingDate || null)} ${getText(
                  'contract-calendar-popover-at'
                )} ${printDateHoursMinutes(content?.emptyingDate)}`}</Typography>
                <Typography variant='subtitle1' color='text.secondary' fontWeight={400}>
                  {getText('contract-calendar-popover-status')}
                </Typography>
                <Typography variant='h6' fontWeight={400}>
                  {getText(getStatusTranslations(content?.eventType))}
                </Typography>
                {content?.description && (
                  <>
                    <Typography variant='subtitle1' color='text.secondary' fontWeight={400}>
                      {getText('contract-calendar-popover-feedback')}
                    </Typography>
                    <Typography variant='h6' fontWeight={400}>
                      {content?.description}
                    </Typography>
                  </>
                )}
              </Stack>
            </Container>
          </Box>
        ))}
      </React.Fragment>
    );
  };

  const availableDates: Date[] = events.map((date) => new Date(date.emptyingDate));

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.level2,
        paddingX: 4,
        paddingY: 4,
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Grid container direction={largerThanPhone ? 'row' : 'column'} wrap='nowrap'>
        <Grid item xs={12} sm={8} md={5} lg={5}>
          <Typography marginBottom={3} variant='h5'>
            {getText('contract-calendar-header')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4} md={8} lg={8}>
          <Grid container wrap={largerThanPhone ? 'nowrap' : 'wrap'}>
            <Grid item xs={12} sm={6}>
              <ResponsiveCalendar
                inlineCalendar
                popoverHeaderTextKey={'contract-calendar-header'}
                onChange={handleOnChange}
                renderDayContents={renderDayContents}
                renderPopoverContent={renderPopover}
                availableDates={availableDates}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContractCalendar;
