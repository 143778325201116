/**
   * Returns color updated to given opacity color.
   * @param color - Hex color value.
   * @param opacity - Opacity value, ex. 0.5
   * @returns New hex color value. Or original if opacity is out of bounds.
   */
export function generateOpacity(color: string, opacity: number) {
  if (opacity > 1 || opacity < 0) {
    return color;
  }
  const opacityHex = Math.round(opacity * 255).toString(16).toUpperCase();

  // 0.05 for instance returns D, but it should retun 0D, adds it manually
  if(opacityHex.length === 1) {
    return color + '0' + opacityHex;
  }

  return color + opacityHex;
}