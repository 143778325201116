import React from 'react';
import { useHistory } from 'react-router-dom';
import { NavButton } from './inputs';

type BackButtonProps = {
  onClick?: () => void;
};

const BackButton = ({ onClick }: BackButtonProps): JSX.Element => {
  const history = useHistory();
  return (
    <NavButton onClick={onClick ?? (() => history.goBack())}>&larr;</NavButton>
  );
};

export default BackButton;
