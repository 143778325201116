import { AllInvoicingMethodTypes, EInvoicing } from '../../../model';

export const WIDTH_DESKTOP = '50%';
export const WIDTH_MOBILE = '100%';
export interface BillingInfoFormData {
  companyName: string;
  companyCode: string;
  personalIdentificationNumber: string;
  firstName: string;
  lastName: string;
  address: string;
  postCode: string;
  city: string;
  email: string;
  phoneNumber: string;
  consumerEInvoiceType: AllInvoicingMethodTypes;
  emailInvoicing: boolean;
  contactFirstName: string;
  contactLastName: string;
  billingEmail: string;
  reference: string;
  eInvoiceInformation?: EInvoicing;
  useSameBillingContactPerson?: boolean;
}

export interface LocationInfoFormData {
  companyName?: string;

  firstName?: string;
  lastName?: string;
  address: string;
  postCode: string;
  city: string;

  contactFirstName: string;
  contactLastName: string;
  contactPhone: string;
  contactEmail: string;

  area: string;
  conurbation: string;
  permanentBuildingId: string;
  buildingUsageType: string;
  buildingType: string;
  population: string;
  propertyId: string;
  apartmentCount: string;

  startDate: Date;
  referenceNumber: string;

  useSameEmptyingLocationBasicInfoPerson?: boolean;
  useSameEmptyingLocationContactPerson?: boolean;
}
