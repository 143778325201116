import React, { ReactNode, useContext } from 'react';

import { LanguageContext } from '../../contexts/language-context';
import { PrimaryHeader } from './headers';
import {
  HeaderContainer,
  HeaderItemLeft,
  HeaderItemCenter,
  HeaderItemRight,
  HeaderLogo,
  Row,
  HeaderDescription,
} from './containers';
import { Language, UiTexts } from '../../model';
import LanguageSelect from './LanguageSelect';
import BackButton from './BackButton';
import AuthContext from '../../contexts/auth-context';

type LoginHeaderProps = {
  headerKey: keyof UiTexts;
  descriptionKey: keyof UiTexts;
  language: Language;
  setLanguage: (lang: Language) => void;
  backButton?: boolean;
  children?: ReactNode;
};

const LoginHeader = (props: LoginHeaderProps): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const authService = useContext(AuthContext);

  const {
    headerKey,
    descriptionKey,
    language,
    setLanguage,
    backButton,
    children,
  } = props;

  return (
    <HeaderContainer>
      <Row>
        <HeaderItemLeft>{backButton ? <BackButton /> : null}</HeaderItemLeft>
        <HeaderItemCenter>
          <HeaderLogo
            src={`${authService.getDomain()}/Company/${authService.getTenantId()}/logo`}
            alt="Logo"
          />
        </HeaderItemCenter>
        <HeaderItemRight>
          <LanguageSelect language={language} setLanguage={setLanguage} />
        </HeaderItemRight>
      </Row>
      <PrimaryHeader>{getText(headerKey)} </PrimaryHeader>
      <HeaderDescription>{getText(descriptionKey)}</HeaderDescription>
      {children}
    </HeaderContainer>
  );
};

export default LoginHeader;
