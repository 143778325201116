import { Container, Dialog, Divider, IconButton, Stack, Typography, SxProps } from '@mui/material';
import { forwardRef, ReactElement, ReactNode, Ref, useContext } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import { LanguageContext } from '../../contexts/language-context';
import { UiTexts } from '../../model';
interface Props {
  headerTextKey: keyof UiTexts;
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  secondaryHeader?: JSX.Element;
  titleVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  paperSx?: SxProps;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export const overlayDialogPadding = '16px 0px';

const OverlayDialog = ({
  headerTextKey,
  open,
  onClose,
  children,
  secondaryHeader,
  titleVariant,
  paperSx,
}: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  return (
    <Dialog
      PaperProps={{
        sx: { borderRadius: '16px 16px 0px 0px', ...paperSx },
      }}
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{ top: '48px' }}
      aria-labelledby='overlay-dialog-title'
    >
      <Container>
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Typography p={overlayDialogPadding} variant={titleVariant ?? 'h4'} id='overlay-dialog-title'>
            {getText(headerTextKey)}
          </Typography>

          <IconButton onClick={onClose} aria-label='close-dialog'>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack direction='row' alignItems='flex-start' marginLeft={0}>
          {secondaryHeader}
        </Stack>
      </Container>

      <Divider />

      {children}
    </Dialog>
  );
};

export default OverlayDialog;
