import { useContext } from 'react';
import { Container, Grid, Stack, Typography, useTheme } from '@mui/material';
import { LanguageContext } from '../../../../../contexts/language-context';
import {
  HouseHoldWaterInfo,
  WellCollectionEquipment,
  WellCollectionHouseholdWaterSource,
  WellCollectionWCType,
} from '../models';
import { LargerThanBreakpoint } from '../../../../../util/viewportUtils';

interface Props {
  waterSupplyInfo: HouseHoldWaterInfo;
}

const PropertyWaterSupplySummary = ({ waterSupplyInfo }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const { palette } = useTheme();
  const direction = LargerThanBreakpoint('md') ? 'row' : 'column';
  const marginBottom = LargerThanBreakpoint('md') ? 5 : 0;
  const subtitleMarginBottom = LargerThanBreakpoint('md') ? 2 : 1;

  const translateEquipment = (input: string) => {
    switch (input) {
      case WellCollectionWCType.WaterWC:
        return getText('well-collection-property-water-supply-water-wc');
      case WellCollectionWCType.DryCompostFreezingOrBurning:
        return getText('well-collection-property-water-supply-non-water-wc');
      case WellCollectionHouseholdWaterSource.PressureWater:
        return getText('well-collection-property-water-supply-household-water-pressure');
      case WellCollectionHouseholdWaterSource.CarriedWater:
        return getText('well-collection-property-water-supply-household-water-carried');
      case WellCollectionEquipment.Shower:
        return getText('well-collection-property-water-supply-equipment-shower');
      case WellCollectionEquipment.Dishwasher:
        return getText('well-collection-property-water-supply-equipment-dishwasher');
      case WellCollectionEquipment.WashingMachine:
        return getText('well-collection-property-water-supply-equipment-washing-machine');
      default:
        return '';
    }
  };

  const equipments = waterSupplyInfo.equipments.map((equipment) => `${' '}${translateEquipment(equipment)}`);

  return (
    <Container>
      <Grid container direction={direction} wrap='nowrap' spacing={4} justifyContent={'space-between'} marginTop={2}>
        <Grid item xs={12} sm={7} md={8}>
          <Typography variant='h5' sx={{ paddingTop: '10px' }}>
            {getText('property-water-supply-summary-title')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Stack direction={'column'}>
            <Stack>
              <Typography
                variant='h6'
                sx={{ fontWeight: '450', paddingTop: '10px' }}
                marginBottom={subtitleMarginBottom}
              >
                {getText('property-water-supply-summary-information-title')}
              </Typography>
              <Stack direction={direction} justifyContent={'space-between'} marginBottom={marginBottom}>
                <Stack direction={'column'}>
                  <Typography color={palette.text.secondary}>
                    {getText('property-water-supply-summary-wc-type')}
                  </Typography>
                  <Typography>{translateEquipment(waterSupplyInfo.wcType)}</Typography>
                </Stack>
                <Stack direction={'column'}>
                  <Typography color={palette.text.secondary}>
                    {getText('property-water-supply-summary-household-water-source')}
                  </Typography>
                  <Typography>{translateEquipment(waterSupplyInfo.householdWaterSource)}</Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack>
            <Typography color={palette.text.secondary}>
              {getText('property-water-supply-summary-household-equipment-level')}
            </Typography>
            <Typography>{equipments.join(',')}</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PropertyWaterSupplySummary;
