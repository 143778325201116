import React from 'react';
import FormThemeProvider from '../../components/common/FormThemeProvider';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { Typography } from '@mui/material';

// Using \n doesn't work in schema unless whiteSpace pre-line is defined
const CustomDescriptionField = ({ description }: any) => {
  const theme = FormThemeProvider;

  return (
    <MuiThemeProvider theme={theme}>
      <Typography display={'block'} sx={{ whiteSpace: 'pre-line' }}>
        {description}
      </Typography>
    </MuiThemeProvider>
  );
};

export default CustomDescriptionField;
