import { useContext } from 'react';
import { Stack } from '@mui/material';
import { PermitApplicationTableRow } from './PermitApplicationTableRow';
import { DataTable } from '../../components/common-materialui/data-table/DataTable';
import { LanguageContext } from '../../contexts/language-context';
import { PermitItemInfo, UiTexts } from '../../model';

type Props = {
  infos: PermitItemInfo[];
  handleStatus: (status: string) => boolean;
  fetchPdf: (id: string) => any;
};

export function permitApplicationTableHeaders(getText: (key: keyof UiTexts, params?: string[] | undefined) => string) {
  const headers: any[] = [
    {
      id: 'subject',
      label: getText('permit-application-table-header-subject'),
      alignment: 'left',
    },
    {
      id: 'applicationSent',
      label: getText('permit-application-table-header-application-sent'),
      alignment: 'left',
    },
    {
      id: 'status',
      label: getText('permit-application-table-header-status'),
      alignment: 'left',
    },
    {
      id: 'permit',
      label: getText('permit-application-table-header-permit'),
      alignment: 'left',
    },
  ];

  return headers;
}

const PermitApplicationTable = ({ infos, handleStatus, fetchPdf }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);

  const renderRows = (permitInfo: PermitItemInfo[]) => {
    return permitInfo.map((info, _) => {
      return (
        <PermitApplicationTableRow key={info.permitAppId} info={info} handleStatus={handleStatus} fetchPdf={fetchPdf} />
      );
    });
  };

  return (
    <Stack style={{ marginTop: '1rem' }}>
      <DataTable headers={permitApplicationTableHeaders(getText)} customRows={renderRows(infos)} cards={[]} />
    </Stack>
  );
};

export default PermitApplicationTable;
