import { Box, Collapse, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, ReactNode, useContext } from 'react';
import ExpandMoreButton from '../../../components/common-materialui/ExpandMoreButton';
import { ServiceType, UiTexts } from '../../../model';
import { LanguageContext } from '../../../contexts/language-context';

interface Props {
  serviceType: ServiceType;
  show: boolean;
  labelTextKey: keyof UiTexts;
  itemCount: number | null;
  isExpanded: boolean;
  toggleExpanded: () => void;
  children: ReactNode;
}

const ServiceListContainer: FC<Props> = (props) => {
  const { serviceType, show, labelTextKey, itemCount, isExpanded, toggleExpanded, children } = props;
  const { getText } = useContext(LanguageContext);
  const { palette } = useTheme();
  const theme = useTheme();
  const largerThanPhone = useMediaQuery(theme.breakpoints.up('md'));

  const getItemCountText = () => {
    if (serviceType === ServiceType.Order) {
      return itemCount && itemCount > 1
        ? getText('emptying-location-service-list-order-plural')
        : getText('emptying-location-service-list-order');
    } else {
      return itemCount && itemCount > 1
        ? getText('emptying-location-service-list-service-plural')
        : getText('emptying-location-service-list-service');
    }
  };

  if (!show) {
    return null;
  }

  return (
    <Stack p={1} direction='column' bgcolor={palette.background.level2} sx={{ border: `1px solid ${palette.divider}` }}>
      <Stack direction='row'>
        <Box p={1} component={'div'} onClick={toggleExpanded} sx={{ cursor: 'pointer' }}>
          <Typography variant='subtitle1' noWrap={largerThanPhone}>
            {getText(labelTextKey)}
          </Typography>
        </Box>
        <ServiceItemCount itemCount={itemCount} itemCountText={getItemCountText()} />
        <ExpandMoreButton isExpanded={isExpanded} onClick={toggleExpanded} />
      </Stack>
      <Collapse in={isExpanded} timeout='auto' unmountOnExit>
        {children}
      </Collapse>
    </Stack>
  );
};

const ServiceItemCount: FC<{ itemCount: number | null; itemCountText: string }> = ({ itemCount, itemCountText }) => {
  const { palette } = useTheme();

  if (itemCount) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row-reverse', width: '100%' }}>
        <Stack p={1} direction='row' color={palette.secondary.mainVariant}>
          <Typography variant='body2'>{itemCount}</Typography>
          <Typography variant='body2' ml={0.5}>
            {itemCountText}
          </Typography>
        </Stack>
      </Box>
    );
  }
  return null;
};

export default ServiceListContainer;
