import { Box, FormControl, RadioGroup, FormControlLabel, Radio, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { LanguageContext } from '../../../../contexts/language-context';
import { LargerThanBreakpoint } from '../../../../util/viewportUtils';
import { WIDTH_DESKTOP, WIDTH_MOBILE } from '../../add-emptying-location/models';
import { ProductCategory } from '../../../../model';

interface Props {
  categories: ProductCategory[];
  radioValue: string;
  setRadioValue: React.Dispatch<React.SetStateAction<string>>;
}

const ProductCategorySectionForm = ({ categories, radioValue, setRadioValue }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const width = LargerThanBreakpoint('md') ? WIDTH_DESKTOP : WIDTH_MOBILE;

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setRadioValue(e.target.value);
  }

  const getProductTypeText = (productType: string) => {
    switch (productType) {
      case 'Contract':
        return getText('emptying-location-order-product-category-contract');
      case 'Order':
        return getText('emptying-location-order-product-category-order');
    }
  };

  return (
    <Stack width={width}>
      <FormControl>
        <RadioGroup
          aria-labelledby='radio-group-for-selecting-product-category'
          value={radioValue}
          onChange={handleChange}
        >
          {categories.map((category: ProductCategory, index: number) => (
            <FormControlLabel
              sx={{ border: '1px solid #E8E8E8', padding: '1rem', margin: '0 0 0.5rem 0' }}
              key={index}
              value={category.name}
              control={<Radio sx={{ paddingBottom: '1.5rem' }} />}
              label={
                <Stack display='flex' alignItems='left' spacing={0.25}>
                  <Typography variant='h6'>{category.name}</Typography>
                  <Typography variant='body2' color='text.secondary'>
                    {getProductTypeText(category.productType)}
                  </Typography>
                </Stack>
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};

export default ProductCategorySectionForm;
