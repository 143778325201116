import React from 'react';
import { EmptyingEvent } from '../../model';
import DateInput from './DateInput';

type CalendarProps = {
  emptyingEvents: EmptyingEvent[];
};

const Calendar = ({ emptyingEvents }: CalendarProps): JSX.Element => {
  return (
    <DateInput
      date={null}
      inlineCalendar
      emptyingEvents={emptyingEvents}
      onChange={(_: Date) => null}
    />
  );
};

export default Calendar;
