import { Alert, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  children?: ReactNode;
  severity: 'success' | 'info' | 'warning' | 'error';
  open: boolean;
  onClose?: () => void;
  sx?: SxProps<Theme>;
};

const ExtendedAlert = (props: Props): JSX.Element => {
  const { children, severity, open, onClose, sx } = props;

  return (
    <>
      {open ?
        <Alert sx={sx} severity={severity} onClose={onClose ? () => onClose() : undefined}>
          {children}
        </Alert>
        : null
      }
    </>
  );
};

export default ExtendedAlert;
