import React, { useEffect } from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import FormThemeProvider from '../../components/common/FormThemeProvider';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { getUiSchemaTitle } from './utils';

const CustomRadioWidget = function (props: any) {
  const [options, setOptions] = React.useState<Array<any>>([]);
  const [label, setLabel] = React.useState<string>(props.label);
  const theme = FormThemeProvider;

  const initialize = (properties: any) => {
    // Deep copies, so that not accidentally update schemas
    let enumValues = properties.schema['enum'] ? [...properties.schema['enum']] : null;
    const schemaEnumNames = properties.schema.enumNames ? [...properties.schema.enumNames] : null;
    let enumNames = properties?.uiSchema['ui:options']?.enumNames
      ? [...(properties?.uiSchema['ui:options']?.enumNames ?? null)]
      : schemaEnumNames;

    setLabel(getUiSchemaTitle(props));

    // Radio button contains enum data
    if (enumValues != null) {
      let data: Array<any> = enumValues.map((x: any) => [x, x]);
      for (let i = 0; i < enumValues.length; i++) {
        let text = enumNames != null && i < enumNames.length ? enumNames[i] : enumValues[i];
        data[i] = [enumValues[i], text];
      }

      setOptions(data);
    }
    // Radio button is made using oneOfs
    else if (properties.schema.oneOf != null) {
      const data = properties.schema.oneOf.map((x: { const: boolean; title: string }) => [x.const, x.title]);
      setOptions(data);
    }
    // Radio button is just for boolean
    else {
      let texts = properties.uiSchema['ui:options']?.enumNames ??
        properties.uiSchema['ui:options']?.titles ?? ['true', 'false'];
      const data = new Array<Array<any>>([true, texts[0]], [false, texts[1]]);

      setOptions(data);
    }
  };

  useEffect(() => {
    initialize(props);
  }, [props.formContext.formId, props.formContext.language]);

  const onChange = (event: any) => {
    if (options == null) {
      return;
    }
    const indexValue = event.target.value;
    const index = Number(indexValue);
    let convertedValue = options[index][0];

    props.onChange(convertedValue);
  };

  if (options === null) {
    return <FormControl />;
  }
  
  const requiredCheck = label.length > 0 ? props?.required ?? false : false;

  return (
    <MuiThemeProvider theme={theme}>
      <FormControl required={requiredCheck}>
        <FormLabel>{label}</FormLabel>
        <RadioGroup>
          {options.map((opt: any, index: any) => (
            <FormControlLabel
              key={props.id + '-control-' + index}
              control={<Radio key={props.id + '-radio-' + index} value={index} onChange={onChange} />}
              label={opt[1]}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </MuiThemeProvider>
  );
};

export default CustomRadioWidget;
