import React, {
  FormEvent,
  useContext,
  useRef,
  useEffect,
  useState,
} from 'react';
import { Content, Form } from '../../common/containers';
import UserNameAndPw from './UserNameAndPw';
import { useHistory } from 'react-router-dom';

import { LanguageContext } from '../../../contexts/language-context';
import AuthContext from '../../../contexts/auth-context';
import { SecondaryHeader } from '../../common/headers';
import TextInput from '../../common/TextInput';
import DialogContext from '../../../contexts/dialog-context';
import HandleError from '../errors/HandleError';
import { renderConditionsAndRegistrationButtonContentRow } from './registerUtils';

const ExistingAccount = (): JSX.Element => {
  const history = useHistory();
  const { getText } = useContext(LanguageContext);
  const authService = useContext(AuthContext);
  const showDialog = useContext(DialogContext);

  const [invoiceNumber, setInvoiceNumber] = useState<string>('');
  const [customerNumber, setCustomerNumber] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');

  const [userName, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmedPassword, setConfirmedPassword] = useState<string>('');

  const [iHaveReadConditions, setIHaveReadConditions] = useState<boolean>(
    false
  );
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const componentIsMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const renderBasicInfo = () => {
    return (
      <Content>
        <SecondaryHeader>{getText('basic-info')}</SecondaryHeader>
        <TextInput
          label="basic-info-invoice-number"
          val={invoiceNumber}
          setter={setInvoiceNumber}
          validations={['integer']}
          showErrors={isSubmitted}
        />
        <TextInput
          label="basic-info-customer-number"
          val={customerNumber}
          setter={setCustomerNumber}
          validations={['required']}
          showErrors={isSubmitted}
        />
        <TextInput
          label="basic-info-first-name"
          val={firstName}
          setter={setFirstName}
          validations={['required']}
          showErrors={isSubmitted}
        />
        <TextInput
          label="basic-info-last-name"
          val={lastName}
          setter={setLastName}
          validations={['required']}
          showErrors={isSubmitted}
        />
        <TextInput
          label="basic-info-phone"
          val={phone}
          setter={setPhone}
          validations={['required']}
          showErrors={isSubmitted}
        />
        <TextInput
          label="basic-info-email"
          val={email}
          setter={setEmail}
          validations={['email', 'required']}
          showErrors={isSubmitted}
        />
      </Content>
    );
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    const { logIn, registerExistingAccount } = authService;

    const params = {
      invoiceNumber: parseInt(invoiceNumber),
      customerNumber,
      userName,
      password,
      email,
      phoneNumber: phone,
      firstName,
      lastName,
      language: 'fi'
    };

    registerExistingAccount(params)
      .then(() => {
        return logIn(userName, password);
      })
      .then(() => {
        history.replace('/emptying-infos');
      })
      .catch((err: any) => {
        HandleError(err, showDialog, authService);
      })
      .finally(() => {
        if (componentIsMounted.current) {
          setIsLoading(false);
        }
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      {renderBasicInfo()}
      <UserNameAndPw
        userName={userName}
        setUserName={setUserName}
        password={password}
        setPassword={setPassword}
        confirmedPassword={confirmedPassword}
        setConfirmedPassword={setConfirmedPassword}
        isSubmitted={isSubmitted}
      />
      {
        renderConditionsAndRegistrationButtonContentRow(
          iHaveReadConditions,
          isLoading,
          setIHaveReadConditions,
          setIsSubmitted,
          getText('register')
        )
      }
    </Form>
  );
};

export default ExistingAccount;
