import React, { Dispatch, SetStateAction, useContext } from 'react';
import { Content } from '../../common/containers';
import { SecondaryHeader } from '../../common/headers';
import TextInput from '../../common/TextInput';
import { LanguageContext } from '../../../contexts/language-context';

type InputProps = {
  firstName: string;
  setFirstName: Dispatch<SetStateAction<string>>;
  lastName: string;
  setLastName: Dispatch<SetStateAction<string>>;
  phone: string;
  setPhone: Dispatch<SetStateAction<string>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  askSsn: boolean;
  ssn?: string;
  setSsn?: Dispatch<SetStateAction<string>>;
  contactName: string;
  setContactName: Dispatch<SetStateAction<string>>;
  isSubmitted: boolean;
};

const PrivateCustomerBasicInfo = (props: InputProps): JSX.Element => {
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    phone,
    setPhone,
    email,
    setEmail,
    askSsn,
    ssn,
    setSsn,
    contactName,
    setContactName,
    isSubmitted,
  } = props;
  const { getText } = useContext(LanguageContext);

  return (
    <Content>
      <SecondaryHeader>{getText('basic-info')}</SecondaryHeader>
      <TextInput
        label="basic-info-first-name"
        val={firstName}
        setter={setFirstName}
        validations={['required']}
        showErrors={isSubmitted}
      />
      <TextInput
        label="basic-info-last-name"
        val={lastName}
        setter={setLastName}
        validations={['required']}
        showErrors={isSubmitted}
      />
      <TextInput
        label="basic-info-phone"
        val={phone}
        setter={setPhone}
        validations={['required']}
        showErrors={isSubmitted}
      />
      <TextInput
        label="basic-info-email"
        val={email}
        setter={setEmail}
        validations={['required', 'email']}
        showErrors={isSubmitted}
      />

      {askSsn ? (
        <TextInput
          label="basic-info-ssn"
          val={ssn!}
          setter={setSsn!}
          validations={['required', 'hetu']}
          showErrors={isSubmitted}
          maxLength={11}
        />
      ) : null}

      <TextInput
        label="contact-person"
        val={contactName}
        setter={setContactName}
        showErrors={isSubmitted}
      />
    </Content>
  );
};

export default PrivateCustomerBasicInfo;
