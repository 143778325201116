import { useContext } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { LanguageContext } from '../../../contexts/language-context';
import { convertToCurrentLocaleLang } from '../../../util/numberLocalization';
import { PriceCalculationResult } from '../../../model';

interface RenderPriceProps {
  value?: number;
  excludeUnit?: boolean;
  isWasteManagement?: boolean;
  price?: PriceCalculationResult;
}

interface AdditionalRowRenderProps {
  name: string;
  eventType: string;
  destination: string;
  price?: PriceCalculationResult;
}

interface PriceTableProps {
  price: PriceCalculationResult;
  productName?: string;
  additionalProductCalculations?: AdditionalRowRenderProps[];
  currentNumberOfContainers?: number;
}

const PriceTable = ({
  price,
  productName,
  additionalProductCalculations,
  currentNumberOfContainers,
}: PriceTableProps): JSX.Element => {
  const { palette, breakpoints } = useTheme();
  const { getText, lang } = useContext(LanguageContext);
  const includesVatString = getText('includes-vat');
  const cellStyle = { border: 'none' };
  const headerCellStyle = { borderBottom: `1px solid ${palette.divider}` };
  const totalCellStyle = {
    backgroundColor: palette.divider,
    borderBottom: `1px solid ${palette.grey[300]}`,
    borderTop: `1px solid ${palette.grey[300]}`,
  };

  const largerThanPhone = useMediaQuery(breakpoints.up('md'));
  const textStyle = { fontSize: largerThanPhone ? '0.875rem' : '1rem' };

  function renderPrice(data: RenderPriceProps) {
    let unitText = '';
    if ((!data?.value && data?.value !== 0) || !data?.price) {
      return '';
    }

    if (!data?.excludeUnit) {
      unitText = data?.price?.AmountUnit ? '/' + data?.price?.AmountUnit : '';
    }

    // If it's waste management or additional product, display weight unit when it's weight based
    if (data?.isWasteManagement && !data?.excludeUnit) {
      unitText = data?.price?.IsWeightBased ? '/' + data?.price?.WeightUnit : '/' + data?.price?.AmountUnit;
    }

    return `${convertToCurrentLocaleLang(lang, data.value, undefined, 2)} €${unitText}`;
  }

  function renderTotalPrice(data: RenderPriceProps) {
    let unitText = '';
    if ((!data?.value && data?.value !== 0) || !data?.price) {
      return '';
    }

    // In the waste management price row, should not display total price if its weight based
    if (data?.isWasteManagement && data?.price?.IsWeightBased) {
      return '';
    }

    if (!data?.excludeUnit) {
      unitText = '/' + data?.price?.AmountUnit;
    }

    return `${convertToCurrentLocaleLang(lang, data.value, undefined, 2)} €${unitText}`;
  }

  function renderAmount(value?: number, priceData?: PriceCalculationResult, checkWeightBased?: boolean) {
    if ((!value && value !== 0) || !priceData) {
      return '';
    }

    if (checkWeightBased && priceData?.IsWeightBased) {
      return '';
    }

    return `${value} ${priceData?.AmountUnit ?? ''}`;
  }

  function renderMainProductRow() {
    return (
      <TableRow>
        <TableCell sx={cellStyle} align='left'>
          <Typography variant='body1' noWrap sx={textStyle}>
            {productName + ' '} {includesVatString}
          </Typography>
        </TableCell>
        <TableCell sx={cellStyle} align='center'>
          <Typography variant='body1' noWrap sx={textStyle}>
            {renderPrice({ value: price?.TransportUnitPriceIncludingVat, price: price })}
          </Typography>
        </TableCell>
        <TableCell sx={cellStyle} align='center'>
          <Typography variant='body1' noWrap sx={textStyle}>
            {renderAmount(currentNumberOfContainers, price)}
          </Typography>
        </TableCell>
        <TableCell sx={cellStyle} align='center'>
          <Typography variant='body1' noWrap sx={textStyle}>
            {renderTotalPrice({ value: price?.TransportPriceIncludingVat, excludeUnit: true, price: price })}
          </Typography>
        </TableCell>
      </TableRow>
    );
  }

  function renderWasteManagementRow() {
    return (
      <TableRow>
        <TableCell sx={cellStyle} align='left'>
          <Typography variant='body1' noWrap sx={textStyle}>
            {getText('order-product-row-waste-management') + ' '}
            {includesVatString}
          </Typography>
        </TableCell>
        <TableCell sx={cellStyle} align='center'>
          <Typography variant='body1' noWrap sx={textStyle}>
            {renderPrice({ value: price?.HandlingUnitPriceIncludingVat, isWasteManagement: true, price: price })}
          </Typography>
        </TableCell>
        <TableCell sx={cellStyle} align='center'>
          <Typography variant='body1' noWrap sx={textStyle}>
            {renderAmount(currentNumberOfContainers, price, true)}
          </Typography>
        </TableCell>
        <TableCell sx={cellStyle} align='center'>
          <Typography variant='body1' noWrap sx={textStyle}>
            {renderTotalPrice({
              value: price?.HandlingPriceIncludingVat,
              excludeUnit: true,
              isWasteManagement: true,
              price: price,
            })}
          </Typography>
        </TableCell>
      </TableRow>
    );
  }

  function renderTotalRow(isWeightBased: boolean) {
    if (isWeightBased) {
      return null;
    }
    return (
      <TableRow sx={{ backgroundColor: palette.divider }}>
        <TableCell sx={totalCellStyle} align='left'>
          <Typography variant='subtitle1' noWrap sx={textStyle}>
            {getText('order-product-total-header') + ' '}
            {includesVatString}
          </Typography>
        </TableCell>
        <TableCell sx={totalCellStyle} align='center'></TableCell>
        <TableCell sx={totalCellStyle} align='center'></TableCell>
        <TableCell sx={totalCellStyle} align='center'>
          <Typography variant='subtitle1' noWrap sx={textStyle}>
            {renderTotalPrice({ value: price?.TotalPriceGross, excludeUnit: true, price: price })}
          </Typography>
        </TableCell>
      </TableRow>
    );
  }

  function renderAdditionalProductRow(data: AdditionalRowRenderProps, idx: number) {
    return (
      <TableRow key={data.name + '-' + idx}>
        <TableCell sx={cellStyle} align='left'>
          <Typography variant='body1' noWrap sx={textStyle}>
            {data.name + ' '} {includesVatString}
          </Typography>
        </TableCell>
        <TableCell sx={cellStyle} align='center'>
          <Typography variant='body1' noWrap sx={textStyle}>
            {renderPrice({
              value: data?.price?.TransportUnitPriceIncludingVat,
              isWasteManagement: true,
              price: data?.price,
            })}
          </Typography>
        </TableCell>
        <TableCell sx={cellStyle} align='center'>
          <Typography variant='body1' noWrap sx={textStyle}>
            {renderAmount(currentNumberOfContainers, data?.price)}
          </Typography>
        </TableCell>
        <TableCell sx={cellStyle} align='center'>
          <Typography variant='body1' noWrap sx={textStyle}>
            {renderTotalPrice({
              value: data?.price?.TransportPriceIncludingVat,
              excludeUnit: true,
              price: data?.price,
            })}
          </Typography>
        </TableCell>
      </TableRow>
    );
  }
  return (
    <TableContainer sx={{ backgroundColor: palette.background.level1 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={headerCellStyle} align='left'>
              <Typography variant='subtitle1'>{getText('order-product-service-header')}</Typography>
            </TableCell>
            <TableCell sx={headerCellStyle} align='center'>
              <Typography variant='subtitle1'>{getText('order-product-unit-price-header')}</Typography>
            </TableCell>
            <TableCell sx={headerCellStyle} align='center'>
              <Typography variant='subtitle1'>{getText('order-product-amount-header')}</Typography>
            </TableCell>
            <TableCell sx={headerCellStyle} align='center'>
              <Typography variant='subtitle1'>{getText('order-product-total-header')}</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderMainProductRow()}
          {renderWasteManagementRow()}
          {renderTotalRow(price?.IsWeightBased ?? false)}
          {additionalProductCalculations?.map((data, idx) => renderAdditionalProductRow(data, idx))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PriceTable;
