import React, { FormEvent, useContext, useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import AuthContext from '../../contexts/auth-context';
import DialogContext from '../../contexts/dialog-context';
import { LanguageContext } from '../../contexts/language-context';
import { Language } from '../../model';
import HttpError from '../../util/HttpError';
import { Column, Form, Content, CompactColumn, Row } from '../common/containers';
import { SecondaryHeader } from '../common/headers';
import { Checkbox } from '../common/inputs';
import LoginHeader from '../common/LoginHeader';
import TextInput from '../common/TextInput';
import VingoButton from '../common/VingoButton';
import { ResetPasswordParams } from '../../service/authService';
import Spinner from '../common/Spinner';
import Terms from '../common/Terms';

type ResetPasswordProps = {
  language: Language;
  setLanguage: (lang: Language) => void;
};

const ResetPassword = (props: ResetPasswordProps): JSX.Element => {
  const history = useHistory();
  const { language, setLanguage } = props;
  const { getText } = useContext(LanguageContext);
  const authService = useContext(AuthContext);
  const showDialog = useContext(DialogContext);

  const [iHaveReadConditions, setIHaveReadConditions] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [confirmedPassword, setConfirmedPassword] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [token, setToken] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    try {
      const pageUrl: string = decodeURIComponent(window.location.href);
      const splitBase = pageUrl.split('reset-password?');
      const splitVar = splitBase[1].split('&');
      setToken(splitVar[0].split('token=')[1]);
      setEmail(splitVar[1].split('email=')[1]);
      setUserName(splitVar[2].split('username=')[1]);
      const langParam = splitVar[3].split('newLang=')[1];
      if (langParam != null) {
        setLanguage(langParam as Language);
      }
      else {
        setLanguage('fi');
      }
    } catch (err: unknown) {
      console.error(err);
      showDialog(
        'error-general-title',
        'error-general-message',
        () => history.replace('/login')
      );
    }
  }, []);

  const renderConditionsAndRegistrationButton = () => {
    return (
      <Content>
        <Row style={{ marginTop: '1.2rem', marginBottom: '0.5rem' }}>
          <Terms
            type="default"
            iHaveReadConditions={iHaveReadConditions}
            setIHaveReadConditions={setIHaveReadConditions}
          />
        </Row>
        <VingoButton
          disabled={!iHaveReadConditions}
          onClick={() => setIsSubmitted(true)}
          isLoading={isLoading}
        >
          {getText('reset-password-send-button')}
        </VingoButton>
      </Content>
    );
  };


  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    const params: ResetPasswordParams = {
      email: email,
      userName: userName,
      token: token,
      password: password
    };
    authService
      .resetPassword(params)
      .then(() => {
        showDialog(
          'message-success-generic-title',
          'reset-password-ok-message',
          () => history.replace('/login')
        );
      })
      .catch((err: unknown) => {
        if (err instanceof HttpError) {
          if (err.statusCode == 400
            && err.response != null
            && err.response.type != null
            && err.response.type == "/problems/e-services-users/user-account-not-valid") {
            showDialog('forgot-password-account-not-valid-title', 'forgot-password-account-not-valid-message');
          }
          else if (err.statusCode == 400
            && err.response != null
            && err.response.type != null
            && err.response.type == "/problems/identity-operation-failed") {
            showDialog('reset-password-identity-failed-header', 'reset-password-identity-failed-message');
          }
          else {
            showDialog('error-general-title', 'error-general-message');
          }
        }
        else {
          showDialog('error-general-title', 'error-general-message');
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Column>
      <LoginHeader
        headerKey="reset-password-form-header"
        descriptionKey="reset-password-description"
        language={language}
        setLanguage={setLanguage}
        backButton={true}
      />
      {email && token ?
        (
          <CompactColumn>
            <Form onSubmit={handleSubmit}>
              <Content>
                <SecondaryHeader>
                  {getText('reset-password-header')}
                </SecondaryHeader>

                <TextInput
                  label="password"
                  inputType="password"
                  val={password}
                  setter={setPassword}
                  validations={['password']}
                  showErrors={isSubmitted}
                />
                <TextInput
                  label="confirm-pw"
                  inputType="password"
                  val={confirmedPassword}
                  setter={setConfirmedPassword}
                  validations={[{ equalToValue: password }]}
                  showErrors={isSubmitted}
                />
              </Content>
              {renderConditionsAndRegistrationButton()}
            </Form>
          </CompactColumn>
        ) : (
          <CompactColumn>
            <Spinner />
          </CompactColumn>
        )
      };
    </Column>
  );
};

export default ResetPassword;
