import { createContext, FC, useContext, useMemo, useState } from "react";
import ApiContext from "../../../../contexts/api-context";
import { useQuery } from "@tanstack/react-query";
import { Area, BuildingClassification, BuildingType, Conurbation, UiTexts } from "../../../../model";
import { LanguageContext } from "../../../../contexts/language-context";

interface EmptyingLocationContextProps {
  alertErrorMsg: keyof UiTexts | null;
  areasLoading: boolean;
  areaOptions: { value: string; text: string }[];
  buildingTypesLoading: boolean;
  buildingTypeOptions: { value: string; text: string }[];
  buildingUsageTypesLoading: boolean;
  buildingUsageTypeOptions: { value: string; text: string }[];
  conurbationsLoading: boolean;
  conurbationOptions: { value: string; text: string }[];
  setAlertErrorMsg: (msg: keyof UiTexts) => void;
}

export const EmptyingLocationContext = createContext<EmptyingLocationContextProps>({} as EmptyingLocationContextProps);

export const EmptyingLocationContextProvider: FC = ({ children }) => {

  const { lang } = useContext(LanguageContext);
  const { fetchBuildingUsageTypes, fetchBuildingTypes, fetchAreas, fetchConurbations } = useContext(ApiContext);
  const [alertErrorMsg, setAlertErrorMsg] = useState<keyof UiTexts | null>(null);

  const { isLoading: buildingUsageTypesLoading, data: buildingUsageTypes } = useQuery<BuildingClassification[]>(
    ['building-usage-types'],
    () => fetchBuildingUsageTypes(lang),
    {
      onError: () => setAlertErrorMsg('add-emptying-location-building-info-usage-data-error'),
    }
  );

  const { isLoading: buildingTypesLoading, data: buildingTypes } = useQuery<BuildingType[]>(
    ['building-types'],
    () => fetchBuildingTypes(lang),
    {
      onError: () => setAlertErrorMsg('add-emptying-location-building-info-usage-data-error'),
    }
  );

  const { isLoading: areasLoading, data: areas } = useQuery<Area[]>(['area'], () => fetchAreas(lang), {
    onError: () => setAlertErrorMsg('add-emptying-location-building-info-area-error'),
  });

  const { isLoading: conurbationsLoading, data: conurbations } = useQuery<Conurbation[]>(
    ['conurbations'],
    () => fetchConurbations(),
    {
      onError: () => setAlertErrorMsg('add-emptying-location-building-info-conurbation-error'),
    }
  );

  const buildingUsageTypeOptions = useMemo(() => {
    return buildingUsageTypes
      ? buildingUsageTypes.map((t) => ({ value: t.code, text: t.name }))
      : [];
  }, [buildingUsageTypes]);

  const buildingTypeOptions = useMemo(() => {
    return buildingTypes
      ? buildingTypes.map((t) => ({ value: t.code, text: t.name }))
      : [];
  }, [buildingTypes]);

  const areaOptions = useMemo(() => {
    return areas
      ? areas.map((t) => ({ value: t.code, text: t.name }))
      : [];
  }, [areas]);

  const conurbationOptions = useMemo(() => {
    return conurbations
      ? conurbations.map((t) => ({ value: t.id, text: t.name }))
      : [];
  }, [conurbations]);


  const value = useMemo(() => {
    return {
      alertErrorMsg,
      areasLoading,
      areaOptions,
      buildingTypesLoading,
      buildingTypeOptions,
      buildingUsageTypesLoading,
      buildingUsageTypeOptions,
      conurbationsLoading,
      conurbationOptions,
      setAlertErrorMsg,
    };
  }, [
    alertErrorMsg,
    areasLoading,
    areaOptions,
    buildingTypesLoading,
    buildingTypeOptions,
    buildingUsageTypesLoading,
    buildingUsageTypeOptions,
    conurbationsLoading,
    conurbationOptions,
    setAlertErrorMsg,
  ]);

  return (
    <EmptyingLocationContext.Provider value={value}>
      {children}
    </EmptyingLocationContext.Provider>
  );
}