import { useContext, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import MoreActionsMenu from '../../../components/common-materialui/more-actions-menu/MoreActionsMenu';
import { ReactComponent as MenuIcon } from '../../../assets/more-horizontal.svg';
import { LanguageContext } from '../../../contexts/language-context';
import OverlayDialog from '../../../components/common-materialui/OverlayDialog';
import WellCollection from './WellCollection';
import WasteWaterInvestigation, { WasteWaterInvestigationOptionEnum } from './WasteWaterInvestigation';
import ApiMessageDialog from '../../../components/common-materialui/ApiMessageDialog';

const WellCollectionMenu = (): JSX.Element => {
  const [showAddWellCollectionDialog, setShowAddWellCollectionDialog] = useState<boolean>(false);
  const [showWasteWaterInvestigationDialog, setShowWasteWaterInvestigationDialog] = useState<boolean>(false);
  const [wasteWaterInvestigationOption, setWasteWaterInvestigationOption] =
    useState<WasteWaterInvestigationOptionEnum>();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  const { getText } = useContext(LanguageContext);
  const theme = useTheme();
  const largerThanPhone = useMediaQuery(theme.breakpoints.up('md'));
  const iconSize = largerThanPhone ? 35 : 24;

  const menuItems = [
    {
      label: getText('well-collection-menu-create-notification-item'),
      onClick: () => {
        setShowWasteWaterInvestigationDialog(true);
      },
    },
  ];

  const handleCloseAddWellCollectionDialog = () => {
    setShowAddWellCollectionDialog(false);
  };

  const handleCloseWasteWaterInvestigationDialog = () => {
    setShowWasteWaterInvestigationDialog(false);
  };

  const handleCloseConfirmationDialog = () => {
    setShowConfirmationDialog(false);
  };

  const handleWasteWaterInvestigation = () => {
    if (wasteWaterInvestigationOption === WasteWaterInvestigationOptionEnum.NotConnected) {
      setShowAddWellCollectionDialog(true);
      handleCloseWasteWaterInvestigationDialog();
      setWasteWaterInvestigationOption(undefined);
    } else if (wasteWaterInvestigationOption === WasteWaterInvestigationOptionEnum.Connected) {
      handleCloseWasteWaterInvestigationDialog();
      setShowConfirmationDialog(true);
      setWasteWaterInvestigationOption(undefined);
    }
  };

  return (
    <>
      <OverlayDialog
        headerTextKey='waste-water-investigation-header'
        open={showWasteWaterInvestigationDialog}
        onClose={handleCloseWasteWaterInvestigationDialog}
        titleVariant='h3'
      >
        <WasteWaterInvestigation
          option={wasteWaterInvestigationOption}
          setOption={setWasteWaterInvestigationOption}
          onSave={handleWasteWaterInvestigation}
        />
      </OverlayDialog>
      <OverlayDialog
        headerTextKey='emptying-location-waste-water-header'
        open={showAddWellCollectionDialog}
        onClose={handleCloseAddWellCollectionDialog}
      >
        <WellCollection />
      </OverlayDialog>
      <ApiMessageDialog
        open={showConfirmationDialog}
        buttonLabelKey='dialog-ok'
        descriptionMessageKey='waste-water-sewer-network-connected-confirmation-message'
        onClose={handleCloseConfirmationDialog}
      />
      <MoreActionsMenu
        menuItems={menuItems}
        menuButton={<MenuIcon color='secondary' width={iconSize} height={iconSize} />}
      />
    </>
  );
};

export default WellCollectionMenu;
