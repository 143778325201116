import { Fragment, useContext, useRef } from 'react';
import { Stack, Container, Box, Button, Typography, Grid, Divider, useTheme } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import OrderProductPrice from './OrderProductPrice';
import { FormDataFinishOrder, WeekInterval } from '../OrderProduct';
import ControlCheckboxInput from '../../../../components/common-materialui/form/ControlCheckboxInput';
import TermsAndConditions from '../../../../components/common-materialui/TermsAndConditions';
import { LanguageContext } from '../../../../contexts/language-context';
import ApiContext from '../../../../contexts/api-context';
import { LargerThanBreakpoint } from '../../../../util/viewportUtils';
import {
  VingoProduct,
  PriceCalculationResult,
  AddNewContractReq,
  ProductOrder,
  BinOwnership,
  NewEmptyingInterval,
  convertStringToBinOwnership,
} from '../../../../model';
import { getAlertWithErrorMessages } from '../../../../components/common-materialui/status-handling/httpErrorUtils';
import { printddMMDate } from '../../../../util/calendarUtil';
import { printIntervalBasedOnTimesWeek } from '../../../../util/util';

interface Props {
  emptyingId: string;
  formDataFinishOrder: FormDataFinishOrder;
  priceFinishOrder: PriceCalculationResult | undefined;
  containerSizeFinishOrder: VingoProduct | null | undefined;
  weekIntervalFinishOrder: WeekInterval[] | undefined;
  onPreviousPhaseChange: () => void;
  onCancel: () => void;
}

interface TermsOfService {
  termsOfService: boolean;
}

const OrderProductSummary = ({
  emptyingId,
  formDataFinishOrder,
  priceFinishOrder,
  containerSizeFinishOrder,
  weekIntervalFinishOrder,
  onPreviousPhaseChange,
  onCancel,
}: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<TermsOfService>({
    defaultValues: {
      termsOfService: false,
    },
  });
  const api = useContext(ApiContext);

  const queryClient = useQueryClient();

  const titleTypographyVariant = LargerThanBreakpoint('lg') ? 'h5' : 'h6';
  const orientation = LargerThanBreakpoint('md') ? 'row' : 'column';

  const contentRef = useRef<HTMLDivElement | null>(null);

  const { palette } = useTheme();

  const scrollToTop = (): void => {
    if (contentRef.current !== null) {
      contentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const {
    mutate: orderNewContract,
    error: orderNewContractError,
    isLoading,
    reset: newContractReset,
  } = useMutation((data: AddNewContractReq) => api.addNewContract(emptyingId, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['contracts']);
      onCancel();
    },
    onError: scrollToTop,
  });

  const displayContainerOwnership = (binOwnership: BinOwnership | string | undefined): string => {
    const value = convertStringToBinOwnership(binOwnership);
    return dispayContainerOwnershipRecord[value];
  }

  const dispayContainerOwnershipRecord: Record<BinOwnership, string> = {
    Buy: getText('order-product-container-ownership-buy'),
    Delivered: getText('order-product-container-ownership-delivered'),
    Owned: getText('order-product-container-ownership-owned'),
    Rent: getText('order-product-container-ownership-rent'),
  };

  return (
    <Stack direction='column'>
      <Container sx={{ paddingX: '2rem' }} ref={contentRef}>
        {orderNewContractError ? (
          <Container>
            {getAlertWithErrorMessages(orderNewContractError, getText, () => {
              newContractReset();
            })}
          </Container>
        ) : null}
        <Grid container direction={orientation} wrap='nowrap' marginTop={2}>
          <Grid item xs={12} sm={7} md={8} sx={{ marginLeft: -2 }}>
            <Typography variant={titleTypographyVariant}>
              {getText('order-product-summary-product-information-header')}
            </Typography>
          </Grid>

          <Grid container direction='column' marginTop={1}>
            <Grid item mt={1} marginBottom={2}>
              <Typography variant='subtitle1'>
                {getText('order-product-summary-waste-type-and-container-header')}
              </Typography>
              <Grid container spacing={2} alignItems='center' mb={1} mt={1}>
                <Grid item xs={6}>
                  <Typography color={palette.text.secondary}>{getText('order-product-summary-waste-type')}</Typography>
                  <Typography>{containerSizeFinishOrder?.wasteTypeName}</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography color={palette.text.secondary}>
                    {getText('order-product-summary-container-size')}
                  </Typography>
                  <Typography>{containerSizeFinishOrder?.name}</Typography>
                </Grid>
              </Grid>
              {formDataFinishOrder?.containerOwnership && (
                <Stack direction={'column'}>
                  <Typography color={palette.text.secondary}>
                    {getText('order-product-summary-container-ownership')}
                  </Typography>
                  <Typography>{displayContainerOwnership(formDataFinishOrder?.containerOwnership)}</Typography>
                </Stack>
              )}
            </Grid>
            <Divider />

            <Grid item mt={2}>
              <Typography variant='subtitle1'>
                {getText('order-product-summary-contract-information-header')}
              </Typography>
              <Grid container spacing={2} alignItems='center' mb={2} mt={2}>
                <Grid item xs={6}>
                  <Typography color={palette.text.secondary}>
                    {getText('order-product-summary-number-of-containers')}
                  </Typography>
                  <Typography textAlign={'left'}>{formDataFinishOrder?.numberOfContainers}</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography color={palette.text.secondary}>
                    {getText('order-product-summary-contract-start-date')}
                  </Typography>
                  <Typography textAlign={'left'}>
                    {printddMMDate(formDataFinishOrder?.contractStartDate || null)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Divider />

            <Grid item marginTop={2}>
              <Typography variant='subtitle1'>{getText('order-product-summary-emptying-intervals-header')}</Typography>
              <Grid container spacing={2} mb={1} mt={2}>
                {weekIntervalFinishOrder?.map((week: WeekInterval, id: number) => (
                  <Fragment key={id}>
                    <Grid item xs={6}>
                      <Typography>
                        {printIntervalBasedOnTimesWeek(getText, Number(week.interval), Number(week.timesPerWeek))}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Box mb={2}>
                        <Stack direction={'column'}>
                          <Typography color={palette.text.secondary}>
                            {getText('order-product-summary-contract-validity-weeks')}
                          </Typography>
                          <Typography textAlign={'left'}>{`${week.startWeek} - ${week.endWeek}`} </Typography>
                        </Stack>
                      </Box>
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
              <Divider />
            </Grid>

            <Grid item mt={2}>
              <Typography variant='subtitle1'>{getText('order-product-summary-info-for-driver-header')}</Typography>
              <Grid container spacing={2} mb={1} mt={1}>
                <Grid item xs={6}>
                  <Typography color={palette.text.secondary}>
                    {getText('order-product-summary-gate-key-code')}
                  </Typography>
                  <Typography textAlign={'left'}>{formDataFinishOrder?.gateKeyCode || '-'} </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography color={palette.text.secondary}>
                    {getText('order-product-summary-additional-information')}
                  </Typography>
                  <Box style={{ maxWidth: '20rem' }}>
                    <Typography
                      style={{
                        overflowWrap: 'break-word',
                        wordWrap: 'break-word',
                      }}
                    >
                      {formDataFinishOrder?.additionalInformation || '-'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Divider sx={{ marginTop: 2 }} />

      <Container>
        <Grid container direction={orientation} wrap='nowrap' marginTop={2}>
          <Grid item xs={12} sm={5} md={5} lg={5}>
            <Typography variant={titleTypographyVariant}>{getText('order-product-summary-price-header')}</Typography>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8}>
            <OrderProductPrice
              price={priceFinishOrder}
              productName={containerSizeFinishOrder?.name ?? ''}
              containerSizeSelected={containerSizeFinishOrder}
              currentNumberOfContainers={Number(formDataFinishOrder?.numberOfContainers)}
              emptyingId={emptyingId}
            />
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Stack alignItems={'flex-end'}>
          <Box>
            <ControlCheckboxInput
              label={<TermsAndConditions type='terms-of-service' />}
              validations={['required']}
              control={control}
              name='termsOfService'
              error={errors.termsOfService}
            />
          </Box>
        </Stack>
      </Container>

      <Container>
        <Stack direction='row' justifyContent='space-between' spacing={2} paddingX={3} marginBottom={3}>
          <Box>
            <Button size='large' color='primary' onClick={onPreviousPhaseChange}>
              {getText('dialog-back')}
            </Button>
          </Box>
          <Stack direction='row' spacing={2}>
            <Box>
              <Button size='large' color='secondary' onClick={onCancel}>
                {getText('dialog-cancel')}
              </Button>
            </Box>
            <Box>
              <Button
                variant='contained'
                size='large'
                disabled={isLoading}
                onClick={handleSubmit(() => {
                  const newEmptyinInterval: NewEmptyingInterval[] = (weekIntervalFinishOrder ?? []).map((item) => {
                    return {
                      startWeek: parseInt(item.startWeek, 10) ?? 0,
                      endWeek: parseInt(item.endWeek, 10),
                      amountPerWeek: parseInt(item.timesPerWeek, 10),
                      interval: parseInt(item.interval, 10),
                    };
                  });

                  const newProduct: ProductOrder = {
                    name: containerSizeFinishOrder?.name ?? null,
                    productGuid: containerSizeFinishOrder?.id || '',
                    amount: Number(formDataFinishOrder?.numberOfContainers),
                    emptyingIntervals: newEmptyinInterval,
                    binOwnership: convertStringToBinOwnership(formDataFinishOrder?.containerOwnership),
                    startingDate: formDataFinishOrder.contractStartDate,
                    key: formDataFinishOrder?.gateKeyCode ?? null,
                    comment: formDataFinishOrder?.additionalInformation ?? null,
                  };
                  const newContractEntityReq: AddNewContractReq = { products: [newProduct] };
                  orderNewContract(newContractEntityReq);
                })}
              >
                {getText('dialog-save')}
              </Button>
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

export default OrderProductSummary;
