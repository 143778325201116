import { FormEvent, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AuthContext from '../../contexts/auth-context';
import DialogContext from '../../contexts/dialog-context';
import { LanguageContext } from '../../contexts/language-context';
import { Language } from '../../model';
import { Box, Container, Stack, Typography, useTheme } from '@mui/material';
import { Form } from '../../components/common/containers';
import { SecondaryHeader } from '../../components/common/headers';
import TextInput from '../../components/common/TextInput';
import VingoButton from '../../components/common/VingoButton';
import LogoNavigationLanguageBar from '../../components/common-materialui/header/LogoNavigationLanguageBar';

type ForgotUsernameProps = {
  language: Language;
  setLanguage: (lang: Language) => void;
};

const ForgotUsername = (props: ForgotUsernameProps): JSX.Element => {
  const history = useHistory();
  const { palette } = useTheme();
  const { language, setLanguage } = props;
  const { getText } = useContext(LanguageContext);
  const authService = useContext(AuthContext);
  const showDialog = useContext(DialogContext);

  const [email, setEmail] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    authService
      .forgotUsername(email)
      .then(() => {
        showDialog(
          'message-success-generic-title',
          'forgot-username-ok-message',
          () => history.replace('/login')
        );
      })
      .catch((err: unknown) => {
          showDialog('error-general-title', 'error-general-message');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <LogoNavigationLanguageBar />
      <Container>
        <Box
          sx={{
            backgroundColor: palette.background.level2,
            padding: 3,
            maxWidth: '530px',
            margin: 'auto',
            border: `1px solid ${palette.divider}`,
          }}
        >
          <Stack spacing={4}>
            <Stack spacing={2}>
              <Typography variant='h3'>{getText('forgot-username-header')}</Typography>
              <Typography variant={'body2'} color={palette.text.primary}>
                {getText('forgot-username-description')}
              </Typography>
            </Stack>
            <Stack spacing={0}>
              <Form onSubmit={handleSubmit}>
                <SecondaryHeader>
                  {getText('forgot-username-form-header')}
                </SecondaryHeader>
                <TextInput
                  label="email"
                  val={email}
                  setter={setEmail}
                  validations={['email', 'required']}
                  showErrors={isSubmitted}
                />
                <Link className="small-link" to="/login">
                  {getText('forgot-username-return-to-login-link')}
                </Link>
                <VingoButton
                  onClick={() => setIsSubmitted(true)}
                  isLoading={isLoading}
                >
                  {getText('forgot-username-send-button')}
                </VingoButton>
              </Form>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </>
  );
};

export default ForgotUsername;
