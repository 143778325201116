import { Stack } from "@mui/material";
import { FC } from "react";
import { SmallerThanBreakpoint } from "../../../../util/viewportUtils";
import TextInput from "../../../../components/common/TextInput";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { validateInput } from "../../../../components/common/input/validation";
import FormErrorField from "../../../../components/common-materialui/form/FormErrorField";
import { EditEmptyingContactFormData } from "./EditContactPerson";



interface Props {
  control: Control<EditEmptyingContactFormData, any>;
  errors: FieldErrors<EditEmptyingContactFormData>;
}

const EditContactPersonForm: FC<Props> = ({ control, errors }) => {
  const stackWidth = SmallerThanBreakpoint("md") ? "100%" : "50%";

  return (
    <Stack sx={{ width: stackWidth }} >
      <Controller
        control={control}
        name="contactFirstName"
        render={({ field: { onChange, value } }) => {
          return (
            <TextInput
              val={value}
              setter={onChange}
              label={'basic-info-first-name'}
            />
          );
        }}
      />

      <Controller
        control={control}
        name="contactLastName"
        render={({ field: { onChange, value } }) => {
          return (
            <TextInput
              val={value}
              setter={onChange}
              label={'basic-info-last-name'}
            />
          );
        }}
      />

      <Controller
        control={control}
        name="phoneNumber"
        rules={{
          validate: { checkPhone: v => validateInput(v, ["phone"])[0] }
        }}
        render={({ field: { onChange, value } }) => {
          return (
            <TextInput
              val={value}
              setter={onChange}
              label={'basic-info-phone'}
            />
          );
        }}
      />
      <FormErrorField errorMsg={errors.phoneNumber?.message} />

      <Controller
        control={control}
        name="email"
        rules={{
          validate: { checkEmail: v => validateInput(v, ["email", "required"])[0], }
        }}
        render={({ field: { onChange, value } }) => {
          return (
            <TextInput
              val={value}
              setter={onChange}
              label={'basic-info-email'}
            />
          );
        }}
      />
      <FormErrorField errorMsg={errors.email?.message} />
    </Stack>
  );
};

export default EditContactPersonForm;