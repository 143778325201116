import { FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { FC, useCallback, useContext, useEffect } from 'react';
import { UiTexts, convertToEServiceLanguage, convertToLanguage } from '../../model';
import { Control, Controller, FieldErrors, useFormContext } from 'react-hook-form';
import ControlTextInput from '../../components/common-materialui/form/ControlTextInput';
import ControlCheckboxInput from '../../components/common-materialui/form/ControlCheckboxInput';
import TermsAndConditions from '../../components/common-materialui/TermsAndConditions';
import { LargerThanBreakpoint } from '../../util/viewportUtils';
import { LanguageSelectionContext } from '../../contexts/LanguageSelectionContextProvider';

export interface RegisterFormData {
  customerNumber?: string;
  invoicingNumber?: number;
  personSsn?: string;

  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  language: string;
  username: string;
  password: string;
  confirmPassword: string;
  termsOfUse: boolean;
}

export const RegisterFormDataInitialValues: RegisterFormData = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  language: '',
  username: '',
  password: '',
  confirmPassword: '',
  termsOfUse: false,
};

export interface DisabledStates {
  [fieldName: string]: boolean;
}

interface BasicInformationSectionProps {
  getText: (key: keyof UiTexts, params?: string[] | undefined) => string;
  titleKey: keyof UiTexts;
  disabledStates?: DisabledStates;
}

export const RenderBasicInformationSection: FC<BasicInformationSectionProps> = ({
  getText,
  titleKey,
  disabledStates,
}) => {

  const { control, setValue } = useFormContext<RegisterFormData>();
  const errors = control._formState.errors;

  const {
    availableLanguages,
    getLanguageSelectionBasicFormText,
    language,
    setLanguage,
  } = useContext(LanguageSelectionContext);

  const currentLanguage = convertToEServiceLanguage(language);
  const formLanguage = control._formValues['language'];

  // Assign "default" initial language radio value (format : EServiceLanguage)
  useEffect(() => {
    if (availableLanguages.length > 0) {
      setValue('language', currentLanguage);
    }
  }, [availableLanguages, currentLanguage, formLanguage, setValue]);

  // Custom handler for language change, so we can update the language in the form & language selection context
  const handleLanguageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const targetLanguage = convertToLanguage(event.target.value);
      setLanguage(targetLanguage);
      setValue('language', event.target.value);
    },
    [setLanguage, setValue]
  );

  const largerThanMobile = LargerThanBreakpoint('md');

  // Define a default state where all fields are enabled
  const defaultDisabledStates = {
    firstName: false,
    lastName: false,
    phoneNumber: false,
    email: false,
  };

  // Use the provided disabledStates or default to enabling all fields
  const finalDisabledStates = { ...defaultDisabledStates, ...disabledStates };

  return (
    <Stack spacing={0}>
      <Typography mb={2} variant='h5'>
        {getText(titleKey)}
      </Typography>
      <ControlTextInput
        control={control}
        name='firstName'
        label='basic-info-first-name'
        error={errors.firstName}
        validations={['required']}
        disabled={finalDisabledStates.firstName}
      />
      <ControlTextInput
        control={control}
        name='lastName'
        label='basic-info-last-name'
        error={errors.lastName}
        validations={['required']}
        disabled={finalDisabledStates.lastName}
      />
      <ControlTextInput
        control={control}
        name='phoneNumber'
        label='basic-info-phone'
        error={errors.phoneNumber}
        validations={['required', 'phone']}
        disabled={finalDisabledStates.phoneNumber}
      />
      <ControlTextInput
        control={control}
        name='email'
        label='basic-info-email'
        error={errors.email}
        validations={['required', 'email']}
        disabled={finalDisabledStates.email}
      />

      <Typography mt={2} variant='subtitle1'>
        {getText('basic-info-language')}
      </Typography>
      <Controller
        control={control}
        name='language'
        render={() => {
          return (
            <FormControl>
              <RadioGroup
                row={largerThanMobile}
                aria-labelledby='register-new-language-select-radio-group'
                name='language-select-radio-group'
                value={control._formValues['language']}
                onChange={handleLanguageChange}
              >
                {availableLanguages?.map((language) => (
                  <FormControlLabel
                    sx={{ marginRight: 5 }}
                    key={language}
                    value={language}
                    control={<Radio />}
                    label={getLanguageSelectionBasicFormText(language)}
                  />
                ))}  
              </RadioGroup>
            </FormControl>
          );
        }}
      />   
    </Stack>
  );
}

export function renderUsernamePasswordSection(
  control: Control<RegisterFormData, any>,
  errors: FieldErrors<RegisterFormData>,
  getText: (key: keyof UiTexts, params?: string[] | undefined) => string,
  currentPassword: string
) {
  return (
    <Stack>
      <Typography mb={2} variant='h5'>
        {getText('username-and-pw')}
      </Typography>
      <ControlTextInput
        control={control}
        name='username'
        label='basic-info-username'
        error={errors.username}
        validations={['required']}
      />
      <ControlTextInput
        control={control}
        name='password'
        label='password-minimum12'
        error={errors.password}
        validations={['required', 'password']}
        inputType='password'
      />
      <ControlTextInput
        control={control}
        name='confirmPassword'
        label='confirm-pw'
        error={errors.confirmPassword}
        validations={['required', { equalToValue: currentPassword }, 'password']}
        inputType='password'
      />
      <ControlCheckboxInput
        label={<TermsAndConditions type='terms-of-use' />}
        validations={['required']}
        control={control}
        name='termsOfUse'
        error={errors.termsOfUse}
      />
    </Stack>
  );
}
