export const WIDTH_DESKTOP = '50%';
export const WIDTH_MOBILE = '100%';

export interface EditUserInformationFormData {
  collectionDate: Date;
  amount: string;
  additionalInformation: string;
  termsAndConditions: boolean;
}
export interface EditLoginInformationFormData {
  currentPassword: string;
  newPassword: string;
  confirmedPassword: string;
}
