import { Autocomplete, InputAdornment, TextField, useTheme } from "@mui/material";
import { useContext } from 'react';
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { LanguageContext } from "../../contexts/language-context";

interface Props {
  searchPhrase: string;
  setSearchPhrase: React.Dispatch<React.SetStateAction<string>>;
  options?: string[];
  marginTop?: string;
  marginBottom?: string;
}

const SearchBar = ({
  searchPhrase,
  setSearchPhrase,
  options,
  marginTop,
  marginBottom
}: Props): JSX.Element => {
  const theme = useTheme();
  const { getText } = useContext(LanguageContext);

  const renderSearchInput = (params: any) => {
    return (
      <TextField
        {...params}
        onChange={(e) => {
          setSearchPhrase(e.currentTarget.value);
        }}
        placeholder={getText("search-bar-label")}
        InputProps={{
          ...params.InputProps,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
              {params.InputProps.startAdornment}
            </InputAdornment>
          ),
        }}
      />
    );
  };

  return (
    <Autocomplete
      disableCloseOnSelect
      disableClearable
      id="searchbar"
      freeSolo
      options={options ?? []}
      renderInput={(params) => renderSearchInput(params)}
      size={"medium"}
      sx={{
        ...theme.components?.MuiAutocomplete,
        marginBottom: marginBottom ?? undefined,
        marginTop: marginTop ?? undefined,
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: theme.palette.secondary.main,
          }
        }
      }}
    />
  );
};

export default SearchBar;