import { Box, Button, Container, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Header from '../../components/common-materialui/header/Header';
import { useContext } from 'react';
import { LanguageContext } from '../../contexts/language-context';
import { useMutation } from '@tanstack/react-query';
import ApiContext from '../../contexts/api-context';
import ControlTextInput from '../../components/common-materialui/form/ControlTextInput';
import { useForm, useWatch } from 'react-hook-form';
import { LargerThanBreakpoint, SmallerThanBreakpoint } from '../../util/viewportUtils';
import { EditLoginInformationFormData, WIDTH_DESKTOP, WIDTH_MOBILE } from './models';
import { useHistory } from 'react-router-dom';
import { UserAccountInfo } from '../../model';
import DialogContext from '../../contexts/dialog-context';
import HttpError from '../../util/HttpError';

type EditLoginInformationProps = {
  location: {
    state: { userAccountInfo: UserAccountInfo };
  };
};

const EditLoginInformation = (props: EditLoginInformationProps): JSX.Element => {
  const history = useHistory();
  const { palette, breakpoints } = useTheme();
  const { getText } = useContext(LanguageContext);
  const { saveUserAccountInfo } = useContext(ApiContext);
  const showDialog = useContext(DialogContext);

  const largerThanPhone = useMediaQuery(breakpoints.up('md'));
  const headerStackDirection = largerThanPhone ? 'row' : 'column';

  const { userAccountInfo } = props.location.state ?? { email: '', phoneNumber: '', firstName: '', lastName: '' };

  const { mutateAsync: updateUserAccountInfo, isLoading: updateUserAccountInfoIsLoading } = useMutation(
    (data: EditLoginInformationFormData) =>
      saveUserAccountInfo({
        ...userAccountInfo,
        password: {
          currentPassword: currentPassword,
          newPassword: newPassword,
        },
      }),
    {
      onSuccess: () => history.replace('/account-settings'),
    }
  );

  const handleUpdatePassword = async (data: EditLoginInformationFormData) => {
    try {
      await updateUserAccountInfo(data);
    } catch (err: unknown) {
      if (err instanceof HttpError) {
        if (
          err.statusCode === 400 &&
          err.response != null &&
          err.response.type != null &&
          err.response.type === '/problems/identity-operation-failed'
        ) {
          showDialog('error-general-title', 'error-incorrect-login-password');
        }
      } else {
        showDialog('error-general-title', 'error-general-message');
      }
    }
  };

  const width = LargerThanBreakpoint('md') ? WIDTH_DESKTOP : WIDTH_MOBILE;
  const orientation = SmallerThanBreakpoint('md') ? 'column' : 'row';

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<EditLoginInformationFormData>();

  const currentPassword = useWatch({
    control,
    name: 'currentPassword',
  });

  const newPassword = useWatch({
    control,
    name: 'newPassword',
  });

  const doValidation = !!currentPassword || !!newPassword;

  const headerContent = () => {
    return (
      <Stack
        spacing={3}
        justifyContent='space-between'
        direction={headerStackDirection}
        marginTop={largerThanPhone ? 1 : 0.75}
        marginBottom={largerThanPhone ? 3 : 2}
      >
        <Typography variant='h2'>
          {getText('account-settings-edit-login-info-header')}
          <Typography variant='body1' mt={1}>
            {getText('account-settings-edit-login-info-guide-text')}
          </Typography>
        </Typography>
      </Stack>
    );
  };

  return (
    <>
      <Header content={headerContent()} />
      <Container>
        <Stack
          direction={orientation}
          justifyContent={'space-between'}
          spacing={2}
          p='1rem'
          border={1}
          borderColor='divider'
          sx={{
            backgroundColor: palette.background.level2,
          }}
        >
          <Typography variant='h6'>{getText('account-settings-edit-login-info-form-header')}</Typography>
          <Stack direction='column' width={width}>
            <ControlTextInput
              control={control}
              name='currentPassword'
              label='user-account-current-pw'
              error={errors.currentPassword}
              inputType='password'
              validations={['required', 'password']}
            />
            <ControlTextInput
              control={control}
              name='newPassword'
              label='user-account-new-pw'
              error={errors.newPassword}
              inputType='password'
              validations={doValidation ? [{ notEqualToValue: currentPassword }] : ['required', 'password']}
              guideText={getText('pw-info')}
            />
            <ControlTextInput
              control={control}
              name='confirmedPassword'
              label='user-account-confirm-new-pw'
              error={errors.confirmedPassword}
              inputType='password'
              validations={doValidation ? [{ equalToValue: newPassword }] : ['required', 'password']}
            />
          </Stack>
        </Stack>
      </Container>
      <Container>
        <Stack direction='row' justifyContent='flex-end' spacing={2} paddingX={3} marginBottom={3}>
          <Box>
            <Button size='large' color='secondary' onClick={() => history.replace('/account-settings')}>
              {getText('dialog-cancel')}
            </Button>
          </Box>
          <Box>
            <Button
              variant='contained'
              size='large'
              disabled={updateUserAccountInfoIsLoading}
              onClick={handleSubmit((e) => handleUpdatePassword(e))}
            >
              {getText('dialog-save')}
            </Button>
          </Box>
        </Stack>
      </Container>
    </>
  );
};

export default EditLoginInformation;
