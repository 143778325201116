import React, { useContext, useState } from 'react';
import AuthContext from '../../contexts/auth-context';
import { LanguageContext } from '../../contexts/language-context';
import { Checkbox } from './inputs';
import { UiTexts } from '../../model';
import CustomMessageDialog from './CustomMessageDialog';

type InputProps = {
  type: string;
  iHaveReadConditions: boolean;
  setIHaveReadConditions: React.Dispatch<React.SetStateAction<boolean>>;
};

const Terms = (props: InputProps): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const authService = useContext(AuthContext);
  const [dialogState, setDialogState] = useState<boolean>(false);
  const [titleKey, setTitleKey] = useState<keyof UiTexts>('terms-of-use-title');
  const [message, setMessage] = useState<keyof UiTexts>('terms-of-use-text');

  const {
    type,
    iHaveReadConditions,
    setIHaveReadConditions
  } = props;


  function GetConditionsTypeText() {
    if (type === 'contract') {
      return getText('contract-conditions');
    }
    return getText('conditions');
  }

  function termDialogue(e: React.MouseEvent) {
    e.preventDefault();

    if (type === 'contract') {
      setTitleKey('terms-of-service-title');
      setMessage('terms-of-service-text');
    }

    setDialogState(true);
  }

  return (
    <>
      <Checkbox
        type="checkbox"
        id="conditions-checkbox"
        onChange={() => setIHaveReadConditions(!iHaveReadConditions)}
      />
      <label htmlFor="conditions-checkbox">
        {getText('i-have-read')}
        &nbsp;
        <a href='' onClick={(e) => termDialogue(e)}>
          {
            GetConditionsTypeText()
          }
        </a>
      </label>
      {
        dialogState
          ?
          <CustomMessageDialog
            openState={dialogState}
            openStateHandler={setDialogState}
            titleKey={titleKey}
            message={message}
          />
          :
          null
      }
    </>
  );
};


export default Terms;
