import { Language } from "../../model";
import AuthContext from '../../contexts/auth-context';
import { useContext } from "react";
import { useHistory } from 'react-router-dom';
import Spinner from "../common/Spinner";
import DialogContext from "../../contexts/dialog-context";

type LoginSamlProps = {
    token: string;
    expiresAt: string;
    nameId: string;
    sessionIndex: string;
    language: Language;
    setLanguage: (lang: Language) => void;
  };

  const ticksToDatetime = (ticksValue: string) => {
    const ticks: number = Number(ticksValue); 
    const ticksToMicrotime: number = ticks / 10000;
    const epochMicrotimeDiff = 2208988800000;
    const tickDate = new Date(ticksToMicrotime - epochMicrotimeDiff);
    return tickDate;
  }
  
  const LoginSaml = (props: LoginSamlProps): JSX.Element => {
    const history = useHistory();
    const authService = useContext(AuthContext);
    const showDialog = useContext(DialogContext);

    var expiresAt = ticksToDatetime(props.expiresAt)
    
    authService.logInWithToken(props.token, expiresAt, props.nameId, props.sessionIndex)
    .then(() => {
      history.replace('/emptying-infos');
    })
    .catch((err: any) => {
      if (err.statusCode === 401) {
        showDialog(
          'error-general-title',
          'error-loginfailed-login-message',
          () => history.replace('/logout')
        );
      } else {
        showDialog(
          'error-service-break-title',
          'error-service-break-message',
          () => history.replace('/login')
        );
      }
    });

    return (
      <Spinner/>
    );
};
export default LoginSaml;