import { useState, useRef, useEffect, useContext } from 'react';
import { Language } from '../../model';

import { RightUpperCornerContainer, Languages } from './containers';
import { LanguageButton } from './inputs';
import { ReactComponent as Chevron } from '../../assets/nuoli.svg';
import AuthContext from '../../contexts/auth-context';
import { EServiceLanguage } from '../../service/authService';

type LanguageSelectProps = {
  language: Language;
  setLanguage: (lang: Language) => void;
};

const LanguageSelect = (props: LanguageSelectProps): JSX.Element => {
  const { language, setLanguage } = props;
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const authService = useContext(AuthContext);
  const [selectableLanguages, setSelectableLanguages] = useState<EServiceLanguage[]>([]);

  useEffect(() => {
    authService.getCompanyInfo().then((companyInfo) => {
      setSelectableLanguages(companyInfo?.eServiceLanguages ?? []);
    });
  }, [authService]);

  useEffect(() => {
    // Close language dropbox when clicking on document body
    const onBodyClick = (event: any) => {
      if (ref.current?.contains(event.target)) {
        return;
      }
      setOpen(false);
    };
    document.body.addEventListener('click', onBodyClick);
    return () => {
      document.body.removeEventListener('click', onBodyClick);
    };
  }, []);

  const selectLanguage = (lang: EServiceLanguage) => {
    const language =  lang.toLowerCase() as Language;
    setLanguage(language);
    setOpen(false);
  };

  const chevronStyle = open ? { transform: 'rotate(180deg)' } : {};

  const getLangText = (lang: EServiceLanguage) => {
    if (lang === EServiceLanguage.Sv) {
      return "På svenska";
    }
    if (lang === EServiceLanguage.En) {
      return "English";
    }
    if (lang === EServiceLanguage.Fi) {
      return "Suomeksi";
    }
    return "";
  }

  return (
    <RightUpperCornerContainer ref={ref}>
      <span onClick={() => setOpen(!open)}>
        {language.toUpperCase()}
        &nbsp;
        <Chevron style={chevronStyle} />
      </span>
      {open && selectableLanguages.length > 0? (
        <Languages>
          {selectableLanguages.map((lang) => (
                <LanguageButton
                  key={lang.toString()}
                  onClick={() => selectLanguage(lang)}>
                  {getLangText(lang)}
                </LanguageButton>
              ))}
        </Languages>
      ) : null}
    </RightUpperCornerContainer>
  );
};

export default LanguageSelect;
