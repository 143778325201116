import React, { useContext, useState, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '../../../contexts/language-context';
import ApiContext from '../../../contexts/api-context';
import { Content, ContentRow, Form } from '../../common/containers';
import VingoButton from '../../common/VingoButton';
import DialogContext from '../../../contexts/dialog-context';
import TextInput from '../../common/TextInput';
import { SecondaryHeader } from '../../common/headers';
import { BillingInfo, ConsumerEInvoiceType, SavePrivateCustomerBillingInfoReq } from '../../../model';
import { RadioButton } from '../../common/inputs';
import AuthContext from '../../../contexts/auth-context';

interface Props {
  billingInfos: BillingInfo;
}

const ModifyPrivate = ({ billingInfos }: Props): JSX.Element => {
  const history = useHistory();
  const { getText } = useContext(LanguageContext);
  const api = useContext(ApiContext);
  const auth = useContext(AuthContext);
  const showDialog = useContext(DialogContext);

  const [firstName, setFirstName] = useState<string>(billingInfos?.name?.split(' ')[1] ?? '');
  const [lastName, setLastName] = useState<string>(billingInfos?.name?.split(' ')[0] ?? '');

  const [phone, setPhone] = useState<string>(billingInfos?.billingPhone ?? '');
  const [email, setEmail] = useState<string>(billingInfos?.billingEmail ?? '');
  const [contactName, setContactName] = useState<string>(billingInfos?.contactPerson?.name ?? '');

  const [streetAddress, setStreetAddress] = useState<string>(billingInfos.address.street);
  const [postCode, setPostCode] = useState<string>(billingInfos.address.postalCode);
  const [postOffice, setPostOffice] = useState<string>(billingInfos.address.postOffice);

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [billType, setBillType] = useState<ConsumerEInvoiceType>(billingInfos.consumerEInvoiceType);
  const hiddenCondition = billType === ConsumerEInvoiceType.ConsumerChannel || billType === ConsumerEInvoiceType.DirectInvoicing;

  const readOnly = auth.isReadOnlyBillingAddressEnabled();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    const data: SavePrivateCustomerBillingInfoReq = {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phone,
      billingEmail: email,
      billingAddress: streetAddress + ',' + postCode + ' ' + postOffice,
      consumerEInvoiceType: billType,
      contactPerson: contactName,
    };

    api
      .savePrivateCustomerBillingInfo(billingInfos.id, data)
      .then((_res) => {
        setIsLoading(false);
        showDialog('message-success-generic-title', 'message-data-saved');
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.statusCode === 401) {
          showDialog('error-unauthorized-title', 'error-unauthorized-message', () => history.replace('/logout'));
        } else {
          showDialog('error-service-break-title', 'error-service-break-message');
        }
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Content>
        <SecondaryHeader>{getText('emptying-address-billing-address')}</SecondaryHeader>
        <TextInput label='basic-info-first-name' val={firstName} setter={setFirstName} showErrors={isSubmitted} disabled={readOnly} />
        <TextInput label='basic-info-last-name' val={lastName} setter={setLastName} showErrors={isSubmitted} disabled={readOnly} />
        <TextInput
          label='billing-data-street-address'
          val={streetAddress}
          setter={setStreetAddress}
          showErrors={isSubmitted}
          disabled={readOnly}
        />
        <TextInput
          label='billing-data-post-code'
          val={postCode}
          setter={setPostCode}
          showErrors={isSubmitted}
          validations={[{ minLength: 5 }]}
          disabled={readOnly}
        />
        <TextInput label='billing-data-city' val={postOffice} setter={setPostOffice} showErrors={isSubmitted} disabled={readOnly} />
      </Content>
      <Content>
        <SecondaryHeader>{getText('billing-data-billing-contact-person')}</SecondaryHeader>
        <TextInput
          label='basic-info-phone'
          val={phone}
          setter={setPhone}
          validations={['required']}
          showErrors={isSubmitted}
          disabled={readOnly}
        />
        <TextInput
          label='basic-info-email'
          val={email}
          setter={setEmail}
          validations={['required', 'email']}
          showErrors={isSubmitted}
          disabled={readOnly}
        />
        <TextInput
          label='contact-person'
          val={contactName}
          setter={setContactName}
          validations={['required']}
          showErrors={isSubmitted}
          disabled={readOnly}
        />
      </Content>

      <Content>
        <SecondaryHeader>{getText('billing-data-billing-method')}</SecondaryHeader>
        <ContentRow style={{ marginTop: '0.5rem' }}>
          <RadioButton
            hidden={hiddenCondition}
            type='radio'
            name='bill-type-selection'
            id='paper-bill'
            checked={billType === ConsumerEInvoiceType.NotInUse}
            onChange={() => setBillType(ConsumerEInvoiceType.NotInUse)}
            disabled={readOnly}
          />
          <label hidden={hiddenCondition} htmlFor='paper-bill'>
            {getText('paper-bill')}
          </label>
        </ContentRow>
        {auth.isAllowRegisterEmailInvoicingEnabled() && (
          <ContentRow hidden={hiddenCondition}>
            <RadioButton
              hidden={hiddenCondition}
              type='radio'
              name='bill-type-selection'
              id='email-bill'
              checked={billType === ConsumerEInvoiceType.EmailInvoicing}
              onChange={() => setBillType(ConsumerEInvoiceType.EmailInvoicing)}
              disabled={readOnly}
            />
            <label hidden={hiddenCondition} htmlFor='email-bill'>
              {getText('email-bill')}
            </label>
          </ContentRow>
        )}
        <ContentRow hidden={billType !== ConsumerEInvoiceType.ConsumerChannel}>
          <RadioButton
            hidden={billType !== ConsumerEInvoiceType.ConsumerChannel}
            type='radio'
            name='bill-type-selection'
            id='e-bill-private'
            checked={billType === ConsumerEInvoiceType.ConsumerChannel}
            onChange={() => setBillType(ConsumerEInvoiceType.ConsumerChannel)}
            disabled={readOnly}
          />
          <label hidden={billType !== ConsumerEInvoiceType.ConsumerChannel} htmlFor='e-bill-private'>
            {getText('e-bill-private')}
          </label>
        </ContentRow>
        <ContentRow hidden={billType !== ConsumerEInvoiceType.DirectInvoicing}>
          <RadioButton
            hidden={billType !== ConsumerEInvoiceType.DirectInvoicing}
            type='radio'
            name='bill-type-selection'
            id='e-direct-payment'
            checked={billType === ConsumerEInvoiceType.DirectInvoicing}
            onChange={() => setBillType(ConsumerEInvoiceType.DirectInvoicing)}
            disabled={readOnly}
          />
          <label hidden={billType !== ConsumerEInvoiceType.DirectInvoicing} htmlFor='e-direct-payment'>
            {getText('e-direct-payment')}
          </label>
        </ContentRow>
      </Content>

      {!readOnly && (
        <Content>
          <VingoButton onClick={() => setIsSubmitted(true)} isLoading={isLoading} disabled={readOnly}>
            {getText('user-account-save')}
          </VingoButton>
        </Content>)}
    </Form>
  );
};

export default ModifyPrivate;
