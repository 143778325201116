import { FormControlLabel, Stack, Switch } from '@mui/material';
import { useContext } from 'react';
import { LanguageContext } from '../../../../contexts/language-context';
import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form';
import { BillingInfoFormData, WIDTH_DESKTOP, WIDTH_MOBILE } from '../models';
import TextInput from '../../../../components/common/TextInput';
import FormErrorField from '../../../../components/common-materialui/form/FormErrorField';
import { validateInput } from '../../../../components/common/input/validation';
import { LargerThanBreakpoint } from '../../../../util/viewportUtils';
import { ConsumerEInvoiceType } from '../../../../model';

interface Props {
  control: Control<BillingInfoFormData, any>;
  errors: FieldErrors<BillingInfoFormData>;
  disabled: boolean;
}

const PrivateBillingAddressSection = ({ control, errors, disabled }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const consumerEInvoiceTypeWatch = useWatch({ control, name: 'consumerEInvoiceType' });
  const width = LargerThanBreakpoint('md') ? WIDTH_DESKTOP : WIDTH_MOBILE;

  return (
    <Stack direction='column' width={width}>
      <Controller
        control={control}
        name='firstName'
        rules={{
          validate: { validateFunc: (v) => validateInput(v, ['required'])[0] },
        }}
        render={({ field: { onChange, value } }) => {
          return <TextInput disabled={disabled} val={value} setter={onChange} label={'basic-info-first-name'} />;
        }}
      />
      <FormErrorField errorMsg={errors.firstName?.message} />

      <Controller
        control={control}
        name='lastName'
        rules={{
          validate: { validateFunc: (v) => validateInput(v, ['required'])[0] },
        }}
        render={({ field: { onChange, value } }) => {
          return <TextInput disabled={disabled} val={value} setter={onChange} label={'basic-info-last-name'} />;
        }}
      />
      <FormErrorField errorMsg={errors.lastName?.message} />

      <Controller
        control={control}
        name='personalIdentificationNumber'
        rules={{
          validate: { checkSSN: (v) => validateInput(v, ['hetu'])[0] },
        }}
        render={({ field: { onChange, value } }) => {
          return <TextInput disabled={disabled} val={value} setter={onChange} label={'basic-info-ssn'} />;
        }}
      />
      <FormErrorField errorMsg={errors.personalIdentificationNumber?.message} />

      <Controller
        control={control}
        name='address'
        rules={{
          validate: { validateFunc: (v) => validateInput(v, ['required'])[0] },
        }}
        render={({ field: { onChange, value } }) => {
          return <TextInput disabled={disabled} val={value} setter={onChange} label={'billing-data-street-address'} />;
        }}
      />
      <FormErrorField errorMsg={errors.address?.message} />

      <Controller
        control={control}
        name='postCode'
        rules={{
          validate: { validateFunc: (v) => validateInput(v, ['required', 'post-code'])[0] },
        }}
        render={({ field: { onChange, value } }) => {
          return <TextInput disabled={disabled} val={value} setter={onChange} label={'billing-data-post-code'} />;
        }}
      />
      <FormErrorField errorMsg={errors.postCode?.message} />

      <Controller
        control={control}
        name='city'
        rules={{
          validate: { validateFunc: (v) => validateInput(v, ['required'])[0] },
        }}
        render={({ field: { onChange, value } }) => {
          return <TextInput disabled={disabled} val={value} setter={onChange} label={'billing-data-city'} />;
        }}
      />
      <FormErrorField errorMsg={errors.city?.message} />

      <Controller
        control={control}
        name='consumerEInvoiceType'
        render={({ field: { onChange, value } }) => {
          return (
            <FormControlLabel
              control={
                <Switch
                  disabled={disabled}
                  checked={value === ConsumerEInvoiceType.EmailInvoicing}
                  onChange={() => {
                    return onChange(
                      value === ConsumerEInvoiceType.EmailInvoicing
                        ? ConsumerEInvoiceType.NotInUse
                        : ConsumerEInvoiceType.EmailInvoicing
                    );
                  }}
                />
              }
              label={getText('email-bill')}
            />
          );
        }}
      />

      {consumerEInvoiceTypeWatch === ConsumerEInvoiceType.EmailInvoicing ? (
        <>
          <Controller
            control={control}
            name='billingEmail'
            rules={{
              validate: { checkEmail: (v) => validateInput(v, ['email', 'required'])[0] },
            }}
            render={({ field: { onChange, value } }) => {
              return <TextInput disabled={disabled} val={value} setter={onChange} label={'billing-email'} />;
            }}
          />
          <FormErrorField errorMsg={errors.billingEmail?.message} />
        </>
      ) : null}
    </Stack>
  );
};

export default PrivateBillingAddressSection;
