import { useContext } from 'react';
import { Stack } from '@mui/material';
import { Control, FieldErrors, useWatch } from 'react-hook-form';
import { LanguageContext } from '../../../../../../contexts/language-context';
import ControlDropdownInput from '../../../../../../components/common-materialui/form/ControlDropdownInput';
import ControlTextInput from '../../../../../../components/common-materialui/form/ControlTextInput';
import { BlackWaterForm, WeeklyFrequencyEnum } from '../../models';

interface Props {
  control: Control<BlackWaterForm, any>;
  errors: FieldErrors<BlackWaterForm>;
}
const BlackWater = ({ control, errors }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);

  const residentCount = useWatch({
    control,
    name: 'blackWater.residentCount',
  });

  const weeklyFrequencyOption = useWatch({
    control,
    name: 'blackWater.weeklyFrequency',
  });

  const weeklyFrequencyOptions = [
    { text: getText('need-for-emptying-weekly-frequency-half-year'), value: WeeklyFrequencyEnum.EveryHalfAYear },
    { text: getText('need-for-emptying-weekly-frequency-year'), value: WeeklyFrequencyEnum.EveryYear },
    {
      text: getText('need-for-emptying-weekly-frequency-other'),
      value: WeeklyFrequencyEnum.Other,
    },
  ];

  if (residentCount && residentCount > 0) {
    weeklyFrequencyOptions.splice(2, 0, {
      text: getText('need-for-emptying-weekly-frequency-two-year'),
      value: WeeklyFrequencyEnum.EverySecondYear,
    });
  }

  return (
    <Stack direction={'column'}>
      <ControlTextInput
        control={control}
        error={errors?.blackWater?.residentCount}
        label={'need-for-emptying-black-water-resident-count'}
        validations={['required', 'positive-integer', 'gt_zero', 'integer-not-start-zero']}
        name={'blackWater.residentCount'}
        maxValue={99}
        inputType='number'
      />
      <ControlDropdownInput
        control={control}
        error={errors?.blackWater?.weeklyFrequency}
        validations={['required']}
        dropdownItems={weeklyFrequencyOptions}
        label={'need-for-emptying-weekly-frequency-dropdown-label'}
        name='blackWater.weeklyFrequency'
      />
      {weeklyFrequencyOption === WeeklyFrequencyEnum.Other && (
        <ControlTextInput
          control={control}
          error={errors?.blackWater?.other}
          label='need-for-emptying-other-input-label'
          name='blackWater.other'
          validations={['required']}
        />
      )}
    </Stack>
  );
};

export default BlackWater;
