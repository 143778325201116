type Colors = {
  bodyBackground: string;
  contentBackground: string;
  contentBorder: string;
  header: string;
  secondaryHeader: string;
  lightLabel: string;
  textContent: string;
  errorText: string;
  errorBackground: string;
  inputBorder: string;
  inputFocused: string;
  primaryButton: string;
  primaryButtonDisabled: string;
  primaryButtonBorder: string;
  primaryButtonFont: string;
  secondaryButton: string;
  secondaryButtonDisabled: string;
  infoContentBackground: string;
  infoContentBorder: string;
  warningContentBackground: string;
  warningContentBorder: string;
  statusInfo: string;
  statusInfoBorder: string;
  statusInfoText: string;
  statusInfoDone: string;
  statusInfoDoneBorder: string;

  logo: string;
  menuTransparentPart: string;
  menuClosedTransparentPart: string;
  linkColor: string;
  linkHover: string;
  calendarSelectedDate: string;
  calendarEmptyingDate: string;
};

const colors: Colors = {
  bodyBackground: '#f7f7f7',
  contentBackground: '#ffffff',
  contentBorder: '#e8e8e8',
  header: '#000000',
  secondaryHeader: '#41414a',
  lightLabel: '#99999f',
  textContent: '#41414a',
  errorBackground: '#feda31',
  errorText: '#444444',
  inputBorder: '#e2e2e1',
  inputFocused: '#4373b5',
  primaryButton: '#377a6c',
  primaryButtonDisabled: '#377a6c88',
  primaryButtonBorder: '#e8e8e8',
  primaryButtonFont: '#ffffff',
  secondaryButton: '#41414a',
  secondaryButtonDisabled: '#41414a88',
  infoContentBackground: '#eaeef3',
  infoContentBorder: '#d1dcea',
  warningContentBackground: '#f3eaea',
  warningContentBorder: '#ead1d1',
  statusInfo: '#fcf7e4',
  statusInfoBorder: '#faf0ce',
  statusInfoText: '#876d40',
  statusInfoDone: '#eaeef3',
  statusInfoDoneBorder: '#d1dcea',

  logo: '#5ac37d',
  menuTransparentPart: 'rgba(0, 0, 0, 0.36)',
  menuClosedTransparentPart: 'rgba(0, 0, 0, 0)',
  linkColor: '#4373b5',
  linkHover: ' #719bd6',
  calendarSelectedDate: '#377a6c',
  calendarEmptyingDate: '#9ABCB5'
};

export default colors;
