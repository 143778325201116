import { FC } from 'react';
import { Container, Grid, Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material';

const PermitApplicationSkeleton: FC = () => {
  const theme = useTheme();
  const largerThanMd = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Container>
      <Grid container direction={'row'}>
        <Grid item xs={4} md={8} lg={12}>
          <Stack direction='column' spacing={1}>
            {[...Array(4)].map((_x, idx) => {
              return largerThanMd ? (
                <Skeleton key={'skeleton-' + idx} variant='rectangular' height={58} width={'100%'} />
              ) : (
                <Skeleton key={'skeleton-' + idx} variant='rectangular' height={100} width={'100%'} />
              );
            })}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PermitApplicationSkeleton;
