import { useState, useContext } from 'react';
import { Stack, Button, FormControlLabel, Radio, Collapse } from '@mui/material';
import { LanguageContext } from '../../../../contexts/language-context';

type WasteTypesCollapsableProps = {
  wasteItems: string[];
  selectedValue?: string;
};

const CONSTANTS = {
  MINIMUM_DISPLAYED_ITEMS: 8,
};

const WasteTypesCollapsable = ({ wasteItems, selectedValue }: WasteTypesCollapsableProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { getText } = useContext(LanguageContext);

  const displayItems = wasteItems.slice(0, CONSTANTS.MINIMUM_DISPLAYED_ITEMS);

  return (
    <>
      {displayItems.map((item: string, index: number) => (
        <FormControlLabel key={index} control={<Radio checked={item === selectedValue} />} label={item} value={item} />
      ))}

      <Collapse in={isOpen}>
        <Stack direction='column'>
          {wasteItems.slice(CONSTANTS.MINIMUM_DISPLAYED_ITEMS).map((item: string, index: number) => (
            <FormControlLabel
              key={index + CONSTANTS.MINIMUM_DISPLAYED_ITEMS}
              control={<Radio checked={item === selectedValue} />}
              label={item}
              value={item}
            />
          ))}
        </Stack>
      </Collapse>
      {wasteItems.length > CONSTANTS.MINIMUM_DISPLAYED_ITEMS && (
        <Button onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? getText('order-product-show-less') : getText('order-product-show-more')}
        </Button>
      )}
    </>
  );
};

export default WasteTypesCollapsable;
