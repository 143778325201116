import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../../../../contexts/language-context';
import { BillingInfoFormData, WIDTH_DESKTOP, WIDTH_MOBILE } from '../models';
import { LargerThanBreakpoint } from '../../../../util/viewportUtils';
import { BillingInfo } from '../../../../model';
import DropdownSelect from '../../../../components/common-materialui/DropdownSelect';
import { UseFormReset } from 'react-hook-form';

type RadioOptions = 'exists' | 'new';

interface Props {
  formReset: UseFormReset<BillingInfoFormData>;
  billingInfos?: BillingInfo[];
  billingId: string;
  setBillingId: React.Dispatch<React.SetStateAction<string>>;
  setCustomerType: React.Dispatch<React.SetStateAction<'private' | 'company'>>;
}

const BillingExistingSection = ({
  formReset,
  billingInfos,
  billingId,
  setBillingId,
  setCustomerType,
}: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);
  const width = LargerThanBreakpoint('md') ? WIDTH_DESKTOP : WIDTH_MOBILE;
  const [radioValue, setRadioValue] = useState<RadioOptions>('exists');
  const [changeDisabled, setChangeDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (billingInfos && billingInfos.length > 0) {
      setBillingId(billingInfos[0].id);
      setRadioValue('exists');
      setCustomerType(billingInfos[0].isCompany ? 'company' : 'private');
      setChangeDisabled(false);
    } else {
      setBillingId('');
      setRadioValue('new');
      setChangeDisabled(true);
      formReset();
    }
  }, [billingInfos]);

  function onDropDownSelectChange(value: string) {
    if (billingInfos && billingInfos.length > 0) {
      const billingInfoIndex = billingInfos?.findIndex((info) => info.id === value);
      if (billingInfoIndex !== -1) {
        setCustomerType(billingInfos[billingInfoIndex].isCompany ? 'company' : 'private');
      }
    }

    setBillingId(value);
  }

  function onRadioChange(value: string) {
    if (value === 'new') {
      setBillingId('');
      setCustomerType('private');
      formReset();
    }
    if (value === 'exists' && billingInfos && billingInfos.length > 0) {
      setBillingId(billingInfos[0].id);
      setCustomerType(billingInfos[0].isCompany ? 'company' : 'private');
    }
    setRadioValue(value as RadioOptions);
  }

  return (
    <Box width={width}>
      <FormControl fullWidth>
        <RadioGroup
          aria-labelledby='radio-group-for-selecting-private-or-company'
          value={radioValue}
          onChange={(e) => onRadioChange(e.target.value)}
        >
          <FormControlLabel
            value='exists'
            control={<Radio disabled={changeDisabled} />}
            label={getText('billing-info-existing-exists')}
          />
          {billingInfos && radioValue === 'exists' ? (
            <DropdownSelect
              label={getText('billing-info-existing-selector-dropdown-label')}
              items={billingInfos.map((info) => {
                return {
                  value: info.id,
                  text: `${info.name}, ${info.address.street}, ${info.address.postOffice}`,
                };
              })}
              value={billingId}
              onChange={(e) => onDropDownSelectChange(e.target.value as string)}
            />
          ) : null}

          <FormControlLabel value='new' control={<Radio />} label={getText('billing-info-existing-new')} />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default BillingExistingSection;
