import { useContext } from 'react';
import { addDays } from 'date-fns';
import { Controller, FieldError, FieldValues, UseControllerProps } from 'react-hook-form';
import { Box } from '@mui/material';
import FormErrorField from './FormErrorField';
import DateInput from '../../common-materialui/form/DateInput';
import { Validation, validateInput } from '../../common/input/validation';
import { LanguageContext } from '../../../contexts/language-context';
import { UiTexts } from '../../../model';

interface Props<T extends FieldValues> extends UseControllerProps<T> {
  error: FieldError | undefined;
  validations: Validation[];
  minDate?: Date;
  maxDate?: Date;
  placeholder?: string;
  label?: keyof UiTexts;
  onDateChange?: (event: any) => void;
}

const ControlDateInput = <T extends FieldValues>({
  control,
  name,
  error,
  validations,
  minDate,
  maxDate,
  placeholder,
  label,
  onDateChange,
}: Props<T>) => {
  const { getText } = useContext(LanguageContext);
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: { validateFunc: (v) => validateInput(v, validations)[0] },
      }}
      render={({ field: { onChange, value } }) => (
        <>
          <Box mb={1} mt={0.5}>
            {label && <label>{getText(label)}</label>}
            <DateInput
              date={value}
              onChange={(e) => {
                onChange(e);
                onDateChange && onDateChange(e);
              }}
              minDate={minDate || addDays(new Date(), 1)}
              maxDate={maxDate}
              placeholderText={placeholder}
            />
          </Box>
          <FormErrorField errorMsg={error?.message} />
        </>
      )}
    />
  );
};

export default ControlDateInput;
