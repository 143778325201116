import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

interface ExpandMoreButtonProps {
  isExpanded: boolean;
  onClick: () => void;
}

interface ExpandMoreProps extends IconButtonProps {
  isExpanded: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { isExpanded, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, isExpanded }) => ({
  transform: !isExpanded ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ExpandMoreButton = ({ isExpanded, onClick }: ExpandMoreButtonProps) => {
  return (
    <ExpandMore
      isExpanded={isExpanded}
      onClick={onClick}
      aria-expanded={isExpanded}
      aria-label='show more'
    >
      <ExpandMoreIcon />
    </ExpandMore>
  );
};

export default ExpandMoreButton;
