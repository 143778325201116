import React, { useContext, useEffect, useState } from 'react';
import PermitApplicationCard from './PermitApplicationCard';
import PermitApplicationTable from './PermitApplicationTable';
import { PermitItemInfo } from '../../model';
import DialogContext from '../../contexts/dialog-context';
import ApiContext from '../../contexts/api-context';
import { LargerThanBreakpoint } from '../../util/viewportUtils';

type sortOrder = {
  [key: string]: number;
};

type Props = {
  infos: PermitItemInfo[];
};

const PermitApp = ({ infos }: Props): JSX.Element => {
  const showDialog = useContext(DialogContext);
  const api = useContext(ApiContext);
  const largerThanPhone = LargerThanBreakpoint('lg');
  const [valid, setValid] = useState<boolean>(false);
  const [isSorted, setIsSorted] = useState<boolean>(false);

  useEffect(() => {
    validateInfos();
  }, [infos]);

  useEffect(() => {
    if (valid) {
      sortItems();
      setIsSorted(true);
    }
  }, [valid]);

  const fetchPdf = (id: string) => {
    api
      .fetchPermitDecisionPdf(id)
      .then((pdf) => {
        const url = URL.createObjectURL(pdf);
        window.open(url, '_blank', 'noopener,noreferrer');
      })
      .catch((_err: any) => {
        showDialog('error-general-title', 'error-fetching-file');
      });
  };

  const validateInfos = () => {
    setValid(true);

    if (infos == null || infos == undefined || infos.length <= 0 || !Array.isArray(infos)) {
      setValid(false);
    }
  };

  const statusOrder: sortOrder = {
    Expired: 4,
    Ready: 3,
    InProcess: 2,
    Sent: 1,
  };

  // Should first sort by date, then by status
  const sortItems = () => {
    infos = infos.sort(
      (a, b) => b.sentDate.getTime() - a.sentDate.getTime() || statusOrder[a.status] - statusOrder[b.status]
    );
  };

  const handleStatus = (status: string) => {
    if (status === 'Ready') {
      return true;
    }
    return false;
  };

  return valid && isSorted ? (
    <React.Fragment>
      {largerThanPhone ? (
        <PermitApplicationTable infos={infos} handleStatus={handleStatus} fetchPdf={fetchPdf} />
      ) : (
        <PermitApplicationCard infos={infos} handleStatus={handleStatus} fetchPdf={fetchPdf} />
      )}
    </React.Fragment>
  ) : (
    <React.Fragment />
  );
};

export default PermitApp;
