import { Container, Grid, Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';

const ContentSkeleton: FC = () => {
  const theme = useTheme();
  const largerThanPhone = useMediaQuery(theme.breakpoints.up('md'));
  const largerThanMd = useMediaQuery(theme.breakpoints.up('lg'));
  const contentDirection = largerThanMd ? 'row' : 'column';
  const infoBoxesMarginTop = largerThanMd ? 0 : 1;
  const contactAndBillingInfoDirection = largerThanPhone && !largerThanMd ? 'row' : 'column';

  return (
    <Container>
      <Grid container direction={contentDirection}>
        <Grid item xs={4} md={8}>
          <Stack direction='column' spacing={1}>
            {[...Array(4)].map((_x, idx) => (
              <Skeleton key={'skeleton-' + idx} variant='rectangular' height={58} width={'100%'} />
            ))}
          </Stack>
        </Grid>
        <Grid item xs={4} md={8} lg={4} mt={infoBoxesMarginTop}>
          <Grid container direction={contactAndBillingInfoDirection}>
            <Grid item xs={4}>
              <Skeleton variant='rectangular' height={128} width={'100%'}></Skeleton>
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant='rectangular' height={198} width={'100%'}></Skeleton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContentSkeleton;