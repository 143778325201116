import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { useMutation } from '@tanstack/react-query';
import { PriceCalculationRequest, PriceCalculationResult, VingoProduct } from '../../../../model';
import { useMediaQuery, useTheme } from '@mui/material';
import ApiContext from '../../../../contexts/api-context';
import PriceTable from '../../../../components/common-materialui/price-table/PriceTable';
import PriceCard from '../../../../components/common-materialui/price-table/PriceCard';

interface OrderProductPriceProps {
  price: PriceCalculationResult | undefined;
  productName: string;
  containerSizeSelected?: VingoProduct | null;
  currentNumberOfContainers: number;
  emptyingId: string;
}

interface AdditionalRowRenderProps {
  name: string;
  eventType: string;
  destination: string;
  price?: PriceCalculationResult;
}

const OrderProductPrice = ({
  price,
  productName,
  containerSizeSelected,
  currentNumberOfContainers,
  emptyingId,
}: OrderProductPriceProps) => {
  const { breakpoints } = useTheme();
  const largerThanPhone = useMediaQuery(breakpoints.up('md'));
  const { calculatePrice } = useContext(ApiContext);

  const [additionalProductCalculations, setAdditionalProductCalculations] = useState<AdditionalRowRenderProps[]>([]);

  const { mutateAsync: calculatePriceMutationAsync } = useMutation(
    (data: {
      newPriceCalculationRequest: PriceCalculationRequest;
      name: string;
      eventType: string;
      destination: string;
    }) => calculatePrice(data.newPriceCalculationRequest),
    {
      onError: (_err) => {
        //console.log(_err);
      },
      onSuccess: (response, data) => {
        const newData: AdditionalRowRenderProps = {
          name: data.name,
          eventType: data.eventType,
          destination: data.destination,
          price: response,
        };
        setAdditionalProductCalculations((prev) => [...prev, newData]);
      },
    }
  );

  // Additional products of type/destination Order can be skipped since they won't be shown anyways
  // For now only other option in destination is Contract
  const calculateAdditionalProducts = useCallback(
    async (containerSizeSelected: VingoProduct | null | undefined, currentNumberOfContainers: number) => {
      setAdditionalProductCalculations([]);
      if (containerSizeSelected?.additionalProducts) {
        containerSizeSelected.additionalProducts.forEach(async (product) => {
          if (product.destination === 'Contract') {
            const newPriceCalculationRequest: PriceCalculationRequest = {
              productGuid: product.id,
              customerId: emptyingId,
              amount: Number(currentNumberOfContainers),
              weight: 1,
            };
            try {
              await calculatePriceMutationAsync({
                newPriceCalculationRequest: newPriceCalculationRequest,
                name: product.name,
                eventType: product.eventType,
                destination: product.destination,
              });
            } catch (_err) {
              //console.log('Error', _err);
            }
          }
        });
      }
    },
    [calculatePriceMutationAsync, emptyingId]
  );

  // Debounce the calculation of additional products
  // Incase you change currentNumberOfContainers multiple times in a row it will only calculate once
  const debouncedCalculation = useRef(
    debounce(
      (containerSizeSelected, currentNumberOfContainers) =>
        calculateAdditionalProducts(containerSizeSelected, currentNumberOfContainers),
      500
    )
  );

  useEffect(() => {
    debouncedCalculation.current(containerSizeSelected, currentNumberOfContainers);
  }, [debouncedCalculation, containerSizeSelected, currentNumberOfContainers]);

  // If data is missing or invalid return null
  if (!price || currentNumberOfContainers < 0) {
    return null;
  }

  return largerThanPhone ? (
    <PriceTable
      price={price}
      additionalProductCalculations={additionalProductCalculations}
      productName={productName}
      currentNumberOfContainers={currentNumberOfContainers}
    />
  ) : (
    <PriceCard
      price={price}
      additionalProductCalculations={additionalProductCalculations}
      productName={productName}
      currentNumberOfContainers={currentNumberOfContainers}
    />
  );
};

export default OrderProductPrice;
